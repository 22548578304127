import { Text, View ,ScrollView } from 'react-native'
import React, { useState} from 'react'
import { Whisper,Message , Button, IconButton, Table , List} from 'rsuite';
import { Icon,  } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomText from '../CustomText';


const ComponentListView = (props) => {

    const [loading, setLoading] = useState(false);
    const [bordered, setBordered] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [refresh , setRefresh] = useState(false);

    const toggle_refresh = () => {
        setRefresh(!refresh)

    }
    function handleModalClose(){
        setPopupActive(false);
    }

   
    function viewEdit(input){
      props.setchoosen_data(input);
      console.log(input);
      
    }
    function viewFile(input){
      props.chooseANDShow(input);
      console.log(input);
      
    }
    const ActionCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View style={{ height: '80%'  }}>
            <IconButton icon={<Icon><FontAwesomeIcon icon={"pen-to-square"} /></Icon>}
              onClick={() => {viewEdit(rowData)}}
            />
           
          </View>
        </Table.Cell>
    );



    const Overlay = React.forwardRef(({  content, ...rest }, ref) => {
      const styles = {
        color: '#000',
        background: '#fff',
        width: 400,
        padding: 10,
        borderRadius: 4,
        position: 'absolute',
        border: '1px solid #ddd',
        boxShadow: '0 3px 6px -2px rgba(0, 0, 0, 0.6)'
      };
    
      return (
        <View  style={styles} ref={ref}>
            <Text>
                {content}
            </Text>
        </View>
      );
    });

    const ActionCellNotes = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View>  
              <Whisper
                  style={{width: 10}}
                  trigger="click"
                  placement={'top'}
                  controlId={`control_id` + rowData["NO"]}
                  preventOverflow
                  speaker={
                      <Overlay content={rowData["NOTLAR"]} />

                  }
                >
                  {rowData["NOTLAR"].length > 0 ?  <IconButton icon={<Icon><FontAwesomeIcon icon={'book-open'} /></Icon>} /> :  <IconButton icon={<Icon><FontAwesomeIcon icon={'square'} /></Icon>} /> }
                
                </Whisper>
          </View>
        </Table.Cell>
    );

    const ActionCellView = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
        <View style={{ height: '80%'  }}>
          {(rowData["S3_KEY"] != null && rowData["S3_KEY"] != "") ?  <IconButton icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}
            onClick={() => {viewFile(rowData);}}
          />
         :  <IconButton icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}
            onClick={
            () => {
              Message.error("Dosya bulunamadı");
            }
          }
          />}
        </View>
      </Table.Cell>
  );

  var currency_symbol = "₺"
    var formatter = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    var kmformatter = new Intl.NumberFormat('tr-TR', {
      style: 'unit',
      unit: 'kilometer',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    function formatAmount(input){
      return formatter.format(input).replace("₺","₺ ")
    }


  function editRowData(input,dataKey){
    if(input[dataKey] == null || input[dataKey] == undefined ) {
      return input[dataKey];
    }
    else if(dataKey.includes("TARİH")){
      return reverseDate(input[dataKey]);
    }
    else if(dataKey.includes("TUTAR")){
        return formatAmount(input[dataKey])
    }
    else if(dataKey.includes("KM")){
      return kmformatter.format(input[dataKey])
  }
    else{
      return input[dataKey];
    }
  }

  function reverseDate(input){
    var result = "";  
    
    var comps = input.split("-");

    for(var c = 2 ; c >= 0 ; c--){
      for(var i = 0 ; i < comps[c].length  ; i++){
        result += comps[c][i];
    }
    c != 0 ? result += "-": result += "";
    }
   
    return result;
}

    
    function parseData(item,column){
      if(column.includes("TARİH")){
        console.log("TARİH VAR");
        return reverseDate(item[column]);
      }
      else if(column.includes("TUTAR")){
          return formatAmount(item[column])
      }
      else if(column.includes("KM")){
        return kmformatter.format(item[column])
    }
      else{
        return item[column];
      }
    }

    return ( 

      <ScrollView style={{height: 600}}>
      <View style={{ backgroundColor: 'white', alignItems: 'center' }}>
            <List  style={{width: '100%'}} bordered>
                {props.data.map((item, index) => {
                  return(
                  <List.Item key={index} index={index}>
                    <View style={{justifyContent:'space-between',alignItems:'center', width: '100%',flexDirection: 'row'}}>
                      <View style={{justifyContent:'flex-start',alignItems:'center', flex: 1}}>
                          {
                              props.listHeads.map((column, index) => {
                                return(
                                  <View style= {{justifyContent:'flex-start',alignItems:'flex-start', width: '100%'}}>
                                    <CustomText style={{textAlign : 'left'}} fontSize= {14}>{column  + " : " + parseData(item,column)}</CustomText>
                                  </View>
                                )
                              }
                            )
                          }
                          <View style= {{justifyContent:'flex-start',alignItems:'flex-start', width: '100%', marginTop: 10}}>
                            <Whisper
                            trigger="click"
                            placement={'top'}
                            controlId={`control_id` + item["NO"]}
                            preventOverflow
                            speaker={
                                <Overlay content={item["NOTLAR"]} />

                            }
                            
                          >
                            { item["NOTLAR"].length > 0 ?  <IconButton icon={<Icon><FontAwesomeIcon icon={'book-open'} /></Icon>} > <CustomText>Notlar</CustomText> </IconButton> :  <IconButton icon={<Icon><FontAwesomeIcon icon={'square'} /></Icon>} ><CustomText>Notlar</CustomText></IconButton> }
                          
                          </Whisper>
                        </View>
                      </View>
                      <View style={{ paddingRight: 5 , justifyContent:'space-around',alignItems:'flex-end', flex: 1 ,flexDirection: 'column', borderRightWidth: 5 ,  borderRightColor : props.current == item ? '#FFC100' : '#F2F2F2'}}>
                            {
                          props.hasDoc && <IconButton icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}
                              onClick={() => {viewFile(item);}}
                              style={{width: 120}}
                            > <CustomText> Döküman </CustomText> </IconButton>
                            }
                          <View style={{height: 10}}></View>
                          <IconButton icon={<Icon><FontAwesomeIcon icon={"pen-to-square"} /></Icon>}
                            onClick={() => {viewEdit(item)}}
                            style={{width: 120}}

                          > <CustomText> Güncelle </CustomText> </IconButton>
                          <View style={{height: 10}}></View>
                          <Button
                            style={{width: 120}}
                            onClick={() => {props.showDetails(item)}}
                          >
                            <CustomText>Detaylar</CustomText>
                          </Button>

                      </View>
                    </View>
                  </List.Item>
                  )
                }
                )}
            </List>
        </View>
        </ScrollView>
  
    )
}

export default ComponentListView;


// <View style={{backgroundColor :'#fff', width: '95%' ,borderRadius: 7 }}>
// <Table
//         wordWrap="break-word"
//         loading={loading}
//         showHeader={showHeader}
//         sortColumn={sortColumn}
//         sortType={sortType}
//         onSortColumn={handleSortColumn}
//         data={getData()}
//         bordered={bordered}
//         cellBordered={bordered}
//         affixHeader
//         affixHorizontalScrollbar
//         autoHeight
//     >
//     <Table.Column width={60} sortable resizable>
//         <Table.HeaderCell> Düzenle </Table.HeaderCell>
//         <ActionCell dataKey= 'Edit'/>
//     </Table.Column>
//     <Table.Column width={60} sortable resizable>
//         <Table.HeaderCell> Notlar </Table.HeaderCell>
//         <ActionCellNotes dataKey= 'Notlar'/>
//     </Table.Column>
//     <Table.Column width={60} sortable resizable>
//         <Table.HeaderCell> Döküman </Table.HeaderCell>
//         <ActionCellView dataKey= 'Doc'/>
//     </Table.Column>
//     {columns.map(column => {
//         const { key, label, ...rest } = column;
//         return (
//             <Table.Column sortable resizable width = {getWidth(column)}>
//                 <Table.HeaderCell>{column}</Table.HeaderCell>
//                 <CustomCellView dataKey={column} />
//             </Table.Column>
//         );
//     })}
//     </Table>
//     <Pagination
//         prev
//         next
//         first
//         last
//         ellipsis
//         boundaryLinks
//         maxButtons={5}
//         size="xs"
//         layout={['total', '-', 'limit', '|', 'pager', 'skip']}
//         total={props.data.length}
//         limitOptions={[10, 20]}
//         limit={limit}
//         activePage={page}
//         onChangePage={setPage}
//         onChangeLimit={handleChangeLimit}
//         locale={{
//             more: 'Daha',
//             prev: 'Önceki',
//             next: 'Sonraki',
//             first: 'İlk',
//             last: 'Son',
//             limit: '{0} / sayfa',
//             total: 'Toplam Satır: {0}',
//             skip: 'Git {0}'
//         }}
//         />
// </View>

