import { View, Text ,StyleSheet} from 'react-native';
import { useEffect , useState } from 'react';
import { Button , MultiCascader ,Form,List,SelectPicker, IconButton, Input} from 'rsuite';
import axios from 'axios';
import configdata from '../../config/config.json'
import formatter from "../../utils/formatter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import {useDropdownData} from "../../hooks/useDropdownData";




const BMPScreen = () => {

    const [BMPData,setBMPData] = useState([]);
    const {getBrands} = useDropdownData();
    const [choosenBrand,setChoosenBrand] = useState(undefined);

    useEffect(() => {
        getBrands(setBMPData);
    },[])

    function reset(){
        getBrands(setBMPData);
    }

    useEffect(() => {
        console.log(BMPData);
        if(choosenBrand == undefined)
        {
            if(BMPData.length > 0) setChoosenBrand(BMPData[0])
        }else{
            setChoosenBrand(BMPData.find((item) => {return item.id == choosenBrand.id}) != undefined ? BMPData.find((item) => {return item.id == choosenBrand.id}) :  BMPData.length > 0 &&  BMPData[0]);
        }
        
    },[BMPData])

    function sendData(){
        const api = configdata.API_BASE + '/uidropdownlist';
        axios.post(api,{
          "ENV" : configdata.APP_ENV,
            "type" : "SET_NEW",
            "category": "BRANDS",
            "value" : newBrand,
        })
        .then((response) => {
          console.log(response);
          reset();
        })
        .catch((error) => {
          console.log("POST ERROR" + error);
          
        });
    }


    const [newBrand,setNewBrand] = useState(undefined);
    return (
        <View style={{backgroundColor: 'white', padding: 20, flex: 1}}>
            <View style={[styles.cardView,{height: 100, justifyContent: 'center'}]}>
                <View style ={{justifyContent: 'center', flexDirection: 'row', alignItems :'center'}}>
                    <Text style={{fontSize: 12}} >{"Yeni Marka"}</Text>
                    <Input placeholder="Marka Adı" style={{width: 200, margin: 10}} onChange={setNewBrand} value={newBrand}></Input>
                    <Button appearance="primary" style={{margin: 10}} onClick={() => {sendData();}}>Ekle</Button>
                </View>
            </View>
            <SelectPicker data={BMPData} onSelect={(value,item,event) => {setChoosenBrand(item)}} value={choosenBrand != undefined ? choosenBrand.value: ""} ></SelectPicker>
            {
            choosenBrand != undefined &&
                <BrandComponent BRAND={choosenBrand} data={choosenBrand.children} reset={reset} ></BrandComponent>
            }

           
        </View>
    );
}


const BrandComponent = (props) => {
    const [choosenModel,setChoosenModel] = useState(undefined);
    const [chooseToEdit,setChooseToEdit] = useState(undefined);

    useEffect(() => {
        if(choosenModel == undefined)
        {
            if(props.data.length > 0) setChoosenModel(props.data[0])
            
        }else{
            setChoosenModel(props.data.find((item) => {return item.id == choosenModel.id}) != undefined ? props.data.find((item) =>{
             return item.id == choosenModel.id}) : props.data.length > 0 ? props.data[0] : undefined);
        }
        
    },[props.data])

    function toggleEdit(item){
        if(chooseToEdit == undefined){
            setChooseToEdit(item);
        }else{
            if(chooseToEdit.id == item.id){
                setChooseToEdit(undefined);
            }else{
                setChooseToEdit(item);
            }
        }
    }

    function reset(){
        setChooseToEdit(undefined);
        props.reset();
    }

    
    return(
        <View style={[styles.cardView,{flex: 1}]}>
            <View style = {styles.headerLabel}>
                {
                    chooseToEdit != undefined && chooseToEdit.id == props.BRAND.id &&
                    <NewComponentLabel header={"Marka"} type="UPDATE" category="BRANDS" id={props.BRAND.id} parentID={undefined} reset={reset}></NewComponentLabel>
                }
                <Text style = {{fontWeight: 'bold' , color :'white', marginHorizontal: 15}}>{props.BRAND.label}</Text>
                <IconButton icon={<Icon><FontAwesomeIcon icon={"edit"}></FontAwesomeIcon></Icon>} onClick={() => { toggleEdit(props.BRAND)}} />
            </View>
            <View style={{flexDirection: 'row', flex: 1, width: '100%'}}>
                <View style={{flex: 1}}>
                    <View>
                            <NewComponentLabel header={"Model"} type={"NEW"} category="MODELS" parentID={props.BRAND.id} reset={reset}></NewComponentLabel>
                    </View>
                    <Text style={{fontWeight: 'bold',marginBottom: 10}}>Modeller</Text>
                    <List bordered style={{height: 500}} hover>
                        {props.data.map((item,index) => {
                            return(
                                <List.Item key={index} index={index} style={{padding: 10}}>
                                    <View style={{flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center' , height: 40}}>
                                        {
                                            chooseToEdit != undefined && chooseToEdit.id == item.id &&
                                            <NewComponentLabel header={"Model"} type="UPDATE" id={item.id} category="MODELS" parentID={props.id} reset={reset}></NewComponentLabel>
                                        }
                                        <Text>{item.label}</Text>
                                        <View style={{flexDirection: 'row'}}>
                                            <IconButton icon={<Icon><FontAwesomeIcon icon={"edit"}></FontAwesomeIcon></Icon>} onClick={() => {toggleEdit(item)}} />
                                            <View style={{width: 10}}></View>
                                            <IconButton icon={<Icon><FontAwesomeIcon icon={"arrow-right"}></FontAwesomeIcon></Icon>} onClick={() => {setChoosenModel(item)}} />
                                        </View>

                                    </View>
                                </List.Item>
                            )
                        })}
                    </List>

                </View>
                <View style={{width: 3, borderRadius: 36, height: '100%',marginHorizontal: 5}}></View>
                {
                    choosenModel != undefined &&

                    
                        <View style={{flex: 1}}>
                        <View>
                                <NewComponentLabel type={"NEW"} header={"Paket"} category="PACKAGES" parentID={choosenModel.id} reset={reset}></NewComponentLabel>
                        </View>
                        <Text style={{fontWeight: 'bold', marginBottom: 10}}>{choosenModel.label + "  Paketleri"} </Text>
                        <List bordered style={{height: 500}} hover>
                            {choosenModel.children.map((item,index) => {
                                return(
                                    <List.Item key={index} index={index} style={{padding: 10}}>
                                        <View style={{flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center', height: 40}}>
                                            {
                                                chooseToEdit != undefined && chooseToEdit.id == item.id &&
                                                <NewComponentLabel header={"Paket"} type="UPDATE" id={item.id} category="PACKAGES" parentID={choosenModel.id} reset={reset}></NewComponentLabel>
                                            }
    
                                            <Text>{item.label}</Text>
                                            <IconButton icon={<Icon><FontAwesomeIcon icon={"edit"}></FontAwesomeIcon></Icon>} onClick={() => {toggleEdit(item)}} />
                                        </View>
                                    </List.Item>
                                )
                            })}
                        </List>

                    </View>
                    
                }


            </View>
            <View style={{height: 20, borderRadius: 36, width: '100%',marginVertical: 5}}></View>

            <View style={{flex: 1, width: '100%'}}>
                    <Text style={{fontWeight: 'bold',marginBottom: 10, textAlign: 'center'}}> Marka Ekstra Donanım Özellikleri </Text>
                    <List bordered hover>
                        {props.BRAND.childrenExtras.map((item,index) => {
                            return(
                                <List.Item key={index} index={index} style={{padding: 10}}>
                                    <View style={{flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center' , height: 40}}>
                                        {
                                            chooseToEdit != undefined && chooseToEdit.id == item.id &&
                                            <NewComponentLabel type="UPDATE" id={item.id} category="CAR_EXTRAS" parentID={props.id} reset={reset}></NewComponentLabel>
                                        }
                                        <Text>{item.label}</Text>
                                        <IconButton icon={<Icon><FontAwesomeIcon icon={"edit"}></FontAwesomeIcon></Icon>} onClick={() => {toggleEdit(item)}} />
                                    </View>
                                </List.Item>
                            )
                        })}
                    </List>
                    <View>
                            <NewComponentLabel header={"Marka Ekstra Donanım"} type={"NEW"} category="CAR_EXTRAS" parentID={props.BRAND.id} reset={reset}></NewComponentLabel>
                    </View>
                </View>
        </View>
    )
}


const NewComponentLabel = (props) => {

    const [value,setValue] = useState(undefined);

    function sendData(){
        const api = configdata.API_BASE + '/uidropdownlist';

        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : props.type == "UPDATE" ? "SET_OLD" : "SET_NEW",
            "category": props.category,
            "value" : value,
            "İSİM" : value,
            "parentID": props.parentID,
            "IS_ACTIVE" : 1,
            "ID" : props.type == "UPDATE" ? props.id :  ""
        }
        console.log(body);
        axios.post(api,body).then((response) => {
            console.log(response);
            props.reset();
        }).catch((error) => {
            console.log("POST ERROR" + error);

        });
    }

    return(
        <View style={ props.type == "NEW" ? [styles.cardView,{height: 100, justifyContent: 'center'}] : {justifyContent: 'center'}}>
            <View style ={{justifyContent: 'center', flexDirection: 'row', alignItems :'center'}}>
                <Input placeholder="Yeni Değer" style={{width: 200, margin: 10}} onChange={setValue} value={value}></Input>
                <Button appearance="primary" style={{margin: 10}} onClick={() => {sendData();}}>{props.type == 'UPDATE' ? "Güncelle" : props.header +  " Ekle"}</Button>
            </View>
        </View>

    )
}



const styles = StyleSheet.create({
    cardView: {
        justifyContent : 'flex-start', //Centered vertically
        alignItems: 'center',
        margin: 10,
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 12,
        shadowColor: '#FFB6C1',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 12,
        elevation: 5,
    },
    headerLabel: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'powderblue',
        padding: 10,
        borderRadius: 12,
        margin: 10,
    }
}
);



export default BMPScreen;