import { Text, View , StyleSheet,ActivityIndicator, Image, TouchableOpacity,TextInput,ScrollView,Modal } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { useToaster,Schema,Message, Button, Form ,SelectPicker, Steps ,MaskedInput,Tabs,IconButton, Table,Input ,InputNumber, RadioGroup,ButtonGroup ,Radio, InputGroup, TagPicker, DateRangePicker, DatePicker, Panel, Stack, Grid, Row, Col  } from 'rsuite';
import PhoneInput from 'react-phone-input-2'
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-phone-input-2/lib/style.css'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../../aws-exports';
import { CarouselItem } from 'react-bootstrap';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import locale from '../../../tr';
import ExportData from '../../../Components/TableManagement/ExportData';
import FileLoaderInline from '../../../Components/FileManagements/FileLoaderInline';
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { useDropdownData } from '../../../hooks/useDropdownData';
import CustomText from '../../../Components/CustomText';
import NewCustomerScreen from '../CustomerManagement/NewCustomerScreen';


const NewRentAggreementDrawer = (props) => {


    const {get_curent_user} = useUserToken();
    
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }


    function reset(){

    }
    const {kdvOranları,getDropDownValues} = useDropdownData()

    useEffect(()=>{
        getCustomers();
        CreateCarLabels();
        getDropDownValues("RENT_TYPES",setRentTypes);
    },[])



    const [customers,setCustomers] = useState([])
    const [CarsDataDDown,setCarsDataDDown] = useState([]);
    const [rentTypes,setRentTypes] = useState([]);

    const [rentType,setRentType] = useState(0);
    const [customer,setCustomer] = useState(0);
    const [cars,setCars] = useState([]);
    const [carsWithPrices,setCarsWithPrices] = useState([]);
    const [dateRange,setDateRange] = useState("");
    const [customerLabel,setCustomerLabel] = useState("Müşteri Seçilmedi");
    const [carLabels,setCarLabels] = useState({});
    const [carLabel,setCarLabel] = useState("Araç Seçilmedi");
    const [dateRangeLabel,setDateRangeLabel] = useState("Dönem Seçilmedi");
    
    const [kdvRate,setKdvRate] = useState(20);
    const [kdvExcAmt,setKdvExcAmt] = useState(0,0);
    const [kdvExcAmtLabel,setKdvExcAmtLabel] = useState(0);
    const [kdvAmt,setKdvAmt] = useState(0,0);
    const [kdvAmtLabel,setKdvAmtLabel] = useState(0,0);
    const [kdvIncAmt,setKdvIncAmt] = useState(0,0);
    const [kdvIncAmtLabel,setKdvIncAmtLabel] = useState(0,0);
    const [installmentCount,setInstallmentCount] = useState(1);
    const [firstInstalmentDay,setFirstInstalmentDay] = useState("");
    const [instalmentTableData,setInstallmentTableData] = useState([]);
    const [aggrementName,setAggrementName] = useState("");
    const [notes,setnotes] = useState("");
    const [S3Key,setS3Key] = useState(undefined);
    const [monthDif,setMonthDif] = useState(0);
    const [dayDif,setDayDif] = useState(0);
    const fileUploaderRef= useRef();

    const paymentPeriods = [
        {label : "GÜNLÜK" , value : "GÜNLÜK"},
        {label : "AYLIK" , value : "AYLIK"},
    ]

    const [paymentPeriod,setPaymentPeriod] = useState("GÜNLÜK");
    const [rentTime,setRentTime] = useState(0);
    const [newCustomerToggle,setNewCustomerToggle] = useState(false);


    //#region  Calculations

    useEffect(() => {
        if(installmentCount > 0){
            calcInstallmentTable();
        }
        
    },[installmentCount])
    useEffect(() => {
        if(installmentCount > 0){
            calcInstallmentTable();
        }
        
    },[firstInstalmentDay])

    useEffect(() => {
        console.log(kdvExcAmt)
        var value = (kdvExcAmt * kdvRate / 100) + "";
        if(value.includes('.')){
            value = value.replace('.',',')
        }
        setKdvAmtLabel(value)
        setKdvAmt(kdvExcAmt * kdvRate / 100)
    },[kdvExcAmt])

    useEffect(() => {
        console.log("KDV RATE" + kdvRate);
        console.log(kdvExcAmt)
        var value = (kdvExcAmt * kdvRate / 100) + "";
        if(value.includes('.')){
            value = value.replace('.',',')
        }
        setKdvAmtLabel(value)
        setKdvAmt(kdvExcAmt * kdvRate / 100)
        changeVATCars();
        // setKdvAmtLabel(kdvExcAmt * kdvRate / 100)
    },[kdvRate])



    useEffect(() => {
        // console.log(parseFloat(kdvAmt) + parseFloat(kdvExcAmt))
        setKdvIncAmt(parseFloat(kdvAmt) + parseFloat(kdvExcAmt))
        var value = (parseFloat(kdvAmt) + parseFloat(kdvExcAmt)) + "";
        if(value.includes('.')){
            value = value.replace('.',',')
        }
        setKdvIncAmtLabel(value)
    },[kdvAmt])

    useEffect(() => {

        calcInstallmentTable();
        
    },[kdvIncAmt])

    function CreateCarLabels(){
        let resultObj = {};
        let result = [];
        props.cars.forEach((element) => {
            resultObj[element.NO] = {
                "label": element["PLAKA"],
            }
            let periodPrice =  element["PERIOD_PRICE"] != undefined ? element["PERIOD_PRICE"] : 0;
            let periodPriceVat = periodPrice + periodPrice * 20 / 100;
            let kdvExc = periodPrice * rentTime;
            let kdvIncAmt = kdvExc * 20 / 100 + kdvExc;

            result.push(
                {
                        ID: element["NO"] , PLAKA: element["PLAKA"] , ARAÇ: element["ARAÇ"],calcType: "AYLIK",periodPrice : periodPrice,periodPriceVat: periodPriceVat,kdvExc : kdvExc , kdvIncAmt: kdvIncAmt , kdv: 20
                }
            )
        })
        console.log(resultObj);
        console.log(result);
        setCarLabels(resultObj);
        setCars(result);
    }

    function addMonths(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }
    function calcInstallmentTable (){
        var result = [];
        for(var i = 1 ; i <= installmentCount ; i++ ){
            result.push({
                "Taksit" : i,
                "Ödeme Tarihi" : addMonths(new Date(firstInstalmentDay),i - 1),
                "KDV Hariç Tutar" : kdvExcAmt / installmentCount,
                "KDV Dahil Tutar" : kdvIncAmt / installmentCount,
            })
        }
        
        setInstallmentTableData(result);
    }

    useEffect(()=>{
        console.log(cars);
        calcKdvExc();
        
    },[cars])

    function calcKdvExc(){
        var result = 0;
        console.log("CALC KDV EXC");
        console.log(cars);
        cars.forEach((element)=>{
            result += element["kdvExc"];
        })
        setKdvExcAmt(result);
        setKdvExcAmtLabel(result);
    }

    function changeVATCars(){
        cars.forEach((element)=>{
            element["kdv"] = kdvRate;
            element["kdvIncAmt"] = element["kdvExc"] * kdvRate / 100 + element["kdvExc"];
        })
    }

    useEffect(()=>{
        if(dateRange != ""){
            var dateF = new Date(dateRange[0])
            var dateL = new Date(dateRange[1])
            // console.log(dateRange);
            // console.log(dateF.toLocaleDateString("en-GB"))

            setDateRangeLabel(dateF.toLocaleDateString("en-GB") + " - " + dateL.toLocaleDateString("en-GB"))
        }
    },[dateRange])

    function getCustomers(){
        var result = []
        const api = configdata.API_BASE + '/customers'
        const header = {"type" : "GET_CLIENTS","ENV" :configdata.APP_ENV }
        axios.post(api,header).then((response) => {
            response.data.forEach((element) => {
            if(element["TYPE"] == "KURUMSAL"){
                result.push(
                    {
                        "label": element["NAME"],
                        "value": element["ID"],
                        "type": "FİRMA"
                    }
                )
            }else if(element["TYPE"] == "BİREYSEL"){
                result.push(
                    {
                        "label": element["PERSON_NAME"] + " " +element["PERSON_SURNAME"],
                        "value": element["ID"],
                        "type": "BİREYSEL MÜŞTERİ"
                    }
                )
            }
            })
            setCustomers(result);
        })
    }     

    //#endregion

    const {fetchIdToken} = useUserToken();

    async function sendData(){
    const api = configdata.API_BASE + '/rent-agreements';
        const attributes = await get_curent_user();
        const header = {
            "ENV" :configdata.APP_ENV ,
            "type" : "NEW_RENT_AGREEMENT",
            "CONTRACT_NAME": aggrementName ,
            "STATUS": "",
            "RENT_TYPE": rentType,
            "START_DATE": dateRange[0],
            "END_DATE": dateRange[1],
            "CLIENT_ID": customer,
            "VAT": kdvRate,
            "CONTRACT_AMT_VAT_EXC": kdvExcAmt,
            "CONTRACT_AMT_VAT_INC": kdvIncAmt,
            "INSTALLMENT_COUNT": installmentCount,
            "FIRST_INSTALLMENT_DATE": firstInstalmentDay,
            "AWS_USER_ID": attributes["custom:rds_user_id"],
            "CARS" : cars,
            "INSTALLMENTS": instalmentTableData,
            "RENT_TIME": rentTime,
            "RENT_TIME_TYPE": paymentPeriod,
            }
        console.log(header);

        axios.post(api,header).then((response) => {
            console.log(response.data);
            if(response.errorMessage != null){
                messageToggle("Sözleşme kaydı başarısız","error")
            }
            else{
                messageToggle("Sözleşme başarıyla kaydedildi","success")
                fileUploaderRef.current.load();
                reset();
            }
        }).catch((error) => {
            console.log(error)
            messageToggle("Sözleşme kaydı başarısız","error")
        })
    }

    const customersRef = useRef();


    function updateCustomers(){
        getCustomers();
        setNewCustomerToggle(false);
        customersRef.current.refresh();
    }
    


    return (
        <View style={{flex: 1,width: '100%', alignItems : 'center', justifyContent :'space-between',padding: 20, backgroundColor:'#F2F2F2'}}>

            <Tabs defaultActiveKey={0} style={{width: '100%'}} appearance="primary"  vertical>
                <Tabs.Item eventKey={0} title="Müşteri ve Dönem Seçimi" >
                <View style = {{width: '100%',marginTop: 10, padding: 5,justifyContent: 'flex-start', alignItems: 'flex-start',flexDirection: 'row'}}>
                    <View style={{flex: 1 ,marginVertical: 10,justifyContent: 'flex-start'}}>
                        <Group title = {"Müşteri Seçimi"}>
                             <InputGroup style={item_style.wide}>
                                    <SelectPicker ref={customersRef} value= {customer} onChange ={(value) => {
                                        setCustomer(value);
                                    }} 
                                    onSelect = {(value,item) => {
                                        console.log(item);
                                        setCustomerLabel(item.label)
                                    }}
                                    style= {item_style.wide} placeholder = "Müşteri Seç" groupBy = {"type"} data = {customers}> </SelectPicker>
                                    <Button onClick = {() => {setNewCustomerToggle(!newCustomerToggle)}} style={{backgroundColor: 'white', color: 'black', borderRadius: 5}}> + </Button>
                            </InputGroup>
                        </Group>
                        <Group title = {"Kira Tipi"}>
                            <SelectPicker style={item_style.wide} data={rentTypes} onChange={setRentType} value={rentType} ></SelectPicker>
                        </Group>
                        <Group title = {"Tarih Aralığı Seçimi"}>
                            <DateRangePicker 
                                value={dateRange}
                                onChange={(value) => {
                                    setDateRange(value);
                                }} 
                            style= {item_style.wide} placeholder = "Tarih Aralığı Seç">  </DateRangePicker>
                        </Group>
                        <Group title = {"Ödeme Hesaplama Birimi"}>
                            <SelectPicker style={item_style.wide} data={paymentPeriods} onChange={setPaymentPeriod} value={paymentPeriod}></SelectPicker>
                        </Group>
                        <Group title = {"Kiralama Süresi"}>
                            <InputNumber value={rentTime} style={item_style.standard} onChange={(value) => setRentTime(value)}></InputNumber>
                        </Group>
                    </View>
                        {
                            newCustomerToggle == true &&
                        <View style={{flex: 1 ,marginVertical: 10,justifyContent: 'flex-start'}}>
                            <NewCustomerScreen update={updateCustomers} inline ></NewCustomerScreen>
                        </View>
                        }

                    </View>
                </Tabs.Item>
                <Tabs.Item eventKey={1} title="Araçlar Ve Fiyatlar" >
                    <View style = {{width: '100%',marginTop: 10, padding: 5,justifyContent: 'space-evenly'}}>
                        <View style={{flex: 1 ,width: '100%',marginVertical: 10}}>
                            <CustomText fontWeight = {800} fontSize = {20}> Araçlar </CustomText>
                            <View style ={{height: 20}}></View>
                            <ComponentListViewRentCars  data={cars} setData={setCars} calcType={paymentPeriod} rentTime={rentTime} calcKdvExc={calcKdvExc} cars={CarsDataDDown} ></ComponentListViewRentCars>
                        </View>
                        <Panel style={{width: '100%',marginVertical: 3, backgroundColor:'white'}} shaded>
                                    

                        <Grid fluid  style={{width:'100%'}}>
                            <Row gutter={20}>
                                <Col xs={24} sm={24} md={6}>
                                    <Panel shaded bordered bodyFill style={{backgroundColor: 'white', justifyContent:'center', alignItems:'center', height: 110, padding: 10 , marginBottom: 20}} >
                                        <CustomText fontWeight = {800} fontSize = {14}> KDV Hariç Toplam </CustomText>
                                        <View style={{height: 10}}></View>
                                        <CustomText fontSize = {14}> {formatter.format(kdvExcAmt).replace("₺","₺ ")} </CustomText>
                                    </Panel>
                                </Col>
                                <Col xs={24} sm={24} md={4}>
                                    <Panel shaded bordered bodyFill style={{backgroundColor: 'white', justifyContent:'center', alignItems:'center', height: 110, padding: 10, marginBottom: 20}} >
                                        <CustomText fontWeight = {800} fontSize = {14}> KDV Oranı </CustomText>
                                        <View style={{height: 10}}></View>
                                        <SelectPicker value={kdvRate} style={item_style.standard} data= {kdvOranları} onChange={(value)=> {setKdvRate(value)}}></SelectPicker>
                                    </Panel>
                                </Col>  
                                <Col xs={24} sm={24} md={6}>
                                    <Panel shaded bordered bodyFill style={{backgroundColor: 'white', justifyContent:'center', alignItems:'center', height: 110, padding: 10, marginBottom: 20}} >
                                        <CustomText fontWeight = {800} fontSize = {14}> KDV Tutarı </CustomText>
                                        <View style={{height: 10}}></View>
                                        <CustomText  fontSize = {14}> {formatter.format(kdvAmt).replace("₺","₺ ")} </CustomText>
                                    </Panel>
                                </Col>

                                <Col xs={24} sm={24} md={6}>
                                    <Panel shaded bordered bodyFill style={{backgroundColor: 'white', justifyContent:'center', alignItems:'center', height: 110, padding: 10 , marginBottom: 20}} >
                                        <CustomText fontWeight = {800} fontSize = {14}> KDV Dahil Toplam </CustomText>
                                        <View style={{height: 10}}></View>
                                        <CustomText  fontSize = {14}> {formatter.format(kdvIncAmt).replace("₺","₺ ")} </CustomText>
                                    </Panel>
                                </Col>
                            </Row>

                        </Grid>
                        </Panel>
                    </View>
                </Tabs.Item>
                <Tabs.Item eventKey={2} title="Ödeme Planı" >
                    <View style = {{width: '100%',marginTop: 10, padding: 5,justifyContent: 'space-evenly'}}>
                    <Form>                           
                                <Form.Group style={{alignItems: 'center'}}>
                                    <Form.ControlLabel> Taksit Sayısı </Form.ControlLabel>
                                    <InputNumber style={item_style.standard} onChange={(value) => setInstallmentCount(value)}></InputNumber>
                                </Form.Group>
                                <Form.Group style={{alignItems: 'center'}}>
                                    <Form.ControlLabel> İlk Taksit Günü </Form.ControlLabel>
                                    <DatePicker placement = {'autoVerticalStart'} format="dd-MM-yyyy" locale={locale} oneTap onChange={(value) => setFirstInstalmentDay(value)} style={item_style.wide} ></DatePicker>
                            </Form.Group>
                        </Form>
                        <View>
                            <Text> Taksit Tablosu </Text>
                            <ComponentListViewRentInstallments tableName = {customerLabel + " -- TAKSİT TABLOSU " + dateRangeLabel} tableHead={["Taksit" , "Ödeme Tarihi",   "KDV Hariç Tutar" , "KDV Dahil Tutar" ]}  data={instalmentTableData}></ComponentListViewRentInstallments>
                    </View>
                    </View>
                </Tabs.Item>
                    
                <Tabs.Item eventKey={3} title="Sözleşme ve Notlar" >
                <View style = {{width: '100%',marginTop: 10, padding: 5,justifyContent: 'space-evenly'}}>
    
                    <Form>
                        <Form.Group>
                            <Form.ControlLabel> Sözleşme Adı </Form.ControlLabel>
                            <Form.Control style = {item_style.standard} onChange = {(value) => setAggrementName(value) } ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> NOTLAR </Form.ControlLabel>
                            <Input value={notes} as={"textarea"} onChange={ (value) => {setnotes(value);}} name= {"notes"}  rows={5}  /> 
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> Sözleşme Dökümanı yükle </Form.ControlLabel>
                            <FileLoaderInline ref = {fileUploaderRef} fileCategory = {"RENT_AGGREEMENT"} isMultiRelated = {true} ClientID = {customer} carID = {cars} setS3Value = {(value) => {setS3Key(value);}}></FileLoaderInline>
                        </Form.Group>
                        <Form.Group>
                            <Button style={item_style.buttonStyle} onClick= {() => { sendData();}} > Kaydet  </Button>
                        </Form.Group>
                    </Form>
                </View>
                </Tabs.Item>
            </Tabs>
        
        </View>
            
        )
}

const item_style = {
    standard: { width : 200 },
    wide: { width : 500 },
    viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10},
    buttonStyle:{
        height: 40 ,textAlign: 'center', marginHorizontal: 6, backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 100
        },
}

const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    maximumFractionDigits: 5,
});

const Group = ({ children, ...props }) => (
    <View style={{ marginBottom: 10 }} {...props}>
    <CustomText fontSize = {14} style={{marginBottom: 10}}>{props.title}</CustomText>
      {children}
    </View>
);


    //{id: element["ID"] , plaka: element["PLAKA"] , arac: element["ARAÇ"],calcType: "AYLIK",periodPrice : 0,periodPriceVat: 0,kdvExc : 0 , kdvIncAmt: 0 , kdv: 18}
const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {


    const [priceValue,setPriceValue] = useState(0);
    const [calcPrice,setCalcPrice] = useState(0);

    const inputStyle = {standard: {width : 150},}

    var formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    maximumFractionDigits: 5,
    allowDecimal: true,
    decimalLimit: 5

    });

    useEffect(() => {
        setPriceValue(rowData["periodPrice"]);
        setCalcPrice(rowData["periodPrice"]);
        rowData["periodPriceVat"] = rowData["periodPrice"] + rowData["periodPrice"] *rowData["kdv"] / 100 ;
        rowData["kdvExc"] = rowData["periodPrice"];
        rowData["kdvIncAmt"] = rowData["periodPrice"] * rowData["kdv"] / 100 + rowData["periodPrice"];
        onChange(rowData.id,dataKey,rowData)

    },[rowData])


    useEffect(() => {
        var result = priceValue;
                        var resultTemp = result;
                        result = result * props.rentTime;
                        rowData["periodPrice"] = resultTemp;
                        rowData["periodPriceVat"] = resultTemp + resultTemp *rowData["kdv"] / 100 ;
                        rowData["kdvExc"] = result;
                        rowData["kdvIncAmt"] = result * rowData["kdv"] / 100 + result;
                        
                        setCalcPrice(result);
                        onChange(rowData.id,dataKey,rowData)
    },[props.rentTime])

    function formatAmount(input){
        return formatter.format(input).replace("₺","₺ ")
    }
    const numberMaskTL = createNumberMask({
        prefix: '₺ ',
        thousandsSeparatorSymbol : '.',
        suffix: '' ,// This will put the dollar sign at the end, with a space.
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 5
    })
    return (
    <Table.Cell {...props} className={'table-content-editing'}>
        <View style = {{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <View style={{flexDirection: 'row'}}>
                <View style= {{width: 10}}></View>
                <MaskedInput
                style={inputStyle.standard}
                value={priceValue}
                guide= {false}
                mask={numberMaskTL}
                onChange={(value) => {
                    // setKdvExcAmt(parseFloat(value.replace(" TL","").replaceAll(".","").replace(',','.')))
                    // setKdvExcAmtLabel(value)
                    // setPriceValue(parseFloat(value.replace("₺ ","").replaceAll(".","").replace(',','.')))
                    setPriceValue(value.replace("₺ ","").replaceAll(".",",").replace(',','.'))
                    var result = parseFloat(value.replace("₺ ","").replaceAll(".","").replace(',','.'))
                    var resultTemp = result;

                    result = result * props.rentTime;
                    rowData["periodPrice"] = resultTemp;
                    rowData["periodPriceVat"] = resultTemp + resultTemp *rowData["kdv"] / 100 ;
                    rowData["kdvExc"] = result;
                    rowData["kdvIncAmt"] = result * rowData["kdv"] / 100 + result;
                    console.log(rowData);
                    setCalcPrice(result);
                    onChange(rowData.id,dataKey,rowData)
                }}
                defaultValue={0}
            />
                <View style= {{width: 10}}></View>
            
            </View>
            <CustomText style={{textAlign:'left',marginVertical :3 }}>{"Toplam =  " + formatAmount(calcPrice)}</CustomText>
            <View style= {{height: 10}}></View>
        </View>
    
        
    </Table.Cell>
    );
};

const ComponentListViewRentCars = (props) => {

    const [bordered, setBordered] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);
    


    const handleChange = (id, key, value) => {
        console.log("CHANGE");
        console.log(id);
        console.log(key);
        console.log(value);
        var result = props.data.map((item) => {
            if(item["ID"] == id){
                item[key] = value[key];
            }
            return item;
        })
        props.calcKdvExc();
        props.setData(result);
    }



    function getWidth(input){
        var max = input.length * 20;
        props.data.forEach(element => {
        var str = element[input] + "";
        if(str.length * 10 > max){
            max = str.length * 10;
        }
        
        })
        return max;
    }
    var currency_symbol = "₺"
    var formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        maximumFractionDigits: 5,
    });

    function formatAmount(input){
        return formatter.format(input).replace("₺","₺ ")
    }

    function editRowData(input,dataKey){
        if(dataKey.includes("Inc")){
            return formatAmount(input[dataKey])
        }
        if(dataKey.includes("Exc")){
            return formatAmount(input[dataKey])
        }
        return input[dataKey];
    }

    function reverseDate(input){
        var result = "";  
        
        var comps = input.split("-");

        for(var c = 2 ; c >= 0 ; c--){
        for(var i = 0 ; i < comps[c].length  ; i++){
            result += comps[c][i];
        }
        c != 0 ? result += "-": result += "";
        }
    
        return result;
    }
    const CustomCellView = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%', justifyContent: 'center', alignItems :'center' }}>
            <View style={{ height: '80%' , alignItems : 'center' , justifyContent: 'center' }}>
                <Text style={{textAlign: 'left'}}>{editRowData(rowData,dataKey)}</Text>
            </View>
        </Table.Cell>
    );

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    function getData(){
        if (sortColumn && sortType) {
            return props.data.sort((a, b) => {
              let x = a[sortColumn];
              let y = b[sortColumn];
              if (typeof x === 'string') {
                x = x.charCodeAt();
              }
              if (typeof y === 'string') {
                y = y.charCodeAt();
              }
              if (sortType === 'asc') {
                return x - y;
              } else {
                return y - x;
              }
            });
        }
        return props.data;
    };

    
    
    //id: element["ID"] , plaka: element["PLAKA"] , arac: element["ARAÇ"],kdvExc : 0 , kdvIncAmt: 0 , kdv: 18
    return (
        <Panel style={{width: '100%',marginVertical: 3, backgroundColor:'white', padding: 5}} shaded>
            <CustomText fontWeight = {800} fontSize = {20}>{" Araç Sayısı :  " + props.data.length}</CustomText>
            <Table 
                    style={{width: '100%'}}
                    wordWrap="break-word"
                    showHeader={true}
                    bordered={false}
                    cellBordered={true}
                    loading={loading}
                    rowHeight={100}
                    autoHeight
                    minHeight={300}
                    sortColumn={String(sortColumn)} sortType={typeof(sortType) == "string" ? sortType : "desc"} onSortColumn={handleSortColumn}
                    data={getData()}
                    onRowClick={data => {
                        console.log("ROW CLICK");
                        console.log(data);
                    }
                    }
                    onPointerEnter={data => {
                        console.log("POINTER ENTER");
                        console.log(data);
                    }
                    }
                    
                >
                <Table.Column width={50} sortable >
                    <Table.HeaderCell> NO </Table.HeaderCell>
                    <CustomCellView dataKey="ID"/>
                </Table.Column>
                <Table.Column width={150} sortable >
                    <Table.HeaderCell> PLAKA </Table.HeaderCell>
                    <CustomCellView dataKey="PLAKA"/>
                </Table.Column>
            
                <Table.Column  width={400} sortable>
                    <Table.HeaderCell> ARAÇ </Table.HeaderCell>
                    <CustomCellView dataKey="ARAÇ"/>
                </Table.Column>
            
                <Table.Column  width={400}  flexGrow={1}> 
                    <Table.HeaderCell>KDV HARİÇ TUTAR</Table.HeaderCell>
                    <EditableCell dataKey="kdvExc" onChange={handleChange} calcType = {props.calcType} rentTime = {props.rentTime} />
                </Table.Column>

                {/* <Table.Column width={50}>
                    <Table.HeaderCell> KDV </Table.HeaderCell>
                    <CustomCellView dataKey="kdv"/>
                </Table.Column> */}
                <Table.Column width={200}>
                    <Table.HeaderCell> KDV DAHİL TUTAR </Table.HeaderCell>
                    <CustomCellView dataKey="kdvIncAmt"/>
                </Table.Column>
            </Table>
        </Panel>
    
    )
}

const ComponentListViewRentInstallments = (props) => {

    const [bordered, setBordered] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [refresh , setRefresh] = useState(false);

    const toggle_refresh = () => {
        setRefresh(!refresh)
    }
    function handleModalClose(){
        setPopupActive(false);
    }

    useEffect(()=> {getData()},[props.data]);

    const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
    };

    // const data = props.data.filter((v, i) => {
    //   const start = limit * (page - 1);
    //   const end = start + limit;
    //   return i >= start && i < end;
    // });
    const columns = props.tableHead;

    var currency_symbol = "₺"
    var formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    maximumFractionDigits: 5,
    });

    function formatAmount(input){
    return formatter.format(input).replace("₺","₺ ")
    }

    function editRowData(input,dataKey){
        if(dataKey.includes("Tutar")){
            return formatAmount(input[dataKey])
        }if(dataKey.includes("Tarih")){
            var dateN = input[dataKey];
            return dateN.toLocaleDateString("en-GB")
        }
        return input[dataKey];
    }

    function reverseDate(input){
        var result = "";  
        
        var comps = input.split("-");

        for(var c = 2 ; c >= 0 ; c--){
        for(var i = 0 ; i < comps[c].length  ; i++){
            result += comps[c][i];
        }
        c != 0 ? result += "-": result += "";
        }
    
        return result;
    }
    const CustomCellView = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
            <View style={{ height: '80%' , alignItems : 'center'  }}>
                <Text>{editRowData(rowData,dataKey)}</Text>
            </View>
        </Table.Cell>
    );

    const [checkedKeys, setCheckedKeys] = React.useState([]);
    let checked = false;
    let indeterminate = false;
    
    //   if (checkedKeys.length === data.length) {
    //     checked = true;
    //   } else if (checkedKeys.length === 0) {
    //     checked = false;
    //   } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    //     indeterminate = true;
    //   }
    
    //   const handleCheckAll = (value, checked) => {
    //     const keys = checked ? data.map(item => item.id) : [];
    //     setCheckedKeys(keys);
    //   };
    //   const handleCheck = (value, checked) => {
    //     const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    //     setCheckedKeys(keys);
    //   };
    
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const getData = () => {
    if (sortColumn && sortType) {
        return props.data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
            x = x.charCodeAt();
        }
        if (typeof y === 'string') {
            y = y.charCodeAt();
        }
        if (sortType === 'asc') {
            return x - y;
        } else {
            return y - x;
        }
        });
    }
    return props.data;
    };

    const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
        setLoading(false);
        setSortColumn(sortColumn);
        setSortType(sortType);
    }, 500);
    };

    function getWidth(input){
        var max = input.length * 20;
        props.data.forEach(element => {
        var str = element[input] + "";
        if(str.length * 10 > max){
            max = str.length * 10;
        }
        
        })
        return max;
    }
    
    return ( 

    <View style = {{marginVertical: 3}}>
            <View style = {{flexDirection : 'row', marginVertical: 3}}>
            <ExportData data = {props.data} tableHead = {props.tableHead} tableName={props.tableName}></ExportData>
            </View>
            <View style={{width: '100%'}}>
                <Table
                        wordWrap="break-word"
                        loading={loading}
                        showHeader={showHeader}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        bordered={false}
                        cellBordered={false}
                        autoHeight = {true}
                        width = {600}
                    >
                    {columns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Table.Column flexGrow={1} width = {getWidth(column)}>
                                <Table.HeaderCell>{column}</Table.HeaderCell>
                                <CustomCellView dataKey={column} />
                            </Table.Column>
                        );
                    })}

                    </Table>
                    {/* <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={props.data.length}
                        limitOptions={[12, 24]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                        locale={{
                            more: 'Daha',
                            prev: 'Önceki',
                            next: 'Sonraki',
                            first: 'İlk',
                            last: 'Son',
                            limit: '{0} / sayfa',
                            total: 'Toplam Satır: {0}',
                            skip: 'Git {0}'
                        }}
                        /> */}
            </View>
        </View>
    

    )
}

const numberMaskTL = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol : '.',
    suffix: ' TL' ,// This will put the dollar sign at the end, with a space.
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 5
})

export default NewRentAggreementDrawer;

