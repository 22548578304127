import { Text, View } from 'react-native';
import React, {useState, useImperativeHandle, forwardRef, useRef} from 'react';
import { Modal, Button } from 'rsuite';

import FileLoader from '../../../Components/FileManagements/FileLoader';
import FileLoaderInline from "../../../Components/FileManagements/FileLoaderInline"; // Correct path

/**
 * CarLicenceAddModal component serves as a modal for adding car license information.
 * It allows users to open the modal and upload car license files.
 * @param {Object} props - The props passed to the component
 * @param {React.Ref} ref - Reference to the component
 */
const CarLicenceAddModal = forwardRef((props, ref) => {
    const [PopupActive, setPopupActive] = useState(false);
    const [choosenData, setChoosenData] = useState(undefined);
    const [refresh, setRefresh] = useState(false);  // For reinitializing the FileLoader when modal opens

    const refLoader = useRef();
    function handleModalClose() {
        setPopupActive(false);
        setChoosenData(undefined); // Reset data on close
    }

    useImperativeHandle(ref, () => ({
        open: (rowData) => {
            setChoosenData(rowData);  // Set the chosen data for car licence
            setPopupActive(true);     // Open the modal
            setRefresh(!refresh);     // Force FileLoader to refresh
        },
    }));

    function loadFile(file, ObjectID) {
        // FileLoader.load(file, ObjectID);
        refLoader.current.load(ObjectID);  // Use refLoader to call load method
    }

    return choosenData !== undefined ? (
        <Modal
            overflow={true}
            size={'full'}
            backdrop={true}
            keyboard={false}
            open={PopupActive}
            onClose={handleModalClose}
        >
            <Modal.Header>
                <Modal.Title>
                    {choosenData['S3 KEYS LICENCES'] !== undefined
                        ? typeof JSON.parse(choosenData['S3 KEYS LICENCES']).data.S3_KEY
                        : 'DENEME'}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ marginHorizontal: 10 }}>Ruhsat Yükle</Text>
                    {/* Correct FileLoader integration with a key prop for ensuring re-render */}
                    <FileLoaderInline
                        key={refresh}  // Use refresh state to force re-render of FileLoader when modal opens
                        reset={props.reset}
                        carID={choosenData['ID']}
                        fileCategory={'CAR_LICENSE'}
                        ref={refLoader}
                    />
                </View>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleModalClose} appearance="subtle">
                    Cancel
                </Button>
                <Button onClick={() => loadFile(null, choosenData['ID'])} appearance="primary">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    ) : (
        <View></View>
    );
});

export default CarLicenceAddModal;
