import { Text, View , StyleSheet , Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef } from 'react'
import axios from 'axios';
import { Input,useToaster, Message, Button ,DatePicker ,SelectPicker,IconButton  ,Modal ,Checkbox, List, Drawer, MaskedInput, Stack, Panel, Grid, Row ,Col} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import locale from '../../../tr'
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faCalendar, faHashtag, faMoneyBill,faSquare } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import CustomText from '../../../Components/CustomText';
import ExportData from '../../../Components/TableManagement/ExportData';



const CarExeminationScreen = (props) => {

    const [editPopup, setEditPopup] = useState(false);
    const [filePopup,setFilePopup] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [editData,setEditData] = useState({});


    function closeEditPopup(){
        setEditPopup(false);
    }

    function closeFilePopup(){
      setFilePopup(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    const {fetchIdToken} = useUserToken();
    async function getComponents(){
      const idToken = await fetchIdToken();
      const body = {
        "ENV" :configdata.APP_ENV ,
        "type" : "GET_EXAMINATION_DATA",
        "ID" : props.data["NO"],
      }
      const headers = {
        "Authorization" : `Bearer ${idToken}`
      }
      const api = configdata.API_BASE + '/cardata';
        axios.post(api,body,{headers}).then((response)=>{
            if(response.data.length > 0){
              setCurrentActive(response.data); 
            }
            setData(response.data);
        }).catch((error)=> {
            console.log(error);
        })
    }

    function addMonths(date, months) {
      var d = date.getDate();
      date.setMonth(date.getMonth() + months);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
    }
    function getAddedDate(date,months){
      var interval = parseInt(months) + 1;
      var newDate = addMonths(new Date(date),interval);
      
      var month_str = newDate.getMonth() < 10 ? "0" + newDate.getMonth():newDate.getMonth() ;
      var day_str = newDate.getDate() < 10 ? "0" + newDate.getDate(): newDate.getDate() ;
      return newDate.getFullYear() + "-" + month_str + "-" + day_str;

    }

    function setCurrentActive(input){
      for(var i = 0; i< input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
            setCurrent(input[i]);
          break;
        }
      }
    }

    function editChoosen(input){
      setEditData(input);
      setModalType("EDIT");
      setEditPopup(true);
    }
    const[viewData,setViewData] = useState({});
      
    function showFileOfChoosen(input){
      setViewData(input);
      setFilePopup(true);
    }

    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }

    const FileViewModal = () => {
      return(
        <View>
           <Modal size={'full'} backdrop={true} keyboard={false} open={filePopup} onClose={closeFilePopup}>
                    <Modal.Header>
                    <Modal.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"MUAYENE BİLGİLERİ"} </Text>
                      </View>} 
                    </Modal.Title>
                    </Modal.Header>
  
                    <Modal.Body>
                     
                    <FileViewer inline={true} s3KEY = {viewData["S3_KEY"]}></FileViewer>
            
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button onClick={handleCloseFILEModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
        </View>
      )
    }

    const tableHeads= ["MUAYENE TARİHİ","TUTAR","İSTASYON","MUAYENE ARALIĞI","SONRAKİ MUAYENE","MUAYENE KM"];
    const listHeads = ["MUAYENE TARİHİ","SONRAKİ MUAYENE","NOTLAR"];

    const headers = [
      {"header" : "MUAYENE TARİHİ" , "data" : current["MUAYENE TARİHİ"] , "icon" : faCalendar}, 
      {"header" : "TUTAR" , "data" : current["TUTAR"] , "icon" : faMoneyBill},
      {"header" : "İSTASYON" , "data" : current["İSTASYON"] , "icon" : faBuilding},
      {"header" : "MUAYENE ARALIĞI" , "data" : current["MUAYENE ARALIĞI"] , "icon" : faSquare},
      {"header" : "SONRAKİ MUAYENE" , "data" : current["SONRAKİ MUAYENE"] , "icon" : faCalendar},
      {"header" : "MUAYENE KM" , "data" : current["MUAYENE KM"] , "icon" : faHashtag},
      {"header" : "NOTLAR" , "data" : current["NOTLAR"] , "icon" : faHashtag},
    ]

    return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2' , padding: 20}}>
              <Drawer size= {Dimensions.get('window').width < 700 ? 'full' : 'sm'} backdrop={'static'} keyboard={false} open={editPopup} onClose={closeEditPopup}>
                    <Drawer.Header>
                    <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"MUAYENE BİLGİLERİ"} </Text>
                      </View>} 
                    </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        {modalType == "New" ? (<NewCarExeminationPanel 
                        type={"NEW"}
                        cardata = {props.data} 
                        data = {editData}
                        reset = {() => {getComponents(); closeEditPopup();}}></NewCarExeminationPanel>) 
                        :
                        ( <NewCarExeminationPanel 
                          type={"EDIT"}
                          cardata = {props.data} 
                          data = {editData}
                          reset = {() => {getComponents(); closeEditPopup();}} ></NewCarExeminationPanel> )}
                       
                    </Drawer.Body>
              </Drawer>

              <Grid fluid style={{width: '100%'}}>
                <Row gutter={20}>
                  <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                  <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200, marginBottom: 20}} 
                          header  = {
                            <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                              <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                <Stack.Item grow={1}>
                                <IconButton 
                                  style={{width: '100%', height: 35}}
                                  onClick={() => {
                                    setEditPopup(true);
                                    setModalType("New");}}
                                    icon={<Icon><FontAwesomeIcon icon={"plus"} /></Icon>}
                                    >
                                    Yeni Muayene Bilgisi
                                </IconButton>
                                </Stack.Item>
                                <ExportData data = {data} tableHead = {tableHeads} tableName={"Muayene_Bilgileri" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                              </Stack> 
                           
                        </View>
                        } >   
                            
                            <CustomText fontWeight= {800} fontSize={14} > {"Muayene Bilgileri" } </CustomText>
                            <ComponentListView hasDoc = {true}  current={current} showDetails={showDetailsOfChoosen}  chooseANDShow={showFileOfChoosen} setchoosen_data={editChoosen} listHeads={listHeads}  tableName = {"Bakım_" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads}  data={data}></ComponentListView>

                        </Panel>
                  </Col>
                  <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                    <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}} header = {<CustomText fontWeight={800} > {"Muayene Detayları -- " + props.data["PLAKA"] } </CustomText> } >
                        <List>
                        {headers.map((item) =>
                          {
                          return(<List.Item key={item} index={item}>
                            <CustomListItem item={item} ></CustomListItem>
                          </List.Item>)
                          }
                          )}
                      </List>
                    </Panel>
                  </Col>
                </Row>
              </Grid>
            <View style={{flexDirection : 'row' , alignItems : 'center', backgroundColor :'#F2FFFB'}}>
                <FileViewModal></FileViewModal> 
            </View>
        </View>
    )
}


const NewCarExeminationPanel = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }


    const [activateAddButton,setActiveAddButton] = useState(true);


    // Conbo Box Values
    const[exeminationTypes,setExeminationTypes] = useState([]);

    // SENT VALUES
    const [exeminationDate,setExeminationDate] = useState(new Date());
    const [nextExeminationDate,setNextExeminationDate] = useState(new Date());
    const [exeminationType,setExeminationType] = useState(0);
    const [exeminationKM,setExeminationKM] = useState(0);
    const [service,setService] = useState("");
    const [amount,setAmount] = useState(0);
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [S3Key,setS3Key] = useState(undefined);
    const fileUploaderRef= useRef();


    function reset(){
        setExeminationDate(new Date());
        setExeminationType(0);
        setExeminationKM(0);

        setService("");
        setAmount(0);
        setNote("");
        setIS_ACTIVE(true);
    }
    
    function getEditData(){
        
        setExeminationType(props.data["MUAYENE TİPİ"]);
        setExeminationKM(props.data["MUAYENE KM"]);
        setExeminationDate(new Date(props.data["MUAYENE TARİHİ"]));
        setService(props.data["İSTASYON"]);
        setAmount(props.data["TUTAR"]);
        setNote(props.data["NOTLAR"]);
        setIS_ACTIVE(props.data["IS_ACTIVE"] == 1);
  }

  function getDropDownValues(input,set){
  
    const api = configdata.API_BASE + '/uidropdownlist';
    axios
    .post(api,{
      "ENV" :configdata.APP_ENV ,  
        "category": input,
        "type" : "GET_GLOBAL",
        
    })
    .then((response) => {
      // console.log(response);
      // console.log(response.data);
      //setUserData(JSON.parse(response.data));
      
      var result = [];
      response.data.forEach( element =>
        { 
          result.push({"label" : element.VALUE , "value" : element.ID})
        }
        );
      set(result);
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
      
    });

  }


   const {fetchIdToken} = useUserToken();
   async function sendData(){
    const idToken = await fetchIdToken();
    const headers = {
      "Authorization" : `Bearer ${idToken}`  
      
    }

    let amountNew = amount ;
    if(amountNew.replace != undefined){
      amountNew = Number(amountNew.replace(" ₺","").replace(",","."));
    }

    let KM = exeminationKM ;
    if(KM.replace != undefined){
      KM = Number(KM.replace(" KM","").replace(",","."));
    }

    const api = configdata.API_BASE + '/cardata';
    const body =  {
      "ENV" :configdata.APP_ENV ,  
        "type": props.type == 'EDIT' ? "UPDATE_EXAMINATION" : "NEW_EXAMINATION_DOC",
        "EXAMINATION_KM" : KM,
        "EXAMINATION_TYPE_ID" :exeminationType,
        "EXAMINATION_DATE" : exeminationDate,
        "NEXT_EXAMINATION_DATE" : nextExeminationDate,
        "AMOUNT" : amountNew,
        "STATION" : service,
        "NOTES" :note,
        "CAR_ID" : props.cardata["NO"],
        "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
        "ID" : props.data["NO"],
    }
    
      axios.post(api,body,{headers} ).then((response) => {
        console.log(response.data);
        reset();
        props.reset();
        if(response.data["errorMessage"] != null){
            messageToggle("Muayene Bilgileri eklenemedi , Bağlantınızı kontrol edin", "error")
        }else{

            if(response.data.split("_"[0] == "SUCCESS")){
              messageToggle("Muayene Bilgileri başarıyla eklendi", "success")
              fileUploaderRef.current.load(response.data.split("_")[1]);
            }
            else{
              messageToggle("Muayene Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
            }
        }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("Muayene Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

    useEffect(() => {
      
     if(props.type == "EDIT"){getEditData()}
     getDropDownValues("EXAMINATION_TYPE",setExeminationTypes);
    

    },[])


    
    return(
        <View style={{ flexDirection: 'column', width: '100%'}}>
          {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
          <View style={styles.form}>
              <Group header ={"Mayene Tarihi"}>
                <DatePicker value={exeminationDate} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                      {setExeminationDate(value);}}
                      oneTap format="dd-MM-yyyy"
                      locale={locale}
                  ></DatePicker>
              </Group>
              <Group header ={"Sonraki Muayene Tarihi"}>
                <DatePicker value={nextExeminationDate} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                      {setNextExeminationDate(value);}}
                      oneTap format="dd-MM-yyyy"
                      locale={locale}
                      shouldDisableDate={(date) => { return date < exeminationDate; }
                      }
                  ></DatePicker>
              </Group>

              <Group header ={"Tutar"}>
                <MaskedInput mask={TutarMask} value={amount} style={styles.standard} min={0} onChange={ (value) => {setAmount(value);}} name= {"tutar"} /> 
              </Group>
            
              <Group header ={"İstasyon"}>
                <Input value={service} style={styles.standard}  onChange={ (value) => {setService(value);}} name= {"service"} /> 
              </Group>


              <Group header ={"Muayene KM"}>
                <MaskedInput mask={KmMask} value={exeminationKM} style={styles.standard} min={0} onChange={ (value) => {setExeminationKM(value);}} name= {"KM"} /> 
              </Group>

              <Group header ={"Muayene Tipi"}>
                <SelectPicker value={exeminationType}  style={styles.standard}  onSelect={(value) => { setExeminationType(value)}} data = {exeminationTypes} ></SelectPicker>
              </Group>

              {
                props.type == "EDIT" && 
                <Group header= {"DURUM"}>
                  <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                </Group>
              }
              <Group header ={"Notlar"}>
                <Input style={styles.standard} value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"}   /> 
              </Group>
              <Group header ={"Döküman"}>
                <FileLoader reset={props.reset} ref = {fileUploaderRef} fileCategory = {"CAR_EXAMINATION"} carID = {props.cardata["NO"]} ></FileLoader>
              </Group>
          </View>
          <View style={{height: 50}}></View>
          <Button
              disabled = {!activateAddButton}
              style={styles.buttonStyle}
              onClick={() => { sendData();}}
            > <CustomText fontWeight = {800}  fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>
        </View>
    )
}




const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{ marginRight: 5}}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);

const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  form: {
    width: '100%',
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  standard: { flex: 1 , maxWidth: 300, minWidth: 150,width: '100%'},
  input: {
    flex: 1,
  },
});

const KmMask = createNumberMask({ 
  prefix: '',
  thousandsSeparatorSymbol : '.',
  suffix: ' KM', 
  allowDecimal : false,
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
});

const TutarMask = createNumberMask({
  prefix: '₺',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
});






export default CarExeminationScreen;