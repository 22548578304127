import React, { useState } from 'react';
import './DatepickerComponent.css'; 

const DatepickerComponent = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleDateChange = (event) => {
    let dateValue = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    // Automatically insert "-" at the appropriate positions
    if (dateValue.length > 2) {
      dateValue = dateValue.slice(0, 2) + '-' + dateValue.slice(2);
    }
    if (dateValue.length > 5) {
      dateValue = dateValue.slice(0, 5) + '-' + dateValue.slice(5);
    }

    setSelectedDate(dateValue);

    // Regular expression for DD-MM-YYYY format
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    
    if (datePattern.test(dateValue) || dateValue === '') {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <div className="datepicker-container">
      <input
        id="date-input"
        type="text"
        value={selectedDate}
        onChange={handleDateChange}
        placeholder="DD-MM-YYYY"
        maxLength="10"
        className={`datepicker-input ${isValid ? '' : 'datepicker-input-invalid'}`}
      />
      {!isValid && <p className="datepicker-error">Hatalı Tarih</p>}
    </div>
  );
};

export default DatepickerComponent;
