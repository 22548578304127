import { Text, View } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { useToaster, Message, Button, Form ,SelectPicker  ,Checkbox,Pagination,IconButton, Table} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ExportData from '../Components/TableManagement/ExportData';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import configdata from '../config/config.json';
import { useMessage } from '../hooks/useMessage';
import PropTypes from 'prop-types';

const CarTagManagerScreen = (props) => {
    const {messageToggle} =  useMessage();
    const [data,setData] = useState([]);
    const [editData,setEditData] = useState(undefined);
    const [refresh,setRefresh] = useState(false);

    useEffect(()=> {
        getComponents();
    },[]);


    function getComponents(){
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${sessionStorage.getItem("idToken")}`,
    }
        axios.post(api,{
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CAR_TAGS_ALL",
        },{headers}).then((response)=>{
          
            console.log(response.data);
            if(response.data.length > 0 && editData == undefined ){
                console.log(response.data[0]);
                setEditData(response.data[0]);
            }
            setData(response.data);

            setRefresh(!refresh);
        }).catch((error)=> {
            console.log(error);
        })
    }

    function getTags(input){
        let result = [];
        result.push({"label" : "Ana Etiket" , "value" : -1 })
        input.forEach(element => {
            if(element.PARENT_ID == -1){
                result.push({"label" : element.İSİM , "value" : element.NO })
            }
        })
        return result;
    }

   

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
   
    }
    useEffect(()=> {
        if(editData != undefined){
            console.log(editData);
            setRefresh(!refresh);
            ref_showEdit.current.reset();
        }
      
    },[editData]);

    
    const ref_showEdit = useRef();
    const tableHeads= ["NO","İSİM","ÜST KATEGORİ","DURUM"]


    return(
        <View style={{padding : 10}}>
             
             <NewCarTagPanel 
                type={"NEW"}
                tags = {getTags(data)}
                reset = {() => {getComponents(); handleModalClose();}}></NewCarTagPanel>
                
            <ComponentListView setchoosen_data={chooseANDEdit}  tableName = {"Araç_Etiketleri"} tableHead={tableHeads}  data={data}></ComponentListView>
            {(editData != null && editData != undefined) &&
             <EditShowCarTagPanel
             tags = {getTags(data)}
             data = {editData}
             reset = {() => {getComponents(); handleModalClose();}}
             ref= {ref_showEdit}
             ></EditShowCarTagPanel>
            
            }
        </View>
    )
}


const EditShowCarTagPanel = forwardRef((props,ref) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }
    const [activateAddButton,setActiveAddButton] = useState(true);
    // COMBO BOX ARRAYS
   
    // SENT VALUES
    const [value,setValue] = useState(props.data["İSİM"]);
    const [parentValue,setParentValue] = useState(props.data["PARENT_ID"]);
    const [parentName,setParentName] = useState(props.data["ÜST KATEGORİ"]);
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [type,setType] = useState("Show");
    
    useImperativeHandle(ref,() => ({
        reset: () => {
            getEditData();
        }
    }))
    
    useEffect(() => {  
        console.log(props.data["İSİM"]); 
        getEditData()
    },[])
   

    function getEditData(){
        console.log(props.data["İSİM"]);
        console.log(props.data["PARENT_ID"]);
        setValue(props.data["İSİM"]);
        setParentValue(props.data["PARENT_ID"]);
        setParentName(props.data["ÜST KATEGORİ"]);
        setIS_ACTIVE(props.data["DURUM"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    function sendData(){
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${sessionStorage.getItem("idToken")}`,
      }
      axios.post(api,
        {
            "ENV" : configdata.APP_ENV,
            "type": "UPDATE_CAR_TAG",
            "VALUE" : value,
            "PARENT_ID": parentValue,
            "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
            "ID" : props.data["NO"]
        },{headers}
      ).then((response) => {
        console.log(response);
        setType("Show");
        props.reset();
        if(response.data["errorMessage"] != null){
            messageToggle("Araç Etiketi eklenemedi, Bağlantınızı kontrol edin", "error")
        }else{
            messageToggle("Araç Etiketi Başarıyla Eklendi", "success")
        }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("Araç Etiketi eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

       
    const item_style = {
    standard: { width : 200 },
    viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }
    const styles = {
        buttonStyle:{
          textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
        }
      }
    
    return(
        <View style= {{ paddingTop: 5,justifyContent: 'flex-start', alignItems: 'center'}}>
          <View style= {{flexDirection: 'column',width: '100%',justifyContent: 'flex-start',alignItems :'center'}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
              
              <View style={{backgroundColor :'white', borderRadius: 7, padding: 5, width: '100%', alignItems :'center', justifyContent:'center', height: 100}}>
              {
                type == "Show" ? 
                <View style={{width: '60%',alignItems: 'center',justifyContent: 'center'}}>
                    <View style={{width: '100%',flexDirection : 'row',justifyContent: 'space-between'}}>
                        <View style={{flexDirection : 'row',alignItems:'center'}}>
                                <Text  style ={{ textAlign: 'center' , fontWeight: 'bold'}}> Etiket İsmi : </Text>
                                <Text style ={{ textAlign: 'center' }}> {value} </Text>
                        </View>
                        <View style={{flexDirection : 'row',alignItems:'center'}}>
                                <Text  style ={{ textAlign: 'center' , fontWeight: 'bold'}}> Üst Kategori : </Text>
                                <Text style ={{ textAlign: 'center' }}> {parentName} </Text>
                        </View>
                        <Form>
                            <Form.Group>
                            <Button
                                style={styles.buttonStyle}
                                onClick={() => { setType("Edit"); getEditData();}}
                                > Düzenle </Button>
                            </Form.Group>
                        </Form>
                       
                    </View>
                </View> :
                <View style={{width: '100%',flexDirection: 'row'}}>
                    <View style={item_style.viewStyle}>
                        <Form layout='inline'>

                        {/* Etiket İsmi*/}
                        <Form.Group>
                            <Form.ControlLabel> Etiket İsmi </Form.ControlLabel>
                            <Form.Control value={value} style={item_style.standard} onChange={ (value) => {setValue(value);}} name= {"Etiket İsmi"} /> 
                        </Form.Group>
                        {/* Üst Kategoriler */}
                        <Form.Group>
                            <Form.ControlLabel> Üst Kategori Etiketler </Form.ControlLabel>
                            <SelectPicker placement="topStart" value={parentValue} style={item_style.standard}  onSelect={(value) => { setParentValue(value)}} data = {props.tags} ></SelectPicker>
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel> DURUM </Form.ControlLabel>
                            <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                        </Form.Group>
                        <Form.Group>
                            {/* <Form.ControlLabel> DURUM </Form.ControlLabel> */}
                            <Button
                                disabled = {!activateAddButton}
                                style={styles.buttonStyle}
                                onClick={() => { sendData();}}
                                > Kaydet </Button>
                        </Form.Group>
                        <Form.Group>
                            {/* <Form.ControlLabel> DURUM </Form.ControlLabel> */}
                            <Button
                                style={styles.buttonStyle}
                                onClick={() => { setType("Show"); getEditData();}}
                                > İptal Et </Button>
                        </Form.Group>
                        </Form>
                    </View>
                </View>}
              </View>
               
                {/* <StandardButton color= '#F20F38' iconName = {"close-circle-outline"} buttonText = {"Cancel"}  onPress = {props.togglePanel}></StandardButton> */}
            </View>
        </View>
    )
});
const NewCarTagPanel = (props) => {
    const {messageToggle} = useMessage();

    const styles = {
      buttonStyle:{
        textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
      }
    }
    const [activateAddButton,setActivateAddButton] = useState(true);
    const [value,setValue] = useState("");
    const [parentValue,setParentValue] = useState("");


    useEffect(() => {
       
        if(props.type == "EDIT"){
            getEditData()}
    },[])
   
       useEffect(() => {
           console.log("ETİKET İSMİ : --- " , value);
          },[value])
     function reset(){
        setValue("");
        setParentValue(-1);

    }

    function getEditData(){
        console.log(props.data["İSİM"]);
        console.log(props.data["PARENT_ID"] == -1 ? 0 : props.data["PARENT_ID"]);
        setValue(props.data["İSİM"]);
        setParentValue(props.data["PARENT_ID"] == -1 ? 0 : props.data["PARENT_ID"]);

  }


    function sendData(){
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${sessionStorage.getItem("idToken")}`,
      }
      axios.post(api,
        {
            "ENV" : configdata.APP_ENV,
            "type": "NEW_CAR_TAG",
            "VALUE" : value,
            "PARENT_ID": parentValue,
        },{headers}
      ).then((response) => {
        console.log(response);
        reset();
        props.reset();
        if(response.data["errorMessage"] != null){
            messageToggle("Araç Etiketi eklenemedi, Bağlantınızı kontrol edin", "error")
        }else{
            messageToggle("Araç Etiketi Başarıyla Eklendi", "success")
        }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("Araç Etiketi eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

       
    const item_style = {
    standard: { width : 200 },
    viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }

    
    return(
        <View style= {{ paddingTop: 5,justifyContent: 'flex-start', alignItems: 'center'}}>
          <View style= {{flexDirection: 'column',width: '100%',justifyContent: 'flex-start',alignItems :'center'}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
            <View style={{width: '100%',flexDirection: 'row'}}>
              <View style={item_style.viewStyle}>
                <Form layout='inline'>
                      {/* Etiket İsmi*/}
                   <Form.Group>
                     <Form.ControlLabel> Etiket İsmi </Form.ControlLabel>
                     <Form.Control style={item_style.standard} onChange={ (value) => {setValue(value);}} name= {"Etiket İsmi"} /> 
                   </Form.Group>
                   {/* Üst Kategoriler */}
                   <Form.Group>
                     <Form.ControlLabel> Üst Kategori Etiketler </Form.ControlLabel>
                     <SelectPicker  style={item_style.standard}  onSelect={(value) => { setParentValue(value)}} data = {props.tags} ></SelectPicker>
                   </Form.Group>
                   <Form.Group>
                     {/* <Form.ControlLabel> Üst Kategori Etiketler </Form.ControlLabel> */}
                     <Button
                  disabled = {!activateAddButton}
                  style={styles.buttonStyle}
                  onClick={() => { sendData();}}
                > Kaydet </Button>
                   </Form.Group>
                </Form>
              </View>
              </View>
              <View style={{height: 50}}></View>
              
            {/* <StandardButton color= '#F20F38' iconName = {"close-circle-outline"} buttonText = {"Cancel"}  onPress = {props.togglePanel}></StandardButton> */}
          </View>
        </View>
    )
}

NewCarTagPanel.propTypes = {
    tags: PropTypes.array,
    reset: PropTypes.func,
    type: PropTypes.string,
    data: PropTypes.object.isRequired
  };


const ComponentListView = (props) => {

    const [loading, setLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);



    useEffect(()=> {getData()},[props.data]);
  
    const handleChangeLimit = dataKey => {
      setPage(1);
      setLimit(dataKey);

    };
  
    const data = props.data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    const columns = props.tableHead;
    

   
    function viewEdit(input){
      props.setchoosen_data(input);
      console.log(input);
      
    }

    const ActionCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View style={{ height: '80%'  }}>
            <IconButton icon={<Icon><FontAwesomeIcon icon={"pen-to-square"} /></Icon>}
              onClick={() => {viewEdit(rowData)}}
            />
           
          </View>
        </Table.Cell>
    );


function parseRowData (rowData,dataKey){
    if(dataKey == "DURUM"){
        if(rowData[dataKey] == 1) return "AKTİF"
        else return "PASİF"
    }else{
        return rowData[dataKey];
    }
}

  const CustomCellView = ({ rowData, dataKey, ...props }) => (
    <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
      <View style={{ height: '80%' , alignItems : 'center'  }}>
          <Text>{parseRowData(rowData,dataKey)}</Text>
      </View>
    </Table.Cell>
);



      const getData = () => {
        if (sortColumn && sortType) {
         return data.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (typeof x === 'string') {
              x = x.charCodeAt();
            }
            if (typeof y === 'string') {
              y = y.charCodeAt();
            }
            if (sortType === 'asc') {
              return x - y;
            } else {
              return y - x;
            }
          });
        }

          return data;
       
      };

      const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };

      function getWidth(input){

        var max = input.length * 20;
        data.forEach(element => {
          var str = element[input] + "";
          if(str.length * 10 > max){
            max = str.length * 10;
          }
         
        })
          return max;
      }
    
    return ( 

    <View style = {{marginVertical: 3}}>
            <View style = {{flexDirection : 'row', marginVertical: 3}}>
              <ExportData data = {data} tableHead = {props.tableHead} tableName={props.tableName}></ExportData>
            </View>
            <View>
                <Table
                        autoHeight
                        wordWrap="break-word"
                        loading={loading}
                        showHeader={true}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        data={getData()}
                        bordered={true}
                        cellBordered={true}
                        
                    >
                    <Table.Column width={60} sortable resizable>
                        <Table.HeaderCell> Düzenle </Table.HeaderCell>
                        <ActionCell dataKey= 'Edit'/>
                    </Table.Column>
                    {columns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Table.Column sortable width = {getWidth(column)}>
                                <Table.HeaderCell>{column}</Table.HeaderCell>
                                <CustomCellView dataKey={column} />
                            </Table.Column>
                        );
                    })}
                    </Table>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={props.data.length}
                        limitOptions={[10, 20]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                        locale={{
                            more: 'Daha',
                            prev: 'Önceki',
                            next: 'Sonraki',
                            first: 'İlk',
                            last: 'Son',
                            limit: '{0} / sayfa',
                            total: 'Toplam Satır: {0}',
                            skip: 'Git {0}'
                        }}
                        />
            </View>
        </View>
    
  
    )
}


export default CarTagManagerScreen;