import { Text, View , StyleSheet,Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef} from 'react'
import axios from 'axios';
import { Input,useToaster, Message, Button ,DatePicker ,SelectPicker,IconButton  ,Modal ,Checkbox, List, Drawer, MaskedInput,Stack, Panel,Grid,Row,Col} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import locale from '../../../tr'
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faCalendar, faMoneyBill, faPhone, faSquare } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import ExportData from '../../../Components/TableManagement/ExportData';
import CustomText from '../../../Components/CustomText';
import { useMessage } from '../../../hooks/useMessage';

const KaskoScreen = (props) => {
        return(
          <View style={{flex: 1 , backgroundColor :'#F2F2F2'}}>
            <KaskoStandard reset={props.reset} data = {props.data}></KaskoStandard>
          </View>
        )
}


const KaskoStandard = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }
      const [modal, setModal] = useState(false);
      const [modalType,setModalType] = useState("New")
      const [data,setData] = useState([]);
      const [current,setCurrent] = useState({});
      const [editData,setEditData] = useState({});
      const [s3KEYS , setS3Keys] = useState(getS3Keys());
      const [refresh,setRefresh] = useState(false);
      const [type,setType] = useState(props.data["KASKO TİPİ"]);
      const fileViewRef = useRef();

      function handleModalClose(){
          setModal(false);
      }
      const [modalFILEOpen,setModalFILEOpen] = useState(false);
  
      function handleCloseFILEModal(){
        setModalFILEOpen(false);
      }
  
      useEffect(()=> {
          getComponents();
      },[]);
  
      function editRow(){
          setModalType("Edit");
      }
  
      const {fetchIdToken} = useUserToken();

      async function getComponents(){
       const idToken = await fetchIdToken();
       const headers = {
         "Authorization" : `Bearer ${idToken}`  
         
       }
        const api = configdata.API_BASE + '/cardata';
          axios.post(api,{
            "ENV" :configdata.APP_ENV ,
              "type" : "GET_INSURANCES",
              "ID" : props.data["NO"],
              "category" : "KASKO"
          },{headers}).then((response)=>{
              if(response.data.length > 0){
                setCurrentActive(response.data);
              
              }
             
              setData(response.data);
          }).catch((error)=> {
              console.log(error);
          })
      }

      function reset(){
        props.reset();
        setRefresh(!refresh);
        getComponents(); 
        handleModalClose();
      }
  
      function setCurrentActive(input){
        for(var i = 0; i< input.length ; i++){
          if(input[i]["IS_ACTIVE"] == 1){
            setCurrent(input[i]);
            // console.log(input[i]["S3_KEY"]);
            // fileViewRef.current.set(input[i]["S3_KEY"]);
            break;
          }
        }
          // input.forEach(element => {
          //   if(element["IS_ACTIVE"] == 1){
          //     setCurrent(element);
          //   }
          // })
      }
  
      function chooseANDEdit(input){
        setEditData(input);
        setModalType("EDIT");
        setModal(true);
      }
      const[viewData,setViewData] = useState({});
      
      function chooseANDShow(input){
        setViewData(input);
        setModalFILEOpen(true);
      }

      function showDetailsOfChoosen(input){ 
        console.log(input);
        setCurrent(input);
      }
  
      function getS3Keys(){
        // if(JSON.parse(props.data["S3 KEYS K_INSURENCES"]).data != null){
        //   return JSON.parse(props.data["S3 KEYS K_INSURENCES"]).data[0];
        // }
        return [];
      }

      async function UpdateStandardToHavuz(){
       const idToken = await fetchIdToken();
       const headers = {
         "Authorization" : `Bearer ${idToken}`  
         
       }
        const api = configdata.API_BASE + '/cardata';
        axios.post(api,
          {
            "ENV" :configdata.APP_ENV ,
              "type":"UPDATE_CAR_ONE_COLUMN",
              "Column" : "KASKO_TİPİ",
              "Value" : "HAVUZ",
              "ID" : props.data["NO"]
          },{headers}
        ).then((response) => {
          console.log(response);
          reset();
          setType("HAVUZ");
          if(response.data["errorMessage"] != null){
              messageToggle("KASKO tipi HAVUZ olarak değişitrilemedi, Bağlantınızı kontrol edin", "error")
          }else{
              messageToggle("KASKO tipi HAVUZ olarak değişitrildi", "success")
          }
         
        })
        .catch((error) => {
          console.log(error);
          messageToggle("KASKO tipi HAVUZ olarak değişitrilemedi, lütfen girilen bilgileri kontrol edin", "error")
        });
      }


      async function UpdateHavuzToStandard(){
       const idToken = await fetchIdToken();
       const headers = {
         "Authorization" : `Bearer ${idToken}`  
         
       }
        const api = configdata.API_BASE + '/cardata';
        axios.post(api,
          {
            "ENV" :configdata.APP_ENV ,
              "type":"UPDATE_CAR_ONE_COLUMN",
              "Column" : "KASKO_TİPİ",
              "Value" : "STANDARD",
              "ID" : props.data["NO"]
          },{headers}
        ).then((response) => {
          console.log(response);
          reset();
          setType("STANDARD");
          if(response.data["errorMessage"] != null){
              messageToggle("KASKO tipi standard olarak değişitrilemedi, Bağlantınızı kontrol edin", "error")
          }else{
              messageToggle("KASKO tipi standard olarak değişitrildi", "success")
          }
         
        })
        .catch((error) => {
          console.log(error);
          messageToggle("KASKO tipi standard olarak değişitrilemedi, lütfen girilen bilgileri kontrol edin", "error")
        });
      }
  
     
      const FileViewModal = () => {
        return(
          <View>
             <Modal size={'full'}  backdrop={true} keyboard={false} open={modalFILEOpen} onClose={handleCloseFILEModal}>
                      <Modal.Header>
                      <Modal.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"KASKO"} </Text>
                      </View>} 
                    </Modal.Title>
                      </Modal.Header>
    
                      <Modal.Body>
                        <FileViewer inline={true} s3KEY = {viewData["S3_KEY"]}></FileViewer>
                      </Modal.Body>
                      {/* <Modal.Footer>
                        <Button onClick={handleCloseFILEModal} appearance="subtle">
                          Cancel
                        </Button>
                      </Modal.Footer> */}
                    </Modal>
          </View>
        )
      }

      
      const tableHeads= ["POLİÇE NO","BAŞLANGIÇ TARİHİ","BİTİŞ TARİHİ","TUTAR","SİGORTA ŞİRKETİ","ACENTE","ACİL YOL YARDIM NO"]
      const labelHeads= ["POLİÇE NO","BAŞLANGIÇ TARİHİ","BİTİŞ TARİHİ","TUTAR","SİGORTA ŞİRKETİ","ACENTE","ACİL YOL YARDIM NO","NOTLAR"]
      const listHeads= ["POLİÇE NO","BİTİŞ TARİHİ","SİGORTA ŞİRKETİ","ACİL YOL YARDIM NO"]

      const headers = [
        {"header" : "POLİÇE NO" , "data" : current["POLİÇE NO"] , "icon" :faSquare },
        {"header" : "BAŞLANGIÇ TARİHİ" , "data" : current["BAŞLANGIÇ TARİHİ"] , "icon" :faCalendar },
        {"header" : "BİTİŞ TARİHİ" , "data" : current["BİTİŞ TARİHİ"] , "icon" :faCalendar },
        {"header" : "TUTAR" , "data" : current["TUTAR"] , "icon" :faMoneyBill },
        {"header" : "SİGORTA ŞİRKETİ" , "data" : current["SİGORTA ŞİRKETİ"] , "icon" :faBuilding },
        {"header" : "ACENTE" , "data" : current["ACENTE"] , "icon" :faBuilding },
        {"header" : "ACİL YOL YARDIM NO" , "data" : current["ACİL YOL YARDIM NO"] , "icon" :faPhone },

      ]


      
      return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2', padding:20}}>

                  <Drawer size={Dimensions.get('window').width < 700 ? 'full' : 'sm'} backdrop={'static'} keyboard={false} open={modal} onClose={handleModalClose}>
                      <Drawer.Header>
                      <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"KASKO"} </Text>
                      </View>} 
                      </Drawer.Title>
                      </Drawer.Header>
                      <Drawer.Body style={{width: '100%'}}>
                          {modalType == "New" ? (<KaskoEditAddPanel 
                          type={"NEW"}
                          cardata = {props.data} 
                          data = {editData}
                          reset = {reset}></KaskoEditAddPanel>) 
                          :
                          ( <KaskoEditAddPanel 
                            type={"EDIT"}
                            cardata = {props.data} 
                            data = {editData}
                            reset = {reset} ></KaskoEditAddPanel> )}
                         
                      </Drawer.Body>
                  </Drawer>
                  <FileViewModal></FileViewModal>
              
                {type == "STANDARD" ?  
                    (
                      
                      <Grid fluid style={{width: '100%'}}>
                      <Row gutter={20}>
                        <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                            <Panel shaded style={{ backgroundColor: 'white', height: 800, minWidth: 200, marginBottom: 20 }} header={
                              <View style={{ flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Stack wrap spacing={10} style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                  <Stack.Item grow={1}>
                                    <IconButton
                                      style={{ width: '100%', height: 35 }}
                                      onClick={() => {
                                        setModal(true);
                                        setModalType("New");
                                      }}
                                      icon={<Icon><FontAwesomeIcon icon={"plus"} /></Icon>}
                                    >
                                      Yeni Kasko
                                    </IconButton>
                                  </Stack.Item>
                                  <ExportData data={data} tableHead={tableHeads} tableName={"Kasko_Listesi_" + props.data["PLAKA"] + "_" + new Date()}></ExportData>
                                </Stack> 
                              </View>
                            }>
                               <CustomText fontWeight= {800} fontSize={14} >  {"Kaskolar"} </CustomText>
                              <ComponentListView hasDoc = {true}  current={current} showDetails={showDetailsOfChoosen} chooseANDShow={chooseANDShow} setchoosen_data={chooseANDEdit} tableName={"Kasko_Poliçeleri_" + props.data["PLAKA"] + "_" + new Date()} tableHead={tableHeads} listHeads={listHeads} data={data}></ComponentListView>
                            </Panel>
                          </Col>
                          <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>

                            <Panel shaded style={{ backgroundColor: 'white', height: 800, minWidth: 200 }} header={<CustomText fontWeight= {800}> {"Kasko Detayları -- " + props.data["PLAKA"]} </CustomText>}>
                              <List>
                                {headers.map((item) => (
                                  <List.Item key={item} index={item}>
                                    <CustomListItem item={item} ></CustomListItem>
                                  </List.Item>
                                ))}
                              </List>
                              <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 10 }}>
                                <CustomText fontWeight={800} > {"Notlar"} </CustomText>
                                <CustomText> {current["NOTLAR"]} </CustomText>
                              </View>
                            </Panel>
                          </Col>
                        </Row>
                      </Grid>
                    ) 
                    :
                    (
                    <View style={{flex : 1, alignItems: 'center'}}>
                        <Text style={{fontSize : 24 , fontWeight :'bold' , color : '#A4A4A4'}}>
                            HAVUZ KASKO TİPİ
                        </Text>
                        <View style={{marginVertical: 10}}>
                        <Button 
                            style={{width: 200}}
                            onClick={() => {UpdateHavuzToStandard();}}>
                            STANDARD KASKOYA ÇEVİR
                        </Button>
                    </View>
                    </View>
                    )
              
              }
             
          </View>
      )
}


const KaskoEditAddPanel = (props) => {

    const {messageToggle} = useMessage();
    const [activateAddButton,setActiveAddButton] = useState(true);

    // COMBO BOX ARRAYS
    const [InsurenceFirmsAll,setInsurenceFirmsAll] = useState([]);
    const [AgentFirmsAll,setAgentFirmsAll] = useState([]);

    // SENT VALUES
    const [documentNO,setdocumentNO] = useState("");
    const [agentFirm,setAgentFirm] = useState(0);
    const [insurenceFirm,setInsurenceFirm] = useState(0);
    const [emergencyHelpNo,setEmergencyHelpNo] = useState("");
    const [amount,setAmount] = useState(0);
    const [StartDATE,setStartDATE] = useState(new Date());
    const [EndDATE,setEndDATE] = useState(new Date());
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [S3Key,setS3Key] = useState(undefined);
    const fileUploaderRef= useRef();

    useEffect(() => {
      if(S3Key != undefined){
        console.log(S3Key);
        sendData();
       
      }
    },[S3Key])
    useEffect(()=> {console.log(agentFirm)},[agentFirm])
    useEffect(()=> {console.log(insurenceFirm)},[insurenceFirm])
    function reset(){
        setdocumentNO("");
        setAgentFirm(0);
        setInsurenceFirm(0);
        setEmergencyHelpNo("");
        setAmount(0);
        setStartDATE("");
        setEndDATE("");
        setNote("");
        setIS_ACTIVE(true);
    }

    function getEditData(){
      setdocumentNO(props.data["POLİÇE NO"]);
      setAgentFirm(props.data["AGENT_FIRM_ID"]);
      setInsurenceFirm(props.data["INSURENCE_FIRM_ID"]);
      setEmergencyHelpNo(props.data["ACİL YOL YARDIM NO"]);
      setAmount(props.data["TUTAR"]);
      setStartDATE(new Date(props.data["BAŞLANGIÇ TARİHİ"]));
      setEndDATE(new Date(props.data["BİTİŞ TARİHİ"]));
      setNote(props.data["NOTLAR"]);
      setIS_ACTIVE(props.data["IS_ACTIVE"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    function getDropDownValues(input,set){
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,
          "category": input,
          "type" : "GET_GLOBAL",
          
      })
      .then((response) => {
        var result = [];
        response.data.forEach( element =>
          { 
           result.push({"label" : element.VALUE , "value" : element.ID})
          }
         );
        set(result);
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });
    }

    const {fetchIdToken} = useUserToken();

    async function sendData(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
     let unformatAmount = amount;
     if(amount.replace != undefined){
       unformatAmount = Number(amount.replace("₺ ","").replace(".","").replace(",","."));
     }

      console.log(unformatAmount);
      const api = configdata.API_BASE + '/cardata';
      axios.post(api,
        {
          "ENV" :configdata.APP_ENV ,
            "type": props.type == 'EDIT' ? "UPDATE_INSURANCE" : "NEW_INSURANCE_DOC",
            "category" : "KASKO",
            "DOCUMENT_NO" : documentNO,
            "START_DATE": StartDATE,
            "END_DATE" : EndDATE,
            "AMOUNT" : unformatAmount,
            "INSURENCE_FIRM_ID": insurenceFirm,
            "AGENT_FIRM_ID" :agentFirm,
            "EMERGENCY_HELP_NO":emergencyHelpNo,
            "NOTES" :note,
            "CAR_ID" : props.cardata["NO"],
            "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
            "ID" : props.data["NO"] != undefined ? props.data["NO"] : 0,
        },{headers}
      ).then((response) => {
        console.log(response);
        if(response.data["errorMessage"] != null){
            messageToggle("KASKO Poliçe Bilgileri eklenemedi, Bağlantınızı kontrol edin", "error")
        }else
        {
          if(response.data.split("_")[0] == "SUCCESS"){
            messageToggle("KASKO Poliçe Bilgileri Başarıyla Eklendi", "success")
            fileUploaderRef.current.load(response.data.split("_")[1]);
            // props.reset();
          }
          else{
            messageToggle("KASKO Poliçe Bilgileri eklenemedi, Bağlantınızı kontrol edin", "error")
          }
        }
        
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("KASKO Poliçe Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

    // function sendAndLoad(){
    //   fileUploaderRef.current.load();
    // }

    useEffect(() => {
      if(props.type == "EDIT"){getEditData()}
      getDropDownValues("INSURENCE_FIRM",setInsurenceFirmsAll);
      getDropDownValues("AGENT_FIRM",setAgentFirmsAll);
    },[])


    
    return(

          <View style= {{flexDirection: 'column',width: '100%',flex: 1}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
                    <View style={styles.form}>
                        <Group header={"Poliçe No"}>
                          <Input value={documentNO} style={styles.standard} onChange={ (value) => {setdocumentNO(value);}} name= {"Poliçe No"} /> 
                        </Group>

                        <Group header={"Başlangıç Tarihi"}>
                          <DatePicker value={StartDATE} style={styles.standard} isoWeek = {true} onSelect={(value) => {setStartDATE(value);}} oneTap format="dd-MM-yyyy" locale={locale} ></DatePicker>
                        </Group>

                        <Group header = {"Bitiş Tarihi"}>
                            <DatePicker value={EndDATE} style={styles.standard} isoWeek = {true} onSelect={(value) => {setEndDATE(value);}} oneTap format="dd-MM-yyyy" locale={locale} ></DatePicker>
                        </Group>
                        
                        <Group header={"Sigorta Firması"} >
                          <SelectPicker value={insurenceFirm} style={styles.standard}  onSelect={(value) => { setInsurenceFirm(value)}} data = {InsurenceFirmsAll} ></SelectPicker>
                        </Group>
                      
                        <Group header={"Acil Yol Yardım No"}>
                          <Input value={emergencyHelpNo} style={styles.standard}  onChange={ (value) => {setEmergencyHelpNo(value);}} name= {"acil yol yardım no"} /> 
                        </Group>
                  
                        <Group header = {"Tutar"} >
                          <MaskedInput mask={numberMaskTL} value={amount} style={styles.standard} min={0} onChange={ (value) => {setAmount(value);}} name= {"tutar"}  />
                        </Group>
                        <Group header={"Acente"}>
                          <SelectPicker value={agentFirm} style={styles.standard} onSelect={(value) => { setAgentFirm(value)}} data = {AgentFirmsAll} ></SelectPicker>
                        </Group>
                        {
                          props.type == "EDIT" &&
                          <Group header = {"Durum"}>
                            <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                          </Group>
                        }
                        <Group header={"Notlar"}>
                          <Input style={styles.standard} value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"} /> 
                        </Group>
                        <Group header={"Dosya"}>
                          <FileLoader reset={props.reset} ref = {fileUploaderRef} fileCategory = {"KASKO"} carID = {props.cardata["NO"]} ></FileLoader>
                        </Group>
                      </View>
              <View style={{height: 50}}></View>
              <Button
                  disabled = {!activateAddButton}
                  style={styles.buttonStyle}
                  onClick={() => { sendData();}}
                > <CustomText fontWeight = {800}  fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>
          </View>

    )
}



const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{ marginRight: 5 }}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);



const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  form: {
    width: '100%',
  },
  standard: { flex: 1 , maxWidth: 350, minWidth: 150, width: '100%'},
  input: {
    flex: 1,
  },
});




const numberMaskTL = createNumberMask({
  mask: Number,
  prefix: '₺ ',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
})





export default KaskoScreen;