import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify'; // Assuming you're using AWS Amplify
import axios from 'axios';
import configData from "../config/config.json";

const useUserToken = () => {
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);

  const fetchUserToken = async () => {
    try {
            const authUser = await Auth.currentAuthenticatedUser({ bypassCache: false });
      const userJson = {
        username: authUser.username,
      };
      const userStr = JSON.stringify(userJson);
      return userStr;
      
    } catch (error) {
      console.error("Failed to fetch user token", error);
      // Handle the error appropriately
    }
  };

  const fetchIdToken = async () => {
    const currentSession = await Auth.currentSession();
    const token = currentSession.getIdToken().getJwtToken();
    return token;
  }

  const [attributesC,setAttributes] = useState()

  async function get_curent_user (){
      const authUser = await Auth.currentAuthenticatedUser({bypassCache: true});
      const { attributes } =  authUser;
      sessionStorage.setItem('user', JSON.stringify(attributes));
      return attributes;
  }
  

  async function fetchAuthGroup (){
    const authUser = await Auth.currentAuthenticatedUser({bypassCache: true});
    const { attributes } =  authUser;
    const AuthGroupID =  attributes["custom:AuthGroupID"];
    return AuthGroupID;
  }

  const fetchUserID = async () => {
    const authUser = await Auth.currentAuthenticatedUser({bypassCache: true});
    const { attributes } =  authUser;
    const UserID =  attributes["custom:rds_user_id"];
    return UserID;
  }


  return { 
    user, 
    idToken,
    fetchUserToken,
    fetchAuthGroup,
    fetchIdToken,
    fetchUserID,
    get_curent_user
  };
  
};

export  {useUserToken};
