import { View, Text ,StyleSheet} from 'react-native';
import { useEffect , useState } from 'react';
import { Button , MultiCascader ,Form,List,SelectPicker, IconButton, Input} from 'rsuite';
import axios from 'axios';
import configdata from '../../config/config.json'
import formatter from "../../utils/formatter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import {useDropdownData} from "../../hooks/useDropdownData";


const KasaScreen = () => {

    const [data,setData] = useState([]);
    const {getDropDownValues} = useDropdownData();
    const [choosen,setChoosen] = useState(undefined);

    useEffect(() => {
        getDropDownValues("CAR_BODY_STYLES",setData);
    },[])

    function reset(){
        getDropDownValues("CAR_BODY_STYLES",setData);
        toggleEdit(undefined);
    }

    function toggleEdit(item){
        if(choosen != undefined && choosen.id == item.id){
            setChoosen(undefined);
        }else{
            console.log(item);
            setChoosen(item);
        }
    }

    return (
        <View style={[styles.cardView,{padding: 50,flex: 1}]}>
            <View style={{flex: 1, width: '100%',}}>
                <Text style={{fontWeight: 'bold',marginBottom: 10}}> Kasalar </Text>
                <List hover>
                    {data.map((item,index) => {
                        return(
                            <List.Item key={index} index={index} style={{padding: 10}}>
                                <View style={{flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center' , height: 40}}>
                                    {
                                        choosen != undefined && choosen.value == item.value &&
                                        <NewComponentLabel type="UPDATE" id={item.value} category="CAR_BODY_STYLES"  reset={reset}></NewComponentLabel>
                                    }
                                    <Text>{item.label}</Text>
                                    <IconButton icon={<Icon><FontAwesomeIcon icon={"edit"}></FontAwesomeIcon></Icon>} onClick={() => {toggleEdit(item)}} />
                                </View>
                            </List.Item>
                        )
                    })}
                </List>
                <View>
                        <NewComponentLabel type={"NEW"} category="CAR_BODY_STYLES" reset={reset}></NewComponentLabel>
                </View>
            </View>
        </View>
    );
}




const NewComponentLabel = (props) => {

    const [value,setValue] = useState(undefined);

    function sendData(){
        const api = configdata.API_BASE + '/uidropdownlist';

        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : props.type == "UPDATE" ? "SET_OLD" : "SET_NEW",
            "category": props.category,
            "value" : value,
            "İSİM" : value,
            "IS_ACTIVE" : 1,
            "ID" : props.type == "UPDATE" ? props.id :  ""
        }
        console.log(body);
        axios.post(api,body).then((response) => {
            console.log(response);
            props.reset();
        }).catch((error) => {
            console.log("POST ERROR" + error);

        });
    }

    return(
        <View style={ props.type == "NEW" ? [styles.cardView,{height: 100, justifyContent: 'center'}] : {justifyContent: 'center'}}>
            <View style ={{justifyContent: 'center', flexDirection: 'row', alignItems :'center'}}>
                <Input placeholder="Yeni Değer" style={{width: 200, margin: 10}} onChange={setValue} value={value}></Input>
                <Button appearance="primary" style={{margin: 10}} onClick={() => {sendData();}}>{props.type == "UPDATE" ? "Güncelle" : "Ekle"}</Button>
            </View>
        </View>

    )
}



const styles = StyleSheet.create({
    cardView: {
        justifyContent : 'flex-start', //Centered vertically
        alignItems: 'center',
        margin: 10,
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 12,
        shadowColor: '#FFB6C1',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 12,
        elevation: 5,
    },
    headerLabel: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'powderblue',
        padding: 10,
        borderRadius: 12,
        margin: 10,
    }
}
);



export default KasaScreen;