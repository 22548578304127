import { Text, View , StyleSheet,ActivityIndicator, Image, TouchableOpacity,TextInput,ScrollView,Modal } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { useToaster,Schema,Message, Button, Form ,SelectPicker, Steps ,MaskedInput,Pagination,IconButton, Table,Input ,InputNumber, RadioGroup,ButtonGroup ,Radio, InputGroup, TagPicker, DateRangePicker, DatePicker, Panel, Drawer  } from 'rsuite';
import { Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-phone-input-2/lib/style.css'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../../aws-exports';
import locale from '../../../tr';
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import configData from '../../../config/config.json';
import FileLoaderInline from '../../../Components/FileManagements/FileLoaderInline';
import FileUploader from '../../../Components/FileManagements/FileUploader';
import * as XLSX from 'xlsx';
import NewRentAggreementDrawer from './NewRentAggreementDrawer';
import CustomText from '../../../Components/CustomText';

const NewRentAggreementScreen = () => {

     const {fetchIdToken} = useUserToken();
    const [cars,setCars] = useState([]);
    const [filteredCars,setFilteredCars] = useState([]); // [ {ID: 1, PLAKA: '34ABC34', ARAÇ: 'MERCEDES', ARAÇ_DURUM: 'KİRALIK'}, {ID: 2, PLAKA: '34ABC35', ARAÇ: 'BMW', ARAÇ_DURUM: 'KİRALIK'}
    const [file,setFile] = useState();
    const [starterFileData,setStarterFileData] = useState();
    
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }

    
    async function getCarsBasic(){
        console.log("get cars basic");
        const api = configdata.API_BASE + '/cardata'
        let token = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "ENV" : configData.APP_ENV,
            "type" : "GET_CARS_BASIC",
        }

        axios.post(api,body,{headers:headers}).then((response)=>{
            console.log(response.data);
            setCars(response.data);
        }
        ).catch((error)=>{
            console.log(error);
        })
    }



    useEffect(()=>{
        getCarsBasic();
    },[])

    const CustomCellCheckbox = ({rowData, dataKey, ...props}) => {
        const [value, setValue] = useState(false);
        const handleChange = (value) => {
        setValue(value);
        };

        return (
        <Table.Cell {...props}>
            <Radio checked={value} onChange={handleChange} />
        </Table.Cell>
        );
    }
    
    async function parseExcelFile (file){

        const tempCars = [];
        const reader = new FileReader();
    
        const readFile = (file) => {
            return new Promise((resolve, reject) => {
                reader.onload = (evt) => {
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
        
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
        
                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    const headers = data[0];
                    const rows = data.slice(1);
                    rows.forEach((row) => {
                        const carTemp = {};
                        row.forEach((cell, i) => {
                            carTemp[headers[i]] = cell;
                        });
                        tempCars.push(carTemp);
                    });
                    resolve(tempCars);
                }
                reader.readAsBinaryString(file);
            });
        }
    
        await readFile(file); // Wait for file reading to complete
    
        console.log("file parsed");
        console.log(tempCars);
    
        let filteredTemp = [];
        cars.forEach((car) => {
            tempCars.forEach((fileCar) => {
                if(car.PLAKA === fileCar.PLAKA){
                    console.log("car found");
                    let tempC = car;
                    tempC["PERIOD_PRICE"] = fileCar["FİYAT"];
                    filteredTemp.push(tempC);
                }
            })
        });
        console.log(filteredTemp);
        setFilteredCars(filteredTemp);
    }
    
    const fileTypes = ["XLSX", "CSV"]
    const DragDrop = () => {
        const handleChange = (file) => {
            // setFileName(file.name);
            //loadFile(file);
            setFile(file);
            parseExcelFile(file);
            console.log("file changed");

        };
        return (
            <View style={{marginVertical: 3, alignItems: 'flex-start', marginHorizontal: 3}}>
                <FileUploader style= {{width: '100%'}} handleChange={handleChange} name="file" types={fileTypes} />
            </View>

        );
    }


    const [openRentAggreementDrawer,setOpenRentAggreementDrawer] = useState(false);

    return (
        <View style={{flex:1,flexDirection:'column',justifyContent:'flex-start',alignItems:'center', padding: 20}}>
            <Panel header="Sözleşme Araçlarını Yükle" bordered style={{width: '100%' , backgroundColor: 'white' }}>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start' , alignItems :'center'}}>
                    <DragDrop ></DragDrop>
                    <View style={{width: 20}}></View>
                    <IconButton icon ={<Icon><FontAwesomeIcon icon={"plus"}></FontAwesomeIcon></Icon>}  onClick = {()=>{setOpenRentAggreementDrawer(true)}}> <CustomText> Sözleşme Oluştur </CustomText> </IconButton>
                </View>

            </Panel>
            <Drawer open={openRentAggreementDrawer} size={'full'} placement={'right'} onClose={()=>{setOpenRentAggreementDrawer(false)}} >
                <Drawer.Header>
                    <Drawer.Title> Sözleşme Oluştur </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <NewRentAggreementDrawer cars = {filteredCars} onClose={()=>{setOpenRentAggreementDrawer(false)}}></NewRentAggreementDrawer>
                </Drawer.Body>

            </Drawer>
            <View style={{height : 20}}></View>
            <Panel header="Araçlar" bordered style={{width: '100%' , backgroundColor: 'white'}}>
                <Table data={filteredCars} height={600} rowHeight={50}>
                    <Table.Column width={200} resizable>
                        <Table.HeaderCell> SEÇ </Table.HeaderCell>
                        <CustomCellCheckbox dataKey="ID" />
                    </Table.Column>
                    <Table.Column width={200} resizable>
                        <Table.HeaderCell> PLAKA </Table.HeaderCell>
                        <Table.Cell dataKey="PLAKA" />
                    </Table.Column>
                    <Table.Column width={200} resizable>
                        <Table.HeaderCell> ARAÇ </Table.HeaderCell>
                        <Table.Cell dataKey="ARAÇ" />
                    </Table.Column>
                    <Table.Column width={200} resizable>
                        <Table.HeaderCell> DURUM </Table.HeaderCell>
                        <Table.Cell dataKey="ARAÇ_DURUM" />
                    </Table.Column>
                    <Table.Column width={200} resizable>
                        <Table.HeaderCell> FİYAT </Table.HeaderCell>
                        <Table.Cell dataKey="PERIOD_PRICE" />
                    </Table.Column>
                </Table>
            </Panel>
        </View>
    )
}

export default NewRentAggreementScreen;