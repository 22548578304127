import { Text, View ,Image} from 'react-native'
import React, {  useState ,useEffect } from 'react'
import {useToaster, Message, Button,Modal ,Form ,DatePicker,TreePicker ,SelectPicker ,InputNumber  } from 'rsuite';
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { SvgUri } from 'react-native-svg';

const BrandIconManager = () => {
    return (
      <View>
        <Text>BrandIconManager</Text>
      </View>
    )
  
}

const BrandSvg = (props) => {
    const[url,setURL] = useState("");

    useEffect(()=>{
        // getURL("BrandIcons/FIAT.svg")
        getURL("BrandIcons/" + props.brand + ".svg");
    },[])

    useEffect(()=>{
        // getURL("BrandIcons/FIAT.svg")
        // console.log(url);
    },[url])
    const getURL = async (input) => {
        if(input != null && input != undefined){
            await Storage.get(input,{ 
                expires: 120,
                level: 'public/'
              /*   identityId: '2cd3970d-b634-4bf1-b27a-86fc58ff3fa7' */
            }).then
            (data => {
                setURL(data);
                // console.log("----- url name " + data);
                return(data);
            }).catch(
                err => {
                    // console.log("storage error " + err)
                }
            );
        }
     
    } 
    return(
       
    <View style={{padding: 5, marginHorizontal: 10}}>
        {url.length > 0 && 
        //  <SvgUri uri={url} width="2em" height="2em" >
        //  </SvgUri>
        <Image
        resizeMode='contain'
        style={{width: '32px' , height : '32px'}}
        source={url}
        />}
        
       

    </View>
    )
}

export {BrandSvg};
   