import { Text, View , StyleSheet,ScrollView } from 'react-native'
import React, { useState, useEffect ,useRef, forwardRef, useImperativeHandle } from 'react'
import axios from 'axios';
import { useToaster,Schema  ,Message, Button, Form ,DatePicker,TreePicker ,SelectPicker ,InputGroup, MaskedInput,Popover, Input,IconButton, Modal, List  } from 'rsuite';
import { NumericFormat } from 'react-number-format'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import locale from '../../tr';
import "rsuite/dist/rsuite.min.css";
import configdata from '../../config/config.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { useUserToken } from '../../hooks/useUserToken';

const NewCarScreen = forwardRef((props,ref) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }

    //#region Number Mask
    var formatterKm = new Intl.NumberFormat('tr-TR', {
      style: 'unit',
      unit: 'kilometer',
    });

    var formatterStandard = new Intl.NumberFormat('tr-TR', {
      style: 'unit',
      unit: 'kilometer',
    });
    //#endregion

    //#region Styles
    const styles = {
      buttonStyle:{
        textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
      },
      buttonStyleRed:{
        textAlign: 'center', backgroundColor : '#d9534f',color: 'white', borderRadius: 7,width: 120
      },
      iconStyleRed:{
        textAlign: 'center', backgroundColor : '#d9534f',color: 'white'
      },
      iconStyleGreen:{
        textAlign: 'center', backgroundColor : '#5cb85c',color: 'white'
      },
      buttonStyleGreen:{
        textAlign: 'center', backgroundColor : '#5cb85c',color: 'white', borderRadius: 7,width: 120
      },
      wideButtonStyle:{
        textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 200
      }
    }

    const item_style = {
      header: {fontWeight: 'bold'},
      standard: { width : 200 },
      short: { width : 100 },
      viewStyle: { backgroundColor: 'white', height : 300 ,borderRadius: 5, width:'90%',justifyContent: 'center', alignItems :'space-between', padding : 25, marginVertical : 25},
      numeric :{width : 200 ,borderStyle: 'solid', borderWidth: '1px',borderColor :'#e5e5ea', borderRadius: 5, height: 35, outlineColor: '#4d98f7', outlineWidth: '1px' , padding : 5}
    }
    //#endregion

    //#region consts UseState
    const [activateAddButton,setActiveAddButton] = useState(true);

    // COMBO BOX ARRAYS
    const [disabledBMs, setDisabledBMs] = useState([]);
    const [optionsBMP,setOptionsBMP] = useState([]);
    const [optionsCarTags,setOptionsCarTags] = useState([]);
    const [carBodyStyles,setCarBodyStyles] = useState([]);
    const [factory_years,setfactory_years] = useState([]);
    const [fuel_types,setFuel_types] = useState([]);
    const [cc_types,setcc_types] = useState([]);
    const [carOwners,setCarOwners] = useState([]);
    const [colors,setColors] = useState([]);
    const [transmissions,settransmissions] = useState([]);
    const [km_periods,setKm_periods] = useState([]);
    const [araçDurumlar,setAraçDurumlar] = useState([]);
    const [satimAlimYerleri,setSatimAlimYerleri] = useState([]);
    
    // SENT VALUES

    const [BMP,setBMP] = useState(["","",""])
    const [BMP_ID,setBMP_ID] = useState([0,0,0])
    const [plaka,setPlaka] = useState("");

    const [carBodyStyle,setCarBodyStyle] = useState(0);
    const [carBodyStyleName,setCarBodyStyleName] = useState("");
    const [factory_year,setFactoryYear] = useState(0);
    const [fuel_type,setFuel_type] = useState(0);
    const [fuel_typeName,setFuel_typeName] = useState("");
    const [cc_type,setcc_type] = useState(0);
    const [transmission,settransmission] = useState(0);
    const [transmissionName,settransmissionName] = useState("");
    const [HP_value,setHP_value] = useState("");
    const [KW_value,setKW_value] = useState("");
    const [sasi,setSasi] = useState("");
    const [EngineNO,setEngineNO] = useState("");
    const [carColor,setCarColor] = useState("");
    const [radioCode,setRadioCode] = useState("");
    const [RUHSAT_NO,setRUHSAT_NO] = useState("");
    const [color,setColor] = useState(0);
    const [colorName,setColorName] = useState("");
    const [isMounted,setIsMounted] = useState(false);

    const [satimAlimYeri,setSatimAlimYeri] = useState(0);
    const [satimAlimYeriCode,setSatimAlimYeriNameCode] = useState("");
    const [araçDurum, setAraçDurum] = useState(1);
    const [cartag,setCarTag] = useState(["","",""])
    const [cartag_id,setCartag_id] = useState("")
    const [tescilTarihi,setTescilTarihi] = useState(null);
    const [firsttescilTarihi,seFirstTescilTarihi] = useState(null);
    const [KM,setKM] = useState("0");
    const [km_period,setKm_period] = useState(0);
    const [CC_SECTION,setCC_SECTION] = useState("");
    const [CC_SECTION_value,setCC_SECTION_value] = useState("");
    const [KM_valueStr,setKM_valueStr] = useState("0");
    const [HGSNO,setHGSNO] = useState("");
    const [TrafficInsurenceNO,setTrafficInsurenceNO] = useState("");
    const [TrafficInsurenceDate,setTrafficInsurenceDate] = useState("");
    const [KaskoNO,setKaskoNO] = useState("");
    const [KaskoDATE,setKaskoDATE] = useState("");
    const [MuayeneDATE,setMuayeneDATE] = useState("");
    const [carOwner,setCarOwner] = useState("");
    const [hpDisabled,setHpDisabled] = useState(0);
    const [kwDisabled,setKwDisabled] = useState(0);
    const [rowID,setRowID] = useState(0);
    //#endregion

    //#region Functions
    function reset(){
      console.log("reset")
      console.log(props.data)
      setBMP([props.data["MARKA_NAME"],props.data["MODEL_NAME"],props.data["PAKET_NAME"]])
      setBMP_ID([props.data["MARKA"],props.data["MODEL"],props.data["PAKET"]])
      setCarBodyStyle(props.data["KASA_TİPİ"]);
      setFactoryYear (props.data["MODEL_YILI"]);
      setFuel_type(props.data["YAKIT_TİPİ"]);
      setcc_type(props.data["CC"]);
      settransmission (props.data["VİTES_TİPİ"]);
      setCarColor(props.data["RENK"]);
      setHP_value(props.data["HP"]);
      setKW_value(props.data["KW"]);
      setColor(props.data["RENK_ID"]);
      setRowID(props.data["ID"]);

      // setCarTag(["","",""])
      // setCartag_id("")
      // setTescilTarihi(null);
      // seFirstTescilTarihi(null);
      // setPlaka("");
      // setKM(0);
      // setKM_valueStr("");
      // setKm_period (-1);
      // setCC_SECTION ("");
      // setSasi ("");
      // setEngineNO ("");
      // setHGSNO("");
      // setTrafficInsurenceNO("");
      // setTrafficInsurenceDate("");
      // setKaskoNO ("");
      // setKaskoDATE ("");
      // setMuayeneDATE("");
      // setRUHSAT_NO("");
      // setCarOwner(0);
      // setRadioCode("");
    }


    //#endregion

    //#region Use Effect Section
    useEffect(() => {
      getBrands();
      getCarTags();
      getDropDownValues("CAR_BODY_STYLES",setCarBodyStyles);
      getDropDownValues("SERVICE_KM_PERIOD",setKm_periods);
      getDropDownValues("TRANSMISSIONS",settransmissions);
      getDropDownValues("FUEL_TYPES",setFuel_types);
      getDropDownValues("CC_TYPES",setcc_types);
      getDropDownValuesGlobal("CAR_OWNER",setCarOwners);
      getDropDownValuesGlobal("COLOR",setColors);
      getDropDownValuesService("GET_LAST_50_YEARS",setfactory_years);
      // getDropDownValues("SATIN_ALIM_YER",setSatimAlimYerleri);
      getClients();
      getParseAraçDurum();
      setIsMounted(true);
      reset();
    },[])

  
    function getClients(){
      console.log("getClients");
      const api = configdata.API_BASE + '/customers';
        axios.post(api,{
          "ENV" : configdata.APP_ENV,
          "type" : "GET_CLIENTS_TEDARIK"
        }).then(
            (response) => {
                console.log(response.data);
                let temp = [];
                  response.data.forEach(element => {
                      if(element.NAME != null && element.NAME != ""){
                          temp.push({"label" : element.NAME , "value" : element.ID ,"code" : "X" +element.ID + "X"})
                      }
                  });
                  console.log(temp);
                  setSatimAlimYerleri(temp);
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
  }
    //#region Use Effect parsers
    useEffect(() => { fixPlaka(); },[plaka])
    useEffect(() => {if(HP_value != null || HP_value != undefined)setKwDisabled(String(HP_value / 1.3596216173).substring(0,5))},[HP_value])
    useEffect(() => {if(KW_value != null || KW_value != undefined)setHpDisabled(String(KW_value * 1.3596216173).substring(0,5))},[KW_value])
    useEffect(() => {if(sasi != null || sasi != undefined)setSasi(sasi.toUpperCase())},[sasi])
    useEffect(() => {if(radioCode != null || radioCode != undefined) setRadioCode(radioCode.toUpperCase())},[radioCode])
    useEffect(() => {if(RUHSAT_NO != null || RUHSAT_NO != undefined) setRUHSAT_NO(RUHSAT_NO.toUpperCase())},[RUHSAT_NO])
    useEffect(() => {if(EngineNO != null || EngineNO != undefined) setEngineNO(EngineNO.toUpperCase())},[EngineNO])
    useEffect(() => {
      if((props.type == "UPDATE" && isMounted) || (props.type == "UPDATE_ON_ROW" && isMounted)) {
        editCar();

      }},[isMounted])

    //#endregion
    
    //#region Parse Functions
    function parseKM(value){
      let newValue = "";
      for(let i = 0; i < value.length; i++){
        if(value[i] != "."){
          newValue += value[i];
        }
      }
      return newValue;
    }

    function getParseAraçDurum(){
      const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "category": "ARAÇ_DURUM",
          "hasParent": "NO",
          "type" : "GET",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
            if(element.PARENT == -1){
              result.push({"label" : element.İSİM , "value" : element.ID, children : []})
            }
          }
         );

         response.data.forEach( element =>
          {
            if(element.PARENT != -1){
              result.find( parent => parent.value == element.PARENT).children.push({"label" : element.İSİM , "value" : element.ID})
            }
          });
        setAraçDurumlar(result);
        // console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });
    }

    //#endregion
    //#endregion

    //#region Get UI Data
    function parseBrands(input){
      var result= [];
      var Brands = [];
      input.forEach(element => {
        if(element.LEVEL == "1"){
          Brands.push( {"label": element.İSİM, "id" : element.ID ,"value": element.İSİM  ,"children": [] })
        }
      });
      input.forEach(element => {
        if(element.LEVEL == "2"){
          Brands.find(brand => brand.id == element.PARENT_ID).children.push({"label": element.İSİM, "id" : element.ID , "value": element.İSİM ,"children": [] })
        }
      });

      input.forEach(element => {
        if(element.LEVEL == "3"){
          Brands.forEach(brand => {
           // console.log(brand.children.length);
            brand.children.forEach(model => 
              { 
                if(model.id == element.PARENT_ID){
                 // console.log(model.children);
                  model.children.push({"label": element.İSİM, "id" : element.ID ,"value": element.İSİM })
              }}
            )
          
            // push({"label": element.İSİM, "value": element.ID ,"children": [] }
            // if(brand.children.length > 0 ){
            //  
            // }
          })
        }
      });

    
      Brands.forEach(element => {
        result.push(element);
      })
      // result.push(Brands[0]);
      // result.push(Brands[1]);
      // console.log(Brands[0]);
      setOptionsBMP(result);
    }
    function getBrands(){
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "type" : "GET_BRAND_TREE",
          
      })
      .then((response) => {

        parseBrands(response.data);
        getBrandModelArray(response.data);
      })
      .catch((error) => {
        console.log(error);
        
      });
    }

    function parseCarTags(input){
      var result= [];
      var heads = [];
      var otherLevels = [] // objects for not added to level 1 and 2
      input.forEach(element => {
        if(element.PARENT_ID == "-1"){
          heads.push( {"label": element.İSİM, "id" : element.NO ,"value": element.İSİM  ,"children": [] })
        }
      });
      input.forEach(element => {
        if(element.PARENT_ID != "-1"){
          var cond = false;
          heads.find(head => {
            cond =  head.id == element.PARENT_ID;
            return head.id == element.PARENT_ID}).children.push({"label": element.İSİM, "id" : element.NO , "value": element.İSİM ,"children": [] })
            if(!cond){
                otherLevels.push(element);
            }
        }
      });

      setOptionsCarTags(heads);
    }

    const {fetchIdToken} = useUserToken();
    
    async function getCarTags(){
      const idToken = await fetchIdToken()
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        'Authorization': `Bearer ${idToken}`
      };
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "type" : "GET_CAR_TAGS_ALL",
          
      },{headers})
      .then((response) => {
        // console.log(response.data);
        parseCarTags(response.data);
      })
      .catch((error) => {
        console.log(error);
        
      });
    }

    function getDropDownValues(input,set){
   
      const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "category": input,
          "hasParent": "NO",
          "type" : "GET",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
            var label = element.İSİM
            if(input == "SERVICE_KM_PERIOD"){
              label = formatterKm.format(label)
            }
            if(input.includes("(lt)")){
              label = formatterKm.format(label)
            }

            if(element.İSİM == null){
              label = element.VALUE
            }

            if(element.CODE != null){
              result.push({"label" : label ,value: element.ID, "code" : element.CODE})
            }else{
              result.push({"label" : label ,value: element.ID})
            }

          }
         );
        set(result);
        // console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });

    }

    function getDropDownValuesGlobal(input,set){
   
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "category": input,
          "type" : "GET_GLOBAL",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
           result.push({"label" : element.VALUE , "value" : element.ID})
          }
         );
        set(result);
        // console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });

    }

    function getDropDownValuesService(input,set){
   
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "hasParent": "NO",
          "type" : input,
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        (response.data).forEach( element =>
          { 
            result.push({"label" : element , "value" : element})
          }
          );

        set(result);
        // console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });

    }

    function getBrandModelArray(input){
      var result = [];
      input.forEach(element => {
        if(element.LEVEL == 1 ||element.LEVEL == 2){
            result.push(element.İSİM);
        }
      })
      setDisabledBMs(result);
    }
    
    const getSelectedBrandModels = () => {
        if(optionsBMP.length == 0 || undefined){
          return;
        }else{
          if(BMP_ID[0] != 0 && BMP_ID[0] != undefined){
          return optionsBMP.find((element) => element.id == BMP_ID[0]).children;
          }else{
            return;
        }}
    }

    const getSelectedModelPacks = () => {
       if(optionsBMP.length == 0 || undefined){
         return;
       }else{
         if(BMP_ID[1] != 0 && BMP_ID[1] != undefined){
         return optionsBMP.find((element) => element.id == BMP_ID[0]).children.find((element) => element.id == BMP_ID[1]).children;
         }else{
           return;
       }}
   }
      
    //#endregion
 
    //#region RULES

      const { StringType, NumberType,ArrayType ,ObjectType} = Schema.Types;
      function fixPlaka(){
        var plakatemp = plaka;
        var letter_count = 0;
        for(var i = 0 ; i < plakatemp.length ; i++){
            if(!is_numeric(plakatemp.charAt(i)) && plakatemp.charAt(i) != " "){
              letter_count ++
            }
        }
        plakatemp = plakatemp.toUpperCase();
        
        if(plakatemp.length > 2 && plakatemp.charAt(2) != " "){
          plakatemp = setCharAt(plakatemp,1,plakatemp.charAt(1) + " ")
        }
        if(letter_count == 1){
          if(plakatemp.length > 4 && plakatemp.charAt(4) != " " && !is_numeric(plakatemp.charAt(3)) ){
         
            plakatemp = setCharAt(plakatemp,3,plakatemp.charAt(3) + " ")
          }
        }
        else if(letter_count == 2){
          if(plakatemp.length > 5 && plakatemp.charAt(5) != " " && !is_numeric(plakatemp.charAt(4)) ){
         
            plakatemp = setCharAt(plakatemp,4,plakatemp.charAt(4) + " ")
          }
        }
        else{
          if(plakatemp.length > 6 && plakatemp.charAt(6) != " " ){
              plakatemp = setCharAt(plakatemp,5,plakatemp.charAt(5) + " ")
          }
        }
       
        setPlaka(plakatemp);
      }
      function setRuleKM(inputKM){
        let kmParsed = parseKM(inputKM.replace(".","").replace(" KM",""));
        let newKMRule; // Declare a new variable to store the updated KMRule
        if (inputKM === "") {
          newKMRule = StringType().isRequired("KM Alanı Boş bırakılamaz");
        } else {
          if (kmParsed.length >= 6) {
            console.log("KM " + kmParsed.length + " hane girdi");
            newKMRule = StringType().isRequired("Km boş bırakılamaz").addRule((value, data) => {
              if (value.replace(".","").replace(" KM","").length > 6 ) {
                return false;
              }
              return true;
            }, "Km 999.999 KM den fazla olamaz");
          } else {
            newKMRule = StringType().addRule(() => true);
          }
        }
        setKMRule(newKMRule); 
      }
      const [KMRule, setKMRule] = useState(null);
      const şaseRule = StringType().isRequired("Şase numarası boş bırakılamaz");
      const motorNoRule = StringType().isRequired("Motor numarası boş bırakılamaz");
      const kasaRule = NumberType().isRequired("Kasa boş bırakılamaz");
      const yakıtRule = NumberType().isRequired("Yakıt boş bırakılamaz");
      //#region  PLAKA rules
      const plakaRule = StringType()
      .isRequired("plaka değeri boş olamaz")
      .addRule((value,data) => is_numeric(value.charAt(0)) && is_numeric(value.charAt(1))  ,"ilk 2 hane sayı olmalıdır")
      .addRule((value,data) => !is_numeric(value.charAt(2)),"üçüncü hane sayı olamaz")
      .addRule((value,data) => {
        var letter_count = 0;
        for(var i = 0 ; i < value.length ; i++){
            if(!is_numeric(value.charAt(i)) && value.charAt(i) != " "){
              letter_count ++
            }
        }
        if(letter_count > 3){
          return false
        }
        return true
      },"Harf Sayısı 3 ü geçemez")
      .addRule((value,data) => {
        if(value.length > 2 ){
          return !is_numeric(value.charAt(3))
        }
        return true
      },"İl kodundan sonra harf gelmelidir")
      .addRule((value,data) => {
        var letter_count = 0;
        for(var i = 0 ; i < value.length ; i++){
            if(!is_numeric(value.charAt(i)) && value.charAt(i) != " "){
              letter_count ++
            }
        }
        var arr = value.split(" ");
        if(letter_count == 1){
          if(arr.length == 3){
            if(arr[2].length != 4)
              return false
          }
        }
        return true
      },"Tek harfli plakalarda plaka kodu 4 hane olmalıdır")
      .addRule((value,data) => {
        var letter_count = 0;
        for(var i = 0 ; i < value.length ; i++){
            if(!is_numeric(value.charAt(i)) && value.charAt(i) != " "){
              letter_count ++
            }
        }
        var arr = value.split(" ");
        if(letter_count == 2){
          if(arr.length == 3){
            if(arr[2].length < 3  || arr[2].length > 4 )
              return false
          }
        }
        
        return true
      },"İki harfli plakalarda plaka kodu 3 veya 4 hane olmalıdır")
      .addRule((value,data) => {
        var letter_count = 0;
        for(var i = 0 ; i < value.length ; i++){
            if(!is_numeric(value.charAt(i)) && value.charAt(i) != " "){
              letter_count ++
            }
        }
        var arr = value.split(" ");
        if(letter_count == 3){
          if(arr.length == 3){
            if(arr[2].length < 2  || arr[2].length > 3 )
              return false
          }
        }
        
        return true
      },"Üç harfli plakalarda plaka kodu 2 veya 3 hane olmalıdır")
      .addRule((value,data) => {
        if(value.length < 8){
          return false
        }
        return true
      },"girilen plaka uzunluğu en az 7 olmalıdır")

      //#endregion

      //#region  RUHSAT SERİ NO RULES
      const ruhsatRule = StringType()
      .addRule((value,data) => {
        if(!is_numeric(value.charAt(0)) && !is_numeric(value.charAt(1))){
          return true
        }
        return false
      },"ilk 2 hane harf olmalıdır")
      .addRule((value,data) => {
        var letter_count = 0;
        for(var i = 0 ; i < value.length ; i++){
            if(!is_numeric(value.charAt(i)) && value.charAt(i) != " "){
              letter_count ++
            }
        }
        if(letter_count > 2){
          return false
        }
        return true
      },"Harf Sayısı 2 olmalıdır")
      .addRule((value,data) => {
        if(value.length != 8){
          return false
        }
        return true
      },"girilen seri no uzunluğu 8 olmalıdır").addRule((value,data) => {
        let spaceCount = 0;
        for(var i = 0 ; i < value.length ; i++){
          if(value.charAt(i) == " "){
            spaceCount ++
          }
        }
        if(spaceCount > 0){
          return false;
        }
        return true;

      },"Boşluk Bırakılamaz")
      //#endregion
      
      const formRef1  = useRef();

      function checkForms(){
        if(props.type == "UPDATE_ON_ROW"){
          rowUpdate();
        }else{
          var ref1value= formRef1.current.check();
          if (ref1value) {
              rowUpdate();
          }else{
            console.error('Form Error');
            return;
          }
        }
      
      }

      function setCharAt(str,index,chr) {
        if(index > str.length-1) return str;
        return str.substring(0,index) + chr + str.substring(index+1);
      }
      function is_numeric(str){
        return /^\d+$/.test(str);
      }
      const numberMaskKM = createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol : '.',
        length: 8,
        suffix: ' KM'// This will put the dollar sign at the end, with a space.
      })
      const numberMaskCC = createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol : '.',
        suffix: ' CC' // This will put the dollar sign at the end, with a space.
      })
      
    
    //#endregion
    function rowUpdate(){
      const body = {
        "MARKA_NAME": BMP[0],
        "MODEL_NAME": BMP[1],
        "PAKET_NAME": BMP[2],
        "KASA_TİPİ_NAME" : carBodyStyleName,
        "VİTES_TİPİ_NAME" : transmissionName,
        "YAKIT_TİPİ_NAME" : fuel_typeName,
        "RENKID_NAME": colorName,
        "MARKA": BMP_ID[0],
        "MODEL": BMP_ID[1],
        "PAKET": BMP_ID[2],
        "KASA_TİPİ" : carBodyStyle,
        "VİTES_TİPİ" : transmission,
        "YAKIT_TİPİ" : fuel_type,
        "CC" : cc_type,
        "MODEL_YILI" : factory_year,
        "HP" : HP_value,
        "KW" : KW_value,
        "RENK": carColor,
        "RENK_ID": color,
        "ID": props.data["ID"],
        "SALES_CODE": satimAlimYeriCode,
        "SALES_CODE_NAME": satimAlimYeri,
        "Count" : props.data["Count"],
        "şasiNos": şasiNos,
        "PLAKA": "",
        "ARAC_KM": 0,
        "CC_SINIF": 0,
        "MOTOR_NO": "",
        "TESCİL_TARİHİ": null,
        "İLK_TESCİL_TARİHİ": null,
        "BAKIM_KM_PERİYODU": 0,
        "HGS_ETİKET_NO": "",
        "TRAFIK_POLİÇE_NO": "",
        "TRAFIK_POLICE_BİTİŞ_TARİHİ": null,
        "MUAYENE_BİTİŞ_TARİHİ": null,
        "RUHSAT_SERİ_NO": "",
        "RADIO_CODE": "",
        "KASKO_NO": "",
        "KASKO_BİTİŞ_TARİHİ": null,
        "ARAÇ_SAHİBİ": 0,
        "KULLANICI": 1
      }

      console.log(body);
      props.setRow(body);
  }
    useEffect(() => {
      rowUpdate();
      console.log("rowUpdate");
    },[carBodyStyle,BMP_ID,transmission,fuel_type,cc_type,factory_year,HP_value,KW_value,carColor,color,props.data["ID"],satimAlimYeriCode,satimAlimYeri]);



    const formControlRef = useRef(null);
    const ruleBMP = StringType().isRequired("Zorunlu Alan");
    const formStyles ={
      groupItem : {
        width: 200,
        marginHorizontal: 15,
      },
    }

    
    const [open, setOpen] = useState(false);
    const [effectTrigger, setEffectTrigger] = useState(0);

    const handleOpen = () => {
      setOpen(true);
      setEffectTrigger(prev => prev + 1); // Increment the trigger
    };

    const handleClose = () => {
      setOpen(false);
      setEffectTrigger(prev => prev + 1); // Increment the trigger
    };

    useEffect(() => {
      console.log("Şasi No Update");
      rowUpdate();
    }, [effectTrigger]); // Depend on the manual trigger


    const [şasiNos, setŞasiNos] = useState([]);

    function updateŞasiNo(){
      let temp = [];
      for(var i = 0 ; i < props.data["Count"] ; i++){
        if(şasiNos[i] != undefined){
          temp.push(şasiNos[i])
        }
        else
       { 
        temp.push("NONE" + i);
       }
      }
      console.log(temp);
      setŞasiNos(temp);
    }


    useImperativeHandle(ref, () => ({
      updateŞasiNos: () => {
        console.log("updateŞasiNos");
        updateŞasiNo();
      }
    }));

    function updateŞasiNoByIndex(index, value) {
      let temp = [...şasiNos]; // Creating a shallow copy of the array
      temp[index] = value;

      setŞasiNos(temp); // Assuming setŞasiNos is the state setter function
    }
    
    
    return(
        <View style= {{flexGrow:1,flexDirection: 'row', justifyContent: 'flex-start',paddingHorizontal:5}}>

        <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title> ŞASE NUMARALARI </Modal.Title>
        </Modal.Header>

        <Modal.Body>
              <View>
                <Text>TEST</Text>
                <List>
                  {şasiNos.map((item,index) => {
                    return(
                      <List.Item key={index} index={index} >
                        <Input value={şasiNos[index]}  onChange={(value) => {
                          updateŞasiNoByIndex(index,value);
                        }}></Input>
                      </List.Item>
                    )
                  })}
                    
                </List>
              </View>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
          </Modal>

          <View style ={{flexDirection: 'row'}} >
            <Button onClick={handleOpen}>
              Şaseleri Ekle
            </Button>
            <View style={formStyles.groupItem}>
                <SelectPicker placeholder={"Satın Alim Yeri"} value={satimAlimYeri} style={item_style.standard} onSelect={(value,item) => { setSatimAlimYeri(value); setSatimAlimYeriNameCode(item.code)}} data = {satimAlimYerleri} ></SelectPicker>
            </View>
            {/* marka model donanım */}
            <View style={formStyles.groupItem}>
              <SelectPicker
              // rule = {ruleBMP}
              style={item_style.standard}
              placeholder={"MARKA"} 
              value={BMP[0]} 
              onClean={() => {setBMP_ID([0,0,0]); setBMP(["","",""])}} 
              data ={optionsBMP} 
              onSelect={(item,value) => { setBMP([value.label,"",""]); setBMP_ID([value.id,0,0]); }} 
              ></SelectPicker>
            </View >
            <View style={formStyles.groupItem}>
              <SelectPicker
              // rule = {ruleBMP}
              placeholder={"MODEL"} 
              style={item_style.standard}
              value={BMP[1]} 
              onClean={() => {setBMP_ID([BMP_ID[0],0,0]); setBMP([BMP[0],"",""])}} 
              // accepter={SelectPicker} 
              data ={getSelectedBrandModels()} 
              onSelect={(item,value) => { setBMP_ID([BMP_ID[0],value.id,0]) ;setBMP([BMP[0],value.label,""]) }} >
              </SelectPicker>
            </View>
            <View style={formStyles.groupItem}>

            <SelectPicker 
            // rule = {ruleBMP}
            style={item_style.standard}
            placeholder={"PAKET"} 
            value={BMP[2]}
            // accepter={SelectPicker}
            data ={getSelectedModelPacks()}
            onSelect = { (item,value) => { setBMP_ID([BMP_ID[0],BMP_ID[1],value.id]); setBMP([BMP[0],BMP[1],value.label])}}
            ></SelectPicker>
            </View >

            {/* Model Tarihi */}
            <View style={formStyles.groupItem} >
            {/* <Form.ControlLabel style={item_style.header}> Model Yılı *</Form.ControlLabel> */}
            <SelectPicker placeholder={"MODEL YILI"} accepter={SelectPicker} rule={NumberType().isRequired("Model Yılı Seçilmeli")} name={"modelYear"} value={factory_year} style={item_style.standard} menuMaxHeight={400} onSelect={(value) => { setFactoryYear(value)}} data = {factory_years} ></SelectPicker>
            </View>
            
            {/* vites*/}
            <View style={formStyles.groupItem}>
              <SelectPicker placeholder={"VİTES TİPİ"} accepter={SelectPicker} name={"vites"} rule={NumberType().isRequired("Vites alanı boş bırakılamaz")} value={transmission} style={item_style.standard} 
              onSelect={(value,item) => { settransmission(value); settransmissionName(item.label)}} data = {transmissions} ></SelectPicker>
            </View>

            {/* KASA*/}
            <View style={formStyles.groupItem}>
              <SelectPicker placeholder={"KASA"}  name={"kasa"} rule={kasaRule} accepter={SelectPicker} value={carBodyStyle} style={item_style.standard} 
              onSelect={(value,item) => { setCarBodyStyle(value); setCarBodyStyleName(item.label)}} data = {carBodyStyles} 
              ></SelectPicker>
            </View>
            {/*CC*/}
            <View style={formStyles.groupItem}>
              <SelectPicker placeholder={"CC"}  name={"CC"}  accepter={SelectPicker} value={cc_type} style={item_style.standard} 
              onSelect={(value,item) => { setcc_type(value); }} data = {cc_types} 
              ></SelectPicker>
            </View>
            <View style={[formStyles.groupItem,{flexDirection: 'row'}]}>
                <MaskedInput guide= {false} mask={[/\d/,/\d/,/\d/]} value={HP_value}  onChange={ (value) => {setHP_value(value)}} name= {"hp_section"} />
                <InputGroup.Addon> {"~ KW " + kwDisabled}</InputGroup.Addon>
            </View>
            <View style={[formStyles.groupItem,{flexDirection: 'row'}]}>
                <MaskedInput guide= {false}  mask={[/\d/,/\d/,/\d/]} value={KW_value}  onChange={ (value) => {setKW_value(value)}} name= {"kw_section"} required={true}/> 
                <InputGroup.Addon> {"~ HP " + hpDisabled}</InputGroup.Addon>
            </View>
            {/* YAKIT*/} 
            <View style={formStyles.groupItem}>
              <SelectPicker placeholder={"YAKIT TİPİ"} name={"yakıt"} rule={yakıtRule} accepter={SelectPicker} value={fuel_type} style={item_style.standard} onSelect={(value,item) => { setFuel_type(value); setFuel_typeName(item.label) }} data = {fuel_types} ></SelectPicker>
            </View>
            <View style={formStyles.groupItem}>
                <SelectPicker placeholder={"RENK"} value={color} style={item_style.standard} onSelect={(value,item) => { setColor(value); setColorName(item.label)}} data = {colors} ></SelectPicker>
            </View>


            <View style={formStyles.groupItem}>
                <Input placeholder={"MARKA RENK"} value={carColor} style={item_style.standard} onChange={ (value) => {setCarColor(value);}} name= {"renk"} /> 
            </View>
            <Button
            style={styles.buttonStyle}
            onClick={() => { reset();}}
          > Sıfırla </Button>
          </View>

        </View>
    )
  });


  export default NewCarScreen;
  

  //   {/*Kullanıcı */}
  //   <Form.Group>
  //   <Form.ControlLabel> Kullanıcı </Form.ControlLabel>
  //   <Form.Control
  //     style={item_style.standard}
  //     accepter={TreePicker}
  //     name= {"carTag"}
  //     value={cartag_id}
  //     onSelect={(item,value) => treePickerChangeCarTags(item)}
  //     data = {optionsCarTags}
  //     defaultExpandAll = {true}
  //   ></Form.Control>
  //   <View style={item_style.standard}>
  //     {/* <TreePicker
  //     value={cartag_id}
  //     onSelect={(item,value) => treePickerChangeCarTags(item)}
  //     data = {optionsCarTags}
  //     defaultExpandAll = {true}
  //   ></TreePicker> */}
  //   <View style={{flexDirection: 'row'}}>
  //     <Text>{cartag[0] + " >> "}</Text>
  //     <Text>{cartag[1]+ " >> "}</Text>
  //   </View>
  //   </View>
  // </Form.Group>