import { Text, View ,StyleSheet} from 'react-native'
import React, { Children, PureComponent, useState , useEffect, useCallback, forwardRef, useImperativeHandle } from 'react'
import axios from 'axios';
import { useToaster,Schema,Message, Button, Form ,SelectPicker, Steps ,MaskedInput,Pagination,IconButton, Table,Input ,InputNumber, RadioGroup,ButtonGroup ,Radio, InputGroup, TagPicker, DateRangePicker, DatePicker, Panel, Drawer, Grid,Row, Col, Stack, Divider, List  } from 'rsuite';
import { Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-phone-input-2/lib/style.css'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import locale from '../../tr';
import { useUserToken } from '../../hooks/useUserToken';
import configData from '../../config/config.json';
import CustomText from '../../Components/CustomText';
import { useDropdownData } from '../../hooks/useDropdownData';
import { useMessage } from '../../hooks/useMessage';
import { Container } from 'aws-amplify-react-native';
import { createNumberMask } from 'text-mask-addons';
import * as PropTypes from "prop-types";


function PriceCalculator(props) {
    return null;
}

PriceCalculator.propTypes = {
    packageDetails: PropTypes.arrayOf(PropTypes.any),
    car: PropTypes.any
};


const SalesCarOfferDocWizard = forwardRef((props,ref) => {

    const {fetchIdToken} = useUserToken();
    const [cars,setCars] = useState([]);
    


    const [customerName,setCustomerName] = useState();
    const [customerSurname,setCustomerSurname] = useState();
    const [customerPhone,setCustomerPhone] = useState();
    const [customerEmail,setCustomerEmail] = useState();
    const [selectedCar,setSelectedCar] = useState(null);
    const [openNewCar,setOpenNewCar] = useState(false);
    const [openCarImage,setOpenCarImage] = useState(false);
    const [carImage,setCarImage] = useState();
    const [packageDetails,setPackageDetails] = useState([]);
    const [BazFiyatCarpani,setBazFiyatCarpani] = useState(1.0);
    const [OTVCarpani,setOTVCarpani] = useState(1.0);
    const [otvMatrah1,setOtvMatrah1] = useState(0);
    const [otvMatrah2,setOtvMatrah2] = useState(0);
    const [otvMatrah3,setOtvMatrah3] = useState(0);
    const [reductionRate,setReductionRate] = useState(0);
    const [campaignReductionRate,setCampaignReductionRate] = useState(0);
    const [withOTV,setWithOTV] = useState(false);
    const [finalPrice,setFinalPrice] = useState(0);

    useEffect(() => {
        let otv1 = selectedCar ? selectedCar.Object.PRICE * (1- BazFiyatCarpani) : 0;
        let otv2 = selectedCar ? (otv1) * OTVCarpani: 0;
        let otv3 = selectedCar ? selectedCar.Object.PRICE - selectedCar.Object.PRICE * reductionRate - selectedCar.Object.PRICE * campaignReductionRate : 0;
        setOtvMatrah1(otv1);
        setOtvMatrah2(otv2);
        setOtvMatrah3(otv3);
        let choosenMatrah = Math.max(otvMatrah1,otvMatrah2,otvMatrah3);
        let calcOTV = selectedCar  ? (choosenMatrah * selectedCar.Object.OTV / 100 + choosenMatrah) : 0;
        setWithOTV(calcOTV);
        let calcKDV = selectedCar ? (calcOTV * selectedCar.Object.KDV / 100 + calcOTV) : 0;
        setFinalPrice(calcKDV);
    },[BazFiyatCarpani,OTVCarpani,reductionRate,campaignReductionRate])


    function getCarsBasic(){
        const api = configData.API_BASE + '/sales-dep'
        let token = fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "type" : "GET_PORTFOY_CARS",
        }

        axios.post(api,body,{headers:headers}).then((response)=>{
            console.log(response.data);
            let tempData = [];
              response.data.map((item) => {
                console.log(item);
                tempData.push({label: item.NAME_DETAIL, value: item.ID , Object: item}
                )
            }
            )
            console.log(tempData);
            setCars(tempData);
        }
        ).catch((error)=>{
            console.log(error);
        })
    }

    function getPackageDetails(){
        const api = configData.API_BASE + "/sales-dep"
        const token = fetchIdToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "type": "GET_PACKAGE_DETAILS",
        }

        axios.post(api, body, {headers: headers}).then((response) => {
            console.log(response);
            setPackageDetails(response.data);    
        }
        ).catch((error) => {
            console.log(error);
        }

        )
    }


    useEffect(() => {
        getCarsBasic();
        getPackageDetails();
    }
    ,[])

    useImperativeHandle(ref, () => ({
        getCar : () => {
            
            if(selectedCar != null){
                let carDetails = [];
                let packageDetailsArr = selectedCar.Object.PACKAGE_DETAILS.split(",");
                console.log(packageDetailsArr);
                packageDetails.map((item) => {
                    if(packageDetailsArr.includes(item.CODE+"")){
                        carDetails.push(item.DESCRIPTION);
                        console.log(item.DESCRIPTION);
                    }
                }
                )

                let result = selectedCar;
                result.Object.PACKAGE_DETAILS_ARR = carDetails;
                console.log(result);
                return result;
            }
            else{
                return null;
            }
        },
        getCustomer : () => {
            return {
                name: customerName,
                surname: customerSurname,
                phone: customerPhone,
                email: customerEmail,
            }
        }
        ,
        getOffer : () => {
            return {
                price: 0,
                choosenImage: carImage,
            }
        }
        
    }));


    function chooseImage(image){    
        setCarImage(image);
        setOpenCarImage(false);
    }



    return(
        <View style={{padding: 10 }}>

            {/* Müşteri Bilgileri */}

            <Panel header="Müşteri Bilgileri" style={{margin: 10, backgroundColor: 'white'}} shaded>
                <Grid fluid>
                    <Row>
                        <Col xs={24} md={8} lg={4}>
                            <Group label="Adı">
                                <Input value={customerName} onChange={(value)=>setCustomerName(value)}></Input>
                            </Group>
                        </Col>
                        <Col xs={24} md={8} lg={4}>
                            <Group label="Soyadı">
                                <Input value={customerSurname} onChange={(value)=>setCustomerSurname(value)}></Input>
                            </Group>
                        </Col>
                        <Col xs={24} md={8} lg={4}>
                            <Group label="Telefon">
                                <Input value={customerPhone} onChange={(value)=>setCustomerPhone(value)}></Input>
                            </Group>
                        </Col>
                        <Col xs={24} md={8} lg={4}>
                            <Group label="Email">
                                <Input value={customerEmail} onChange={(value)=>setCustomerEmail(value)}></Input>
                            </Group>
                        </Col>
                    </Row>
                </Grid>
            </Panel>
            <Drawer size="full" open={openNewCar}  onClose={()=>{setOpenNewCar(false)}}>
               <Drawer.Header>
                     <Drawer.Title>Yeni Araç Ekle</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <CarTemplateInput></CarTemplateInput>
                </Drawer.Body>
            </Drawer>
            <Drawer size="full" open={openCarImage}  onClose={()=>{setOpenCarImage(false)}}>
                <Drawer.Header>
                    <Drawer.Title> Araç Resmi Seç </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <View style={{marginVertical: 3, alignItems: 'flex-start', marginHorizontal: 3}}>
                        <ImageChooser setImage={chooseImage}></ImageChooser>
                    </View>
                </Drawer.Body>
                <Drawer.Footer>
                    <Button onClick={()=>{setOpenCarImage(false)}}>Kapat</Button>
                </Drawer.Footer>
            </Drawer>
            {/* Araç Bilgileri */}  
            <Panel header="Araç Bilgileri" style={{margin: 10, backgroundColor: 'white'}} shaded>
                <Stack spacing={10}>
                    <SelectPicker data={cars} style={{width: 200}} placeholder="Araç Seç" onSelect={(item,value)=>{setSelectedCar(value)}}></SelectPicker>
                    <IconButton icon={<Icon><FontAwesomeIcon icon={"plus"}></FontAwesomeIcon></Icon>} onClick={()=>{setOpenNewCar(true)}}></IconButton>
                    <IconButton icon={<Icon><FontAwesomeIcon icon={"image"}></FontAwesomeIcon></Icon>} onClick={()=>{setOpenCarImage(true)}}></IconButton>
                </Stack>
                <Divider></Divider>
                {
                     selectedCar && <CarCard car={selectedCar.Object}></CarCard>
                }
            </Panel>

            <Panel header="Fiyat Bilgileri" style={{margin: 10, backgroundColor: 'white'}} shaded>
                    <Stack>
                        <Group label="Fiyat">
                            <CustomText>{selectedCar ? selectedCar.Object.PRICE : 0} </CustomText>
                        </Group>
                        <Group label="OTV">
                            <CustomText> {selectedCar ? selectedCar.Object.OTV : 0} </CustomText>
                        </Group>
                        <Group label="KDV">
                            <CustomText> {selectedCar ? selectedCar.Object.KDV : 0}</CustomText>
                        </Group>
                        <Group label={"Baz Fiyat Çarpanı"}>
                            <InputNumber value={BazFiyatCarpani} onChange={(value)=>setBazFiyatCarpani(value)}></InputNumber>
                        </Group>
                        <Group label={"OTV Çarpanı"}>
                            <InputNumber value={OTVCarpani} onChange={(value)=>setOTVCarpani(value)}></InputNumber>
                        </Group>
                        <Group label={"İndirim Oranı"}>
                            <InputNumber value={reductionRate} onChange={(value)=>setReductionRate(value)}></InputNumber>
                        </Group>
                        <Group label={"Kampanya İndirim Oranı"}>
                            <InputNumber value={campaignReductionRate} onChange={(value)=>setCampaignReductionRate(value)}></InputNumber>
                        </Group>
                        <Group label={"OTV Matrah 1"}>
                            <CustomText>{otvMatrah1}</CustomText>
                        </Group>
                        <Group label={"OTV Matrah 2"}>
                            <CustomText>{otvMatrah2}</CustomText>
                        </Group>
                        <Group label={"OTV Matrah 3"}>
                            <CustomText>{otvMatrah3}</CustomText>
                        </Group>

                        <Group label={"OTV Dahil Fiyat"}>
                            <CustomText>{withOTV}</CustomText>
                        </Group>
                        <Group label={"KDV Dahil Fiyat"}>
                            <CustomText>{finalPrice}</CustomText>
                        </Group>

                        <PriceCalculator car={selectedCar} packageDetails={packageDetails}></PriceCalculator>
                    </Stack>
            </Panel>

               
        </View>
    )
} 

);
const CarCard = ({car}) => {

    return(
        <View>
            <Stack fluid>
                <Group label="MARKA">
                    <CustomText>{car.BRAND_NAME}</CustomText>
                </Group>
                <Group label="MODEL">
                    <CustomText>{car.MODEL_NAME}</CustomText>
                </Group>
                <Group label="PAKET">
                    <CustomText>{car.PACKAGE_NAME}</CustomText>
                </Group>
                <Group label="DETAY">
                    <CustomText>{car.NAME_DETAIL}</CustomText>
                </Group>
                <Group label="Yakıt">
                    <CustomText>{car.FUEL}</CustomText>
                </Group>
            </Stack>
        </View>
    )
}


// choose one image from list of images
const ImageChooser = ({setImage}) => {

    return(
        <View>
            <Stack wrap spacing={20}>
                {
                    Images.map((item,index) => {
                        return(
                            <Stack.Item key={index} index={index} onClick={()=>{setImage(item);}}>
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                                    <img src={item} style={{width: 200}}></img>
                                </View>
                            </Stack.Item>
                        )
                    }
                    )
                }

            </Stack>
        </View>
    )
}

    
    


const CarTemplateInput = () => {
    // BRAND; MODEL ; YEAR ; COLOR ; FUEL ; GEAR ;

    const [BMP , setBMP] = useState(["","",""]);
    const [BMP_ID , setBMP_ID] = useState([0,0,0]);
    const [carDetails,setCarDetails] = useState("");    
    const [cc,setCC] = useState(0);
    const [hp,setHP] = useState(0);
    const [transmission,setTransmission] = useState("");
    const [fuelType,setFuelType] = useState("");
    const [color,setColor] = useState("");
    const [year,setYear] = useState(0);
    const [torque,setTorque] = useState(0);
    const [acceleration,setAcceleration] = useState(0);
    const [topSpeed,setTopSpeed] = useState(0);
    const [batteryCapacity,setBatteryCapacity] = useState(0);
    const [fuelConsumption,setFuelConsumption] = useState(0);
    const [emission,setEmission] = useState(0);
    const [trunkCapacity,setTrunkCapacity] = useState(0);

    const [choosenPackDetaıls,setChoosenPackDetails] = useState([]);
    const [packageDetails,setPackageDetails] = useState([]);

    const [carBaseBrice,setCarBasePrice] = useState(0);
    const [carOTVRate,setCarOTVRate] = useState(0);
    const [carKDVRate,setCarKDVRate] = useState(0);

    
    const {fetchIdToken}  = useUserToken();
    const {messageToggle} = useMessage();

    function getPackageDetails(){
        const api = configData.API_BASE + "/sales-dep"
        const token = fetchIdToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "type": "GET_PACKAGE_DETAILS",
        }

        axios.post(api, body, {headers: headers}).then((response) => {
            console.log(response);
            let tempData = [];
            response.data.map((item) => {
                tempData.push({label: item.DESCRIPTION, value: item.CODE});
            }
            )
            setPackageDetails(tempData);
        }
        ).catch((error) => {
            console.log(error);
        }

        )
    }

    useEffect(() => {
        getPackageDetails();
    },[])


    function saveCar(){
        const api = configData.API_BASE + "/sales-dep"
        const token = fetchIdToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        }

        console.log(choosenPackDetaıls);
        let packageDetailsAsString = "";
        choosenPackDetaıls.map((item) => {
            packageDetailsAsString += item + ",";
        }
        )

        let priceData = {
            "BasePrice" : carBaseBrice,
            "OTVRate": carOTVRate,
            "KDVRate": carKDVRate
        }

        const body = {
            "type": "NEW_TEMPLATE_CAR",
            "brand": BMP_ID[0],
            "model": BMP_ID[1],
            "package": BMP_ID[2],
            "nameDetail": carDetails,
            "cc": cc,
            "hp": hp,
            "transmission": transmission,
            "fuel": fuelType,
            "color": color,
            "year": year,
            "tork": torque,
            "acceleration": acceleration,
            "topSpeed": topSpeed,
            "battery": batteryCapacity,
            "avFuelCo": fuelConsumption,
            "emission": emission,
            "trunkLt": trunkCapacity,
            "packageDetails": packageDetailsAsString,
            "PriceInfo" : priceData
        }

        console.log(body);
        axios.post(api, body, {headers: headers}).then((response) => {
            console.log(response);
            if(response.data == "SUCCESS"){
                messageToggle("Araç başarıyla kaydedildi","success");
            }
            else{
                messageToggle("Araç kaydedilirken bir hata oluştu","error");
            }
        }).catch((error) => {
                console.log(error);
            }

        )
    }


    const TLMASK = createNumberMask({
        prefix: '₺ ',
        suffix: '',
        includeThousandsSeparator: true,
        allowDecimal: true,
        decimalLimit: 2,
        integerLimit: 7,
        requireDecimal: false,
        allowNegative: false,
        allowLeadingZeroes: false
        })




    const {BrandTypes,ModelTypes,PaketTypes,setSelectedBrandID,setSelectedModelID} = useBrandsDropDown();
    

    return(
        <View>
            <Stack wrap spacing={10}>

                <Group label={'Marka'} layout='column' >
                    <BrandTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} BMP = {BMP}  BMP_ID = {BMP_ID} ></BrandTypes>
                </Group>
                <Group label={'Model'} layout='column' >
                    <ModelTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} BMP = {BMP}  BMP_ID = {BMP_ID} ></ModelTypes>
                </Group>
                <Group label={'Paket'} layout='column' >
                    <PaketTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} BMP = {BMP}  BMP_ID = {BMP_ID} ></PaketTypes>
                </Group>
                <Group label={'Araç Özellik Detayı'} layout='column' >
                    <Input value={carDetails} onChange={(value)=>setCarDetails(value)}></Input>
                </Group>
                <Group label={'Motor Hacmi'} layout='column' >
                    <InputNumber value={cc} onChange={(value)=>setCC(value)}></InputNumber>
                </Group>
                <Group label={'Motor Gücü'} layout='column' >
                    <InputNumber value={hp} onChange={(value)=>setHP(value)}></InputNumber>
                </Group>
                <Group label={'Şanzıman'} layout='column' >
                    <Input value={transmission} onChange={(value)=>setTransmission(value)}></Input>
                </Group>
                <Group label={'Yakıt Tipi'} layout='column' >
                    <Input value={fuelType} onChange={(value)=>setFuelType(value)}></Input>
                </Group>
                <Group label={'Renk'} layout='column' >
                    <Input value={color} onChange={(value)=>setColor(value)}></Input>
                </Group>
                <Group label={'Yıl'} layout='column' >
                    <InputNumber value={year} onChange={(value)=>setYear(value)}></InputNumber>
                </Group>
                <Group label={'Tork'} layout='column' >
                    <InputNumber value={torque} onChange={(value)=>setTorque(value)}></InputNumber>
                </Group>
                <Group label={'Hızlanma'} layout='column' >
                    <InputNumber value={acceleration} onChange={(value)=>setAcceleration(value)}></InputNumber>
                </Group>
                <Group label={'Maksimum Hız'} layout='column' >
                    <InputNumber value={topSpeed} onChange={(value)=>setTopSpeed(value)}></InputNumber>
                </Group>
                <Group label={'Batarya Kapasitesi'} layout='column' >
                    <InputNumber value={batteryCapacity} onChange={(value)=>setBatteryCapacity(value)}></InputNumber>
                </Group>
                <Group label={'Yakıt Tüketimi'} layout='column' >
                    <InputNumber value={fuelConsumption} onChange={(value)=>setFuelConsumption(value)}></InputNumber>
                </Group>
                <Group label={'Emisyon'} layout='column' >
                    <InputNumber value={emission} onChange={(value)=>setEmission(value)}></InputNumber>
                </Group>
                <Group label={'Bagaj Kapasitesi'} layout='column' >
                    <InputNumber value={trunkCapacity} onChange={(value)=>setTrunkCapacity(value)}></InputNumber>
                </Group>
            </Stack>
            <Divider></Divider>
            <Panel style={{flex: 1, padding: 10}} header="Paket Detayları" bordered>
                    <TagPicker style={{width: '100%'}}  data={packageDetails} onChange={(value)=>setChoosenPackDetails(value)} placement = 'autoVerticalStart'></TagPicker>
            </Panel>

            <Divider></Divider>

            <Panel style={{flex: 1, padding: 10}} header="Fiyat Bilgileri" bordered>
                <Stack wrap spacing={10}>
                    <Group label="Araç Fiyatı" layout='column'>
                        <InputNumber value={carBaseBrice} onChange={(value)=>setCarBasePrice(value)}></InputNumber>
                    </Group>
                    <Group label="OTV Oranı" layout='column'>
                        <InputNumber value={carOTVRate} onChange={(value)=>setCarOTVRate(value)}></InputNumber>
                    </Group>
                    <Group label="KDV Oranı" layout='column'>
                        <InputNumber value={carKDVRate} onChange={(value)=>setCarKDVRate(value)}></InputNumber>
                    </Group>
                </Stack>
            </Panel>
            <Divider></Divider>
            <Button onClick={saveCar}>Kaydet</Button>
           
        </View>
    )
}


function Group ({ label, children, layout }) {
    return (
        <View style={{flexDirection: layout == "inline" ? 'row' : 'column', flex : 1, width: '100%',alignItems: 'flex-start',margin: 10, maxWidth: 300 , justifyContent: 'center'}}>
            <CustomText style={{ fontSize: 14, color: '#000', margin: 10, textAlign: 'right'}}>{label}</CustomText>
            <div>{children}</div>
        </View>
    );
}


export const useBrandsDropDown = () => {
    const [BMPOptions, setBMPOptions] = useState([]);
    const [choosableModels, setChoosableModels] = useState([]);
    const [choosablePacks, setChoosablePacks] = useState([]);
    const [selectedBrandID, setSelectedBrandID] = useState(0);
    const [selectedModelID, setSelectedModelID] = useState(0);
    const { getBrands } = useDropdownData();
  
    useEffect(() => {
      getBrands(setBMPOptions);
    }, []);

    
  
    useEffect(() => {
      console.log("Selected Brand ID:", selectedBrandID);
      const filteredItems = BMPOptions.filter((item) => item.id === selectedBrandID);
      if (filteredItems.length > 0 && filteredItems[0].children) {
        setChoosableModels(filteredItems[0].children);
      }
    }, [BMPOptions, selectedBrandID]);

    useEffect(() => {
      const filteredItems = choosableModels.filter((item) => item.id === selectedModelID);
      console.log("Filtered Items:", filteredItems);
    
      if (filteredItems.length > 0 && filteredItems[0].children) {
        console.log("Setting choosablePacks:", filteredItems[0].children);
        setChoosablePacks(filteredItems[0].children);
      } else {
        console.log("Resetting choosablePacks to []");
        setChoosablePacks([]); // <-- Consider resetting if conditions aren't met
      }
    }, [choosableModels, selectedModelID]);
    
    function setSelectedBrand(value){
      setSelectedBrandID(value);
    }
    function setSelectedModel(value){
      setSelectedModelID(value);
    }

    const BrandTypes = useCallback (
      (props) => {
      return (
        <View style={styles.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {"rotate-right"}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={styles.standard} placeholder={"MARKA"}  value={props.BMP[0]} onClean={() => {props.setBMP_ID([0,0,0]); props.setBMP(["","",""])}}  data ={BMPOptions} 
              onSelect={(item,value) => { props.setBMP([value.label,"",""]); props.setBMP_ID([value.id,0,0]); setSelectedBrandID(value.id)}} 
          ></SelectPicker>
        </View>
      )
    },[choosableModels,BMPOptions])

    const ModelTypes = useCallback (
      (props) => {
      return (
        <View style={styles.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {"rotate-right"}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={styles.standard}  placeholder={"MODEL"} value={props.BMP[1]} onClean={() => {props.setBMP_ID([props.BMP_ID[0],0,0]); props.setBMP([props.BMP[0],"",""])}}
              data ={choosableModels} onSelect={(item,value) => { props.setBMP([props.BMP[0],value.label,""]); props.setBMP_ID([props.BMP_ID[0],value.id,0]); 
            setSelectedModelID(value.id)
            }}
          ></SelectPicker>
        </View>
      )
    },[choosableModels,BMPOptions])

    const PaketTypes = useCallback (
      (props) => {
      return (
        <View style={styles.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {"rotate-right"}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={styles.standard}  placeholder={"PAKET"} value={props.BMP[2]} onClean={() => {props.setBMP_ID([props.BMP_ID[0],props.BMP_ID[1],0]); props.setBMP([props.BMP[0],props.BMP[1],""])}}
              data ={choosablePacks} onSelect={(item,value) => { props.setBMP([props.BMP[0],props.BMP[1],value.label]); props.setBMP_ID([props.BMP_ID[0],props.BMP_ID[1],value.id]); }}
          ></SelectPicker>
        </View>
      )
    },[choosablePacks,choosableModels,BMPOptions])


    return {
      BrandTypes,
      ModelTypes,
      PaketTypes,
      setSelectedBrandID,
      setSelectedModelID,
    }
}


const styles = StyleSheet.create({
    label: {
        textAlign: 'left',
        padding: 5,
    },
    labelBold: {
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    labelHeader:{
        textAlign: 'right',
        padding: 5,
    },
    labelWhite: {
        textAlign: 'center',
        padding: 5,
        color: 'white',
    },
    standard: {
        width: 160,
    },
    dropDownGroupBack : {
        borderRadius: 12,flexDirection: 'row',height: 40, paddingHorizontal : 0,justifyContent: 'center', alignItems: 'center',backgroundColor:'#F2FFFB'}

});

const Images = [
    require('../../../assets/ARJ_SALES_ASSETS/CARS/AVENGER_BEJ.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/AVENGER_BLACK.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/AVENGER_GRAY.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/AVENGER_GREEN.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/AVENGER_RED.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/AVENGER_WHITE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/AVENGER_YELLOW.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/COMPASS_BLACK.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/COMPASS_GRAY_2.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/COMPASS_GRAY.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/COMPASS_BLUE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/COMPASS_RED.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/COMPASS_WHITE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/GIULIA_BLACK.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/GIULIA_BLUE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/GIULIA_GRAY.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/GIULIA_SILVER.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/GIULIA_RED.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/GIULIA_WHITE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/STELVIO_BLACK.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/STELVIO_BLUE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/STELVIO_GRAY.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/STELVIO_SILVER.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/STELVIO_RED.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/STELVIO_WHITE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/TONALE_BLACK.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/TONALE_BLUE.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/TONALE_GRAY.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/TONALE_GREEN.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/TONALE_RED.png'),
    require('../../../assets/ARJ_SALES_ASSETS/CARS/TONALE_WHITE.png'),
    
    

    
]


export default SalesCarOfferDocWizard