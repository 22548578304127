import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator, Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CarInfoScreen from './CarInfoScreen';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import NewCarScreen from './NewCarScreen';
import { Message , useToaster,Modal, Button, Form ,Nav,TreePicker ,SelectPicker , MultiCascader,Input,Pagination,IconButton, Table ,CheckPicker,Checkbox, DateRangePicker, Container,RangeSlider,TagPicker, Slider} from 'rsuite';
const { Column, HeaderCell, Cell } = Table;
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ExportData from '../../Components/TableManagement/ExportData';
import FileViewer from '../../Components/FileManagements/FileViewer';
import FileLoader from '../../Components/FileManagements/FileLoader';
import locale from '../../tr';
import { BrandSvg } from '../../Components/BrandIconManager';
import configdata from '../../config/config.json';


//#region filter imports
import DateRangeFilter from './/Filters/DateRangeFilter.js';
import SliderFilter from './Filters/SliderFilter';
import FreeTextFilter from './Filters/FreeTextFilter';
import CarTagFilter from './Filters/CarTagFilter';
import BMPFilter from './Filters/BMPFilter';
import TagPickerFilter from './Filters/TagPickerFilter';
import FilterCompChooser from './Filters/FilterCompChooser.';


//#endregion

//#region Modal imports
import CarLicenceAddModal from './Modals/CarLicenceAddModal';
import EditCarModal from './Modals/EditCarModal';
import ColumnManager from './Modals/ColumnChooserModal';
//#endregion


import usePagination from '../../hooks/usePagination';
import useTable from '../../hooks/useTable';
import { useUserToken } from '../../hooks/useUserToken';

//#region Tab Management



  const CarsManagement = forwardRef((props,ref) =>  {
    const tableRef = useRef()
    const filterRef = useRef()
    const [data,setCarData] = useState([]);   
    const [activate,closeIndictor] = useState(true);  
    const [refresh,setRefresh] = useState(false);

    const {fetchIdToken} = useUserToken();
   async  function getCars () {
    const idToken = await fetchIdToken();
    const api = configdata.API_BASE + '/cardata';
    const headers = {
      'Authorization': `Bearer ${idToken}`
    };
    axios.post(api,
      {
      "type": "GET_FRESH_CARS" , 
      "ENV" : configdata.APP_ENV
      }, {headers}).then(
        (response) => {
            console.log("get car reset");
            console.log(response.data);
            setCarData(response.data);
            closeIndictor(false);
            // findOwnerFirms(response.data);
            // getCarBodyTypes(response.data);
            // getServiceKMTypes(response.data);
            // getMinimumMaximumYear(response.data);
            
        }
    ).catch(
        (error) => {
            console.log(error);
        }
    )
    } 

    function reset(){
      console.log("reset");
      getCars();
    }

    useEffect(()=> {
      if(data.length > 0){
        console.log("dataChanged");
        tableRef.current.refresh();
      }
    },[data])

    // TABLE MANAGEMENT
    // all table headers ?? TODO -> can be taken from sql database 
    const [tableHead,setTableHead] = useState([ 
      // "NO",
    // "KULLANICI" ,
    "ARAÇ",
    "ŞASİ" ,
    "ARAÇ DURUM",
    "MOTORNO" ,
    "MODEL YILI",
    "MARKA" ,
    "MODEL",
    "KASA" ,
    "PAKET",
    "CC" ,
    "CC SINIFI",
    "YAKIT TİPİ",
    "VİTES",
    "HP",
    "RENK",
    "MARKA RENK",
]); 

    useImperativeHandle(ref,()=>({

        removeElement: (element) => {
            removeElementEnabledTabs(element);
        }
    }))

    
    const [choosenCar, setChoosenCar] = useState(undefined)
    const [CarChoosenRefresh,setCarChooseRefresh] = useState(false);
    // Tab Conttroller for adding new tab or navigating current
    const [isPopUpvisible,setPopUpVisible] = useState(false) // car popup for expandable car window option TO BE DEVELOPED -- ADD SCROLL for pdf viewer
    const navigation = useNavigation();

    const [enabledTabs,setEnabledTabs] = useState([]);
    function handleEnabledTabs (input){
      setEnabledTabs((enabledTabs) => [...enabledTabs,input])
    }

    function removeElementEnabledTabs(element){
        var index = enabledTabs.indexOf(element);
        if(index >= 0){
          enabledTabs.splice(index,1);
        }
        console.log(enabledTabs);
       
    }
    function chooseCar(input){
      setCarChooseRefresh(!CarChoosenRefresh);
      setChoosenCar(input);
    }
    // function arrayRemove(arr, value) { 
    
    //   return arr.filter(function(ele){ 
    //       return ele != value; 
    //   });
    // }
    // function closeTab(input){
     
    //   console.log(enabledTabs);
    //   var new_Arr = arrayRemove(enabledTabs,input)
    //   console.log(enabledTabs);
    //   setEnabledTabs(new_Arr);
    //   props.closeTab(input);
    // }


    const  CarInfo = () => (<CarInfoScreen reset={() => {getCars(); setRefresh(!refresh)}} getCars = {getCars} data = {choosenCar}  toggleVisible = {setPopUpVisible} isVisible = {isPopUpvisible}></CarInfoScreen>)

    // table Button controllers

    useEffect(()=>{
      if(choosenCar != undefined ||choosenCar != null) {AddTab(choosenCar); console.log("car choose")} } ,[CarChoosenRefresh])


    useEffect(()=>{getCars(); },[])
    useEffect(()=>{
      console.log("get car refresh");
    },[refresh])
    
    // FILTER Operations 
    const [filteredData,setFiltered] = useState([]);
    const [isFiltered, setApplied] = useState(false);

    function resetTableFilter(){
      setApplied(false);
      setFiltered(data);
      tableRef.current.refresh();
    }

    function filterApplied(input){
      setApplied(input)
      tableRef.current.setPage1();
    }

    return(
      <View style = {{ flex: 1 ,height: '100%',backgroundColor:'#F2FFFB'}}>
          {data.length == 0 ? (
            <ActivityIndicator animating = {data.length == 0} color = '#BABABA' size = 'large' style={{marginTop : 40}}/>) : (
              <View style = {{flex:4}}>
              <View style={{height :'100%',padding: 20,flex: 1}}>
                    <TableRSuite reset={() => {reset();}} resetFilter={resetTableFilter} refresh={refresh} filterData = {data} setCarData={chooseCar} ref ={tableRef} tableHead = {tableHead}
                    data = { isFiltered ? filteredData :data}    initialData= {data}  isFilterApplied={isFiltered} setFiltered = {setFiltered} setApplied={filterApplied}></TableRSuite>
              </View>
          </View>
          )}
      </View>
    );
  }
  );
  //


  // filterItems = {filterItems} setFilterItems = {setFilterItems}
  const TableRSuite = forwardRef( (props,ref) => {
    

    //#region Table comps
    const [loading, setTableLoading] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [PopupActive, setPopupActive] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const {
        page, 
        limit, 
        handlePageChange, 
        handleLimitChange 
    } = usePagination();

    const {
        sortColumn, 
        sortType, 
        handleSortColumn
    } = useTable();
   
    console.log(props.data);
    const data =( props.data != null &&  props.data != undefined) && props.data.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
    
    const toggle_refresh = () => {
        // console.log(refresh);
        setRefresh(!refresh);
    }


    function handleModalClose(){
      setPopupActive(false);
  }

    useImperativeHandle(ref, () => ({
        // methods connected to `ref`
        refresh: () => {
          toggle_refresh()
        },
        setPage1:() => {
          handlePageChange(1);
        },
        filter: () => {
          toggle_refresh();
        }
      }))
  

  
    // const data = 

    

    const[choosen_s3KEY,setChoosenS3KEY] = useState("");

    //#region  Table Functions
    
    function view_car_licence(input){
      var dataS3 = JSON.parse(input["S3 KEYS LICENCES"]).data
      console.log(dataS3);
      if( dataS3 != null){
       dataS3.sort(function(a, b) {
          return parseFloat(b.ID) - parseFloat(a.ID);
      });}
      console.log(dataS3);
      setChoosenS3KEY(dataS3[0].S3_KEY);
      setPopupActive(true);
      // console.log(dataS3[0].s3_KEY);
    }

    function viewCarLicenceEditPopup(rowData){
      carlicenceModalRef.current.open(rowData);
    }
    function editCarPopup(rowData){
      updateCarRef.current.open(rowData);
    }
    function chooseCar(input){
      console.log(input);
      props.setCarData(input);
    }

    //#endregion

    //#region  Table Cell Renderers

    const ShowLicenceCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View style={{ height: '80%'  }}>
            {(JSON.parse(rowData["S3 KEYS LICENCES"]).data != null ) ?  <IconButton icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}
              onClick={() => {view_car_licence(rowData)}}
            />
           :  <IconButton icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}
              onClick={
              () => {
                viewCarLicenceEditPopup(rowData);
              }
            }
            />}
          </View>
        </Table.Cell>
    );

    const ActionCellEdit = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View style={{ height: '80%'  }}>
           <IconButton icon={<Icon><FontAwesomeIcon icon={"pen"} /></Icon>}
              onClick={
              () => {
                editCarPopup(rowData);
              }
            }></IconButton>
            
          </View>
        </Table.Cell>
    );

    function editRowData(input,dataKey){
      if(dataKey.includes("TARİH") && input[dataKey] != null && input[dataKey] != undefined){
       return reverseDate(input[dataKey]);
      }else{
        return input[dataKey];
      }
    }
  
    function reverseDate(input){
      var result = "";  
  
      var comps = input.split("-");
  
      for(var c = 2 ; c >= 0 ; c--){
        for(var i = 0 ; i < comps[c].length  ; i++){
          result += comps[c][i];
      }
      c != 0 ? result += "-": result += "";
      }
     
      return result;
  }

    const CustomCellView = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
        {dataKey == "PLAKA" ?
        <View style={{alignItems : 'center'   }}>
          <Button
           onClick={() => {chooseCar(rowData);}}
          > {rowData[dataKey]} </Button>
         </View>  :
         <View style={{  justifyContent : 'center'  }}>
            <Text style={{textAlign: 'left'}}>{editRowData(rowData,dataKey)}</Text>
        </View>
        }
        
      </Table.Cell>
  );
    
    //#endregion
   
    // SORT AND GETDATA
    function getData(){
    if (sortColumn && sortType) {
        return data.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt();
          }
          if (typeof y === 'string') {
            y = y.charCodeAt();
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }

        return data
      
    };


    const carlicenceModalRef = useRef();
    const updateCarRef = useRef();

    // function getWidth(input){

      
    //   var max = input.length * 12;
      
    //   data.forEach(element => {
    //     if(element[input] != null || element[input] != undefined){
    //       var str = element[input].toString();
    //       if(str.length * 12 > max){
    //         max = str.length * 12;
    //       }
    //     }
    //   })
    //   if(max < 200){max = 200};
    //     return max;
    // }

    //#endregion

    //#region  Table Filters
    const [filterChange , setFilterChange] = useState(0);

    const plakaRef = useRef();
    const bmpref = useRef();
    const ruhsatRef = useRef();
    const modelYiliRef = useRef();
    const tescilTarihRef = useRef();
    const tescilNoRef = useRef();
    const muayeneRef = useRef();
    const kasaRef = useRef();
    const ccRef = useRef();
    const ccSınıfRef = useRef();
    const yakıtRef = useRef();
    const vitesRef = useRef();
    const hpRef = useRef(); 
    const renkRef = useRef(); 
    const KMRef = useRef(); 
    const bakimKMRef = useRef();
    const sasiNoRef = useRef();
    const motorNoRef = useRef();
    const carTagRef = useRef();
    const markaRef = useRef();
    const modelRef = useRef();
    const paketRef = useRef();
    const aracRef = useRef();
    const aracDurumRef = useRef();
    const refs = [plakaRef,aracDurumRef,tescilNoRef, sasiNoRef, motorNoRef, ruhsatRef, bmpref, modelYiliRef, 
      muayeneRef, tescilTarihRef, kasaRef, ccRef, ccSınıfRef, yakıtRef, vitesRef, hpRef, renkRef,
       KMRef, bakimKMRef, carTagRef, markaRef, modelRef, paketRef, aracRef];

    function resetAndClear(){
      props.reset();
      // bmpref.current.clear();
      plakaRef.current.clear();
      sasiNoRef.current.clear();
      motorNoRef.current.clear();
      ruhsatRef.current.clear();
      modelYiliRef.current.clear();
      muayeneRef.current.clear();
      tescilNoRef.current.clear();
      tescilTarihRef.current.clear();
      kasaRef.current.clear();
      ccRef.current.clear();
      ccSınıfRef.current.clear();
      yakıtRef.current.clear();
      vitesRef.current.clear();
      hpRef.current.clear();
      renkRef.current.clear();
      KMRef.current.clear();
      bakimKMRef.current.clear();
      carTagRef.current.clear();
      markaRef.current.clear();
      modelRef.current.clear();
      paketRef.current.clear();
      aracRef.current.clear();
      props.setApplied(false)
      // props.setFiltered(props.initialData)
    }

    // function reset(){
    //   props.reset();
    //   setFilterChange(filterChange + 1);
    // }

    // useEffect(()=> {
    //   if(filterChange > 0){
    //     filter();
    //     console.log(" -------------------- len filter" + props.data.length)
    //   }
      
    // },[filterChange])

    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => checkContains(item,e)))
    }
    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }
    function testMultipleFilter(input){
      let first =  input.shift();
      let final = intersect(first,input);
      console.log(final)
      props.setFiltered(final)
      props.setApplied(true)
    }

    function filter(){

      console.log("_______________________________________ filter activated")
    //free texts
    let final = [];
    
    refs.forEach(refItem => {
      if (refItem != null && refItem.current != null && refItem.current.filter() != null) {
        const arr = refItem.current.filter();
        final.push(arr);
      }
    });

      // var arr1 = plakaRef.current.filter();
      // var arr2 = sasiNoRef.current.filter();
      // var arr3 = motorNoRef.current.filter();
      // var arr4 = tescilNoRef.current.filter();
      // // var arr5 = bmpref.current.filter();
      // var arr6 = ruhsatRef.current.filter();
      // var arr7 = modelYiliRef.current.filter();
      // var arr8 = muayeneRef.current.filter();
      // var arr9 = tescilTarihRef.current.filter();
      // var arr10 = kasaRef.current.filter();
      // var arr11 = ccRef.current.filter();
      // var arr12 = ccSınıfRef.current.filter();
      // var arr13 = yakıtRef.current.filter();
      // var arr14 = vitesRef.current.filter();
      // var arr15 = hpRef.current.filter();
      // var arr16= KMRef.current.filter();
      // var arr17 = bakimKMRef.current.filter();
      // var arr18 = carTagRef.current.filter();
      // var arr19 = renkRef.current.filter();
      // var arr20 = markaRef.current.filter();
      // var arr21 = modelRef.current.filter();
      // var arr22 = paketRef.current.filter();
      // var arr23 = aracRef.current.filter();
      
      testMultipleFilter(final)
      // testMultipleFilter([arr1,arr2,arr3,arr4,arr18])


    }

    const item_style = {
      standard: { width : 120, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }

    //#endregion


    
    const columnCreateData=[
      {
        dataKey: 'ARAÇ',
        label: 'ARAÇ',
        ref: aracRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 300,
      },
      {
        dataKey: 'ARAÇ DURUM',
        label: 'ARAÇ DURUM',
        ref: aracDurumRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 300,
      },
      {
        dataKey: 'ŞASİ',
        label: 'ŞASİ',
        ref: sasiNoRef,
        isActive: "1",
        filterType: 'FreeTextFilter',
        width: 150,

      },
      {
        dataKey: 'MOTORNO',
        label: 'MOTORNO',
        ref: motorNoRef,
        isActive: "1",
        filterType: 'FreeTextFilter',
        width: 150,

      },
      {
        dataKey: 'MODEL YILI',
        label: 'MODEL YILI',
        ref: modelYiliRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,

      },
      {
        dataKey: 'KASA',
        label: 'KASA',
        ref: kasaRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      },
      {
        dataKey: 'CC',
        label: 'CC',
        ref: ccRef,
        isActive: "1",
        filterType: 'SliderFilter',
        width: 150,
      },
      {
        dataKey: 'CC SINIFI',
        label: 'CC SINIFI',
        ref: ccSınıfRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      },
      {
        dataKey: 'YAKIT TİPİ',
        label: 'YAKIT TİPİ',
        ref: yakıtRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      },
      {
        dataKey: 'VİTES',
        label: 'VİTES',
        ref: vitesRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      },
      {
        dataKey: 'HP',
        label: 'HP',
        ref: hpRef,
        isActive: "1",
        filterType: 'SliderFilter',
        width: 150,
      },
      // {
      //   dataKey: 'KULLANICI',
      //   label: 'KULLANICI',
      //   ref: carTagRef,
      //   isActive: "1",
      //   filterType: 'CarTagFilter',
      //   width: 150,
      // },
      {
        dataKey: 'RENK',
        label: 'RENK',
        ref: renkRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      },
      {
        dataKey: 'MARKA',
        label: 'MARKA',
        ref: markaRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      },
      {
        dataKey: 'MODEL',
        label: 'MODEL',
        ref: modelRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      },
      {
        dataKey: 'PAKET',
        label: 'PAKET',
        ref: paketRef,
        isActive: "1",
        filterType: 'TagPickerFilter',
        width: 150,
      }

    ]



    const [choosenColumns,setChoosenColumns] = useState(parseColumnArray(props.tableHead));
    const [columns,setColumns] = useState(props.tableHead);


    function parseColumnArray(arr){
      let parsed = [];
      arr.forEach(element => {
        parsed.push({label: element, value: element})
      });
      return parsed;
    }

    function handleChangeColumns(value){
        setColumns(value);
    }

    const itemTest ={
      dataKey: 'ARAÇ',
      label: 'ARAÇ',
      ref: aracRef,
      isActive: "1",
      filterType: 'FreeTextFilter',
      width: 300,

    }

    function getScreenHeigth(){
      return Dimensions.get('window').height * 0.7;
    }



    return (
    <View style = {{height: '80%'}}>
            <Modal  overflow={true} size={'full'} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title> {props.data["ARAÇ"]} </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FileViewer inline={true} s3KEY = {choosen_s3KEY}></FileViewer>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={handleModalClose} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer>
            </Modal>
            <CarLicenceAddModal reset={props.reset} ref = {carlicenceModalRef} ></CarLicenceAddModal>
            <EditCarModal reset={props.reset} ref = {updateCarRef} ></EditCarModal>
            <View style = {{flexDirection : 'row', marginVertical: 10}}>
              <CheckPicker defaultValue={columns} onChange={(value) => {handleChangeColumns(value);}} data={choosenColumns} style={{ width: 224 }} />
             
            </View>
            <View style = {{flexDirection : 'row', marginVertical: 10}}>
              <ExportData data = {props.data} tableHead = {props.tableHead} columns = {columns} tableName={"ARAÇLAR"}></ExportData>
             
            </View>
            <View>

            <View style = {{flexDirection :'row',justifyContent: 'space-between'}}>
                  
                

                  {/* <BMPFilter filter={filter}  item_style={item_style} refresh={toggle_refresh} ref ={bmpref} data = {props.initialData} tableHeadName = {"MARKA"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></BMPFilter> */}
                
                  {/* <TagPickerFilter filter={filter} item_style={item_style} refresh={toggle_refresh} ref ={vitesRef} data = {props.initialData} tableHeadName = {"VİTES"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></TagPickerFilter>
                  <TagPickerFilter filter={filter} item_style={item_style} refresh={toggle_refresh} ref ={yakıtRef} data = {props.initialData} tableHeadName = {"YAKIT TİPİ"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></TagPickerFilter> */}
                 
                  </View>

              {/* <TableFilter  reset = {resetTableFilter} initialData= {props.data}  data =  {props.data} isFilterApplied={isFiltered} setFiltered = {setFiltered} setApplied={filterApplied}> </TableFilter> */}
                <Table
                        wordWrap="break-word"
                        loading={loading}
                        showHeader={showHeader}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        data={getData()}
                        bordered
                        cellBordered
                        // autoHeight
                        height={getScreenHeigth()}
                        headerHeight={120}
                    >
                    {/* <Table.Column  sortable resizable>
                        <Table.HeaderCell> Seç </Table.HeaderCell>
                        <CheckCell dataKey= 'ID' checkedKeys={checkedKeys} onChange={handleCheck} />
                    </Table.Column> */}
                    <Table.Column  sortable resizable>
                        <Table.HeaderCell> Ruhsat </Table.HeaderCell>
                        <ShowLicenceCell dataKey= 'RUHSAT'/>
                    </Table.Column>
                    <Table.Column  sortable resizable>
                        <Table.HeaderCell> Düzenle </Table.HeaderCell>
                        <ActionCellEdit dataKey= 'EDIT'/>
                    </Table.Column>
                    {/* <Table.Column  sortable resizable>
                        <Table.HeaderCell> Aç </Table.HeaderCell>
                        <ActionCell2 dataKey= 'open'/>
                    </Table.Column> */}
                  
                   {/* <Column width={150}>
                        <Table.HeaderCell>
                            <Text style={{color: 'blue', fontFamily: 'Roboto, sans-serif', fontWeight: '300', textAlign: 'center'}}> PLAKA </Text>
                            <FreeTextFilter filter={filter} item_style={{width : 120}} refresh={toggle_refresh} ref ={plakaRef} data = {props.filterData} tableHeadName = {"PLAKA"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.resetFilter} isFilterApplied= {props.isFilterApplied}></FreeTextFilter>
                        </Table.HeaderCell>
                        <CustomCellView dataKey={"PLAKA"}></CustomCellView>
                    </Column> */}
                      {columnCreateData.map(item => {
                        // console.log(item);
                        return(
                           columns.includes(item.label) &&
                          <Table.Column width={item.width}>
                              <Table.HeaderCell>
                                <Text style={{color: 'blue', fontFamily: 'Roboto, sans-serif', fontWeight: '300', textAlign: 'center'}}> {item.label} </Text>
                                <FilterCompChooser filterType={item.filterType} filter={filter}  item_style={{width: item.width - 20}} refresh={toggle_refresh} 
                                ref ={item.ref} data = {props.filterData} tableHeadName = {item.dataKey} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.resetFilter} isFilterApplied= {props.isFilterApplied}></FilterCompChooser>
                              </Table.HeaderCell>
                              <CustomCellView dataKey={item.dataKey}></CustomCellView> 
                          </Table.Column>
                        )
                      })}
                   
                    {/* {columns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Table.Column  sortable resizable width={getWidth(column)}>
                                <Table.HeaderCell>{column}</Table.HeaderCell>
                                <CustomCellView dataKey={column} />
                            </Table.Column>
                        );
                    })} */}
                    </Table>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={props.data.length}
                        limitOptions={[10, 20,30,40,50,100]}
                        limit={limit}
                        activePage={page}
                        onChangePage={handlePageChange}
                        onChangeLimit={handleLimitChange}
                        locale={{
                          more: 'Daha',
                          prev: 'Önceki',
                          next: 'Sonraki',
                          first: 'İlk',
                          last: 'Son',
                          limit: '{0} / sayfa',
                          total: 'Toplam Satır: {0}',
                          skip: 'Git {0}'
                      }}
                        />
            </View>
        </View>
    
  
    )
  
  }) 
  
  const styles = StyleSheet.create({
    bottomBar: {
      height: 60,
      backgroundColor: 'white',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent:'flex-start',
      borderBottomColor :'#00589B',
      borderBottomWidth: 1
    },

        HeadStyle: { 
          height: 50,
          alignContent: "center",
          backgroundColor: '#f7f7f5',
          // borderWidth: "1px",
          //  borderColor: "black"
        },
        RowStyle: { 
          height: 40,
          alignContent: "center",
          backgroundColor: '#fff'
        },
        TableText: { 
          textAlign: 'center',
          margin: 10
        },
        row: { flexDirection: 'row', backgroundColor: '#fff', height: 40,
      // borderColor: "black", borderWidth : "1px" 
        },
        headerRow: { flexDirection: 'row', backgroundColor: '#f7f7f5', height: 40,
        // borderColor: "black", borderWidth : "1px" 
        },
        btn: { width: "100%", },
        btnText: { textAlign: 'center', color: 'black' },
        modalView: {
          width: "95%", 
          height:"95%",
          margin: 5,
          backgroundColor: "white",
          borderRadius: 7,
          padding: 5,
          alignItems: "center",
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2
          },
          shadowOpacity: 0.25,
          shadowRadius: 4,
          elevation: 5
        },
        modalText: {
          marginBottom: 15,
          textAlign: "center"
        }
  })

export default CarsManagement;
