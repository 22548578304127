import React, { useEffect, useState ,useImperativeHandle, useRef } from 'react';
import { StyleSheet, Dimensions, View ,FlatList,Text, ScrollView, SafeAreaView} from 'react-native';
import { FileUploader } from "react-drag-drop-files";
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import PDF from '../PDF';
import StandardButton from '../StandardButton';
import { Modal } from 'react-native-paper';
import axios from 'axios';
import { forwardRef } from 'react';
import configdata from '../../config/config.json';
import { Button, IconButton, Stack } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Image from 'react-bootstrap/Image';
import ImageToPdfExporter from '../ImageManagements/ImageToPdfExporter';
import CustomText from '../CustomText';
// Amplify.configure(awsconfig);
Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});

const FileViewer = (props,ref) => {

    const [url,setURL] = useState("");
    const [width, setWidth] = useState(400 );
    const [height, setHeight] = useState(400 * 1.41421);
    const [aspectRatio,SetAspectRatio] = useState(1);
    const [refresh,setRefresh] = useState(false);
    const [s3KEY,setS3Key] = useState(undefined);
    
    useImperativeHandle(ref, () => ({
    refresh: () => {
        setRefresh(!refresh);
    },
    set: (input) => {
        
        if(input != null){
          
            setS3Key(parseS3Response(input));
        }
      
       
    }
}));

    function parseS3Response(response) {
        const { data, status } = response;
        if (status !== 200) {
            throw new Error('Failed to fetch the file');
        }else{
        let dataNew =  JSON.parse(data[0]["S3 KEY LICENCES"])
            let final = sortLicenceKeysByID(dataNew.data);

        return final[0].S3_KEY;
        }

    }

    function sortLicenceKeysByID(data) {
        let temp = [...data];
        temp.sort((a, b) => {
            return a["ID"] - b["ID"];
        });
        return temp;
    }

    useEffect(()=>{
        getURL(s3KEY);
    },[s3KEY])

    useEffect(()=>{
        console.log("s3key " + props.s3KEY);
        if(props.s3KEY != null && props.s3KEY != undefined && typeof(props.s3KEY) == 'string'){
            setS3Key(props.s3KEY);
            console.log("s3key setted");
        }
    },[])

    const getURL = async (input) => {
        if(input != null && input != undefined && s3KEY != undefined){
            await Storage.get(input,{ 
                expires: 120,
                level: 'public/'
              /*   identityId: '2cd3970d-b634-4bf1-b27a-86fc58ff3fa7' */
            }).then
            (data => {
                setURL(data);
                console.log("----- url name " + data);

                if(s3KEY.split('.')[1].includes("png") || s3KEY.split('.')[1].includes("jpg") || s3KEY.split('.')[1].includes("jpeg")){
                    Image.getSize(data, (width,height) => {
                        if(props.inline){
                            setWidth(width); 
                            setHeight(height);
                        }else{
                            setWidth(400); 
                            setHeight(400 * (width / height));
                        }
                    });
                }
                
            }).catch(
                err => {
                    console.log("storage error " + err)
                }
            );
        }
     
    } 

    function downloadBlob(blob, filename) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename.split("/")[filename.split("/").length -1 ] || 'download';
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
      }
      
      // usage
      async function download(item) {
        const result = await Storage.get(s3KEY, { download: true });
        downloadBlob(result.Body, s3KEY)
      }


    const [rotate,setRotate] = useState(0);

    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [numPages, setNumPages] = useState(1);
  
    // Functions to handle PDF control actions
    const goToNextPage = () => setPageNumber(pageNumber + 1);
    const goToPreviousPage = () => setPageNumber(pageNumber - 1);
    const zoomIn = () => setScale(scale + 0.1);
    const zoomOut = () => setScale(scale - 0.1);


    return (
        <SafeAreaView style={{flex:1}}>
        {
            s3KEY != undefined && 
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {s3KEY.split('.').pop().toLowerCase() === "pdf" && 
                <View style={[styles.controlsContainer, {top: 20}]}>
                        <IconButton style={{backgroundColor: '#4e4e4e'}} icon={<FontAwesomeIcon style={{color:'white'}} icon={'chevron-left'} />} onClick={goToPreviousPage} disabled={pageNumber <= 1} />
                        <IconButton style={{backgroundColor: '#4e4e4e'}} icon={<FontAwesomeIcon style={{color:'white'}}icon={'minus'} />} onClick={zoomOut} />
                        <CustomText fontSize = {16} fontWeight = {800} style={{color:'#F2F2F2'}}>{"Sayfa " + pageNumber + " / " + numPages}</CustomText>
                        <IconButton style={{backgroundColor: '#4e4e4e'}} icon={<FontAwesomeIcon style={{color:'white'}} icon={"plus"} />} onClick={zoomIn} />
                        <IconButton style={{backgroundColor: '#4e4e4e'}} icon={<FontAwesomeIcon style={{color:'white'}} icon={'chevron-right'} />} onClick={goToNextPage} disabled={pageNumber >= numPages} />
                        <IconButton style={{backgroundColor: '#4e4e4e'}} icon={<FontAwesomeIcon style={{color:'white'}} icon={'download'}></FontAwesomeIcon>} onClick={download}/>
                </View>
            }
    
            {  s3KEY.split('.').pop().toLowerCase() != "pdf"  &&
                <View style={[styles.controlsContainer, {top: 20}]}>
                    <IconButton onClick={download} style={{backgroundColor: '#4e4e4e'}} icon={<Icon><FontAwesomeIcon style={{color:'white'}} icon={"file"}></FontAwesomeIcon></Icon>}>  </IconButton>
                    <IconButton onClick={() => setRotate(`${(parseInt(rotate) + 90) % 360}deg`)} style={{backgroundColor: '#4e4e4e'}} icon={<Icon><FontAwesomeIcon style={{color:'white'}}icon={'rotate'}></FontAwesomeIcon></Icon>}></IconButton>
                    {props.PDFConvertable == true && <ImageToPdfExporter image={url} s3KEY={s3KEY}></ImageToPdfExporter>}
                </View>
                } 
                <View style={{ height: 10 }} />
                {(s3KEY && typeof(s3KEY) == 'string') && (
                    <View style={{ flex :1}} >
                        {["png", "jpg", "jpeg"].includes(s3KEY.split('.').pop()) && 
                            <Image
                                fluid
                                src={url}
                            />
                        }
                        {s3KEY.split('.').pop().toLowerCase() === "pdf" && 
                            <View style={{ flex: 1 }}>
                                <PDF 
                                    setNumPages={setNumPages}
                                    pageNumber={pageNumber}
                                    scale={scale}
                                    width = {Dimensions.get('window').width}
                                    // height={ !props.inline ? (props.height || height) : (props.height || 500) } 
                                    url={url} 
                                /> 
                            </View>
                        }
                    </View>
                )}
                </View>
        }

        </SafeAreaView>
    )
}


const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 7,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.10,
        shadowRadius: 4,
        elevation: 5
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height:'100%',
        margin: 25,
    },
    controlsContainer: {
        position: 'absolute',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: 300,
        padding: 10,
        zIndex: 1,
        borderRadius: 5,
        backgroundColor: 'rgba(0, 0, 0, 0.85)', // Adjust opacity as needed
        // bottom is set dynamically in the component
    },

    listItem:{
        backgroundColor: 'white',
        borderRadius: 7,
    },
    pdf: {
        flex:1,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    }
});

export default forwardRef(FileViewer) ;