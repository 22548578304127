import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import { Message, useToaster, ButtonToolbar, SelectPicker, Button } from 'rsuite';
import { Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResponsiveNav from '@rsuite/responsive-nav';
import MoreIcon from '@rsuite/icons/More';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation ,NavigationContainer} from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import configdata from '../config/config.json';

const CustomersScreen = () => {

    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
               {input}
            </Message>, { placement: 'topEnd' })
    }
    return(
    
        <Button onClick={() => {messageToggle("TEST","info")}}>
            Alert Deneme
        </Button>
    )
    
}

  const TEST = () => {
    return(
        <View>
            <Text>
                TEST
            </Text>
        </View>
    )
  }
  
  const styles = StyleSheet.create({
   
  });

export default CustomersScreen;

    // const toaster = useToaster();
    // function messageToggle(input,type){
    //     toaster.push(
    //         <Message showIcon type={type}>
    //            {input}
    //         </Message>, { placement: 'topEnd' })
    // }