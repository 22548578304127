
import { Text, View , StyleSheet,ActivityIndicator, TouchableOpacity,TextInput,ScrollView,Modal } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import StandardButton from '../../Components/StandardButton';
import CustomCheckBox from '../../Components/CustomCheckBox';
const UserLabel = (props) => {
    const [editable,setEditable] = useState(false);
    const [refresh,setRefresh] = useState(false);
    function toggleEditable(){
      setEditable(!editable);
      setRefresh(!refresh)
    }
    return(  
      <View style= {{marginBottom: 50, zIndex: 10}}>
        {!editable ? 
              (<UserLabelInfo choosenData = {props.choosenData} toggleEditable= {toggleEditable}></UserLabelInfo> ):
              ( <UserLabelEdit  updateData = {props.updateData} reset = {props.reset} choosenData = {props.choosenData} toggleEditable= {toggleEditable}></UserLabelEdit>)
        }
      </View>
    )
  
  
   }
  
  const UserLabelInfo = (props) => {
      return(
        <View style = {styles.userLabel} >
        <View style={{ flexDirection: 'row', margin: 'auto',ustifyContent:'space-around'}}>
        <View style = {{ flexDirection: "column"}}>
          <MiniRow 
          header = {"ID"} 
          content =  {props.choosenData["ID"]}
          />
          <MiniRow 
          header = {"NAME"} 
          content =  {props.choosenData["NAME"]}
          />
          <MiniRow 
          header = {"SURNAME"} 
          content =  {props.choosenData["SURNAME"]}
          />
        </View>
        <View style = {{flexDirection: "column"}}>
          <MiniRow 
          header = {"EMAIL"} 
          content =  {props.choosenData["EMAIL"]}
          />
          <MiniRow 
          header = {"CREATE DATE"} 
          content =  {props.choosenData["CREATE_DATE"]}
          />
          <MiniRow 
          header = {"IS ACTIVE"} 
          content =  {props.choosenData["IS_ACTIVE"] == 1 ? "YES" : props.choosenData["IS_ACTIVE"] == 0 ? "NO" : "-"}
          />
         
        </View>
        
      </View>
        <View style = {{flexDirection: "row", flex: 1, padding: 10,justifyContent:"flex-end"}}>
            <StandardButton color= '#00589B' iconName={"pencil"} buttonText={"Edit"} onPress = {props.toggleEditable}></StandardButton>
          </View>
      </View>
      )
  }
  
  const UserLabelEdit = (props) => {
    const [newName,setNewName] = useState(undefined);
    const [newSurname,setNewSurname] = useState(undefined);
    const [newEmail,setNewEmail] = useState(undefined);
    const [newActive,setnewActive] = useState(props.choosenData["IS_ACTIVE"]);
  
    const edit_user = () => {
      console.log(newName);
      const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/userdata';
      const header = {
        
          "IS_ACTIVE": (newActive != undefined) ? newActive : props.choosenData["IS_ACTIVE"],
          "NAME": (newName != undefined) ? newName : props.choosenData["NAME"],
          "SURNAME": (newSurname != undefined) ? newSurname : props.choosenData["SURNAME"],
          "EMAIL": (newEmail != undefined) ? newEmail : props.choosenData["EMAIL"],
          "ID" : props.choosenData["ID"],
          "IS_NEW" : "NO"
      }
      axios
        .post(api,header)
        .then((response) => {
          console.log(response);
          // setEditable(false);
          // setRefresh(!refresh);
          // assignUserStateProp(header);
          // resetLabel();
          props.toggleEditable();
          props.reset();
        })
        .catch((error) => {
          console.log(error);
        });
      
        props.choosenData["IS_ACTIVE"] = header["IS_ACTIVE"];
        props.choosenData["NAME"] = header["NAME"];
        props.choosenData["SURNAME"] = header["SURNAME"];
        props.choosenData["EMAIL"] = header["EMAIL"];
  
        props.updateData();
      
    } 

    const toggleActive =()=> {
        if(newActive == 1){
          setnewActive(0);
        }else{
          setnewActive(1);
        }
    }
  
    return( 
      <View style = {styles.userLabel}>
        <View style = {{flexDirection: 'row', margin: 'auto',justifyContent:'space-around'}}>
          <View style = {{ flexDirection: "column" , marginHorizontal: 3}}>
            <MiniRow 
            header = {"ID"} 
            content =  {props.choosenData["ID"]}
            />
            <InputWithText 
            header = {"NAME"} 
            content =  {props.choosenData["NAME"]}
            setNew = {setNewName}
            />
            <InputWithText 
            header = {"SURNAME"} 
            content =  {props.choosenData["SURNAME"]}
            setNew = {setNewSurname}
            />
          </View>
          <View style = {{flexDirection: "column", marginHorizontal: 3}}>
            <InputWithText 
            header = {"EMAIL"} 
            content =  {props.choosenData["EMAIL"]}
            setNew = {setNewEmail}
            />
            <MiniRow 
            header = {"CREATE DATE"} 
            content =  {props.choosenData["CREATE_DATE"]}
            />
            <EnableDisable 
            header = {"IS ACTIVE"} 
            content =  {props.choosenData["IS_ACTIVE"]}
            onPress = {toggleActive}
            condition = {newActive}
            />
          
          </View>
        </View>
        <View style = {{flexDirection: "row", padding: 10, justifyContent:"flex-end"}}>
            <StandardButton color= '#00589B' iconName = {"content-save-edit"} buttonText = {"Save"} onPress = {edit_user}></StandardButton>
            <View View style={{width: 25}}></View>
            <StandardButton  color= '#F20F38' iconName = {"close-circle-outline"} buttonText = {"Cancel"} onPress = {props.toggleEditable}></StandardButton>
          </View>
      </View>
    )
  }

  
const InputWithText = (props) => {
    return(
    
    <View style = {{flexDirection: 'row', width: 500, marginVertical: "1rem", height: 30}}>
      <Text style = {{textAlign: "left", color: "black", width: '30%'}}>
        {props.header}
      </Text>
      <TextInput style = { { backgroundColor: "#f7f7f5", width: '70%', borderRadius: 4 , textAlign: "left"} }
       placeholder= {props.content} defaultValue= {props.content} onChangeText = { text => props.setNew(text)}>
      </TextInput>
    </View>
  )
    
  }
  
  const MiniRow = (props) => {
    return(
    
    <View style = {{flexDirection: 'row', width: 500, marginVertical: "1rem", height: 30}}>
      <Text style = {{textAlign: "left", color: "black", width: '30%'}}>
        {props.header}
      </Text>
      <Text style = {{textAlign: "left", color: "black", width: '70%'}}>
        {props.content}
      </Text>
    </View>
  )
    
  }

  const EnableDisable = (props) => {
    return(
    
    <View style = {{flexDirection: 'row', width: 500, marginVertical: "1rem", height: 30, alignItems: 'center'}}>
      <Text style = {{textAlign: "left", color: "black", width: '30%'}}>
        {props.header}
      </Text>
      <CustomCheckBox onPress={props.onPress} condition = {props.condition == 1 ? true : false }></CustomCheckBox>
      {/* <View style = {{alignItems:'center', flexDirection: 'row'}}>
        <Text style = {{textAlign: "left", color: "black"}}>
          {props.condition == 1 ? "YES" : "NO"}
        </Text>
        
      </View>
       */}
      {/* <TextInput style = { { backgroundColor: "#f7f7f5", width: '70%', borderRadius: 4 , textAlign: "left"} }
       placeholder= {props.content} defaultValue= {props.content} onChangeText = { text => props.setNew(text)}>
      </TextInput> */}
    </View>
  )
    
  }
  const styles = StyleSheet.create({
  
    userLabel:{
      backgroundColor: 'white',
      borderRadius: 12,
      height: "400",
      flexDirection: "Column",
      // alignItems: 'center',
      // justifyContent: "center",
      padding: 10,
     
    },
   
    
  });

  export default UserLabel;
  