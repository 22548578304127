import { Text, View } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react';
import { Modal, Button ,Checkbox,CheckboxGroup } from 'rsuite';
import NewCarScreen from '.././NewCarScreen';


const ColumnManager = forwardRef((props,ref) => {

    const [PopupActive, setPopupActive] = useState(false);
    const [choosenData,setChoosenData] = useState([]);
    
    
    function handleModalClose(){
      setPopupActive(false);
   }

    return(

       <View>
        <Button onClick={() => {setPopupActive(true);}}>
        Sütün Seç
        </Button>
            
        <Modal size={"full"} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
        <Modal.Header>
        </Modal.Header>

        <Modal.Body>
            
        <CheckboxGroup name="checkboxList">
            {props.data.map((item) => {
                return (
                    <Checkbox 
                        checked={true}
                        value={item.label} 
                        onChange={(checked) => {
                            props.handleChange(item.label, checked);
                        }}
                    > 
                        {item.label} 
                    </Checkbox>
                );
            })}
        </CheckboxGroup>
        
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={handleModalClose} appearance="subtle">
            Cancel
            </Button>
        </Modal.Footer>
    </Modal>
       </View> 
     
    )
  });

  export default ColumnManager;