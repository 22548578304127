import { useState } from "react";
import axios from "axios";
import configdata from '../config/config.json'
import formatter from "../utils/formatter";


const useCarImages = () => {
    const carImages = {
        'FIAT_EGEA_SEDAN_EASY': require('../../assets/CarStockImages/FIAT_EGEA_SEDAN_EASY.png'),
        'FIAT_EGEA_SEDAN_URBAN': require('../../assets/CarStockImages/FIAT_EGEA_SEDAN_URBAN.png'),
        'FIAT_EGEA_SEDAN_LOUNGE': require('../../assets/CarStockImages/FIAT_EGEA_SEDAN_LOUNGE.png'),
        'ALFA_ROMEO_TONALE': require('../../assets/CarStockImages/ALFA ROMEO_TONALE.png'),
        'ALFA_ROMEO_GIULIA': require('../../assets/CarStockImages/ALFA ROMEO_GIULIA.png'),
        'AUDI_A3': require('../../assets/CarStockImages/AUDI_A3.png'),
        'AUDI_A4': require('../../assets/CarStockImages/AUDI_A4.png'),
        'AUDI_A6': require('../../assets/CarStockImages/AUDI_A6.png'),
        'FORD_COURIER': require('../../assets/CarStockImages/FORD_COURIER.png'),
        'FORD_RANGER': require('../../assets/CarStockImages/FORD_RANGER.png'),
        'HYUNDAI_BAYON': require('../../assets/CarStockImages/HYUNDAI_BAYON.png'),
        'HYUNDAI_i20': require('../../assets/CarStockImages/HYUNDAI_i 20.png'), // Fixed typo
        'JEEP_COMPASS': require('../../assets/CarStockImages/JEEP_COMPASS.png'),
        'JEEP_RENEGADE': require('../../assets/CarStockImages/JEEP_RENEGADE.png'),
        'JEEP_AVENGER': require('../../assets/CarStockImages/JEEP_AVENGER.png'),
        'MERCEDES_VITO': require('../../assets/CarStockImages/MERCEDES_VITO.png'),
        'MITSIBUSHI_L200': require('../../assets/CarStockImages/MITSIBUSHI_L200.png'),
        'PEUGEOT_2008': require('../../assets/CarStockImages/PEUGEOT_2008.png'),
        'PEUGEOT_208': require('../../assets/CarStockImages/PEUGEOT_208.png'),
        'PEUGEOT_408': require('../../assets/CarStockImages/PEUGEOT_408.png'),
        'PEUGEOT_3008': require('../../assets/CarStockImages/PEUGEOT_3008.png'),
        'PEUGEOT_TRAVALLER': require('../../assets/CarStockImages/PEUGEOT_TRAVALLER.png'),
        'RENAULT_CLIO': require('../../assets/CarStockImages/RENAULT_CLIO.png'),
        'RENAULT_CAPTUR': require('../../assets/CarStockImages/RENAULT_CAPTUR.png'),
        'RENAULT_TALIANT': require('../../assets/CarStockImages/RENAULT_TALIANT.png'),
        'RENAULT_MEGANE': require('../../assets/CarStockImages/RENAULT_MEGANE.png'),
        'RENAULT_ZOE': require('../../assets/CarStockImages/RENAULT_ZOE.png'),
        'TOYOTA_COROLLA': require('../../assets/CarStockImages/TOYOTA_COROLLA.png'),
        'TOYOTA_HILUX': require('../../assets/CarStockImages/TOYOTA_HILUX.png'),
        'SKODA_OCTAVIA': require('../../assets/CarStockImages/SKODA_OCTAVIA.png'),
        'SKODA_SUPERB': require('../../assets/CarStockImages/SKODA_SUPERB.png'),
        'VOLKSWAGEN_POLO': require('../../assets/CarStockImages/VOLKSWAGEN_POLO.png'),
        'VOLKSWAGEN_PASSAT': require('../../assets/CarStockImages/VOLKSWAGEN_PASSAT.png'),
        'VOLKSWAGEN_CARAVELLE': require('../../assets/CarStockImages/VOLKSWAGEN_CARAVELLE.png'),
        'OPEL_CORSA': require('../../assets/CarStockImages/OPEL_CORSA.png'),
        'CITROEN_C3_AIRCROSS': require('../../assets/CarStockImages/CITROEN_C3_AIRCROSS.png'),
        'CITROEN_C3': require('../../assets/CarStockImages/CITROEN_C3.png'),
        'DACIA_DUSTER': require('../../assets/CarStockImages/DACIA_DUSTER.png'),
        'FIAT_DOBLO': require('../../assets/CarStockImages/FIAT_DOBLO.png'),
        'FIAT_EGEA_CROSS_WAGON': require('../../assets/CarStockImages/FIAT_EGEA_CROSS_WAGON.png'),
        'FIAT_EGEA_CROSS': require('../../assets/CarStockImages/FIAT_EGEA_CROSS.png'),
        'FIAT_EGEA_HATCHBACK': require('../../assets/CarStockImages/FIAT_EGEA_HATCHBACK.png'),
        'FIAT_FIORINO': require('../../assets/CarStockImages/FIAT_FIORINO.png'),
    };

    const BrandImages = {
        'FIAT': require('../../assets/BrandLogos/LOGO_FIAT.png'),
        'ALFA_ROMEO': require('../../assets/BrandLogos/LOGO_ALFA ROMEO.png'),
        'AUDI': require('../../assets/BrandLogos/LOGO_AUDI.png'),
        'FORD': require('../../assets/BrandLogos/LOGO_FORD.png'),
        'HYUNDAI': require('../../assets/BrandLogos/LOGO_HYUNDAİ.png'),
        'JEEP': require('../../assets/BrandLogos/LOGO_JEEP.png'),
        'MERCEDES': require('../../assets/BrandLogos/LOGO_MERCEDES.png'),
        'MITSIBUSHI': require('../../assets/BrandLogos/LOGO_MITSUBISHI.png'),
        'PEUGEOT': require('../../assets/BrandLogos/LOGO_PEUGEOT.png'),
        'RENAULT': require('../../assets/BrandLogos/LOGO_RENAULT.png'),
        'TOYOTA': require('../../assets/BrandLogos/LOGO_TOYOTA.png'),
        'SKODA': require('../../assets/BrandLogos/LOGO_SKODA.png'),
        'VOLKSWAGEN': require('../../assets/BrandLogos/LOGO_VOLKSWAGEN.png'),
        'OPEL': require('../../assets/BrandLogos/LOGO_OPEL.png'),
        'CITROEN': require('../../assets/BrandLogos/LOGO_CITROEN.png'),
        'DACIA': require('../../assets/BrandLogos/LOGO_DACIA.png'),

    };


    function  returnCarImages(){
        return {
            'FIAT_EGEA_SEDAN_EASY': "/images/FIAT_EGEA_SEDAN_EASY.png",
            'FIAT_EGEA_SEDAN_URBAN': "/images/FIAT_EGEA_SEDAN_URBAN.png",
            'FIAT_EGEA_SEDAN_LOUNGE': "/images/FIAT_EGEA_SEDAN_LOUNGE.png",
            'ALFA_ROMEO_TONALE': "/images/ALFA ROMEO_TONALE.png", // Fixed the space issue
            'ALFA_ROMEO_GIULIA': "/images/ALFA ROMEO_GIULIA.png", // Fixed the space issue
            'AUDI_A3': "/images/AUDI_A3.png",
            'AUDI_A4': "/images/AUDI_A4.png",
            'AUDI_A6': "/images/AUDI_A6.png",
            'FORD_COURIER': "/images/FORD_COURIER.png",
            'FORD_RANGER': "/images/FORD_RANGER.png",
            'HYUNDAI_BAYON': "/images/HYUNDAI_BAYON.png",
            'HYUNDAI_i20': "/images/HYUNDAI_i 20.png", // Fixed typo
            'JEEP_COMPASS': "/images/JEEP_COMPASS.png",
            'JEEP_RENEGADE': "/images/JEEP_RENEGADE.png",
            'JEEP_AVENGER': "/images/JEEP_AVENGER.png",
            'MERCEDES_VITO': "/images/MERCEDES_VITO.png",
            'MITSIBUSHI_L200': "/images/MITSIBUSHI_L200.png",
            'PEUGEOT_2008': "/images/PEUGEOT_2008.png",
            'PEUGEOT_208': "/images/PEUGEOT_208.png",
            'PEUGEOT_408': "/images/PEUGEOT_408.png",
            'PEUGEOT_3008': "/images/PEUGEOT_3008.png",
            'PEUGEOT_TRAVALLER': "/images/PEUGEOT_TRAVALLER.png",
            'RENAULT_CLIO': "/images/RENAULT_CLIO.png",
            'RENAULT_CAPTUR': "/images/RENAULT_CAPTUR.png",
            'RENAULT_TALIANT': "/images/RENAULT_TALIANT.png",
            'RENAULT_MEGANE': "/images/RENAULT_MEGANE.png",
            'RENAULT_ZOE': "/images/RENAULT_ZOE.png",
            'TOYOTA_COROLLA': "/images/TOYOTA_COROLLA.png",
            'TOYOTA_HILUX': "/images/TOYOTA_HILUX.png",
            'SKODA_OCTAVIA': "/images/SKODA_OCTAVIA.png",
            'SKODA_SUPERB': "/images/SKODA_SUPERB.png",
            'VOLKSWAGEN_POLO': "/images/VOLKSWAGEN_POLO.png",
            'VOLKSWAGEN_PASSAT': "/images/VOLKSWAGEN_PASSAT.png",
            'VOLKSWAGEN_CARAVELLE': "/images/VOLKSWAGEN_CARAVELLE.png",
            'OPEL_CORSA': "/images/OPEL_CORSA.png",
            'CITROEN_C3_AIRCROSS': "/images/CITROEN_C3_AIRCROSS.png",
            'CITROEN_C3': "/images/CITROEN_C3.png",
            'DACIA_DUSTER': "/images/DACIA_DUSTER.png",
            'FIAT_DOBLO': "/images/FIAT_DOBLO.png",
            'FIAT_EGEA_CROSS_WAGON': "/images/FIAT_EGEA_CROSS_WAGON.png",
            'FIAT_EGEA_CROSS': "/images/FIAT_EGEA_CROSS.png",
            'FIAT_EGEA_HATCHBACK': "/images/FIAT_EGEA_HATCHBACK.png",
            'FIAT_FIORINO': "/images/FIAT_FIORINO.png",
        }
    }

    return { carImages, returnCarImages };


}

export {useCarImages};