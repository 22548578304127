  
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { View } from 'react-native';
import { DateRangePicker ,Form} from 'rsuite'; // if you're using rsuite's DateRangePicker
import locale from '../../../tr';// if you're using date-fns
  
  const DateRangeFilter =forwardRef((props,ref) => {

    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      filter: () => {
        if(date != null || date != ""){
          props.refresh();
          return filter_data_multiple();
          
        }
        
      },
      clear:() => {
        setDate([]);
      },
      types: () => {
      }
      
    }))

    const[date,setDate] = useState([]);

    useEffect(() => {

      if((date == null || date == "" ) && props.isFilterApplied){
          props.reset();
          props.filter();
          // filter_data_multiple();
      }if((date != null && date != "" )){
          // filter_data_multiple();
          console.log("filter applied -------------------------------- ")
          props.filter();
      }
    },[date])


    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => checkContains(item,e)))
    }

    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }


    const filter_data_multiple = () =>{

        var columns = []
        var inputs = [];

      
        if(date != "" && date != null){
          columns.push(props.tableHeadName)
          inputs.push(date);
        }
    
        if(columns.length > 0){
            let temps = []
            for(let c = 0 ; c< columns.length; c++){
              let temp = [];
                for(let i = 0; i< props.data.length ; i++){
                    if(isDateBetween(props.data[i][columns[c]],inputs[c])){
                        temp.push(props.data[i])
                    }
                }
            //console.log(" temp " + c +temp);
            temps.push(temp);
            }
          // console.log(temps);
            let first =  temps.shift();
            let final = intersect(first,temps);
            console.log("final" + final);
            
            // props.setFiltered(final);
            // props.setApplied(true);
            return final
        }
        return props.data
    }

    function isDateBetween(table_value,picker_value){

        var check = new Date(table_value);
        var str_check = "" + check.getFullYear() + check.getMonth() + check.getDate();
        var str_to = "" + picker_value[1].getFullYear() + picker_value[1].getMonth() + picker_value[1].getDate();
        var str_from = "" +  picker_value[0].getFullYear() + picker_value[0].getMonth() + picker_value[0].getDate();

        if(str_check == str_to || str_check == str_from){
            return true
        }else{
            if((check <= picker_value[1] && check >= picker_value[0]))     
                return true;
            else
                return false;
        }
      
        
    }
    

    return (
      <View style={{marginVertical: 10}}>
      <Form>
        <Form.Group>
            {/* <Form.ControlLabel> {props.tableHeadName} </Form.ControlLabel> */}
            <DateRangePicker value={date} style={props.item_style} onChange={ (value) => {setDate(value)}} name= {props.tableHeadName}   format="dd-MM-yyyy"
                                  locale={locale}/> 
        </Form.Group>
      </Form>
    </View>
    )


  })

  export default DateRangeFilter;