import { Text, View , StyleSheet, TouchableOpacity,TextInput } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {Input} from 'rsuite'

const ErrorLabelInputText = (props) => {
    return(
      <View style={{marginVertical: 20, width: props.width != null ? props.width : '100%'}}>
        <Input
          type = {props.type}
          placeholder= {props.placeholder}
          onChange={text=> {props.onChange(text); props.checkError();}}
          />
         {props.showError && <View style={{flexDirection: 'row', justifyContent: 'flex-start' , alignItems: 'center'}}>
              <MaterialCommunityIcons name = "alert" color={"#F26938"} />
              <Text style = {{marginHorizontal: 10}}>
                {props.errorText}
              </Text>
        </View>}
      </View>
      
    )
  }

  const styles = StyleSheet.create({
    inputText: { 
        borderWidth: 1, 
        borderColor: 'black', 
        height: 50, 
        borderRadius: 4, 
        padding: 5, 
        marginVertical: 5 ,
        outlineStyle : 'none' },
  })
  export default ErrorLabelInputText;