import { Text, View } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react';
import { Modal, Button } from 'rsuite';
import EditCarScreen from '.././EditCarScreen';
import NewCarScreen from '../NewCarScreen';


const EditCarModal = forwardRef((props,ref) => {

    const [PopupActive, setPopupActive] = useState(false);
    const [choosenData,setChoosenData] = useState(undefined);
    function handleModalClose(){
      setPopupActive(false);
   }



    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      open: (rowData) => {
        console.log(rowData);
          setChoosenData(rowData);
          setPopupActive(true);
      },
      
    }))

    function reset(){
        props.reset();
        setPopupActive(false);
    }

  
    return(
      choosenData != undefined  ?
        (<Modal overflow={true} size={"full"} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
          <Modal.Header>
            <Modal.Title> {choosenData["ARAÇ"]} </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            
            <EditCarScreen reset={reset} data = {choosenData} type = "UPDATE"></EditCarScreen>
          
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleModalClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
      </Modal>):
      (<View>

      </View>)
    )
  });

  export default EditCarModal;