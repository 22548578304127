import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { View } from 'react-native';
import { MultiCascader ,Form} from 'rsuite'; 
import FreeTextFilter from './FreeTextFilter';
import CarTagFilter from './CarTagFilter';
import DateRangeFilter from './DateRangeFilter';
import SliderFilter from './SliderFilter';
import TagPickerFilter from './TagPickerFilter';
import BetweenBeyondFilter from './BetweenBeyondFilter';


const FilterCompChooser = (props) => {

    return (
        <View>
            {props.filterType === 'FreeTextFilter' && (
                <FreeTextFilter
                    filter={props.filter}
                    item_style={props.item_style}
                    refresh={props.refresh}
                    ref={props.filterRef}
                    data={props.data}
                    tableHeadName={props.tableHeadName}
                    setApplied={props.setApplied}
                    setFiltered={props.setFiltered}
                    reset={props.reset}
                    isFilterApplied={props.isFilterApplied}
                />
                
            )}

            {props.filterType === 'CarTagFilter' && (
                <CarTagFilter
                    filter={props.filter}
                    item_style={props.item_style}
                    refresh={props.refresh}
                    ref={props.filterRef}
                    data={props.data}
                    tableHeadName={props.tableHeadName}
                    setApplied={props.setApplied}
                    setFiltered={props.setFiltered}
                    reset={props.reset}
                    isFilterApplied={props.isFilterApplied}
                />
            )}

            {props.filterType === 'DateRangeFilter' && (
                <DateRangeFilter
                    filter={props.filter}
                    item_style={props.item_style}
                    refresh={props.refresh}
                    ref={props.filterRef}
                    data={props.data}
                    tableHeadName={props.tableHeadName}
                    setApplied={props.setApplied}
                    setFiltered={props.setFiltered}
                    reset={props.reset}
                    isFilterApplied={props.isFilterApplied}
                />
            )}

            {props.filterType === 'SliderFilter' && (
                <SliderFilter
                    filter={props.filter}
                    item_style={props.item_style}
                    refresh={props.refresh}
                    ref={props.filterRef}
                    data={props.data}
                    tableHeadName={props.tableHeadName}
                    setApplied={props.setApplied}
                    setFiltered={props.setFiltered}
                    reset={props.reset}
                    isFilterApplied={props.isFilterApplied}
                />
            )}

            {props.filterType === 'TagPickerFilter' && (
                <TagPickerFilter
                    filter={props.filter}
                    item_style={props.item_style}
                    refresh={props.refresh}
                    ref={props.filterRef}
                    data={props.data}
                    tableHeadName={props.tableHeadName}
                    setApplied={props.setApplied}
                    setFiltered={props.setFiltered}
                    reset={props.reset}
                    isFilterApplied={props.isFilterApplied}
                />
            )}
            {props.filterType === 'BetweenBeyondFilter' && (
                <BetweenBeyondFilter
                    filter={props.filter}
                    item_style={props.item_style}
                    refresh={props.refresh}
                    ref={props.filterRef}
                    data={props.data}
                    tableHeadName={props.tableHeadName}
                    setApplied={props.setApplied}
                    setFiltered={props.setFiltered}
                    reset={props.reset}
                    isFilterApplied={props.isFilterApplied}
                />
            )}

        </View>
    );
}

export default FilterCompChooser;


    
