import React, { useState ,useEffect, useRef} from 'react';
import { Button , Divider, Drawer, Input, List, Stack  } from 'rsuite';
import { PDFDownloadLink , View, Text,Image , pdf, PDFViewer} from '@react-pdf/renderer';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import Amplify, {Auth, Storage} from 'aws-amplify';
import axios from 'axios';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';


Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});
const ImageToPdfExporter = (props) => {

    const getURL = async (input) => {
        if (input != null && input != undefined) {
            console.log("----- s3Key " + input);
            try {
                const data = await Storage.get(input, { 
                    expires: 120,
                    level: 'public/'
                    /* identityId: '2cd3970d-b634-4bf1-b27a-86fc58ff3fa7' */
                });
                console.log("----- url name " + data);
                return data;
            } catch(err) {
                console.log("storage error " + err);
                return undefined; 
            }
        }
    }

    function MailPDFButton() {

        const [loading, setLoading] = React.useState(false);

        const getUrlAndSend = async (input) => {
            const urlAws = await getURL(props.s3KEY);
            console.log("----- url name " + urlAws);
            generateDocument(urlAws);
        }

        const generateDocument = async (input) => {
            console.log("----- url name " + input);
            setLoading(true);
            const doc = <MyDocument image={input}/>;
            const asPDF = pdf([]); // pass an empty array
            asPDF.updateContainer(doc);
            const blob = await asPDF.toBlob();
            sendPDF(blob);
        };

        function blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = function() {
                    resolve(reader.result.split(',')[1]);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }

        const {fetchIdToken} = useUserToken();
        const sendPDF = async (blob) => {

            const mail = await Auth.currentAuthenticatedUser().then((user) => {
                return user.attributes.email;
            });
            
            const base64Pdf = await blobToBase64(blob);    
            const body = {
                "type": 'SEND_EMAIL',
                "data": base64Pdf,
                "mailto" : mail,
            };
            const api = configdata.API_BASE + '/cardata'
            const token = await fetchIdToken();
    
            const header = {
                'Authorization' : `Bearer ${token}`,
            }


            try {
                const response = await axios.post(api, body, {headers: header});
                console.log(response.data);
            } catch (error) {
                console.error("Error uploading the file.", error);
            }
        };
    
        return (
            <div>
                {/* <Button onClick={generatePDFBlob}>
                    PDF Oluştur
                </Button> */}
                <Button onClick={getUrlAndSend}>
                    Mail Gönder
                </Button>
            </div>
        );
    }

      
    function DownloadButton() {
        const [loading, setLoading] = React.useState(false);



        const getUrlAndSend = async (input) => {
            const urlAws = await getURL(props.s3KEY);
            console.log("----- url name " + urlAws);
            generateDocument(urlAws);
        }
      
        const generateDocument = async (input) => {
            console.log("----- url name " + input);
            setLoading(true);
            const doc = <MyDocument image={input}/>;
            const asPDF = pdf([]); // pass an empty array
            asPDF.updateContainer(doc);
            const blob = await asPDF.toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = ` ${props.s3KEY}.pdf`;
            link.click();
            URL.revokeObjectURL(url);
            setLoading(false);
        };
        
      
        return (
          <Button onClick={getUrlAndSend}>
            {loading ? 'Döküman Oluşturuluyor...' : 'Dökümanını İndir'}
          </Button>
        );
    }


    return (
        <View style={{flex: 1}}>
            <Stack wrap spacing={ 5 }>
                    {/* <div style={{  
                        padding: '20px 20px 20px 20px' ,
                        backgroundColor: 'white',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        
                        
                    </div> */}
                    <DownloadButton></DownloadButton>
                    <MailPDFButton></MailPDFButton>
                {/* <MailPDFButton></MailPDFButton> */}
            </Stack>

        </View>
    );
}

export default ImageToPdfExporter;

// Create Document Component
const MyDocument = (props) => (
    console.log("----- url name " + props.image),
    <Document>
        <Page size="A4" style={{flexDirection: 'column', flex: 1, alignItems:'center', justifyContent :'center'}}>
            <Image src = {props.image} style={{width: 450}}></Image>
        </Page>
    </Document>
);