import { Text, View , Dimensions } from 'react-native'
import React, { PureComponent,useState, useEffect,useRef} from 'react'
import { Button,DatePicker,Form, Input, MaskedInput, SelectPicker ,IconButton, RadioGroup, Radio,Drawer,List,useToaster, Message,Row, Col, Grid, Panel} from 'rsuite'
import axios from 'axios';
import { useUserToken } from '../../../hooks/useUserToken';
import configdata from '../../../config/config.json';
import FileUploader from '../../../Components/FileManagements/FileUploader';
import FileLoaderInline from '../../../Components/FileManagements/FileLoaderInline';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import { StyleSheet } from 'react-native';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import NewCustomerScreen from '../../RentScreens/CustomerManagement/NewCustomerScreen';
import CustomListItem from './CustomListItem';
import { faCalendar, faDiamond, faMoneyBill, faPerson } from '@fortawesome/free-solid-svg-icons';
import { faModx } from '@fortawesome/free-brands-svg-icons';


const BuyInfoShower = (props) => {

    const [current,setCurrent] = useState({});
    const [clientData,setClientData] = useState([]);


    const {fetchIdToken} = useUserToken();
    async function getData(){
        const api = configdata.API_BASE + '/cardata';
        const token = await fetchIdToken();
        const headers = {
            'Authorization' : `Bearer ${token}`,
        }
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_BUY_INFO",
            "CAR_ID" : props.carID
        }
        axios.post(api,body,{headers}).then(response => {
            console.log(response);
            if(response.data.length == 0){
                setCurrent({});
            }else{
                let temp = {...response.data[0]};
                console.log(temp);
                let clientTmp = clientData.filter(item => item.value == temp["CLIENT_ID"]); 
                if(clientTmp.length > 0){
                    temp["TEDARİKÇİ"] = clientTmp[0].label;
                }
                setCurrent(temp);
            }
           
        }).catch(error => {
            console.log(error);
        })
    }

    function getClients(){
        console.log("getClients");
        const api = configdata.API_BASE + '/customers';
          axios.post(api,{
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CLIENTS_TEDARIK"
          }).then(
              (response) => {
                  console.log(response.data);
                  let temp = [];
                    response.data.forEach(element => {
                        if(element.NAME == null || element.NAME == ""){
                            temp.push({"label" : element.PERSON_NAME + " " + element.PERSON_SURNAME , "value" : element.ID})
                        }else {
                            temp.push({"label" : element.NAME , "value" : element.ID})
                        }
                    });
                    console.log(temp);
                  setClientData(temp);
              }
          ).catch(
              (error) => {
                  console.log(error);
              }
          )
    }

    useEffect(() => {
        if(clientData.length > 0){
            console.log("getData");
            getData();
        }
    },[clientData]);

    useEffect(() => {
        getClients();
    },[]);



    const headers =
    [ 
        {"header" : "TEDARİKÇİ" , "data" : current["TEDARİKÇİ"] , "icon" :faPerson },
        {"header" : "SATIN ALIM ŞEKLİ" , "data" : current["BUY_TYPE_NAME"] , "icon" :faDiamond },
        {"header" : "NOTER ALIŞ TARİHİ" , "data" : current["OFFICIAL_BUY_DATE"] , "icon" :faCalendar },
        {"header" : "NOTER ALIŞ TUTARI" , "data" : current["TAX_INC_PRICE"] , "icon" :faMoneyBill },
        {"header" : "NOTER ALIŞ KDV ORANI" , "data" : current["KDV"] , "icon" :faModx },
        {"header" : "NOTER ALIŞ KDV'SİZ TUTAR" , "data" : current["TAX_EXC_PRICE"] , "icon" :faMoneyBill },

    ]    

    const headers2 =
    [
        {"header" : "DİĞER FATURALI TUTAR" , "data" : current["DIGER_KDV_TUTARI"] , "icon" :faMoneyBill },
        {"header" : "DİĞER FATURASIZ TUTAR" , "data" : current["DIGER_FATURASIZ_TUTAR"] , "icon" :faPerson },
        {"header" : "DİĞER FATURALI KDV'SİZ TUTAR" , "data" : current["DIGER_KDVSIZ_TUTAR"] , "icon" :faMoneyBill },
        {"header" : "DİĞER FATURALI KDV ORANI" , "data" : current["DIGER_KDV"] , "icon" :faModx },
        {"header" : "ALIŞ KANALI", "data" : current["BUY_DETAILED_CATEGORY"], "icon" : faDiamond},
        {"header" : "NOTLAR" , "data" : current["NOTES"] , "icon" :faDiamond}
    ]
    
    const [infoDrawer,setInfoDrawer] = useState(false);
    const [fileDrawer,setFileDrawer] = useState(false);
    const [fileCategory,setFileCategory] = useState("BUY_FILE");

    function handleClose(){
        setInfoDrawer(false);
    }

    function handleOpen(){
        setInfoDrawer(true);
    }

    function handleOpenFileDrawer(category){
        setFileCategory(category);
        setFileDrawer(true);
    }

    function handleCloseFileDrawer(){
        setFileDrawer(false);
    }

    function reset(){
        setInfoDrawer(false);
        getData();
    }

    <List>
    {headers.map((item) =>
    {
    return(<List.Item key={item} index={item}>
        <CustomListItem item={item} ></CustomListItem>
    </List.Item>)
    }
    )}

</List>
    
    

    return (
        <View style={{backgroundColor: '#F2F2F2', width: '100%',flex : 1, padding: 20}}>
            <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 50, minWidth: 200,marginBottom: 20 }} >
                <IconButton onClick={() => { setInfoDrawer(true)}} icon={<Icon><FontAwesomeIcon icon = {"plus"}></FontAwesomeIcon></Icon>}> Satın Alım Bilgisi Düzenle</IconButton>
            </Panel>
            <Grid fluid style={{width: '100%', marginBottom: 20}}>
                <Row gutter={20}>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 500, minWidth: 200,marginBottom: 20}} >
                            <List>
                                <List.Item key= {"Plaka"}>
                                <CustomListItem item = {{"header" : "Plaka" , "data" : props.data["PLAKA"]}}></CustomListItem>
                                </List.Item>
                                {headers.map((item) =>
                                {
                                return(<List.Item key={item} index={item}>
                                    <CustomListItem item={item} ></CustomListItem>
                                </List.Item>)
                                }
                                )}
                            </List>
                        </Panel>
                    </Col>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 500, minWidth: 200,marginBottom: 20}} >
                            <List>
                                {headers2.map((item) =>
                                {
                                return(<List.Item key={item} index={item}>
                                    <CustomListItem item={item} ></CustomListItem>
                                </List.Item>)
                                }
                                )}
                                <List.Item key= {"NoterFile"}>
                                    <CustomListItem type={"FILE"} item={{"header" : "NOTER SÖZLEŞMESİ","type" : "NOTER_FILE"}} openFileDrawer= {handleOpenFileDrawer} ></CustomListItem>
                                </List.Item>
                                <List.Item key= {"BuyFile"}>
                                    <CustomListItem type={"FILE"} item={{"header" : "ALIŞ FATURASI", "type" : "BUY_FILE"}} openFileDrawer= {handleOpenFileDrawer} ></CustomListItem>
                                </List.Item>
                            </List>
                        </Panel>
                    </Col>
                </Row>
            </Grid>

            <Drawer overflow={true}  size={'md'}  backdrop={'static'} keyboard={false} open={infoDrawer} onClose={handleClose} >
                <Drawer.Header>
                    <Drawer.Title> {" Satın Alım Bilgisi --" + props.data["ARAÇ EDİNİM ŞEKLİ"]}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <BuyInfoScreenUsedCar data={props.data} type="" reset = {reset} carID={props.carID}></BuyInfoScreenUsedCar>
                </Drawer.Body>
                
            </Drawer>

            <Drawer backdrop= {'static'} size={'full'}  open={fileDrawer} onClose={handleCloseFileDrawer} >
                <Drawer.Header>
                    <Drawer.Title> Satın Alım Dosyası </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    {
                        fileCategory == "BUY_FILE" ? 
                        (
                                <FileViewer inline={false}  height = {1000} s3KEY = {current["BUY_FILE"]}></FileViewer>

                        )
                        :
                        (
                              <FileViewer inline = {false} height = {1000} s3KEY = {current["NOTER_FILE"]}></FileViewer>
                        )
                    }
                </Drawer.Body>
            </Drawer>
        </View>
            
    )
}

const BuyInfoScreenUsedCar = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
                {input}
            </Message>, { placement: 'topEnd' })
    }
    const [client,setClient] = useState(0);
    const [buyType,setBuyType] = useState(0);
    const [buyTypes,setBuyTypes] = useState([]);
    const [clientData,setClientData] = useState([]);
    const [noterDate,setNoterDate] = useState(new Date());
    const [noterfile,setNoterFile] = useState(null);
    const [clientType,setClientType] = useState(0);
    const [alisTutarı,setAlisTutarı] = useState("0 TL");
    const [kdv,setKdv] = useState(1);
    const [buyFile,setBuyFile] = useState(null);
    const [alisFatura,setAlisFatura] = useState(null);
    const [alisFaturaKdvsizTutar,setAlisFaturaKdvsizTutar] = useState(0);
    const [alisKanal,setAlisKanal] = useState("");
    const [notlar,setNotlar] = useState("");
    const [digerFaturalıTutar,setDigerFaturalıTutar] = useState(0);
    const [digerFaturasızTutar,setDigerFaturasızTutar] = useState(0);
    const [digerFaturalıKDVsizTutar,setDigerFaturalıKDVsizTutar] = useState(0);
    const [digerKdv,setDigerKdv] = useState(0);
    const [faturaOrGiderpusulasi,setFaturaOrGiderpusulasi] = useState(0);

    const [componentType,setComponentType] = useState("NEW");
    const [ID,setID] = useState(0);
    const [kdvOranları,setKdvOranları] = useState([ 
        {"label" : "%20" , "value" : 20},
        {"label" : "%18" , "value" : 18},
        {"label" : "%10" , "value" : 10},
        {"label" : "%8" , "value" : 8},
        {"label" : "%1" , "value" : 1},
    ]);

    
    const alisKanallari = [
        {"label" : "Kurumsal" , "value" : "Kurumsal"},
        {"label" : "FBK" , "value" : "FBK"},
        {"label" : "RENT TO RENT" , "value" : "RENT TO RENT"},

    ]

    const giderPusulaRef = useRef(null);
    const noterRef = useRef(null);
    const alisFaturaRef = useRef(null);
    const [s3AlisFatura,setS3AlisFatura] = useState(undefined); 
    const [s3NoterFile,setS3NoterFile] = useState(undefined);
    const [s3GiderPusulaFile,setS3GiderPusulaFile] = useState(undefined);

    const {fetchIdToken} = useUserToken();
    function sendData(){
        // noterRef.current.load();
        noterRef.current.load();
 
    }



    async function sendToRDS(){
        const api = configdata.API_BASE + '/cardata';
        const token = await fetchIdToken();
        const headers = {
            "Authorization" : `Bearer ${token}`,
        }
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : componentType == "NEW" ?  "ADD_BUY_INFO_USED_CAR" : "UPDATE_BUY_INFO_USED_CAR",
            "BUY_TYPE" : buyType,
            "CLIENT_ID" : client,
            "NOTES" : notlar,
            "CAR_ID" : props.carID,
            "OFFICIAL_BUY_DATE" : noterDate,
            "TAX_INC_PRICE" : unformatTLvalue(alisTutarı),
            "TAX_EXC_PRICE" : alisFaturaKdvsizTutar,
            "KDV" : kdv,
            "DIGER_KDV_TUTARI" : unformatTLvalue(digerFaturalıTutar),
            "DIGER_KDVSIZ_TUTAR" : digerFaturalıKDVsizTutar,
            "DIGER_FATURASIZ_TUTAR" : digerFaturasızTutar,
            "DIGER_KDV" : digerKdv,
            "BUY_DETAILED_CATEGORY" : alisKanal,
            "ID" : ID
        }
        axios.post(api,body,{headers : headers}).then(response => {
            console.log(response);
            if(response.data == "SUCCESS"){
                messageToggle("Satın Alım Bilgisi Başarıyla Eklendi","success");
                props.reset();
            }
            else{
                messageToggle("Satın Alım Bilgisi Eklenirken Bir Hata Oluştu","error");
            }
        }
        ).catch(error => {
            console.log(error);
        }
        )
    }

    const  getData = async () => {
        console.log("getData");
        const api = configdata.API_BASE + '/cardata';
        const token = await fetchIdToken();
        const headers = {
            'Authorization' : `Bearer ${token}`,
        }
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_BUY_INFO",
            "CAR_ID" : props.carID
        }
        axios.post(api,body,{headers}).then(response => {
            console.log(response);
            if(response.data.length == 0){
                setComponentType("NEW")
                return;
            }else{
                setID(response.data[0].ID)
                setComponentType("UPDATE");
                setBuyType(response.data[0].BUY_TYPE);
                setClient(response.data[0].CLIENT_ID);
                setNoterDate(new Date(response.data[0].OFFICIAL_BUY_DATE));
                setAlisTutarı(response.data[0].TAX_INC_PRICE);
                setKdv(response.data[0].KDV);
                setDigerFaturalıTutar(response.data[0].DIGER_KDV_TUTARI);
                setDigerFaturasızTutar(response.data[0].DIGER_FATURASIZ_TUTAR);
                setDigerKdv(response.data[0].DIGER_KDV);
                setAlisKanal(response.data[0].BUY_DETAILED_CATEGORY);
                setNotlar(response.data[0].NOTES);
            }


        }).catch(error => {
            console.log(error);
        })
    }


    useEffect(() => {
        getDropDownValuesGlobal("BUY_TYPE",setBuyTypes);
        getClients();
    },[]);

    useEffect(() => {
        if(clientData.length > 0){
            console.log("getData");
            getData();
        }
    },[clientData]);

    useEffect(() => {
        let temp = (unformatTLvalue(alisTutarı) / (1 + (kdv / 100))).toFixed(2);
        setAlisFaturaKdvsizTutar(parseFloat(temp.replace(".",",")));
    },[alisTutarı,kdv]);

    useEffect(() => {
        let temp = (unformatTLvalue(digerFaturalıTutar) / (1 + (digerKdv / 100))).toFixed(2);
        setDigerFaturalıKDVsizTutar(parseFloat(temp.replace(".",",")));
    },[digerFaturalıTutar,digerKdv]);

    function getClients(){
        console.log("getClients");
        const api = configdata.API_BASE + '/customers';
          axios.post(api,{
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CLIENTS"
          }).then(
              (response) => {
                  console.log(response.data);
                  let temp = [];
                    response.data.forEach(element => {
                        if(element.NAME == null || element.NAME == ""){
                            temp.push({"label" : element.PERSON_NAME + " " + element.PERSON_SURNAME , "value" : element.ID})
                        }else {
                            temp.push({"label" : element.NAME , "value" : element.ID})
                        }
                    });
                    console.log(temp);
                  setClientData(temp);
              }
          ).catch(
              (error) => {
                  console.log(error);
              }
          )
    } 

    function getDropDownValuesGlobal(input,set){
        const api = configdata.API_BASE + '/uidropdownlist';
        axios
        .post(api,{
            "ENV" :configdata.APP_ENV ,  
            "category": input,
            "type" : "GET_GLOBAL",
            
        })
        .then((response) => {
            // console.log(response);
            // console.log(response.data);
            //setUserData(JSON.parse(response.data));
            console.log(response.data);
            var result = [];
            response.data.forEach( element =>
            { 
                result.push({"label" : element.VALUE , "value" : element.ID})
            }
            );
            set(result);
            // console.log(result);
        })
    .catch((error) => {
        console.log(error);
        
    });

    }

    function handleFileUpload(file){
        console.log(file);
    }

    const [customerDrawerOpen,setCustomerDrawerOpen] = useState(false);

    function handleClose(){
        setCustomerDrawerOpen(false);
    }

    function handleOpen(){
        setCustomerDrawerOpen(true);
    }

    function resetCustomers(){
        handleClose();
        getClients();
    }


    useEffect(() => {
        console.log(s3NoterFile);
        if(faturaOrGiderpusulasi == 0 && s3NoterFile != undefined){
            alisFaturaRef.current.load();
        }
        else if(faturaOrGiderpusulasi == 1 && s3NoterFile != undefined){
            giderPusulaRef.current.load();
        }
    },[s3NoterFile]);

    useEffect(() => {
        console.log("S3 Values on Components");
        console.log(s3AlisFatura);
        if(s3AlisFatura != undefined){
            sendToRDS();
        }
    },[s3AlisFatura]);

    useEffect(() => {
        console.log("S3 Values on Components2");
        console.log(s3GiderPusulaFile);
        if(s3GiderPusulaFile != undefined){
            sendToRDS();
        }
    },[s3GiderPusulaFile]);

    useEffect(()=> {
        console.log(buyTypes.find(element => element.label.toLowerCase().includes("filo"))?.value)
        console.log(buyType);
        console.log(props.MARKA);

    },[buyType])

    return (
        <View style={{backgroundColor: '#F2F2F2', width: '100%',flex : 1}}>
            <Drawer  overflow={true}  size={'full'}  backdrop={true} keyboard={false} open={customerDrawerOpen} onClose={handleClose}  >
                <Drawer.Header>
                    <Drawer.Title> Yeni Müşteri </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <NewCustomerScreen onRow  reset={resetCustomers} ></NewCustomerScreen>
                </Drawer.Body>
            </Drawer>
            <View style={{ padding : 24 , backgroundColor: 'white' }}>
                <View style={styles.group} >
                    <Group label={ "Satın Alım Şekli"}>
                        <SelectPicker placement='autoVerticalStart' value={buyType}  style={styles.listItem} placeholder= {""} data={buyTypes} onChange={setBuyType} />
                    </Group>
                    {
                        (
                            buyTypes.find(element => element.label.toLowerCase().includes("filo"))?.value === buyType && 
                            props.data["MARKA"] === "FIAT"
                        ) && (
                            <Group label={ "Satın Alım Kanalı"}>
                                <SelectPicker placement='autoVerticalStart' value={alisKanal} style={styles.listItem} placeholder={""} data={alisKanallari} onChange={setAlisKanal} />
                            </Group>
                        )
                    }
                    { 
                    buyTypes.find(element => element.label.toLowerCase().includes("filo"))?.value !== buyType &&
                        <Group label={ "Noter Sözleşmesi"}>
                            {/* <FileUploader onChange={handleFileUpload} /> */}
                            <FileLoaderInline ref={noterRef} carID = {props.carID} fileCategory= "NOTER_FILE" setS3Value={setS3NoterFile}></FileLoaderInline>
                        </Group>  
                    }
                    { 
                        buyTypes.find(element => element.label.toLowerCase().includes("filo"))?.value !== buyType &&
                            <Group label={ "Noter Alış Tarihi"}>
                                <DatePicker oneTap placement='autoVerticalStart' value={noterDate}  onChange={setNoterDate} style={styles.listItem} placeholder="Noter Alış Tarihi" />
                            </Group>
                    }
                  
                    <Group label={ " Alış Tutarı "}>
                        <MaskedInput value={alisTutarı} style={styles.listItem} placeholder="Noter Alış Tutarı" mask={numberMaskTL} onChange={setAlisTutarı} />
                    </Group>
                    <Group label={ "Müşteri"}>
                    <IconButton icon={<Icon><FontAwesomeIcon icon = {"plus"}></FontAwesomeIcon></Icon>}
                        onClick={handleOpen}
                    ></IconButton>
                    <SelectPicker placement='autoVerticalStart' value={client} style={styles.listItem} placeholder= {""} data={clientData} onChange={setClient} />
                    </Group>
                    <Group label={ "Fatura Tipi "}>
                        <RadioGroup name="radioList" inline appearance="picker" defaultValue={0} onChange={setFaturaOrGiderpusulasi}>
                            <Radio value={0}>Faturalı</Radio>
                            <Radio value={1}>Gider Pusulalı</Radio>
                        </RadioGroup>
                    </Group>
                    {
                    faturaOrGiderpusulasi == 0 ? (
                        <View style={styles.group}>
                            <Group label={ "Alış Faturası"}>
                                {/* <FileUploader onChange={setAlisFatura} /> */}
                                <FileLoaderInline ref={alisFaturaRef} carID = {props.carID} fileCategory= "BUY_FILE" setS3Value={setS3AlisFatura}></FileLoaderInline>
                            </Group>
                            <Group label={ "KDV Oranı"}>
                                <SelectPicker placement='autoVerticalStart' value={kdv} style={styles.listItem} placeholder= {""} data={kdvOranları} onChange={setKdv} />
                            </Group>
                            <Group label={ "Alış Faturası KDV'siz Tutar"}>
                                {/* <MaskedInput value={alisFaturaKdvsizTutar} style={styles.listItemDisabled}  mask={numberMaskTL} disabled/> */}
                                <View style={styles.listItemDisabled}>
                                    <Text style={{color : 'white', textAlign  :'left'}}> { numberToCurrency(alisFaturaKdvsizTutar) } </Text>
                                </View>
                            </Group>
                        </View>
                    ) : (
                        <View style={styles.group}>

                            <Group label={ "Gider Pusulası"}>
                                {/* <<FileUploader onChange={setBuyFile}> /> */}
                                <FileLoaderInline ref={giderPusulaRef} carID = {props.carID} fileCategory= "BUY_FILE" setS3Value={setS3GiderPusulaFile}></FileLoaderInline>
                            </Group>
                            <Group label={ "KDV Oranı"}>
                                <DisabledLabel label = {' %0 '} />
                            </Group>    
                        </View>
                        )
                    }

                    <Group label={ "Diğer Faturalı Tutar"}>
                        <MaskedInput value={digerFaturalıTutar} style={styles.listItem} placeholder="Diğer Faturalı Tutar" mask={numberMaskTL} onChange={setDigerFaturalıTutar} />
                    </Group>
                    <Group label={ "KDV"}>
                        <SelectPicker placement='autoVerticalStart' data={kdvOranları} onChange={setDigerKdv} style={styles.listItem} value={digerKdv} ></SelectPicker>
                    </Group>
                    <Group label={ "Diğer Faturalı KDV'siz Tutar"}>
                        {/* <MaskedInput value={digerFaturalıKDVsizTutar} style={styles.listItemDisabled} mask={numberMaskTL} disabled/> */}
                        <View style={styles.listItemDisabled}>
                            <Text style={{color : 'white', textAlign  :'left'}}> { numberToCurrency(digerFaturalıKDVsizTutar) } </Text>
                        </View>
                    </Group>
                    <Group label={ "Diğer Faturasız Tutar"}>
                        <MaskedInput value={digerFaturasızTutar} style={styles.listItem} placeholder="Diğer Faturasız Tutar" mask={numberMaskTL} onChange={setDigerFaturasızTutar} />
                    </Group>
                    <Group label={ "Notlar"}>
                        <Input value={notlar} style={styles.listItem} onChange={setNotlar}  />
                    </Group>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginHorizontal: 10}}>
                    <Button  style={styles.buttonBlue} onClick={sendData}>{componentType == "NEW" ? "Ekle" : "Güncelle"}</Button>
                </View>
            </View>
        </View>
    )
  
}

const DisabledLabel = (props) => {
    return (
        <View style={styles.disabledLabel}>
            <Text style = {{color: 'white'}} >{props.label}</Text>
        </View>
    )
}

function Group ({ label, children, layout }) {
    return (
        <View style={{flexDirection :'row', marginVertical : 15, justifyContent: 'space-between',width: '100%'}}>
            <Text style={{ fontSize: 14, color: '#000', margin: 10}}>{label}</Text>
            <div>{children}</div>
        </View>
    );
}


const numberMaskTL = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol : '.',
    suffix: ' TL' ,
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 5
  })

  function numberToCurrency(number){
    let temp = number;
    if(typeof number == "string" && !number.includes("TL")){
        temp = parseFloat(number);
    }
    return temp.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
    }
            

  const unformatTLvalue = (value) => {
    
    if(value == null || value == ""){
        return 0;
    }
    if(value.split == undefined){
        return value;
    }
    if(value == "₺0,00"){
        return 0;
    }
    let temp = value.split(" ");
    temp = temp[0].split(".");
    let result = "";
    temp.forEach(element => {
        result += element;
    });
    result = result.replace(",",".");
    return parseFloat(result);
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F2FFFB',
        alignItems: 'center',
        justifyContent: 'center',
    },
    group: {
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    listItem: {
        backgroundColor: "#FFF",
        width: 200,
        height: 35,
    },
    listItemDisabled: {
        backgroundColor: "#aac9fa",
        width: 200,
        height: 35,
        borderRadius: 7,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',

    },
    disabledLabel: {
        backgroundColor: "#aac9fa",
        width: 200,
        height: 35,
        borderRadius: 7,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },

    buttonRed : {
        backgroundColor: "#FF0000",
        width: 120,
        height: 35,
        color: 'white',

    },
    buttonBlue : {
        backgroundColor: "#267aff",
        width: 120,
        height: 35,
        color: 'white',

    },

});

export default BuyInfoShower