import React, { Component,useState  } from 'react';
import { Text,StyleSheet, Pressable, View, TextInput, Alert ,TouchableHighlight,  Modal, Image} from 'react-native';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import{useTogglePasswordVisibility} from '../../../hooks/useTogglePasswordVisibility'
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';
import ProfilePopUpComponent from '../../BackOffice/UserManagement/ProfilePopUpComponent';
import BMLogo from '../../../assets/BilenMobi.png';

Auth.configure(awsconfig)
const AuthScreen = (props) => {
    
    const { passwordVisibility, rightIcon, handlePasswordVisibility } =
    useTogglePasswordVisibility();
   
    const navigation = useNavigation();
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('');

  
    // const onlogin = async (usernameI,passwordI) => {
    //     if (loading) {
    //       return;
    //     }
    
    //     setLoading(true);
    //     try {
    //       const response = await Auth.signIn(usernameI, passwordI);
    //       console.log(response);
          
    //     } catch (e) {
    //       Alert.alert('Oops', e.message);
    //     }
    //     setLoading(false);
        
    //   };



    const [toggleNewPassword,setToggleNewPassword] = useState(false);  
    const [refresh,setRefresh] = useState(false);  

    const SignIn = async (iusername,ipassword) => {

        Auth.signIn(iusername, ipassword)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setToggleNewPassword(true);
                    console.log("'NEW_PASSWORD_REQUIRED'")
                } else {
                    // other situations
                    console.log("success");
                    // window.location.reload(false);
                    navigation.navigate("Home");
                }
            }).catch(e => {
                console.log("OOPS" + e);
            });
    }

    
    

    return (
    
        <View style = {styles.container}>
            <LinearGradient
                // Button Linear Gradient
                end={{ x: 1, y: 0 }}
                colors={['#7F7FD5', '#86A8E7', '#91EAE4']}
                style={{width:"100%", height:"100%", alignItems: 'center' , justifyContent : 'center'}}>
                   <Modal
                        animationType="slide"
                        transparent={false}
                        visible={toggleNewPassword}
                    >
                        <View style={{ flex: 1,justifyContent: "center",alignItems: "center", marginTop: 22 }}>
                        <View style={styles.modalView}>
                            <ProfilePopUpComponent username = {username} isNewUser = {true} navigate = {()=> navigation.navigate("Home")} toggleVisible = {setToggleNewPassword} isVisible = {toggleNewPassword}></ProfilePopUpComponent>
                        </View>
                        </View>
                    </Modal> 
            <View style = {[styles.login_input]}>
            <Image source={BMLogo} style={{width: 300, height: 100, alignSelf: 'center', resizeMode: 'contain'}} />
            <View style = {{marginVertical: 10}}></View>
            <View style = {styles.login_input_field}>
                <TextInput
                    style={styles.text_input_login}
                    placeholder="Kullanıcı Adı"
                    onChangeText={newText => setUsername(newText)}
                    textContentType = "emailAddress"
                    defaultValue={username}
                />
            </View>
            <View style= {styles.login_input_field}>
                <TextInput
                    style={styles.text_input_login}
                    
                    name='password'
                    placeholder='Şifre'
                    autoCapitalize='none'
                    autoCorrect={false}
                    textContentType='newPassword'
                    secureTextEntry={passwordVisibility}
                    value={password}
                    enablesReturnKeyAutomatically
                    onChangeText={text => setPassword(text)}
                    />
                

                <Pressable style = {{margin:"auto", marginRight:"1rem"}} onPress={handlePasswordVisibility} >
                <MaterialCommunityIcons name={rightIcon} size={22} color="#232323" />
                </Pressable>

            </View>
          
            <TouchableHighlight onPress={() => SignIn(username,password)} style={styles.default_button}  underlayColor= { `#4082B4`}>
                    <Text style = {{color: "#f7f3f2", fontSize: 20}}> Giriş </Text>
            </TouchableHighlight>
{/* 
            <TouchableHighlight onPress={() => {check_user()}} style={styles.default_button}  underlayColor= { `#4082B4`}>
                    <Text style = {{color: "#f7f3f2", fontSize: 20}}> check </Text>
            </TouchableHighlight> */}
            
        </View>
            </LinearGradient>
        </View>
);
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
    },
    login_input:{
        backgroundColor: "#fff",
        padding: "2rem",
        maxWidth:"80%",
        width: 400,
        borderRadius: 5,
    },
    login_input_field:{
        flexDirection: "row",
        borderRadius: 5,
        backgroundColor: "#E8F8FF",
        marginVertical: 5,
    },
    text_input_login:{
        flex: "1",
        height: 60 ,
        marginRight:"1rem",
        padding:"1rem",
        borderRadius:5,
        outlineStyle : 'none' 
    }, 
    default_button: {
        backgroundColor: '#9C5CFF' , 
         borderRadius: "5px",
         width: '100%',
         height: 50,
         alignItems: 'center',
         justifyContent: 'center',
         marginVertical: 5,
    },
    button_text:{
        color: "white",
        fontSize: 18
    },
     
});


export default AuthScreen;