import { Text, View , StyleSheet, Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef } from 'react'
import axios from 'axios';
import { Input,Button, Form ,DatePicker,IconButton  ,Checkbox, List, Drawer,Stack, MaskedInput, Schema, Panel, Grid,Row, Col} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import locale from '../../../tr'
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faCalendar, faGauge,  faSquare } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import CustomText from '../../../Components/CustomText';
import ExportData from '../../../Components/TableManagement/ExportData';
import { useMessage } from '../../../hooks/useMessage';

const KmScreen = (props) => {
  
    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [editData,setEditData] = useState({});
    const [KM_array,setKM_array] = useState([])
    const [Date_array,setDate_array] = useState([])

    function handleModalClose(){
        setModal(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();
    async function getComponents(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
        const api = configdata.API_BASE + '/cardata';
        axios.post(api,{
          "ENV" :configdata.APP_ENV ,  
            "type" : "GET_KM_DATA",
            "ID" : props.data["NO"],
        },{headers}).then((response)=>{
            if(response.data.length > 0){
              setCurrentActive(response.data);
            
            }
          
            setData(response.data);
            response.data.forEach((element) => {
              setKM_array((KM_array) => [...KM_array,element["GÜNCEL KM"]])
              setDate_array((Date_array) => [...Date_array,element["OLUŞTURULMA TARİHİ"]])
            })
            // if(response.data.length = 0){
            //   setDefaultData([{"GÜNCEL KM" : props.data["ARAÇ KM"],"EDİNİMDEKİ KM" :props.data["ARAÇ KM"]  ,"OLUŞTURULMA TARİHİ" : props.data["UPDATE_DATE"]}])
            // }
        }).catch((error)=> {
            console.log(error);
        })
    }

    function setCurrentActive(input){
      for(var i = 0; i < input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
          setCurrent(input[i]);
          break;
        }
      }
    }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
    }

    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }

   
    const tableHeads= ["GÜNCEL KM","OLUŞTURULMA TARİHİ"];
    const listHeads= ["GÜNCEL KM","OLUŞTURULMA TARİHİ","NOTLAR"];

    const headers = [
      {header : "GÜNCEL KM",data : current["GÜNCEL KM"],icon : faGauge},
      {header : "EDİNİMDEKİ KM",data : current["EDİNİMDEKİ KM"],icon : faGauge},
      {header : "OLUŞTURULMA TARİHİ",data : current["OLUŞTURULMA TARİHİ"],icon : faCalendar},
      {header : "NOTLAR",data : current["NOTLAR"],icon :faSquare},
      
    ]


    return(
      <View style={{flex: 1 , backgroundColor :'#F2F2F2', padding: 20}}>

          <Drawer size= {Dimensions.get('window').width < 700 ? 'full' : 'sm'} backdrop={'static'} keyboard={false} open={modal} onClose={handleModalClose}>
                <Drawer.Header>
                <Drawer.Title> 
                  {<View>
                    <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                    <Text> {"KM BİLGİLERİ"} </Text>
                  </View>} 
                </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                  
            {modalType == "New" ?   

                   ( <NewKMDataPanel 
                    type={"NEW"}
                    cardata = {props.data} 
                    data = {editData}
                    KM_array= {KM_array}
                    Date_array= {Date_array}
                    reset = {() => {getComponents(); handleModalClose();}}></NewKMDataPanel>):
                    ( 
                  <NewKMDataPanel 
                      type={"EDIT"}
                      cardata = {props.data} 
                      data = {editData}
                      KM_array= {KM_array}
                      Date_array= {Date_array}
                      reset = {() => {getComponents(); handleModalClose();}} ></NewKMDataPanel> )
                }
              </Drawer.Body>
          </Drawer>

          <Grid fluid style={{ width: '100%'}}>
            <Row gutter={20}>
              <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
              <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200, marginBottom: 20}}  header  = {
                    <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                      <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                        <Stack.Item grow={1}>
                        <IconButton 
                          style={{width: '100%', height: 35}}
                          onClick={() => {
                            setModal(true);
                            setModalType("New");
                          }}
                            icon={<Icon><FontAwesomeIcon icon={"plus"} /></Icon>}
                            >
                            Yeni Km Bilgisi
                        </IconButton>
                        </Stack.Item>
                        <ExportData data = {data} tableHead = {tableHeads} tableName={"KM_Verileri" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                      </Stack> 
                  </View>
                  }>
                      <CustomText fontWeight= {800} fontSize={14} >  {"Km Verileri" } </CustomText>

                  <ComponentListView current={current} showDetails={showDetailsOfChoosen} setchoosen_data={chooseANDEdit}  tableName = {"KM Verileri" + props.data["PLAKA"] +"_"+ new Date()} listHeads={listHeads} tableHead={tableHeads}  data={data}></ComponentListView>
              </Panel>
              </Col>
              <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                <Panel shaded style={{backgroundColor: 'white', height: 800 , minWidth: 200}} header  = {<CustomText fontWeight={800} > {"KM Detayları -- " + props.data["PLAKA"] } </CustomText>}>
                  <List>
                    {headers.map((item) =>
                      {
                      return(<List.Item key={item} index={item}>
                        <CustomListItem item={item} ></CustomListItem>
                      </List.Item>)
                      }
                      )}
                  </List>
                </Panel>
              </Col>
            </Row>
          </Grid>          
      </View>
    )
}


const NewKMDataPanel = (props) => {

    const {messageToggle} = useMessage();

    const [activateAddButton,setActiveAddButton] = useState(true);
    // SENT VALUES
    const [KM,setKM] = useState(0);
    const [StartDATE,setStartDATE] = useState(new Date());
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const formRef= useRef();

    function reset(){
        setKM(0);
        setStartDATE(new Date());
        setNote("");
        setIS_ACTIVE(true);
    }

    function getEditData(){
        setKM(props.data["GÜNCEL KM"]);
        setStartDATE(new Date(props.data["OLUŞTURULMA TARİHİ"]));
        setNote(props.data["NOTLAR"]);
        setIS_ACTIVE(props.data["IS_ACTIVE"] == 1);
    }


    const {fetchIdToken} = useUserToken();
    async function sendData(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }

      
      var sendNew = formRef.current.check();
      const api = configdata.API_BASE + '/cardata';
      if(sendNew){
        axios.post(api,
          {
            "ENV" :configdata.APP_ENV,  
              "type": props.type == 'EDIT' ? "UPDATE_KM" : "NEW_KM_DOC",
              "CURRENT_KM" : KM,
              "CREATE_DATE": StartDATE,
              "NOTES" :note,
              "CAR_ID" : props.cardata["NO"],
              "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
              "ID" : props.data["NO"]
          },{headers}
        ).then((response) => {
          console.log(response);
  
          if(response.data["errorMessage"] != null){
              messageToggle("KM Bilgileri eklenemedi, Bağlantınızı kontrol edin", "error")
          }else{
             updateKM();
          }
         
        })
        .catch((error) => {
          console.log(error);
          messageToggle(" KM Bilgileri eklenemedi, lütfen girilen bilgileri kontrol edin", "error")
        });
      }
     
    
    }

    async function updateKM(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
     }
      const api = configdata.API_BASE + '/cardata';
      axios.post(api,
        {
          "ENV" :configdata.APP_ENV,  
            "type": "UPDATE_CAR_ONE_COLUMN",
            "Column" : "ARAC_KM",
            "Value": KM,
            "ID" : props.cardata["NO"]
        },{headers}
      ).then((response) => {
        console.log(response);
      
        if(response.data["errorMessage"] != null){
            messageToggle("KM Bilgileri eklenemedi, Bağlantınızı kontrol edin", "error")
        }else{
            messageToggle("KM Bilgileri Başarıyla Eklendi", "success")
            reset();
            props.reset();
        }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle(" KM Bilgileri eklenemedi, lütfen girilen bilgileri kontrol edin", "error")
      });
    }

    useEffect(() => {
  
     if(props.type == "EDIT"){getEditData()}
    },[])
    useEffect(() => {
     formRef.current.check();
    },[StartDATE])



    const { StringType} = Schema.Types;


    const model =  Schema.Model({
        KM: StringType()
        .isRequired("KM değeri girilmeli")
        .addRule((value,data) => {
          var tempArr =[] ;
          var valueNew = value.replace(" KM","").replace(".","")
          
          props.Date_array.forEach((element) => tempArr.push(new Date(element)));
          tempArr.push(StartDATE);
          tempArr = tempArr.sort(function(a, b){return b - a});
          var dateIndex = tempArr.indexOf(StartDATE);
          if(tempArr.length == 2){
            if( dateIndex == tempArr.length - 1 ){
              return valueNew < props.KM_array[props.KM_array.length - 1]
            }
            else if( dateIndex == 0 ){
              return valueNew > props.KM_array[0]
            }
          }
          if(tempArr.length > 2){
            if( dateIndex == tempArr.length - 1 ){
             
              return valueNew < props.KM_array[props.KM_array.length - 1]
            }
            else if( dateIndex == 0 ){
              return valueNew > props.KM_array[0]
            }
            else{
              return valueNew < props.KM_array[dateIndex - 1] && valueNew > props.KM_array[dateIndex]
            }
          }
            return true
          
          
        },"Girilen KM verisi tutarsız" ).addRule((value,data) => {
          var valueNew = value.replace(" KM","").replace(".","")
          if(props.cardata["ARAÇ ALIM KM"] >= valueNew){
            return false
          }
          return true
        },"Girilen değer araç alım km sinden fazla veya eşit olmalıdır")
    })


    const numberMaskKM = createNumberMask({
      prefix: '',
      thousandsSeparatorSymbol : '.',
      suffix: ' KM' // This will put the dollar sign at the end, with a space.
    })


    
    return(

          <View style= {{flexDirection: 'column',width: '100%'}}>
                <Form ref= {formRef} model={model} >
                    <Form.Group>
                        <Form.ControlLabel style={{flex: 1}}> <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}}> Kilometre </CustomText> </Form.ControlLabel>
                        <Form.Control accepter={MaskedInput} guide={false} mask={numberMaskKM} value = {KM} style={styles.standard}  onChange={ (value) => {setKM(value.replace(" KM","").replace(".",""));}} name= {"KM"}   /> 
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel> <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}}> Km Değişiklik Tarihi </CustomText>  </Form.ControlLabel>
                        <DatePicker value={StartDATE} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                              {setStartDATE(value);}}
                              oneTap format="dd-MM-yyyy"
                              locale={locale}
                          ></DatePicker>
                      </Form.Group>
                        {
                          props.type == "EDIT" &&
                          <Form.Group>
                            <Form.ControlLabel> <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}}> Durum </CustomText>  </Form.ControlLabel>
                            <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                          </Form.Group>  
                        }
                        <Form.Group>
                            <Form.ControlLabel>  <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}}> Notlar </CustomText>  </Form.ControlLabel>
                            <Input value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"}  rows={5} /> 
                        </Form.Group>
                  </Form>
                      <View style={{height: 50}}></View>
                      <Button
                        disabled = {!activateAddButton}
                        style={styles.buttonStyle}
                        onClick={() => { sendData();}}
                      > <CustomText fontWeight = {800}  fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>
              </View>
    )
}



const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  form: {
    width: '100%',
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  standard: { flex: 1 , maxWidth: 300, minWidth: 150,width: '100%'},
  input: {
    flex: 1,
  },
});


export default KmScreen;