
import {useState} from 'react';


const formatter = () => {

    var formatterKm = new Intl.NumberFormat('tr-TR', {
        style: 'unit',
        unit: 'kilometer',
      });
  
      var formatterStandard = new Intl.NumberFormat('tr-TR', {
        style: 'unit',
        unit: 'kilometer',
      });

      return {
        formatterKm,
        formatterStandard
      }
}

export default formatter;