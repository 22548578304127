import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { Component,useState ,useEffect } from 'react';
import {View, Text , Button,Image} from 'react-native';
import Amplify, { Auth } from 'aws-amplify';
import AuthScreen from '../Screens/AuthScreens/AuthScreen';
import HomeScreen from '../Screens/HomeScreen';
import { ActivityIndicator } from 'react-native-web';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import awsconfig from '../../aws-exports';
import configdata from '../config/config.json'
import BMLogo from '../../assets/BilenMobi.svg';
// Amplify.configure(awsconfig);

const Stack = createNativeStackNavigator(); 

const Navigation = () => {

  const [user, setUser] = useState(undefined);

  const checkUser = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const idToken = currentSession.getIdToken().getJwtToken();
      sessionStorage.setItem("idToken", idToken);
      const authUser = await Auth.currentAuthenticatedUser({bypassCache: false});
      setUser(authUser);
      const userJson = {
        username : authUser.username
      }
      const user = JSON.stringify(userJson)
      sessionStorage.setItem('user',user);
    } catch (e) {
      setUser(null);
      // sessionStorage.setItem('user', null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);


  
  // if (user === undefined) {
  //   return (
  //     <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
  //       <ActivityIndicator />
  //     </View>
  //   );
  // }

  

  
  

  return(

  
    <NavigationContainer>
        {sessionStorage.getItem('user') != null  ? 
      (
        <Stack.Navigator  screenOptions={{ headerShown: false  }}>

            <Stack.Screen name="Home" component={HomeScreen} options ={{
             // headerLeft: () => (<HeaderTitleCustom/>) ,
              headerShown : true,
              headerTitle: () => (<HeaderTitleCustom/>),
              headerStyle: {
                backgroundColor: 'white',
              },
            }}/>
            <Stack.Screen name="SignIn" component={AuthScreen} />

            
        </Stack.Navigator>
      ) 
      
      : 
      
      (
        <Stack.Navigator  screenOptions={{ headerShown: false  }}>

        {/* {user ? (
          <Stack.Screen name="Home" component={HomeScreen} options ={{
            // headerLeft: () => (<HeaderTitleCustom/>) ,
             headerShown : true,
             headerTitle: () => (<HeaderTitleCustom/>),
             headerStyle: {
               backgroundColor: '#fff',
             },
           }}/>
        ) : (
          
            <Stack.Screen name="SignIn" component={AuthScreen} />
          
        )} */}
            
            <Stack.Screen name="SignIn" component={AuthScreen} />
            <Stack.Screen name="Home" component={HomeScreen} options ={{
             // headerLeft: () => (<HeaderTitleCustom/>) ,
              headerShown : true,
              height: 50,
              headerTitle: () => (<HeaderTitleCustom/>),
              // headerStyle: {
              //   backgroundColor: 'white',
              // },
            }}/>
            
        </Stack.Navigator>
      )}
    
    </NavigationContainer>
   

  );
}

//TODO refresh button
const HeaderTitleCustom = () => {

    return(
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
         {/* <MaterialCommunityIcons name= 'alpha-b' 
         size={50} 
         color= 'black' 
         style={{marginRight: '1rem'}} />
          <Text style = {{color: "black", fontSize: 18, textAlign: 'center'}}>
          { "Bilen Mobi "+ configdata.APP_ENV}
          </Text> */}
          <Image source={BMLogo} style = {{width: 200,height: 40}} resizeMode = 'contain' />
         
      </View>

    );
 
} 

export default Navigation;