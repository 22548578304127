
import { useState } from "react";


const useUtils = () => {

    function ExcelDateToJSDate(serial) {

        if(serial == null || serial == undefined || serial == ""){
          return null;
        }
        var utc_days  = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;                                        
        var date_info = new Date(utc_value * 1000);
     
        var fractional_day = serial - Math.floor(serial) + 0.0000001;
     
        var total_seconds = Math.floor(86400 * fractional_day);
     
        var seconds = total_seconds % 60;
     
        total_seconds -= seconds;
     
        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;
     
          // console.log("date_info");
          // console.log(new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds));
        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
     }

     return  {
         ExcelDateToJSDate,
     };
    
};

const useValidationRules = () => {



    const validateStandard = (input,list) => {
      let isValid = true;
      let temp =  input
      let label = input;
      let id = 0;
      if (temp === undefined || temp === "") {
        isValid = false;
      }
      else {

        if (list.find((element) => element.label === temp) === undefined) {
          isValid = false;
        }
        else {
          label = list.find((element) => element.label === temp).label;
          id = list.find((element) => element.label === temp).value;
        }
      }

      return { isValid, label, id };
    }

    const validateStandardWithID = (input,list) => {
      let isValid = true;
      let temp =  input
      let label = "";
      let id = input;
      if (temp === undefined || temp === "") {
        isValid = false;
      }
      else {

        if (list.find((element) => element.value === temp) === undefined) {
          isValid = false;
        }
        else {
          label = list.find((element) => element.value === temp).label;
          id = list.find((element) => element.value === temp).value;
        }
      }

      return { isValid, label, id };
    }

    const validateStandardBMPID = (input,list) => {
      let isValid = true;
      let temp =  input
      let label = "";
      let id = input;
      if (temp === undefined || temp === "") {
        isValid = false;
      }
      else {

        if (list.find((element) => element.id === temp) === undefined) {
          isValid = false;
        }
        else {
          label = list.find((element) => element.id === temp).label;
          id = list.find((element) => element.id === temp).id;
        }
      }

      return { isValid, label, id };
    }

    const validateStandardBMP = (input,list) => {
      let isValid = true;
      let temp =  input + "";
      let label = input + "";
      let id = 0;
      if (temp === undefined || temp === "") {
        isValid = false;
      }
      else {

        if (list.find((element) => element.label === temp) === undefined) {
          isValid = false;
        }
        else {
          label = list.find((element) => element.label === temp).label;
          id = list.find((element) => element.label === temp).id;
        }
      }

      return { isValid, label, id };
    }

    const ValidateBrandModelPaket = (rowObject) => {
      let isValid = true;
      let brand = removeBlankSpaceEnd(rowObject['MARKA']);
      let model = removeBlankSpaceEnd(rowObject['MODEL']);
      let paket = removeBlankSpaceEnd(rowObject['PAKET']);
      let brandId = 0;
      let modelId = 0;
      let paketId = 0;

      if(brand == null && model == null && paket == null){
        isValid = true;
      }
      else{
        if (brand === undefined || brand === "" || model === undefined || model === "" || paket === undefined || paket === "") {
            if(brand === undefined || brand === ""){
              isValid = false;
            }
            if(model === undefined || model === ""){
              isValid = false;
            }
            if(paket === undefined || paket === ""){
              isValid = false;
            }
    
            // fixNeededRows.push(rowObject);
            isValid = false;
          }
          else {
            if (optionsBMP.find((element) => element.label === brand) === undefined) {
              isValid = false;
            }else {
              let brandJson = optionsBMP.find((element) => element.label === brand)
              if(brandJson.children.find((element) => element.label === model) === undefined){
                isValid = false;
              }else{
                let modelJson = brandJson.children.find((element) => element.label === model)
                if(modelJson.children.find((element) => element.label === paket) === undefined){
                isValid = false;
                  
                }else{
                  brandId = brandJson.id;
                  modelId = modelJson.id;
                  paketId = modelJson.children.find((element) => element.label === paket).id;
                }
              }
              // console.log(brandJson);
            }
          }
      }


      
      return isValid;
    }

    const validatePlateNumber = (plaka) => {
      let isValid = true;
      let plateNumber = plaka;
      if(plateNumber == null || plateNumber == undefined || plateNumber == ""){
        isValid = false;
      }else{
        if (!isFixable(plateNumber)) {
          isValid = false;
        }
        
        else {
          plateNumber = fixPlaka(plateNumber);
        }
      }
      return {isValid,plateNumber};
    }

    
    const fixPlaka = (plaka) => {
      var plakatemp = plaka;
      var letter_count = 0;
  
      for(var i = 0 ; i < plakatemp.length ; i++){
          if(isNaN(plakatemp.charAt(i)) && plakatemp.charAt(i) !== " "){
              letter_count++
          }
      }
      
      plakatemp = plakatemp.toUpperCase();
      
      if(plakatemp.length > 2 && plakatemp.charAt(2) !== " "){
          plakatemp = insertAt(plakatemp, 2, " ");
      }
  
      if(letter_count === 1){
          if(plakatemp.length > 4 && plakatemp.charAt(4) !== " " && isNaN(plakatemp.charAt(3))){
              plakatemp = insertAt(plakatemp, 4, " ");
          }
      }
      else if(letter_count === 2){
          if(plakatemp.length > 5 && plakatemp.charAt(5) !== " " && isNaN(plakatemp.charAt(4))){
              plakatemp = insertAt(plakatemp, 5, " ");
          }
      }
      else{
          if(plakatemp.length > 6 && plakatemp.charAt(6) !== " "){
              plakatemp = insertAt(plakatemp, 6, " ");
          }
      }
      
      return plakatemp;
    }
    const isFixable = (plaka) => {
      const maxPlakaLength = 10;

    // If the input is too long, it's not fixable
      if (plaka.length > maxPlakaLength) {
        return false;
      }

        // Define the templates as regular expressions
        const templates = [
          /^[0-9]{2}-?\s*[A-Z]{3}\s*-?[0-9]{3}$/i,
          /^[0-9]{2}-?\s*[A-Z]{3}\s*-?[0-9]{2}$/i,
          /^[0-9]{2}-?\s*[A-Z]{2}\s*-?[0-9]{3}$/i,
          /^[0-9]{2}-?\s*[A-Z]{2}\s*-?[0-9]{4}$/i,
          /^[0-9]{2}-?\s*[A-Z]\s*-?[0-9]{4}$/i,
          /^[0-9]{2}\s*[A-Z]{3}\s*[0-9]{2}$/i,
          /^[0-9]{2}\s*[A-Z]{2}\s*[0-9]{3}$/i,
          /^[0-9]{2}\s*[A-Z]\s*[0-9]{4}$/i,
        ];
      
        // Convert the input to uppercase to match the templates

      
        // Check if the input matches any of the templates
        for (let i = 0; i < templates.length; i++) {
          if (templates[i].test(plaka)) {
            return true;
          }
        }
        if(plaka == null){
            return true
        }
  
    // If the input doesn't match any template, it's not fixable
    return false;
    };
    function removeBlankSpaceEnd(input){
      if(input === undefined || input === null || input === ""){
        return input;
      }
      else{
        var result = input;
        while(result.charAt != undefined && result.charAt(result.length-1) == " " ){
          result = result.substring(0,result.length-1);
        }
        return result;
      }
    }

    const insertAt = (original, index, string) => {
        return original.slice(0, index) + string + original.slice(index);
    }

    const editFixNeed = (rowObject,text) => {
      if(rowObject['Fix Needed'] === undefined){
        rowObject['Fix Needed'] = text;
      }else{
        rowObject['Fix Needed'] += "," +text;
      }
      
    }

  


    return {
        validateStandard,
        validatePlateNumber,
        ValidateBrandModelPaket,
        validateStandardBMP,
        validateStandardWithID,
        validateStandardBMPID,
        fixPlaka
    };

    //#endregion


};
export  {useUtils, useValidationRules};