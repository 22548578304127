import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { StyleSheet, Dimensions, View ,Image,FlatList,Text} from 'react-native';
import FileUploader from './FileUploader';
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { Modal } from 'react-native-paper';
import axios from 'axios';
import { Message, Panel, Stack, useToaster } from 'rsuite';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../CustomText';
import MiniPDF from '../MiniPdf';
// Amplify.configure(awsconfig);
Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});
const FileLoader = (props,ref) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
               {input}
            </Message>, { placement: 'topEnd' })
    }

    const fileTypes = ["JPG", "PNG", "PDF","DOCX","JPEG"];
    const [uploadProcessText, setUploadProcessText] =  useState("");
    const [toggleUploadProcess, setToggleUploadProcess] = useState(false);
    const [refresh,setRefresh] = useState(true);
    const [label,setLabel] = useState("DÖKÜMANI YÜKLE");
    const [file,setFile] = useState(null);
    // const [fileName,setFileName] =useState("");

    const DragDrop = () => {
        const handleChange = (file) => {
            // setFileName(file.name);
            //loadFile(file);
            setFile(file);
            setLabel("Döküman Yüklendi");
            console.log("file changed");

        };
        return (
            <View style={{marginVertical: 20, alignItems: 'flex-start', marginHorizontal: 3}}>
                <FileUploader handleChange={handleChange} name="file" types={fileTypes} style={{width: '100%'}} />
            </View>
        
        );
    }

    useImperativeHandle(ref, ()=>({
        load : (ObjectID) => {
            if(file != null){
                loadFile(file,ObjectID);
            } 
            else{
                console.log("file is null");
                props.reset();
            }
        }
    }));




    const {fetchIdToken} = useUserToken();
    async function sendData (s3Key,fileName,ObjectID){
        const token = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        const headers = {
            "Authorization" : `Bearer ${token}`,
        }
        const body = {
            "ENV" :configdata.APP_ENV, 
            "type" : "FILE_SET",
            "category" : props.fileCategory,
            "FILE_NAME" : fileName,
            "S3_KEY" : s3Key,
            "CAR_ID": props.carID,
            "OBJECT_ID" :ObjectID
        }

        axios.post(api,body,{headers}
            ).then((response) => {
                console.log(response)
                if(response.data["errorMessage"] != null ){
                    messageToggle(fileName + " Dosya yüklenemedi","error")
                }else{
                    messageToggle(fileName +  " başarıyla yüklendi","success")
                    if(props.reset != null){
                        props.reset();
                    }
                }
              
            })
            .catch((error) => {
                console.log(error)
                messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin RDS","error")
            });
        
       
    }


    const RenderFilesAsLabels = () => {
        return (
            <Panel  bordered style={{margin: 2,marginVertical: 10, padding: 2, alignItems: 'center' , justifyContent:'center' }}>
                {/* <CustomText>
                    {file.name}
                </CustomText> */}
                <MiniPDF file={file} ></MiniPDF>
            </Panel>
        );
    }


    async function loadFile (file,ObjectID){

        const name =  "" + file.name.toString();
        console.log(name);
        var content_type = "application/pdf";
        var testCon = "";
        if(configdata.APP_ENV == "DEV"){
            testCon = "TESTDB_"
        }
        let target_location = ""
        if(name.includes(".png")){
            target_location = testCon + "CAR_" + props.carID + "/PNGs/" + props.fileCategory + "/" + file.name
            content_type = "image/png";
        }
        if(name.includes(".jpg")){
            target_location =testCon + "CAR_" +  props.carID + "/JPEGs/" + props.fileCategory + "/" + file.name
            content_type = "image/jpeg";
        }
        if(name.includes(".jpeg")){
            target_location =testCon + "CAR_" +  props.carID + "/JPEGs/" + props.fileCategory + "/" + file.name
            content_type = "image/jpeg";
        }
        else if(name.includes(".pdf")){
            target_location =testCon + "CAR_" + props.carID +  "/PDFs/" + props.fileCategory + "/" + file.name
            content_type = "application/pdf";
        }
        else if(name.includes(".PDF")){
            target_location =testCon + "CAR_" + props.carID +  "/PDFs/" + props.fileCategory + "/" + file.name
            content_type = "application/pdf";
        }
        else if(name.includes(".docx")){
            target_location =testCon + "CAR_" + props.carID +  "/DOCs/" + props.fileCategory + "/" + file.name
            content_type = "application/msword";
        }
        else if(name.includes(".xlsx")){
            target_location =testCon + "CAR_" + props.carID +  "/DOCs/" + props.fileCategory + "/" + file.name
            content_type = "application/excel";
        }
        else if(name.includes(".csv")){
            target_location = testCon + "CAR_" + props.carID + "/DOCs/" + props.fileCategory + "/" + file.name
            content_type = "application/excel";
        }

        let newFileName = props.fileCategory + "_" + ObjectID + "." + file.name.split('.')[1];
        target_location =  target_location.replace(file.name,newFileName);
        target_location += "." + file.name.split('.').pop();
         console.log(target_location);
         console.log(content_type);
        try {

            await Storage.put(target_location, file, { 
                contentType: content_type, // contentType is optional
                progressCallback(progress) {
                    setToggleUploadProcess(true);
                    let value =((progress.loaded/progress.total) * 100) | 0
                    console.log(`Uploaded:` + value.toString());
                    setUploadProcessText(value.toString());
                    if(progress.loaded/progress.total == 1){
                        setToggleUploadProcess(false);
                    }
              },
            }).then((response) => {
                console.log(response);
                sendData(response.key,file.name,ObjectID);
            });
            // getList();
            setRefresh(!refresh);
          } catch (error) {
            messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin S3","error")
            console.log("Error uploading file: ", error);
            setUploadProcessText("Upload Not Completed : " + error);
          }
    }
    return (
      <View style={{flex: 1, maxWidth: 250}}>
        <DragDrop></DragDrop>
        {
            file != null && file != undefined && <RenderFilesAsLabels></RenderFilesAsLabels>
        }
      </View>
    )
  
}
const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 7,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.10,
        shadowRadius: 4,
        elevation: 5
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height:'100%',
        margin: 25,
    },

    listItem:{
        backgroundColor: 'white',
        borderRadius: 7,
    },
    pdf: {
        flex:1,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    }
});

export default  forwardRef(FileLoader);