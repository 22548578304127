import { Text, View , StyleSheet,ActivityIndicator, Image, TouchableOpacity,TextInput,ScrollView,Modal } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { useToaster,Schema,Message, Button, Form ,SelectPicker, Input,MaskedInput ,InputNumber, RadioGroup, Radio, InputGroup, Checkbox, Panel, TagInput  } from 'rsuite';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../../aws-exports';
import configdata from '../../../config/config.json';
import CustomText from '../../../Components/CustomText';


const NewCustomerScreen = (props) => {

    const [attributesC,setAttributes] = useState()

    async function get_curent_user (){
        const authUser = await Auth.currentAuthenticatedUser({bypassCache: false});
        const { attributes } =  authUser;
        setAttributes(attributes);
        console.log(attributes["custom:rds_user_id"])
      }
    
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }

    const [type,setType] = useState("firm")
    const [nationType,setNationType] = useState("TÜRKİYE")
    const [notes,setNotes] = useState("")

    // bireysel
    const[customerName,setCustomerName] = useState("");
    const[customerSurname,setCustomerSurname] = useState("");
    const[customerAddress,setCustomerAddress] = useState("");
    const[tckn,setTCKN] = useState("");
    const[customerEmail,setCustomerEmail] = useState("");
    const[customerComNo,setCustomerComNo] = useState("+90");

    // firm
    const[firmName,setFirmName] = useState("");
    const[firmAddress,setFirmAddress] = useState("");
    const[VKN,setVKN] = useState("");
    const[VKNCity,setVKNCity] = useState("");
    const[VKNBureau,setVKNBureau] = useState("");
    const[firmEmail,setFirmEmail] = useState("");
    const[firmKep,setFirmKep] = useState("");
    const[firmComNo,setFirmComNo] = useState("+90");
    const [isTedarikci,setIsTedarikci] = useState(false);
    const [clientTags,setClientTags] = useState([]);
    
    //ui data
    const [cities,setCities] = useState([])
    const [bureaus,setBureaus] = useState([])


    const [isKamu,setIsKamu] = useState(false);

    function reset(){
        setCustomerName("");
        setCustomerSurname("");
        setCustomerAddress("");
        setTCKN("");
        setCustomerEmail("");
        setCustomerComNo("+90");
        setFirmName("");
        setFirmAddress("");
        setVKN("");
        setVKNCity("");
        setVKNBureau("");
        setFirmEmail("");
        setFirmKep("");
        setFirmComNo("+90");
        setNotes("");
        setIsTedarikci(false);
    }

    function getData(){
        console.log(props.data);
    
        setCustomerName(props.data["PERSON_NAME"] || "");
        setCustomerSurname(props.data["PERSON_SURNAME"] || "");
        setCustomerAddress(props.data["ADRES"] || "");
        setTCKN(props.data["TCKN"] || "");
        setCustomerEmail(props.data["MAIL ADRESİ"] || "");
        setCustomerComNo(props.data["İLETİŞİM NUMARASI"] || "");
    
        setFirmName(props.data["NAME"] || "");
        setFirmAddress(props.data["ADRES"] || "");
        setVKN(props.data["VKN"] || "");
        setVKNCity(props.data["VKN_CITY"] || "");
        setVKNBureau(props.data["CLIENT_TAX_DEPARTMENT"] || "");
        setFirmEmail(props.data["MAIL ADRESİ"] || "");
        setFirmKep(props.data["KEP ADRESİ"] || "");
        setFirmComNo(props.data["İLETİŞİM NUMARASI"] || "");
        setNotes(props.data["NOTES"] || "");
        setIsTedarikci(props.data["IS_TEDARIKCI"] == 1 ? true : false);
        setClientTags(props.data["TAGS"] || []);
        setType(props.type == "BİREYSEL" ? "person" : "firm");

        setClientTags(parseTagsToArr(props.data["TAGS"]) || []);
    }
    
    function parseTagsToArr(tags){
        var arr = [];
        tags.split(",").forEach((element) => {
            arr.push(element)
        })
        return arr;
    }




    useEffect(()=>{
        get_curent_user();
        getCitiesDropdown();
        if(props.data != undefined && props.data != null){
            getData();
        }

    },[])


    useEffect(()=>{
        console.log(firmComNo);
    },[firmComNo])
    
    function getCitiesDropdown(){
        const api = configdata.API_BASE + '/uidropdownlist'
        const header = {"ENV" : configdata.APP_ENV,"type" : "GET_GIB_CITIES"}
        axios.post(api,header).then((response) => {
            var citiesArr = [];
            var data = {}
            var citiesCombobox = []
            console.log(response.data);
            response.data.forEach((element) => {
                if(citiesArr.includes(element["İL"])){
                    data[element["İL"]].children.push({"label" : element["VERGİ_DAİRESİ"] , "value" : element["ID"]})
                }else{
                    citiesArr.push(element["İL"])
                    data[element["İL"]] = {"children" : []}
                    citiesCombobox.push({"label" : element["İL"] , "value" : element["İL"]})
                    data[element["İL"]].children.push({"label" : element["VERGİ_DAİRESİ"] , "value" : element["ID"]})
                }
            })

            console.log(citiesCombobox);
            console.log(data);
            setCities(citiesCombobox);
            setBureaus(data)
        })
    }

    const item_style = {
        header: {fontWeight: 'bold'},
        standard: { flex: 1 , maxWidth: 600, minWidth: 350, width: '100%'},
        narrow: { width : 150 },
        viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10},
        buttonStyle:{
            textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
          },
        }

   function sendData(){
        const api = configdata.API_BASE + '/customers';
        const header = type == 'person' ? {
            "ENV" : configdata.APP_ENV,
            "type" : props.update != undefined ? "UPDATE_CLIENT_PERSON" : "NEW_CLIENT_PERSON",
            "PERSON_NAME": customerName,
            "PERSON_SURNAME": customerSurname,
            "PERSON_TCKN": tckn,
            "CLIENT_ADDRESS" : customerAddress,
            "CLIENT_EMAIL_ADDRESS" : customerEmail,
            "NOTES": notes,
            "USER_ID" : attributesC["custom:rds_user_id"],
            "PHONE_NUMBER" : customerComNo,
            "DESCRIPTION" : "",
            "NATION" : nationType,
            "TAGS" : clientTags,
            "ID": props.data != undefined ? props.data["NO"] : null
        }
        :
        {
            "ENV" : configdata.APP_ENV,
            "type" : props.update != undefined ? "UPDATE_CLIENT_FIRM" : "NEW_CLIENT_FIRM",
            "NAME": firmName,
            "CLIENT_TAX_DEPARTMENT": VKNBureau,
            "CLIENT_TAX_NO": VKN,
            "FIRM_KEP_ADDRESS": firmKep,
            "NOTES" : notes,
            "CLIENT_ADDRESS" : firmAddress,
            "CLIENT_EMAIL_ADDRESS" : firmEmail,
            "USER_ID" : attributesC["custom:rds_user_id"],
            "PHONE_NUMBER" : firmComNo,
            "DESCRIPTION" : "",
            "NATION" : nationType,
            "IS_TEDARIKCI" : isTedarikci ? 1: 0,
            "IS_KAMU" : isKamu ? 1 : 0,
            "TAGS" : clientTags,
            "ID": props.data != undefined ? props.data["NO"] : null

        }

        console.log(header);

        axios.post(api,header).then((response) => {
            console.log(response.data);
            if(response.data != "SUCCESS"){
                messageToggle("Müşteri kaydı başarısız","error")
            }
            else{
                messageToggle("Müşteri başarıyla kaydedildi","success")
                
                if(props.onRow != undefined && props.onRow != null){
                    props.reset();
                    if(props.inline != undefined && props.inline != null){
                        props.update();
                    }
                }else{
                    reset();

                }
            }
        }).catch((error) => {
            console.log(error)
            messageToggle("Müşteri kaydı başarısız","error")
        })
   }

   const { StringType, NumberType,ArrayType ,ObjectType} = Schema.Types;

   const model =  Schema.Model({
       TCKN: StringType()
       .isRequired("TC Kimlik Numarası Boş Bırakılamaz")
       .addRule((value) => {
            return value.toString().length == 11
       },"TC Kimlik Numarası 11 Hane Olmalıdır"),
        CustomerName : StringType().isRequired("Müşteri Adı Boş Olamaz"),
        CustomerSurname : StringType().isRequired("Müşteri Soyadı Boş Olamaz") ,
      
        })
   const modelPOut =  Schema.Model({
        CustomerName : StringType().isRequired("Müşteri Adı Boş Olamaz"),
        CustomerSurname : StringType().isRequired("Müşteri Soyadı Boş Olamaz") ,
      
        })
   const modelFirm =  Schema.Model({
    FirmName : StringType().isRequired("Firma Adı Boş Olamaz") ,
    })
   const modelVKN =  Schema.Model({
    VKN: StringType().isRequired("VKN Boş Bırakılamaz").addRule(
        (value) => { return value.toString().length == 10},"VKN 10 Hane Olamalıdır"
        ),
    VKNBureau: NumberType().isRequired("Vergi Dairesi Seçilmeli"),
    VKNCity: StringType().isRequired("Vergi Dairesi İli Seçilmeli")
    })

    const modelVKN_NOTRequired =  Schema.Model({
        VKN: StringType().addRule(
            (value) => { return value.toString().length == 10},"VKN 10 Hane Olamalıdır"
            ),
        VKNBureau: NumberType(),
        VKNCity: StringType()
        })



   const form1Ref  = useRef();
   const form2Ref  = useRef();
   const form3Ref  = useRef();
   const form4Ref  = useRef();
   const form5Ref  = useRef();



   const CustomerAddButton = () => {
         return (
            <Button style={{backgroundColor: '#5E6EED', width :'100%', marginTop: 20}} onClick={() => {
                var cond = false;
                if(type == "person" && nationType == "TÜRKİYE") {
                    cond = form1Ref.current.check();
            
                }
                if(type == "person" && nationType == "TÜRKİYE DIŞI") {
                    cond = form2Ref.current.check();
            
                }
                if(type != "person" && nationType == "TÜRKİYE") {
                    var f4cond = form4Ref.current.check()
                    var f3cond = form3Ref.current.check()
                    cond = f4cond && f3cond;
            
                }
                if(type != "person" && nationType == "TÜRKİYE DIŞI") {
                    cond = form5Ref.current.check();
            
                }
                if(cond){
                    sendData();
                }
            }}><CustomText style={{color: '#F2F2F2'}}> {props.update != undefined ? "Güncelle" : "Kaydet"} </CustomText></Button>
         )
    }

    return (
    <View style={{flex: 1,padding: 20,alignItems : 'center', justifyContent :'flex-start'}}>

        <RadioGroup style={{backgroundColor: 'white',marginBottom: 20, width: '100%', maxWidth: 500}} appearance='picker' inline  name= "CustomerType" value={type} onChange = {(value) => {setType(value);
         reset();
        }}>
            <Radio style={{flex : 1}}  value = 'person'> <CustomText> Bireysel </CustomText> </Radio>
            <Radio style={{flex : 1}}  value = 'firm'> <CustomText> Kurumsal </CustomText> </Radio>
        </RadioGroup>
     {
        type == "person" ? 
           
             <View>
                <RadioGroup style={{backgroundColor:'white'}} appearance='picker'  inline name= "CustomerNation" value={nationType} onChange = {(value) => {setNationType(value);
                }}>
                    <Radio  value= 'TÜRKİYE'> <CustomText>Türkiye Vatandaşı </CustomText></Radio>
                    <Radio   value= 'TÜRKİYE DIŞI'> <CustomText>Türkiye Vatandaşı Değil </CustomText></Radio>
                </RadioGroup>
                <View style={{height: 20}}></View>

                {nationType == "TÜRKİYE" ? 
                <Panel shaded style={{backgroundColor: 'white'}}>
                    <Form ref={form1Ref} model={model}>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>Müşteri Adı *</CustomText></Form.ControlLabel>
                            <Form.Control name={"CustomerName"}  value= {customerName}  style={item_style.standard} onChange={(value) => {setCustomerName(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>Müşteri Soyadı *</CustomText></Form.ControlLabel>
                            <Form.Control name={"CustomerSurname"} value ={customerSurname} style={item_style.standard} onChange={(value) => {setCustomerSurname(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>TC Kimlik Numarası *</CustomText> </Form.ControlLabel>
                            <Form.Control accepter={MaskedInput} name={"TCKN"} guide={false} mask= {[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}  value ={tckn} style={item_style.standard} onChange={(value) => {setTCKN(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>Müşteri Adresi </CustomText></Form.ControlLabel>
                            <Input  value ={customerAddress} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setCustomerAddress(value)}} />
                        </Form.Group>
                    </Form>
                    <View style={{width: 20}}></View>
                    <Form>
                        <Form.Group>
                                <Form.ControlLabel> <CustomText> Mail Adresi </CustomText> </Form.ControlLabel>
                                <Form.Control value ={customerEmail} name={"email"} type= "email" style={item_style.standard} onChange={(value) => {setCustomerEmail(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel> <CustomText>İletişim Numarası </CustomText></Form.ControlLabel>
                                    <PhoneInput
                                        inputStyle={item_style.standard}
                                        country={'tr'}
                                        value={customerComNo}
                                        onChange={phone => setCustomerComNo(phone)}
                                    />
                                {/* <Form.Control name={"no"} type= "email" style={item_style.standard} onChange={(value) => {setFirmKep(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control> */}
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel> <CustomText>Notlar</CustomText> </Form.ControlLabel>
                                <Input value ={notes} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setNotes(value)}} />
                            </Form.Group>
                            <Form.Group>
                            <Form.ControlLabel> <CustomText>Etiketler</CustomText> </Form.ControlLabel>
                            <TagInput style={item_style.standard} value={clientTags} onChange={(value) => {setClientTags(value)}}></TagInput>
                        </Form.Group>
                    </Form>
                    <CustomerAddButton></CustomerAddButton>
                </Panel>
            :

                <Panel shaded style={{backgroundColor: 'white'}}>
                    <Form ref= {form2Ref} model={modelPOut}>
                    <Form.Group>
                            <Form.ControlLabel > <CustomText>Müşteri Adı *</CustomText></Form.ControlLabel>
                            <Form.Control name={"CustomerName"}  value= {customerName}  style={item_style.standard} onChange={(value) => {setCustomerName(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>Müşteri Soyadı *</CustomText></Form.ControlLabel>
                            <Form.Control name={"CustomerSurname"} value ={customerSurname} style={item_style.standard} onChange={(value) => {setCustomerSurname(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>Müşteri Adresi </CustomText></Form.ControlLabel>
                            <Input  value ={customerAddress} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setCustomerAddress(value)}} />
                        </Form.Group>
                    </Form>
                    <View style={{width: 20}}></View>
                    <Form>
                    <Form.Group>
                        <Form.ControlLabel> <CustomText> Mail Adresi </CustomText> </Form.ControlLabel>
                        <Form.Control value ={customerEmail} name={"email"} type= "email" style={item_style.standard} onChange={(value) => {setCustomerEmail(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel> <CustomText>İletişim Numarası </CustomText></Form.ControlLabel>
                            <PhoneInput
                                inputStyle={item_style.standard}
                                country={'tr'}
                                value={customerComNo}
                                onChange={phone => setCustomerComNo(phone)}
                            />
                        {/* <Form.Control name={"no"} type= "email" style={item_style.standard} onChange={(value) => {setFirmKep(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control> */}
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel> <CustomText>Notlar</CustomText> </Form.ControlLabel>
                        <Input value ={notes} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setNotes(value)}} />
                    </Form.Group>
                    <Form.Group>
                            <Form.ControlLabel> <CustomText>Etiketler</CustomText> </Form.ControlLabel>
                            <TagInput style={item_style.standard} value={clientTags} onChange={(value) => {setClientTags(value)}}></TagInput>
                        </Form.Group>
                    </Form>
                    <CustomerAddButton></CustomerAddButton>

                </Panel>
                }
             </View>
            
            :
            <View>
                <RadioGroup style={{backgroundColor:'white'}} inline appearance='picker' name= "CustomerNation" value={nationType} onChange = {(value) => {setNationType(value);
                }}>
                    <Radio  value= 'TÜRKİYE'> <CustomText>Türkiye</CustomText> </Radio>
                    <Radio   value= 'TÜRKİYE DIŞI'> <CustomText>Türkiye Dışı</CustomText> </Radio>
                </RadioGroup>
                <View style={{height: 20}}></View>
                {nationType == "TÜRKİYE" ?
                <Panel shaded style={{backgroundColor: 'white'}}>
                    <Form ref={form3Ref} model={modelFirm}>
                        <RadioGroup style={{backgroundColor:'white'}} inline appearance='picker' name= "Kamu" value={isKamu} onChange = {(value) => {setIsKamu(value);
                        }}>
                            <Radio  value= {false}> <CustomText>Özel</CustomText> </Radio>
                            <Radio   value= {true}> <CustomText>Kamu</CustomText> </Radio>
                        </RadioGroup>
                        <View style={{height: 20}}></View>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>Firma Adı *</CustomText> </Form.ControlLabel>
                            <Form.Control name={"FirmName"} value ={firmName}  style={item_style.standard} onChange={(value) => {setFirmName(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel><CustomText> Müşteri Adresi </CustomText></Form.ControlLabel>
                            <Input value ={firmAddress} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setFirmAddress(value)}} />
                        </Form.Group>
                       
                    </Form>
                    <View style={{width: 20}}></View>

                    <Form ref= {form4Ref} model={isKamu ? modelVKN_NOTRequired: modelVKN}>
                        <Form.Group>
                            <Form.ControlLabel ><CustomText> VKN *</CustomText> </Form.ControlLabel>
                            <Form.Control  accepter={MaskedInput} name={"VKN"} guide={false} mask= {[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,]}  value ={VKN} style={item_style.standard} onChange={(value) => {setVKN(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>Vergi Dairesi İl * </CustomText></Form.ControlLabel>
                            <Form.Control accepter={SelectPicker} name={"VKNCity"} value ={VKNCity} style={item_style.standard} data={cities} onChange={(value) => {setVKNCity(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel ><CustomText> Vergi Dairesi * </CustomText> </Form.ControlLabel>
                            <Form.Control accepter={SelectPicker} name={"VKNBureau"} value ={VKNBureau} style={item_style.standard} data={bureaus[VKNCity] == undefined ? [] :bureaus[VKNCity].children} onChange={(value) => {setVKNBureau(value)}}></Form.Control>
                        </Form.Group>
                    </Form>
                    <View style={{width: 20}}></View>
                    <Form>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>Mail Adresi </CustomText> </Form.ControlLabel>
                            <Form.Control value ={firmEmail} name={"firmemail"} type= "email" style={item_style.standard} onChange={(value) => {setFirmEmail(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel><CustomText> Kep Adresi </CustomText> </Form.ControlLabel>
                            <Form.Control value ={firmKep} name={"kep"} type= "email" style={item_style.standard} onChange={(value) => {setFirmKep(value)}} rule={StringType().isEmail("KEP adresi hatalı")}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText> Tedarikçi </CustomText> </Form.ControlLabel>
                            <Checkbox checked={isTedarikci} onChange={(value,checked) => {setIsTedarikci(checked)}}></Checkbox>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText> İletişim Numarası </CustomText> </Form.ControlLabel>
                            <PhoneInput
                            inputStyle={item_style.standard}
                                country={'tr'}
                                value={firmComNo}
                                onChange={phone => setFirmComNo(phone)}
                            />
                            {/* <Form.Control name={"no"} type= "email" style={item_style.standard} onChange={(value) => {setFirmKep(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control> */}
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText> Notlar </CustomText> </Form.ControlLabel>
                            <Input value ={notes} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setNotes(value)}} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>Etiketler</CustomText> </Form.ControlLabel>
                            <TagInput style={item_style.standard} value={clientTags} onChange={(value) => {setClientTags(value)}}></TagInput>
                        </Form.Group>
                    </Form>
                    <CustomerAddButton></CustomerAddButton>

                </Panel>
                :
                <Panel shaded style={{backgroundColor: 'white'}}>
                    <Form ref={form5Ref} model={modelFirm}>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>Firma Adı *</CustomText> </Form.ControlLabel>
                            <Form.Control name={"FirmName"} value ={firmName}  style={item_style.standard} onChange={(value) => {setFirmName(value)}}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel><CustomText> Müşteri Adresi </CustomText></Form.ControlLabel>
                            <Input value ={firmAddress} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setFirmAddress(value)}} />
                        </Form.Group>
                    </Form>
                    <View style={{width: 20}}></View>
                    <Form>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>Mail Adresi</CustomText> </Form.ControlLabel>
                            <Form.Control value ={firmEmail} name={"firmemail"} type= "email" style={item_style.standard} onChange={(value) => {setFirmEmail(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>İletişim Numarası </CustomText></Form.ControlLabel>
                            <PhoneInput
                            inputStyle={item_style.standard}
                                country={'tr'}
                                value={firmComNo}
                                onChange={phone => setFirmComNo(phone)}
                            />
                            {/* <Form.Control name={"no"} type= "email" style={item_style.standard} onChange={(value) => {setFirmKep(value)}} rule={StringType().isEmail("Email adresi hatalı")}></Form.Control> */}
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel > <CustomText>Tedarikçi</CustomText> </Form.ControlLabel>
                            <Checkbox checked={isTedarikci} onChange={(value,checked) => {setIsTedarikci(checked)}}></Checkbox>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>Notlar</CustomText> </Form.ControlLabel>
                            <Input value ={notes} style={item_style.standard} as={"textarea"} rows ={5} onChange={(value) => {setNotes(value)}} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel> <CustomText>Etiketler</CustomText> </Form.ControlLabel>
                            <TagInput style={item_style.standard} value={clientTags} onChange={(value) => {setClientTags(value)}}></TagInput>
                        </Form.Group>
                    </Form>
                    <CustomerAddButton></CustomerAddButton>

                </Panel>}
            </View>
       
            }

       
       
      </View>
    )
}

const styles = StyleSheet.create({
    cardView: {
        padding : 15, backgroundColor :'white', borderRadius: 12, justifyContent: 'center' , alignItems :'space-between', marginVertical : 12
             ,shadowColor: "#BDC9FF", shadowOffset: {width: 2,height: 5,},shadowOpacity: 0.25,shadowRadius: 12,elevation: 5
    }
})

export default NewCustomerScreen;