import { Text, StyleSheet, View, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Button } from 'rsuite';
import "rsuite/dist/rsuite.min.css";

// PROPS onPress  buttonText iconName color
const StandardButton  = (props) => {

    return (
    <View style={[{marginVertical : 5,flexDirection: 'row', alignItems: 'center', height: 30},props.style != null && props.style ]}>
        <View style= {{padding: 5, backgroundColor: props.color ,borderBottomStartRadius : 7, borderTopStartRadius: 7, height: '100%'}}>
            <MaterialCommunityIcons name={props.iconName} size={18} color="#F2F2F2" />
        </View>
                             
        <TouchableOpacity style = {[styles.buttonStandard, {backgroundColor: props.color }]}
        onPress= {props.onPress}>
        <Text style={{color: "white", textAlign: 'center'}}> {props.buttonText} </Text>
        </TouchableOpacity>
         
      </View>
    )
  
}

    {/* <Text style={{color: "white", textAlign: 'center'}}> {props.buttonText} </Text> */}
const styles = StyleSheet.create({

    buttonStandard:{ height: '100%', backgroundColor: "#00589B" , padding: 5 , borderTopEndRadius: 7,  borderBottomEndRadius: 7, width: 120,alignItems: 'center', justifyContent: 'center',marginHorizontal: 1},
})

export default StandardButton;

