  
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { View } from 'react-native';
import { MultiCascader ,Form} from 'rsuite'; 

const FreeTextFilter = forwardRef((props,ref) => {

    const [data,setData] =  useState(props.data);
    const [indicator,setIndicator] = useState(",");
    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      filter: () => {
        if(value != null || value != ""){
          props.refresh();
         
          return filter_data_multiple();
        }
      }, 
      clear: () => {
        setValue("");
      },
      types: () => {
      }
      
    }))

    const[value,setValue] = useState("");

    useEffect(() => {

      if((value == "" || value == null) && props.isFilterApplied){
          props.reset();
          props.filter();
      }
      if((value != null && value != "" )){
          // filter_data_multiple();
          props.filter();
      }
    },[value])


    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => !checkContains(item,e)))
    }

    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }

    function mergeWithNoDuplicates(temps) {
      let data = [];
        if(temps.length == 1){
          return temps[0];
        }else{
            temps.forEach(element => {
              console.log(element);
              element.forEach(indexData => {
                if(!data.includes(indexData))
                {
                  data.push(indexData);
                }
              })
            });
            return data;
        }

    }
    

    const filter_data_multiple = () =>{


        var inputs = [];
        var column = props.tableHeadName;
      
        if(value != "" && value != null){
          inputs = value.split(",");
        }
        let temps = []
        if(column != null && column != undefined){
          for(let c = 0 ; c< inputs.length; c++){
            let temp = [];
            for(let i = 0; i< props.data.length ; i++){
              if(inputs[c] != null && inputs[c] != ""){
                let tempstr = String(props.data[i][column])
                tempstr = tempstr.toLowerCase();
                var input = inputs[c].toLowerCase();
                if(tempstr.includes(input)){
                    // console.log("match to filter " + i)
                    temp.push(props.data[i]);
                }
                
              }
            }
            temps.push(temp);
          }

            // let first =  temps.shift();
            // let final = intersect(first,temps);
         
            if(temps.length > 0){
             let final = mergeWithNoDuplicates(temps);
              console.log(final) ;
              return final;
            }
            

        }
        return props.data
    }


    const item_style = {
      standard: { width : 200, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
  }

    return (
      <View style={{marginVertical: 10}}>
      <Form >
        <Form.Group>
            {/* <Form.ControlLabel> {props.tableHeadName} </Form.ControlLabel> */}
            <Form.Control value={value} style={props.item_style} onChange={ (value) => {setValue(value)}} name= {props.tableHeadName} /> 
        </Form.Group>
      </Form>
    </View>
    )


  })

  export default FreeTextFilter;