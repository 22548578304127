import React, { useEffect, useState } from 'react';
import { StyleSheet, Dimensions, View ,Image,FlatList,Text} from 'react-native';
import PDF from '../Components/PDF';
import { FileUploader } from "react-drag-drop-files";
import StandardButton from '../Components/StandardButton';
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Modal } from 'react-native-paper';
import { Message, useToaster } from 'rsuite';
// Amplify.configure(awsconfig);
Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});
const FolderManagementSystem = (props) => {

    const [PDFPopupActive, setPDFPopupActive] = useState(false);
    const [PNGPopupActive, setPNGPopupActive] = useState(false);
    const [url, setUrl] = useState("");
    const [pdfList, setList] = useState([])
    const [refresh,setRefresh] = useState(true);
    const [uploadProcessText, setUploadProcessText] =  useState("");
    const [toggleUploadProcess, setToggleUploadProcess] = useState(false);
    //https://bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev.s3.eu-central-1.amazonaws.com/private/BilisColorPalette.pdf
    
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
               {input}
            </Message>, { placement: 'topEnd' })
    }
    const fileTypes = ["JPG", "PNG", "PDF","DOCX","XLSX","CSV"];

    const DragDrop = () => {
        const [file, setFile] = useState(null);

        const handleChange = (file) => {
            loadFile(file);
        };
        return (
            <View style={{marginVertical: 20, alignItems: 'flex-start', marginHorizontal: 20}}>
                <FileUploader style= {{width: '90%'}} handleChange={handleChange} name="file" types={fileTypes} />
            </View>
        
        );
    }

    async function loadFile (file){

        const name =  "" + file.name.toString();

        var content_type = "application/pdf";
       
        let target_location = ""
        if(name.includes(".png")){
            target_location = "PNGs/" + file.name
            content_type = "image/png";
        }
        if(name.includes(".jpeg")){
            target_location = "JPEGs/" + file.name
            content_type = "image/png";
        }
        else if(name.includes(".pdf")){
            target_location = "PDFs/"+ file.name
            content_type = "application/pdf";
        }
        else if(name.includes(".docx")){
            target_location = "DOCs/"+ file.name
            content_type = "application/msword";
        }
        else if(name.includes(".xlsx")){
            target_location = "DOCs/"+ file.name
            content_type = "application/excel";
        }
        else if(name.includes(".csv")){
            target_location = "DOCs/"+ file.name
            content_type = "application/excel";
        }

        try {

            await Storage.put(target_location, file, { 
                contentType: content_type, // contentType is optional
                progressCallback(progress) {
                    setToggleUploadProcess(true);
                    let value =((progress.loaded/progress.total) * 100) | 0
                    console.log(`Uploaded:` + value.toString());
                    setUploadProcessText(value.toString());
                    
                    if(progress.loaded/progress.total == 1){
                        setToggleUploadProcess(false);
                    }
              },
            });
            getList();
            setRefresh(!refresh);
            messageToggle("Dosya başarıyla yüklendi","success");
          } catch (error) {
            console.log("Error uploading file: ", error);
            setUploadProcessText("Upload Not Completed : " + error);
            messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin","error");
          }
    }

    const getURL = async (input) => {
        await Storage.get(input,{ 
            expires: 120,
            level: 'public/PDFs' ,
          /*   identityId: '2cd3970d-b634-4bf1-b27a-86fc58ff3fa7' */
        }).then
        (data => {
            setUrl(data);
            console.log(data);
        
        }).catch(
            err => {
                console.log("storage error ")
            }
        );
    } 

    const getList = () =>{
        Storage.list('') // for listing ALL files without prefix, pass '' instead
        .then(
            result => {
                    console.log(result);
                    setList(result);
            }
        )
        .catch(err => console.log(err));
    }

    useEffect(()=> {
        //getURL();
        getList();
    },[])
 

    function togglePDFPopup(input){
        getURL(input);
        setPDFPopupActive(!PDFPopupActive);
       
    }
    function toggleIMGPopup(input){
        getURL(input);
        setPNGPopupActive(!PNGPopupActive);
       
    }

    // const PdfItem = ({item}) => {
    //     return(
    //         <View style={{flexDirection: 'row',alignItems: 'center',justifyContent:'flex-start', width: '100%'}}>
    //             <Text style={{width: '60%',marginLeft:20}}>{item}</Text>
    //             <View style = {{width: 10}}></View>
    //             <StandardButton iconName = {"file-pdf-box"} color = {'#34885B'} buttonText = {"View"} onPress = {() => togglePDFPopup(item)}> </StandardButton>
    //             <View style = {{width: 10}}></View>
    //             <StandardButton iconName = {"download"} color = {'#F2B138'} buttonText = {"Download"} onPress = {() => download(item) }></StandardButton>
    //             <View style = {{width: 10}}></View>

    //         </View>
    //     )
    // }
    const ViewDownloadItem = ({item}) => {
       
        return(
           
        <View style= {{padding: 10}}>
             {item.includes("DOCs/") ?
           ( <View style={[styles.listItem,{flexDirection: 'row',alignItems: 'center',justifyContent:'flex-start', width: '100%'} ]}>
                <Text style={{width: '60%',marginLeft:20}}>{item}</Text>
                <View style={{width: '40%',flexDirection: 'row',justifyContent: 'flex-end', marginHorizontal: 15}}>
                    <StandardButton iconName = {"download"} color = {'#F2B138'} buttonText = {"Download"} onPress = {() => download(item) }></StandardButton>
                </View>
                
            </View>)
            :
           ( <View style={[styles.listItem,{flexDirection: 'row',alignItems: 'center',justifyContent:'flex-start', width: '100%'} ]}>
                <Text style={{width: '60%',marginLeft:20}}>{item}</Text>
               
                <View style={{width: '40%',flexDirection: 'row',justifyContent: 'flex-end', marginHorizontal: 15}}>
                    {item.includes("PDFs/") && <StandardButton iconName = {"file-pdf-box"} color = {'#34885B'} buttonText = {"View"} onPress = {() => togglePDFPopup(item)}> </StandardButton>}
                    {item.includes("PNGs/") && <StandardButton iconName = {"file-png-box"} color = {'#4082B4'} buttonText = {"View"} onPress = {() => toggleIMGPopup(item)}> </StandardButton>}
                    {item.includes("JPEGs/") && <StandardButton iconName = {"file-jpg-box"} color = {'#4082B4'} buttonText = {"View"} onPress = {() => toggleIMGPopup(item)}> </StandardButton>}
                    <View style = {{width: 10}}></View>
                    <StandardButton iconName = {"download"} color = {'#F2B138'} buttonText = {"Download"} onPress = {() => download(item) }></StandardButton>
                </View>
                
            </View>)}
        </View>
           

            
        )
    }
    return (
            <View style= {{width: '100%'}}>
                <View style = {{ flexDirection:'row', width: '100%' , justifyContent:'flex-start' ,alignItems: 'center'}}>
                    <DragDrop></DragDrop>
                    {toggleUploadProcess && <Text style={{color : 'black'}} > {"UPLOAD PROGRESS :" + uploadProcessText + " %"}</Text>}
                </View>
            
                <FlatList
                    data = {pdfList}
                    renderItem = {({item}) => {return ( item.key != ''&& item.key != "DOCs/"&& item.key != "PDFs/" && item.key != "PNGs/"  && item.key != "JPEGs/" && <ViewDownloadItem item = {item.key} ></ViewDownloadItem>)}}
                    keyExtractor = {(data)=> data.key}
                    style = {{width: '100%'}}
                />
                {/* <StandardButton iconName = {"file-pdf-box"} color = {'#34885B'} buttonText = {"View PDF"} onPress = {() => togglePDFPopup()  }></StandardButton> */}
                {PDFPopupActive && 
                    <View>
                        {/* <PDF url = {"https://bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev.s3.eu-central-1.amazonaws.com/PDFTEST.pdf"} togglePDFPopup = {togglePDFPopup}/> */}
                        <PDF url = {url} togglePDFPopup = {togglePDFPopup} />
                    </View>    
                }
                
                <Modal
                    animationType= "fade"
                    transparent={true}
                    visible={PNGPopupActive}
                >
                    <View style={{ flex: 1,justifyContent: "center",alignItems: "center"}}>
                        <View style={styles.modalView}>
                            <Image
                                style={{width: 200, height: 200}}
                                source={url}
                            />
                            <StandardButton iconName = {"close-circle"} color = {'#F26938'} buttonText = {"CLOSE"} onPress = {toggleIMGPopup}></StandardButton>
                        </View>
                    </View>
                </Modal>
                  
                
            </View>
         
    )
 //width: '90%' ,alignItems:'center', justifyContent:'center',padding: 10,backgroundColor:'white',borderRadius: 7
}

function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }
  
  // usage
  async function download(item) {
    const result = await Storage.get(item, { download: true });
    downloadBlob(result.Body, item)
  }

const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 7,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.10,
        shadowRadius: 4,
        elevation: 5
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height:'100%',
        margin: 25,
    },

    listItem:{
        backgroundColor: 'white',
        borderRadius: 7,
    },
    pdf: {
        flex:1,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    }
});

export default FolderManagementSystem;
