import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import CustomText from './CustomText';

// Set the worker URL to the unpkg CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const MiniPDF = (props) => {
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const onChange = ({ window }) => {
      setDimensions(window);
    };

    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  return (
    <View style={styles.container}>
      {props.file ? (
        <>
            <CustomText>{props.file.name}</CustomText>
          <Document
            file={props.file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => console.log("Error loading document:", error)}
          >
            <Page
              pageNumber={pageNumber}
              scale={0.05}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
          <View style={styles.pagination}>
            <CustomText>{"Sayfa " + pageNumber + " / " +  numPages + "   "}</CustomText>
            <TouchableOpacity onPress={() => changePage(-1)} disabled={pageNumber <= 1}>
              <CustomText underline >Önceki</CustomText>
            </TouchableOpacity>
            <View style={{ width: 10 }} />
            <TouchableOpacity onPress={() => changePage(1)} disabled={pageNumber >= numPages}>
              <CustomText underline >Sonraki </CustomText>
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <Text>PDF Yükleniyor...</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
});

export default MiniPDF;



// <TouchableOpacity style = {{ margin: 8,backgroundColor: '#00589B', width: 30, height:100, padding: 5,borderRadius: 7}}
// onPress = {() =>previousPage()}  disabled= {pageNumber <= 1}  
// >
// <MaterialCommunityIcons name='arrow-left' color={'white'} size= {20}></MaterialCommunityIcons>
// </TouchableOpacity>
// <TouchableOpacity style = {{ margin: 8 ,backgroundColor: '#00589B', width: 30, height:100 , padding: 5, borderRadius:7}}
// onPress = {() =>nextPage() }  disabled= {pageNumber  >= numPages} 
// >
// <MaterialCommunityIcons name='arrow-right' color={'white'} size= {20}></MaterialCommunityIcons>
// </TouchableOpacity>
// </View>