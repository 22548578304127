import { Text, View , StyleSheet,ActivityIndicator, FlatList ,TouchableOpacity,TextInput,ScrollView } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { Table,Button,Input, Checkbox,IconButton,Form,Modal ,Pagination, Drawer, Panel, SelectPicker} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
const { Column, HeaderCell, Cell } = Table;
import NewUserPanel from '../../BackOffice/UserManagement/NewUserPanel';
import UserLabel from '../../BackOffice/UserManagement/UserCard';
import configdata from '../../config/config.json';
import usePagination from '../../hooks/usePagination';
import Amplify, { Auth } from 'aws-amplify';
import CustomText from '../../Components/CustomText';
import { useUserToken } from '../../hooks/useUserToken';



// Amplify.configure(awsconfig);



const UserManagementScreen = (props) => {

  const [activate,closeIndictor] = useState(true);   
  const [data, setData] = useState([])

  const [UIdata, setUIdata] = useState([]);
  const [columns, setColumns] = useState(undefined);
  const [currentUser , setAuthUser] = useState("");
  const [choosenRow,setChoosenRow] = useState([]);
  const [choosenID,setChoosenRowID] = useState(-1)
  const [refresh,setRefresh] = useState(false);
  const [newUserActive, setNewUserActive] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [choosenSettingsRow, setChoosenSettingsRow] = useState([]);
  const [authGroups, setAuthGroups] = useState([]);
  const {
    page, 
    limit, 
    handlePageChange, 
    handleLimitChange 
  } = usePagination();
    
  const getAuthGroups = async () => {
    const api = configdata.API_BASE + '/uidropdownlist';
    axios.post(api,{
      "ENV" :configdata.APP_ENV ,  
      "category": "AUTH_GROUPS",
      "hasParent": "NO",
      "type" : "GET",
    })
    .then((response) => {
      console.log(response);
      let temp = [];
      response.data.forEach(element => {
        temp.push({label: element.AUTH_GROUP_NAME, value: element.ID});
      });
      setAuthGroups(temp);
    })
    .catch((error) => {
      console.log("POST ERROR" + error);
      
    });
  }
 





  const filterData = () => {
    let filteredData = [...data];
     console.log(filteredData);
     console.log(searchKeywords);
    Object.keys(searchKeywords).forEach((column) => {
      const keyword = searchKeywords[column];
      if (keyword) {
        filteredData = filteredData.filter((row) =>
          row[column].toString().toLowerCase().includes(keyword.toLowerCase())
        );
      }
    });
    // console.log(filteredData);
    return filteredData.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;;
  });
  };

  
  const get_curent_user= async () => {
    const authUser = await Auth.currentAuthenticatedUser({bypassCache: false});
    sessionStorage.setItem('user', authUser);
    const username = authUser.username;
    // const {
    //   identity_id // Optional, the identity id specified by the provider
    // } = getFromProvider();
    setAuthUser(username);
    console.log(username);
    console.log(authUser)
    const { attributes } = authUser;
    // console.log(identity_id);
    console.log(attributes["custom:rds_user_id"])
  }


  useEffect(() => {
   get_curent_user();
   get_list();
   getAuthGroups();

   }, []);


  const {fetchIdToken} = useUserToken();
  async function get_list () {
    
    const api = configdata.API_BASE + '/userdata';
    let idToken = await fetchIdToken();
    const headers = {
      'Authorization': `Bearer ${idToken}`,
    }
    axios
        .post(api, {"type": "GET_USERS"},{headers})
        .then((response) => {
          //setUserData(JSON.parse(response.data));
          console.log(response);
          console.log(response.data);
          setData(response.data);
          let columns = Object.keys(response.data[0]);
          closeIndictor(false);
          setColumns(columns);
          setSearchKeywords(createKeywordsArray(columns));
          closeIndictor(false);
          
        })
        .catch((error) => {
          console.log(error);
          
        });

  }

 


 const EditableCell = ({ rowData,type, dataKey, onChange, ...props }) => {
   
   return (
     <Cell {...props} >
       { rowData.ID == choosenID ? (
         dataKey == "NAME" || dataKey == "SURNAME" ||  dataKey== "IS_ACTIVE" ? 
         (<input
           className="rs-input"
           defaultValue={rowData[dataKey]}
           onChange={event => {
             onChange && onChange(rowData.ID, dataKey, event.target.value,type);
           }}
         />)
           :
           (
             <span className="table-content-edit-span">{rowData[dataKey]}</span>
           )


       ) : (
         <span className="table-content-edit-span">{rowData[dataKey]}</span>
       )}
     </Cell>
   );
 };
 
 const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
   return (
     <Cell {...props} style={{ padding: '6px' }}>
       <IconButton
         size='xs'
         icon={<Icon> <FontAwesomeIcon icon={"edit"} ></FontAwesomeIcon> </Icon>}
         appearance='subtle'
         onClick={() => {
           // onClick(rowData["MARKA_ID"]);
             if(rowData.ID == choosenID){
                 const nextData = data;
                 nextData.find(item =>item["ID"] === choosenID)["NAME"] = choosenRow["NAME"];
                 nextData.find(item =>item["ID"] === choosenID)["SURNAME"] = choosenRow["SURNAME"];
                  nextData.find(item =>item["ID"] === choosenID)["IS_ACTIVE"] = choosenRow["IS_ACTIVE"];
                //  nextData.find(item =>item["ID"] === choosenID)["EMAIL"] = choosenRow["DURUM"];
                 setData(nextData);
                 updateComponent(choosenRow,"BRANDS");

                 setChoosenRowID(-1);
             }
             else{
               setChoosenRow(rowData);
               setChoosenRowID(rowData.ID);
             }
           }}
       >
         {rowData.ID == choosenID ? 'Kaydet' : 'Düzenle'}
       </IconButton>
     </Cell>
   );
 };
 


 const handleChange = (id, key, value) => {
   // const nextData = Object.assign([], data);
   const ch = choosenRow;
   ch[key] = value;
   setChoosenRow(ch);
 };


 //#region Search Input
 const [searchKeywords, setSearchKeywords] = useState({});

 function createKeywordsArray(columnsData) {
   let keywords = {};
   columnsData.forEach((item) => {
     keywords[item] = "";
   });
   console.log(keywords);
   return keywords;
 }


 // Handle search for a specific column
 const handleSearch = (column, value) => {
   setSearchKeywords((prevSearchKeywords) => ({
     ...prevSearchKeywords,
     [column]: value,
   }));
 };
 
 // Filter data based on searchKeywords


 const renderSearchInput = (column) => (

   // console.log(Object.keys(searchKeywords)),
     Object.keys(searchKeywords).includes(column) ? (
   <Input
     placeholder={"Ara"}
     value={searchKeywords[column]}
     onChange={(value) => handleSearch(column, value)}
     style={{ height: 30 , width: column == "ID" ? 30 : '80%'  }}
   />):
     (      
     <View style= {{height: 30 }}>

     </View>)
 );

 //#endregion
 
 function updateComponent(rowData,tableName){
   console.log("Update component");
   console.log(rowData);
   
   const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/userdata';
   const header = {
     
       "IS_ACTIVE": rowData["IS_ACTIVE"],
       "NAME": rowData["NAME"],
       "SURNAME": rowData["SURNAME"],
       "EMAIL": rowData["EMAIL"],
       "ID" : rowData["ID"],
       "IS_NEW" : "NO"
   }
   axios.post(api, header)
     .then((response) => {
       // console.log("post response " );
       console.log(response);
       // closeIndictor(false);
       get_list();

     })
     .catch((error) => {
       console.log("POST ERROR" + error);
       
     });

   
 }

 const columnsWInfo =[
    {
      title: "ID",
      dataKey: "ID",
      flexGrow: 1,
    },
    {
      title: "İsim",
      dataKey: "NAME",
      flexGrow: 4,
    },
    {
      title: "Soyisim",
      dataKey: "SURNAME",
      flexGrow: 4,
    },
    {
      title: "E-mail",
      dataKey: "EMAIL",
      flexGrow: 4,
    },
    {
      title: "Yetki Grubu",
      dataKey: "AUTH_GROUP_NAME",
      flexGrow: 4,
    },
    {
      title: "Durum",
      dataKey: "IS_ACTIVE",
      flexGrow: 4,
    },
    {
      title: "Güncelleme Tarihi",
      dataKey: "UPDATE_DATE",
      flexGrow: 4,
    },
    {
      title: "Oluşturma Tarihi",
      dataKey: "CREATE_DATE",
      flexGrow: 4,
    },
 ]




 const CustomCellDrawerOpener = ({rowData, dataKey, ...props}) => {
    return (
      <Cell {...props}>
        <IconButton
          size='xs'
          icon={<Icon> <FontAwesomeIcon icon={"gear"} ></FontAwesomeIcon> </Icon>}
          appearance='subtle'
          onClick={() => {
            setOpenSettings(true);
            setChoosenSettingsRow(rowData);
          }}
        >

        </IconButton>
      </Cell>
    );
   
  }
   
  function newUserPopupToggle() {
    setNewUserActive(!newUserActive);
    getAuthGroups();
    get_curent_user();
    get_list();
  }


   return (

    <View style={styles.container}>
    
      {activate ? (<ActivityIndicator
          animating = {activate}
          color = '#fff'
          size = "small"
          style = {{ flex: 1 ,height: '100%'}}/>):
          (
                <View style= {{ width: '100%', height: '100%'}}>
                  <View style ={{width: '100%', height: '100%', alignItems :'center'}}>
        <Drawer overflow={true} size={'lg'}  backdrop={'static'} keyboard={false} open={newUserActive} onClose={newUserPopupToggle}>
              <NewUserPanel data ={data} toggleNewUser = {newUserPopupToggle}></NewUserPanel>
        </Drawer>
        <Drawer open={openSettings} size={'sm'}  backdrop={'static'} onClose={()=>setOpenSettings(false)}>
          <Drawer.Header>
            <Drawer.Title>Kullanıcı Ayarları </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <SettingsDrawer authGroups={authGroups} rowData={choosenSettingsRow} onHandleClose={()=>setOpenSettings(false)}></SettingsDrawer>
          </Drawer.Body>
        </Drawer>

        <Text style= {{textAlign: 'center', fontSize: 18, marginVertical: 5, color: 'powderblue'}}> Kullanıcılar </Text>

        <View style={styles.TableBack}>
          <Table 
            data={filterData()} 
            autoHeight    
            resizable = {false} 
            headerHeight={80}
          >

            <Table.Column width={120}>
                <Table.HeaderCell>Düzenle</Table.HeaderCell>
                <ActionCell dataKey="ID" />
            </Table.Column>
            <Table.Column width={120}>
                <Table.HeaderCell> Kullanıcı Ayarları </Table.HeaderCell>
                <CustomCellDrawerOpener dataKey="ID" />
            </Table.Column>
            { (columns != undefined) && columnsWInfo.map((colName) => (
                <Table.Column flexGrow={colName.flexGrow} key={colName.dataKey} >
                  <Table.HeaderCell>
                    <View style={{alignItems: 'flex-start'}}>
                      {renderSearchInput(colName.dataKey)}
                      <View style={{height: 10}}></View>
                      <Text style={{color: 'powderblue',  textAlign: 'center'}}>{colName.title}</Text>
                    </View>
                    </Table.HeaderCell>
                  <EditableCell dataKey={colName.dataKey} onChange={handleChange} />
                </Table.Column>
              ))}
            </Table>
            <View style={{height: 10}}></View>
            <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size="xs"
                  layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                  total={data.length}
                  limitOptions={[10, 20,30,40,50,100]}
                  limit={limit}
                  activePage={page}
                  onChangePage={handlePageChange}
                  onChangeLimit={handleLimitChange}
                  locale={{
                    more: 'Daha',
                    prev: 'Önceki',
                    next: 'Sonraki',
                    first: 'İlk',
                    last: 'Son',
                    limit: '{0} / sayfa',
                    total: 'Toplam Satır: {0}',
                    skip: 'Git {0}'
                }}
                  />

            <View style = {{flexDirection: 'row', justifyContent: 'flex-end', width: '100%',marginVertical: 10}}>
            <IconButton onClick = {()=> setNewUserActive(true)}  icon={<Icon> <FontAwesomeIcon icon={"plus"} ></FontAwesomeIcon> </Icon>}>
                Yeni Kullanıcı
            </IconButton>
                {/* <IconButton icon={<Icon></Icon>} color= '#00589B' onClick = {()=> setNewUserActive(true)} > Yeni Kullanıcı </IconButton> */}
              </View>
        </View>
     </View>
              </View>
         ) }
    </View>

    
   );
}






const SettingsDrawer = (props) => {

  const [choosenAuthGroup, setChoosenAuthGroup] = useState(0);

  const [valuesMatch, setValuesMatch] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const {fetchIdToken} = useUserToken();


  useEffect(() => {
    if(password1 === password2){
      setValuesMatch(true);
    }
    else{
      setValuesMatch(false);
    }
  },[password1,password2]);

  useEffect(() => {
    console.log(choosenAuthGroup);
  },[choosenAuthGroup]);

  useEffect(() => {
    console.log(props.rowData);
  },[]);
  

  async function resetPassword(){
    const api = configdata.API_BASE + '/userdata';
    let idToken = await fetchIdToken();

    const headers = {
      'Authorization': `Bearer ${idToken}`,
    }

    const header = {
      "ID": props.rowData.ID,
      "NEW_PASSWORD": password1,
      "type": "SET_PASSWORD",
      "EMAIL" : props.rowData.EMAIL
    }

    console.log(header);

    axios.post(api,header,{headers})
    .then((response) => {
      console.log(response);
      if(response.data.statusCode == 200){
        console.log("Password Reset Successful");
        props.onHandleClose();
      }
    })
    .catch((error) => {
      console.log(error);
    });

  }

  async function changeAuthGroup(){
    const api = configdata.API_BASE + '/userdata';
    let idToken = await fetchIdToken();

    const headers = {
      'Authorization': `Bearer ${idToken}`,
    }

    const header = {
      "EMAIL": props.rowData.EMAIL,
      "NEW_AUTH_GROUP_ID": choosenAuthGroup,
      "type": "SET_NEW_AUTH_GROUP_TO_USER",
    }

    axios.post(api,header,{headers})
    .then((response) => {
      console.log(response);
      if(response.data.statusCode == 200){
        console.log("Auth Group Change Successful");
        props.onHandleClose();
      }
    })

  }



  return (
    
    <View style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Panel header="Şifre Değiştirme" collapsible bordered style={{width: '100%' , backgroundColor: 'white'}} defaultExpanded>
        <View style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <CustomText> {props.rowData.NAME} {props.rowData.SURNAME} </CustomText>
          <View style={{height: 10}}></View>
          <CustomText style={{fontSize: 12}}> {props.rowData.EMAIL} </CustomText>
          <View style={{height: 10}}></View>
          <Input placeholder="Yeni Şifre" type="password"  onChange={(value) => setPassword1(value)} value={password1} />
          <View style={{height: 10}}></View>
          <Input placeholder="Yeni Şifre Tekrar" type="password" onChange={(value) => setPassword2(value)}  value={password2} />
          <View style={{height: 3}}></View>
          {valuesMatch ? <CustomText style={{color: 'green', fontStyle : 'italic'}}> Şifreler Eşleşiyor </CustomText> : <CustomText style={{color: 'red', fontStyle : 'italic'}}> Şifreler Eşleşmiyor </CustomText>}
          <View style={{height: 10}}></View>
          <Button  onClick={resetPassword} disabled={!valuesMatch}><CustomText> Şifre Değiştir</CustomText> </Button>
        </View>
        </Panel>

        <View style={{height: 10}}></View>
        <Panel header="Yetki Grubu Değiştirme" collapsible bordered style={{width: '100%' , backgroundColor: 'white'}} defaultExpanded>
          <View style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <CustomText> {props.rowData.NAME} {props.rowData.SURNAME} </CustomText>
              <View style={{height: 10}}></View>
              <CustomText style={{fontSize: 12}}> {props.rowData.EMAIL} </CustomText>
              <View style={{height: 10}}></View>
              <CustomText style ={{fontSize: 12}}> {props.rowData.AUTH_GROUP_NAME} </CustomText>
              <View style={{height: 10}}></View>
              <SelectPicker data={props.authGroups} style={{width: '80%'}} onChange={(value) => setChoosenAuthGroup(value)} />
              <View style={{height: 10}}></View>
              <Button  onClick={changeAuthGroup}> <CustomText>Yetki Grubu Değiştir </CustomText></Button>
            </View>
        </Panel>
    </View>
  )
}



const styles = StyleSheet.create({
  container: { 
    flex: 1,
    flexGrow: 1,
    padding: 18,
    paddingTop: 10,
    backgroundColor: '#F2F2F2',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonStandard:{ backgroundColor: "#00589B" , padding: 5 , borderRadius: 7, width: 120,height: 30,alignItems: 'center', justifyContent: 'center',marginHorizontal: 5},
  insideContianer: {
    flex: 1,
    padding: 18,
    flexDirection: 'column'
  },
  HeadStyle: { 
    height: 50,
    alignContent: "center",
    backgroundColor: '#5FCF90',
    // borderWidth: "1px",
    //  borderColor: "black"
  },
  RowStyle: { 
    height: 40,
    alignContent: "center",
    backgroundColor: '#fff'
  },
  TableBack:{
    width : '95%' , 
    marginVertical: 5, 
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 12, 
    shadowColor: 'powderblue', 
    shadowOpacity: 0.2, 
    shadowRadius: 12,
    shadowOffset: {width: 0.2, height: 0.2}
  },
  TableText: { 
    textAlign: 'center',
    margin: 10,
    fontWeight: 100
  },
  inputText: { 
    borderWidth: 1, 
    borderColor: 'black', 
    height: 50, 
    borderRadius: 12, 
    padding: 3, 
    marginVertical: 5 ,
    outlineStyle : 'none' },

  row: { flexDirection: 'row', backgroundColor: '#fff', height: 40,
 // borderColor: "black", borderWidth : "1px" 
},
  btn: { width: "100%", },
  btnText: { textAlign: 'center', color: 'black' },

  userLabel:{
    backgroundColor: 'white',
    borderRadius: 12,
    height: "400",
    marginVertical: "3rem",
    flexDirection: "Column",
    // alignItems: 'center',
    // justifyContent: "center",
    padding: 20,
    marginBottom: -200,
  },
  modalView: {
    width: "90%", 
    height:"90%",
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  
});

export default UserManagementScreen;



//   var filtered_data_temp = [];
//   console.log(data[0]);
//   //filtered_data_temp.push(data[0]);
//   for(let i = 0; i < data.length; i++) {
//     let data_to_add = data[i];
//     console.log(data_to_add);
//     let temp_check = String(data_to_add[column]).toLocaleLowerCase();
//     let input_s = input.toLocaleLowerCase();
//     var input_array = input_s.split(",")
//     input_array.forEach(
//       element => {
//         if(temp_check.includes(element) && !filtered_data_temp.includes(data_to_add)){
//           console.log("--------------------------------------------------------")
//           filtered_data_temp.push(data_to_add);
//         }
//       }
//     )
    
      
//   }

//   console.log(filtered_data_temp);
//   setFiltered(filtered_data_temp);
//   setApplied(!isFiltered);

//