import React from 'react';
import { Text, StyleSheet,Dimensions } from 'react-native';
import { useState, useEffect } from 'react';



const CustomText = (props) => {
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

        useEffect(() => {
            const handleResize = () => {
                setScreenWidth(Dimensions.get('window').width);
            };

            Dimensions.addEventListener('change', handleResize);

            return () => {
                // Cleanup event listener on component unmount
                Dimensions.removeEventListener('change', handleResize);
            };
            }, []);

            const  defaultFont = {
                fontFamily: props.fontWeight == '800' ? screenWidth < 700 ? 'BarlowCN-800' : 'Barlow-800' : screenWidth < 700 ? 'BarlowCN' : 'Barlow',
                fontSize : props.fontSize != undefined ? props.fontSize : props.header2 != undefined ?  16 : 14,
                textDecorationLine : props.underline != undefined ? 'underline' : 'none',
            }

            
    return <Text style={[defaultFont, props.style]}>{props.children}</Text>;
}


export default CustomText;

