import { useState } from 'react';
import { Message, useToaster } from 'rsuite';

const useMessage = () => {
    const toaster = useToaster();

    function messageToggle(input, type) {
        
        if (typeof input === 'string' && typeof type === 'string') {
            toaster.push(
                <Message closable  showIcon type={type}>
                    {input}
                </Message>, 
                { placement: 'topEnd',
                    duration : 10000
            }
            );
        } else {
            console.error('Invalid type for input or message type');
        }
    }

    return {
        messageToggle,
    };
}

export { useMessage };
