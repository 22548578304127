import React from 'react';
import Navigation from './src/Navigation'
import {SafeAreaView} from 'react-native'
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import './custom-theme.less';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faSearch, faPenSquare, faList, faTableList, faCar, faCalendar, faPlus, faImage,faArrowRight,faArrowDown, faFile,faLanguage,faMoneyBill,faTimes,faUpRightAndDownLeftFromCenter,faEdit,faRotateRight } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faSearch, faPenSquare, faList, faTableList, faCar, faCalendar, faPlus, faImage, faArrowRight, faArrowDown,faPlus,faSearch,faFile,faLanguage,faMoneyBill,faTimes,faUpRightAndDownLeftFromCenter,faEdit,faRotateRight);

class App extends React.Component {
  render() {
    return (
       <SafeAreaView style = {{flex:1, flexGrow: 1,    backgroundColor: '#F2F2F2'}}>
          <Navigation/>
       </SafeAreaView>
      
    );
  }
}

export default App;