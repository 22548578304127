import React, { useEffect, useImperativeHandle, useState } from 'react';
import { StyleSheet, Dimensions, View ,Image,FlatList,Text} from 'react-native';
import FileUploader from './FileUploader';
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { Modal } from 'react-native-paper';
import axios from 'axios';
import { Message, Panel, useToaster } from 'rsuite';
import { forwardRef } from 'react';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../CustomText';

// Amplify.configure(awsconfig);
Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});
const FileLoaderInline = forwardRef((props,ref) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
               {input}
            </Message>, { placement: 'topEnd' })
    }
    

    const fileTypes = ["JPG","JPEG", "PNG", "PDF","DOCX"];
    const [uploadProcessText, setUploadProcessText] =  useState("");
    const [toggleUploadProcess, setToggleUploadProcess] = useState(false);
    const [label,setLabel] = useState("DÖKÜMANI YÜKLE");
    const [refresh,setRefresh] = useState(true);
    // const [fileName,setFileName] =useState("");
    const [file,setFile] = useState(null);
    const [s3KEY,setS3Key] = useState(undefined);
    useImperativeHandle(ref, ()=>({
        load: () => {
            if(file != null){
                if( props.isMultiRelated != null && props.isMultiRelated ){
                    loadFileRentAgreements(file);
                }else{
                    loadFile(file);
                }
            }else{
                console.log("file is null");
                props.setS3Value("");
            }
          
        }
        
        

    }));

    useEffect(() => {
        if(s3KEY != undefined){
          console.log(s3KEY);
        //   props.setS3Key(s3KEY);
         sendData(s3KEY,file.name);
        }
      },[s3KEY])

    const DragDrop = () => {
        const handleChange = (file) => {
            // setFileName(file.name);
            //loadFile(file);
            setFile(file);
            setLabel("Döküman Yüklendi");
            console.log("file changed");

        };
        return (
            <View style={{marginVertical: 3, alignItems: 'flex-start', marginHorizontal: 3}}>
                <FileUploader style= {{width: '100%'}} handleChange={handleChange} name="file" types={fileTypes} />
            </View>

        );
    }


    const {fetchIdToken} = useUserToken();
    async function sendData (s3Key,fileName){
        const token = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        const headers = {
            "Authorization" : `Bearer ${token}`,
          }
        if( props.isMultiRelated != null && props.isMultiRelated ){
            sendDataMultiCarId(s3KEY,fileName)
        }else{
             axios.post(api,{
            "ENV" :configdata.APP_ENV, 
            "type" : "FILE_SET",
            "category" : props.fileCategory,
            "FILE_NAME" : fileName,
            "S3_KEY" : s3Key,
            "CAR_ID": props.carID,
            "OBJECT_ID" : props.ObjectID != null ? props.ObjectID : props.carID,
        },{headers}
            ).then((response) => {
                console.log(response)
                if(response.data["errorMessage"] != null ){
                    messageToggle("Dosya yüklenemedi","error")
                }else{
                    messageToggle("Dosya başarıyla yüklendi","success")
                    if(props.reset != null){
                        props.reset();
                    }
                }
              
            })
            .catch((error) => {
                console.log(error)
                messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin RDS","error")
            });
        }
       
    }

    async function sendDataMultiCarId (s3Key,fileName){
        const token = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        const headers = {
            "Authorization" : `Bearer ${token}`,
          }
        props.carID.forEach((element) => {
           
            axios.post(api,{
                "ENV" :configdata.APP_ENV , 
                "type" : "FILE_SET",
                "category" : props.fileCategory,
                "FILE_NAME" : fileName,
                "S3_KEY" : s3Key,
                "CAR_ID": element["ID"],
                "OBJECT_ID" : props.ClientID,
            },{headers}
                ).then((response) => {
                    console.log(response)
                    // if(response.data["errorMessage"] != null ){
                    //     messageToggle("Dosya yüklenemedi","error")
                    // }else{
                    //     messageToggle("Dosya başarıyla yüklendi","success")
                    //     if(props.reset != null){
                    //         props.reset();
                    //     }
                    // }
                })
                .catch((error) => {
                    console.log(error)
                    messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin RDS","error")
                });

        })

        axios.post(api,{
            "ENV" :configdata.APP_ENV ,
            "type" : "FILE_SET",
            "category" : props.fileCategory,
            "FILE_NAME" : fileName,
            "S3_KEY" : s3Key,
            "CAR_ID": props.ClientID,
            "OBJECT_ID" : props.ClientID,
        },{headers}
            ).then((response) => {
                console.log(response)
                if(response.data["errorMessage"] != null ){
                    messageToggle("Dosya yüklenemedi","error")
                }else{
                    messageToggle("Dosya başarıyla yüklendi","success")
                    if(props.reset != null){
                        props.reset();
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin MULTI CLİENT","error")
            });
        
    }

    async function loadFile (file){

        const name =  "" + file.name.toString();
        console.log(name.includes(".png"));
        var content_type = "application/pdf";
        var testCon = "";
        console.log(typeof(configdata.APP_ENV))
        if(configdata.APP_ENV == "DEV"){
            testCon = "TESTDB_"
        }
        let target_location = ""
        if(name.includes(".png")){
            target_location = testCon + "CAR_" + props.carID +"/"+props.fileCategory + "/PNGs/" + file.name
            content_type = "image/png";
        }
        if(name.includes(".jpg")){
            target_location =testCon + "CAR_" +  props.carID +"/"+props.fileCategory + "/JPEGs/" + file.name
            content_type = "image/jpeg";
        }
        else if(name.includes(".pdf")){
            target_location =testCon + "CAR_" + props.carID + "/"+props.fileCategory + "/PDFs/"+ file.name
            content_type = "application/pdf";
        }
        else if(name.includes(".docx")){
            target_location =testCon + "CAR_" + props.carID + "/"+props.fileCategory + "/DOCs/"+ file.name
            content_type = "application/msword";
        }
        else if(name.includes(".xlsx")){
            target_location =testCon + "CAR_" + props.carID + "/"+props.fileCategory + "/DOCs/"+ file.name
            content_type = "application/excel";
        }
        else if(name.includes(".csv")){
            target_location = testCon + "CAR_" + props.carID +"/"+props.fileCategory + "/DOCs/"+ file.name
            content_type = "application/excel";
        }

         console.log(target_location);
         console.log(content_type);
        try {

            await Storage.put(target_location, file, { 
                contentType: content_type, // contentType is optional
                progressCallback(progress) {
                    setToggleUploadProcess(true);
                    let value =((progress.loaded/progress.total) * 100) | 0
                    console.log(`Uploaded:` + value.toString());
                    setUploadProcessText(value.toString());
                    
                    if(progress.loaded/progress.total == 1){
                        setToggleUploadProcess(false);
                    }
              },
            }).then((response) => {
                console.log(response);
                setS3Key(response.key);
                props.setS3Value(response.key);
                // sendData(response.key,file.name);
            });
            // getList();
            setRefresh(!refresh);
          } catch (error) {
            messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin s3","error")
            console.log("Error uploading file: ", error);
            setUploadProcessText("Upload Not Completed : " + error);
          }
    }

    async function loadFileRentAgreements (file){

        const name =  "" + file.name.toString();
        console.log(name.includes(".png"));
        var content_type = "application/pdf";
        var testCon = "";
        if(configdata.APP_ENV == "DEV"){
            testCon = "TESTDB_"
        }
        let target_location = ""
        if(name.includes(".png")){
            target_location = testCon + "CLIENT_" + props.ClientID +"/"+props.fileCategory + "/PNGs/" + name
            content_type = "image/png";
        }
        if(name.includes(".jpg")){
            target_location =testCon + "CLIENT_" +  props.ClientID +"/"+props.fileCategory + "/JPEGs/" + name
            content_type = "image/jpeg";
        }
        else if(name.includes(".pdf")){
            target_location =testCon + "CLIENT_" + props.ClientID + "/"+props.fileCategory + "/PDFs/"+ name
            content_type = "application/pdf";
        }
        else if(name.includes(".docx")){
            target_location =testCon + "CLIENT_" + props.ClientID + "/"+props.fileCategory + "/DOCs/"+ name
            content_type = "application/msword";
        }
        else if(name.includes(".xlsx")){
            target_location =testCon + "CLIENT_" + props.ClientID + "/"+props.fileCategory + "/DOCs/"+ name
            content_type = "application/excel";
        }
        else if(name.includes(".csv")){
            target_location = testCon + "CLIENT_" + props.ClientID +"/"+props.fileCategory + "/DOCs/"+ name
            content_type = "application/excel";
        }

         console.log(target_location);
         console.log(content_type);
        try {

            await Storage.put(target_location, file, { 
                contentType: content_type, // contentType is optional
                progressCallback(progress) {
                    setToggleUploadProcess(true);
                    let value =((progress.loaded/progress.total) * 100) | 0
                    console.log(`Uploaded:` + value.toString());
                    setUploadProcessText(value.toString());
                    
                    if(progress.loaded/progress.total == 1){
                        setToggleUploadProcess(false);
                    }
              },
            }).then((response) => {
                console.log(response);
                setS3Key(response.key);
                props.setS3Value(response.key);
                // sendData(response.key,file.name);
            });
            // getList();
            setRefresh(!refresh);
          } catch (error) {
            messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin S3","error")
            console.log("Error uploading file: ", error);
            setUploadProcessText("Upload Not Completed : " + error);
          }
    }

    const RenderFilesAsLabels = () => {
        return (
            <Panel shaded bordered style={{width: 100, marginVertical: 10, padding: 10}}>
                <CustomText>
                    {file.name}
                </CustomText>
            </Panel>
        );
    }

    return (
      <View>
         <DragDrop></DragDrop>
         {
            file != null && file != undefined && <RenderFilesAsLabels></RenderFilesAsLabels>
        }
      </View>
    )
  
});
const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 7,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.10,
        shadowRadius: 4,
        elevation: 5
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height:'100%',
        margin: 25,
    },

    listItem:{
        backgroundColor: 'white',
        borderRadius: 7,
    },
    pdf: {
        flex:1,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    }
});

export default FileLoaderInline;