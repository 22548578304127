import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator } from 'react-native'
import { useState , useEffect , UseRef ,forwardRef ,useImperativeHandle} from "react";
import { Form ,Input, InputNumber, MaskedInput } from "rsuite";

const FreeTextFilter = forwardRef((props,ref) => {

    const [data,setData] =  useState(props.data);
    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      filter: () => {
        if(value != null || value != ""){
          props.refresh();
          return filter_data_multiple();
        }
      }, 
      clear: () => {
        setValue("");
      }
      
    }))

    const[value,setValue] = useState("");

    useEffect(() => {

      if((value == "" || value == null) && props.isFilterApplied){
          props.reset();
      }
      if((value != null && value != "" )){
          // filter_data_multiple();
          props.filter();
      }
    },[value])


    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => !checkContains(item,e)))
    }

    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }

    const filter_data_multiple = () =>{

        var columns = []
        var inputs = [];

      
        if(value != "" && value != null){
          columns.push(props.tableHeadName)
          inputs.push(value);
        }
    
        if(columns.length > 0){
            let temps = []
            for(let c = 0 ; c< columns.length; c++){
              let temp = [];
                for(let i = 0; i< props.data.length ; i++){
                  if(inputs[c].charAt(inputs[c].length - 1) != ',' && inputs[c].charAt(0) != ','){
                    let tempstr = String(props.data[i][columns[c]])
                    // console.log(tempstr);
                    tempstr = tempstr.toLowerCase();
                    //console.log(inputs[c]);
                    var inputArray = inputs[c].toLowerCase().split(",");
                    inputArray.forEach(element => {
                        if(tempstr.includes(element)){
                       // console.log("match to filter " + i)
                        temp.push(props.data[i]);
                    }
                    });
                }
                }
            //console.log(" temp " + c +temp);
            temps.push(temp);
            }
          // console.log(temps);
            let first =  temps.shift();
            let final = intersect(first,temps);
            // console.log("final" + final);
            
            return final;

            // props.setFiltered(final);
            // props.setApplied(true);
        }
        return props.data
    }


    const item_style = {
      standard: { width : 200, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
  }

    return (
      <View style={{marginVertical: 10}}>
      <Form>
        {props.isTCKN != null && 
             <Form.Group>
             <Form.ControlLabel> {props.tableHeadName} </Form.ControlLabel>
             <Form.Control accepter={MaskedInput} guide={false} mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}  value={value} style={props.item_style.standard} onChange={ (value) => {setValue(value)}} name= {props.tableHeadName} /> 
         </Form.Group>
        }
         {props.isVKN != null && 
             <Form.Group>
             <Form.ControlLabel> {props.tableHeadName} </Form.ControlLabel>
             <Form.Control accepter={MaskedInput} guide={false} mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/]} value={value} style={props.item_style.standard} onChange={ (value) => {setValue(value)}} name= {props.tableHeadName} /> 
         </Form.Group>
        }

        {(props.isVKN == null && props.isTCKN == null) && 
             <Form.Group>
             <Form.ControlLabel> {props.tableHeadName} </Form.ControlLabel>
             <Form.Control accepter={props.isNumber != null ? InputNumber : Input} value={value} style={props.item_style.standard} onChange={ (value) => {setValue(value)}} name= {props.tableHeadName} /> 
         </Form.Group>
        }
      </Form>
    </View>
    )
  })

  export default FreeTextFilter