import { Text, View } from 'react-native';
import React, { PureComponent } from 'react';
import { CSVLink ,CSVDownload} from "react-csv";
import StandardButton from '../StandardButton';
import { IconButton } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { saveAs } from "file-saver";
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
// import XlsxPopulate from "xlsx-populate";
import 'typeface-roboto'



const ExportData =(props) =>{
    function getSheetData(data, header) {
      var fields = Object.keys(data[0]);
      var sheetData = data.map(function (row) {
        return fields.map(function (fieldName) {
          return row[fieldName] ? row[fieldName] : "";
        });
      });
      sheetData.unshift(header);
      return sheetData;
    }
  
    async function saveAsExcel() {
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(props.data, props.tableHead);
        const totalColumns = sheetData[0].length;
  
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
        var date = new Date();
        var date_Str = "[" +date.getHours() + "." + date.getMinutes() + "] _ " + date.getDate() + "_" + date.getMonth() + "_" + date.getFullYear();
        console.log(date_Str);
       
        return workbook.outputAsync().then((res) => {
          saveAs(res, props.tableName + "_" + date_Str + ".xlsx");
        });
      });
    }

    const downloadExcel = () => {

      let data = [];
      var columns = props.columns;

      console.log(props.data);

      if(props.data[0]["ID"] != null){
        columns.push("ID");
      } 
      if(props.data[0]["CHECKED"] != null){
        columns.push("CHECKED");
      }
      if(columns != null ||columns != undefined){
        if(columns != Object.keys(props.data[0])){
          props.data.forEach((item) => {
            let newItem = {};
            // console.log(columns);
            // console.log(props.columns);
            columns.forEach((column) => {
              newItem[column] = item[column];
            });
            data.push(newItem);
            // console.log(data);
          });
        }else{
          data = props.data;
        }
      }else{
        data = props.data;
      }

      if(props.type != null && props.type == "CHECKED"){
        data = data.filter((item) => item.CHECKED == 1);
      }
      console.log(data);

      var date = new Date();
      var date_Str = "[" +date.getHours() + "." + date.getMinutes() + "] _ " + date.getDate() + "_" + date.getMonth() + "_" + date.getFullYear();
      // const ws = XLSX.utils.json_to_sheet(data);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      var tableName = props.tableName + "_" + date_Str + ".xlsx";
      /* save to file */
      // XLSX.writeFile(wb, date_Str +".xlsx");
      generateAndDownloadExcel(data,tableName);
    }

    async function generateAndDownloadExcel(data,tableName) {
      let workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet("Sheet1");
  
      // Define a style for the header
      let headerStyle = {
          font: {
              bold: true,
              color: {argb: 'FFFFFF'}
          },
          fill: {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'#0AF597'}
          }
      };
  
      // Assuming that data is your array of objects
      let firstRow = data[0];
      let headers = Object.keys(firstRow);
      let columnWidths = headers.map(header => header.length);
  
      // Apply the header style
      headers.forEach((header, index) => {
          let cell = worksheet.getCell(String.fromCharCode(65 + index) + "1");
          cell.value = header;
          cell.style = headerStyle;
      });
  
      // Add the data
      data.forEach((item, index) => {
        let rowNumber = index + 2;  // +2 because headers are on row 1 and array indices are 0-based
        headers.forEach((header, index) => {
            let cellValue = item[header];
            worksheet.getCell(String.fromCharCode(65 + index) + rowNumber).value = cellValue;
            let cellValueLength = String(cellValue).length;
            if (cellValueLength > columnWidths[index]) {
                columnWidths[index] = cellValueLength;
            }
        });
    });

    // Apply column widths
    columnWidths.forEach((width, index) => {
        worksheet.getColumn(index + 1).width = width;
    });
  
      // Generate the Excel file as a byte array
      const buf = await workbook.xlsx.writeBuffer();
  
      // Start file download in the browser
      let blob = new Blob([buf], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = tableName;
      link.click();
  }


    return(
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end',alignItems: 'center',marginVertical: 10, height: 30, marginHorizontal: 12}}>
          <IconButton onClick={downloadExcel} style={{backgroundColor: '#1D6F42' , width : props.width != null && props.width }} icon={<Icon> <FontAwesomeIcon icon={"file-excel"} ></FontAwesomeIcon> </Icon>}>
            <Text style={{ fontWeight : 'bold', color:'#EBFFF9'}}> {(props.type != null && props.type == "CHECKED") ? "Seçilenleri Dışarı Aktar" : props.textLabel != undefined ? props.textLabel : "Tümünü Dışarı Aktar" }</Text>
          </IconButton>
     </View>
    ) ;
}

export default ExportData;