import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator,Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { Stack,Modal, Button, Table , Panel,Drawer, DatePicker, SelectPicker, Input, MaskedInput, Divider,List,IconButton} from 'rsuite';
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import configdata from "../../config/config.json";
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../../Components/CustomText';
import { useMessage } from '../../hooks/useMessage';
import { Tabs } from 'react-bootstrap';
import locale from '../../tr';
import { createNumberMask } from 'text-mask-addons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes} from "@fortawesome/free-solid-svg-icons";


const SalesVisitorInfo = (props) => {

    const {fetchUserID,fetchIdToken} = useUserToken();

    const [visitorData, setVisitorData] = useState(props.data);
    const [loading, setLoading] = useState(true);
    const [cars, setCars] = useState([]);
    const [visits, setVisits] = useState([]);
    const [calls, setCalls] = useState([]);

    useEffect(() => {
        getVisitorData();
    }, []);

    function parseVisits(data){
        let carsTemp = data.cars;
        let visitsTemp = data.visits;
        let callsTemp = data.calls;
        let baseData = data.baseData;

        setCars(carsTemp);
        setCalls(callsTemp);
        setVisitorData(baseData[0]);
        // setCalls(callsTemp);
        setVisits(mergeVisitsAndCars(visitsTemp,carsTemp));
    }

    function mergeVisitsAndCars(visits,cars){
        // cars will be children for each associated visit
        let temp = [];
        visits.forEach((visit) => {
            let tempVisit = visit;
            tempVisit.children = [];
            cars.forEach((car) => {
                if(car.VISIT_ID == visit.ID){
                    tempVisit.children.push(car);
                }
            });
            temp.push(tempVisit);
        });
        return temp;
    }

        


    async function getVisitorData(){
        const UserID = await fetchUserID();
        const idToken = await fetchIdToken();
        const api = configdata.API_BASE + '/sales-visitors';

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }

        const Body = {
            "ENV" :configdata.APP_ENV ,
            "type" : "GET_DETAILS",
            "VISITOR_ID" : props.data.ID,
        }

        axios.post(api,Body,{headers})
        .then((response) => {
            console.log(response);
            parseVisits(response.data);
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }

    function reset(){
        props.res();
        getVisitorData();
        setOnEdit(false);
    }

    const [onEdit, setOnEdit] = useState(false);
    



    return (
        <View style={{padding: 20}}>
            <Drawer open={onEdit} size={Dimensions.get('window').width < 700 ? 'full' : 'sm'}  placement="right" onClose={() => {setOnEdit(false)}}>
                <Drawer.Header>
                    <Drawer.Title>Ziyaretçi Bilgilerini Düzenle</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <VisitorUpdate data={props.data} res={reset} VISITOR_ID={visitorData.ID} />
                </Drawer.Body>
            </Drawer>
            
            <Panel header="Ziyaretçi Bilgileri" shaded style={styles.Panel}>
            
                <Stack style={{marginBottom: 10}} wrap spacing={20}>
                    <Group header="İsim" layout="standard">
                        <CustomText>{visitorData.NAME}</CustomText>
                    </Group>
                    <Group header="Soyisim" layout="standard">
                        <CustomText>{visitorData.SURNAME}</CustomText>
                    </Group>
                    <Group header="Email" layout="standard">
                        <CustomText>{visitorData.EMAIL}</CustomText>
                    </Group>
                    <Group header="Telefon" layout="standard">
                        <CustomText>{visitorData.PHONE}</CustomText>
                    </Group>
                    <Group header="Meslek" layout="standard">
                        <CustomText>{visitorData.PROFESSION}</CustomText>
                    </Group>
                    <Group header = "Satış Danışmanı" layout="standard">
                        <CustomText>{visitorData.STS_DNS}</CustomText>
                    </Group>
                </Stack>
                <IconButton icon={<Icon><FontAwesomeIcon icon={"edit"}></FontAwesomeIcon></Icon>} onClick={() => {setOnEdit(true)}} > Düzenle </IconButton>

            </Panel>
            <Panel header="Ziyaretçi Notları" shaded style={styles.Panel}>
                <CustomText>{visitorData.NOTES}</CustomText>
            </Panel>
        
            <Panel header="Ziyaretçi Detayları" shaded style={styles.Panel}>
                <Tabs style={{marginTop: 20}}>
                    <Tabs.Item eventKey="1" title="Ziyaretler">
                        <SalesVisitorVisits data={visits} VISITOR_ID={visitorData.ID} res={reset} />
                    </Tabs.Item>
                    <Tabs.Item eventKey="2" title="Bakılan Tüm Araçlar">
                        <SalesVisitorCars  data={cars} />
                    </Tabs.Item>
                    <Tabs.Item eventKey="3" title="Yapılan Aramalar">
                        <SalesVisitorCalls  data={calls} VISITOR_ID={visitorData.ID} res={reset} />
                    </Tabs.Item>
                </Tabs>
            </Panel>
        </View>
    )
}

const VisitorUpdate = (props) => {
    const [name, setName] = useState(props.data.NAME);
    const [surname, setSurname] = useState(props.data.SURNAME);
    const [email, setEmail] = useState(props.data.EMAIL);
    const [phone, setPhone] = useState(props.data.PHONE);
    const [notes, setNotes] = useState(props.data.NOTES);
    const [profession, setProfession] = useState(props.data.PROFESSION);
    const {fetchIdToken} = useUserToken();
    const messageToggle = useMessage();
    
    async function saveVisitor(){
        const api = configdata.API_BASE + '/sales-visitors';
        const idToken = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }

        let visitorData = {
            "ENV" :configdata.APP_ENV ,
            "type" : "UPDATE_VISITOR",
            "VISITOR_ID" : props.VISITOR_ID,
            "VISITOR_NAME" : name,
            "VISITOR_SURNAME" : surname,
            "VISITOR_EMAIL" : email,
            "VISITOR_PHONE" : phone,
            "VISITOR_NOTES" : notes,
            "VISITOR_PROFESSION" : profession
        }
        console.log(visitorData);

        axios.post(api,visitorData,{headers})
        .then((response) => {
            console.log(response);
            if(response.data == "SUCCESS"){
                props.res();
                messageToggle("Ziyaretçi bilgileri başarıyla güncellendi","success");
            }
            else{
                messageToggle("Ziyaretçi bilgileri güncellenirken bir hata oluştu","error");
            }
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }

    return (
        <View>
            <View style={{flexDirection: 'column', justifyContent: 'space-between'}}>
                <Group header="İsim">
                    <Input style={styles.standard} value={name} onChange={setName} />
                </Group>
                <Group header="Soyisim">
                    <Input style={styles.standard} value={surname} onChange={setSurname} />
                </Group>
                <Group header="Email">
                    <Input style={styles.standard} value={email} onChange={setEmail} />
                </Group>
                <Group header="Telefon">
                <MaskedInput 
                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      value={phone} 
                      onChange={ (value) => setPhone(value)}
                      style={styles.standard} 
                    />
                </Group>
                <Group header="Notlar">
                    <Input as='textarea' style={styles.standard} value={notes} onChange={setNotes} rows = {5} />
                </Group>
                <Group header="Meslek">
                    <Input style={styles.standard} value={profession} onChange={setProfession} />
                </Group>
                <Button style={{backgroundColor: 'blue'}} onClick={saveVisitor} ><CustomText style={{color: 'white'}}> Kaydet </CustomText></Button>
            </View>
        </View>
    )
}



const SalesVisitorVisits = (props) => {

    const [show, setShow] = useState(false);

    const refreshData = () => {
        props.res();
        setShow(false);
    }


    const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 }}>
          <IconButton
            appearance="subtle"
            onClick={() => {
              onChange(rowData);
            }}
            icon={
              expandedRowKeys.some(key => key === rowData[dataKey]) ? (
                <Icon><FontAwesomeIcon icon={"arrow-down"}></FontAwesomeIcon></Icon>
    
              ) : (
                <Icon><FontAwesomeIcon icon={"arrow-right"}></FontAwesomeIcon></Icon>
              )
            }
          > Araçlar  </IconButton>
        </Table.Cell>
    );

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const handleExpanded = (rowData) => {
        const nextExpandedRowKeys = expandedRowKeys.includes(rowData.ID)
          ? expandedRowKeys.filter(key => key !== rowData.ID)
          : [...expandedRowKeys, rowData.ID];
        setExpandedRowKeys(nextExpandedRowKeys);
    }

    function formatPrice(price){
        return price.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
    }

    const renderRowExpanded = (rowData) => {
        return (
            <View style={{padding: 20}}>
                <View style={{justifyContent: 'flex-start', flexDirection: 'column'}}>
                    <CustomText fontSize= {16} fontWeight={800}> Bakılan Araçlar </CustomText>
                    <View style={{height: 20}}></View>
                    <Stack style={{marginBottom: 10}} wrap spacing={10}>
                        {
                            rowData.children.length > 0 &&
                            rowData.children.map((item,index) => {
                                return (
                                    <Panel shaded key={index} style={{flexDirection: 'column', justifyContent: 'flex-start', backgroundColor:'white'}}>
                                        <View  style={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                                            <CustomText fontSize= {14} fontWeight={800}>Araç Tipi</CustomText>
                                            <View style={{width: 20}}></View>
                                            <CustomText fontSize= {14} >{item.CAR}</CustomText>
                                        </View>
                                        <View style={{width: 20}}></View>
                                        <View  style={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                                            <CustomText fontSize= {14} fontWeight={800}>Araç Fiyatı</CustomText>
                                            <View style={{width: 20}}></View>
                                            <CustomText fontSize={14} >{formatPrice(item.PRICE)}</CustomText>
                                        </View>
                                    </Panel>
                                )
                            })
                        }
                </Stack>
                </View>
            </View>
        );
    }




    return (
        <View style={{padding: 20}}>
            <Drawer open={show} size={Dimensions.get('window').width < 700 ? 'full' : 'sm'} placement="right" onClose={() => {setShow(false)}}>
                <Drawer.Header>
                    <Drawer.Title>Yeni Ziyaret Ekle</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <NewVisit res={refreshData} VISITOR_ID={props.VISITOR_ID} />
                </Drawer.Body>
            </Drawer>
            <Button appearance="primary" onClick={() => {setShow(true)}}> <CustomText style={{color: 'white'}}  > Yeni Ziyaret Ekle </CustomText> </Button>
            <View style={{height: 20}}></View>
            <Panel shaded style={styles.Panel}>
                <Table 
                    height={400} 
                    data={props.data}
                    rowKey={"ID"}
                    onRowClick={data => {
                        console.log(data);
                      }}
                    rowExpandedHeight={200}
                    renderRowExpanded={renderRowExpanded}
                    expandedRowKeys={expandedRowKeys}
                >
                    <Table.Column width={100} align="center" >
                        <Table.HeaderCell> Ziyaret ID </Table.HeaderCell>
                        <ExpandCell dataKey="ID" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
                    </Table.Column>
                    <Table.Column width={300} align="center">
                        <Table.HeaderCell> Ziyaret Tarihi </Table.HeaderCell>
                        <Table.Cell dataKey="VISIT_DATE" />
                    </Table.Column>
                    <Table.Column dd width={500} align="center">
                        <Table.HeaderCell> Ziyaret Notları </Table.HeaderCell>
                        <Table.Cell dataKey="NOTES" />
                    </Table.Column>
                </Table>
            </Panel> 

            
        </View>
    )
}

const SalesVisitorCars = (props) => {

    const FormattedCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props}>
          {rowData[dataKey].toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
        </Table.Cell>
    );

    return (
        <View>
            <Table height={400} data={props.data}>
                <Table.Column flexGrow={1} align="center" fixed>
                    <Table.HeaderCell> Araç ID </Table.HeaderCell>
                    <Table.Cell dataKey="ID" />
                </Table.Column>
                <Table.Column flexGrow={2} width={200} align="center">
                    <Table.HeaderCell> Araç Tipi </Table.HeaderCell>
                    <Table.Cell dataKey="CAR" />
                </Table.Column>
                <Table.Column flexGrow={4} width={500} align="center">
                    <Table.HeaderCell> Araç Fiyatı </Table.HeaderCell>
                    <FormattedCell dataKey="PRICE" />
                </Table.Column>

            </Table>
        </View>
    )
}

const SalesVisitorCalls = (props) => {
    const [show, setShow] = useState(false);

    const refreshData = () => {
        props.res();
        setShow(false);
    }


    return (
        <View>
             <View style={{padding: 20}}>
            <Drawer open={show} size="sm" placement="right" onClose={() => {setShow(false)}}>
                <Drawer.Header>
                    <Drawer.Title>Yeni Arama Ekle</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <NewCall res={refreshData} VISITOR_ID={props.VISITOR_ID} />
                </Drawer.Body>
            </Drawer>
            <Button appearance="primary" onClick={() => {setShow(true)}}> <CustomText style={{color: 'white'}}  > Yeni Arama Ekle </CustomText> </Button>
            <View style={{height: 20}}></View>
            <Panel shaded style={styles.Panel}>
                <Table height={400} data={props.data}>
                    <Table.Column flexGrow={1} align="center" fixed>
                        <Table.HeaderCell> Arama ID </Table.HeaderCell>
                        <Table.Cell dataKey="ID" />
                    </Table.Column>
                    <Table.Column flexGrow={2} width={200} align="center">
                        <Table.HeaderCell> Arama Tarihi </Table.HeaderCell>
                        <Table.Cell dataKey="CALL_DATE" />
                    </Table.Column>
                    <Table.Column flexGrow={4} width={500} align="center">
                        <Table.HeaderCell> Arama Notları </Table.HeaderCell>
                        <Table.Cell dataKey="NOTES" />
                    </Table.Column>
                </Table>
            </Panel>

            
        </View>
        </View>
    )
}

const NewCall = (props) => {
    const [date, setDate] = useState(new Date());
    const [notes, setNotes] = useState("");

    const {fetchIdToken} = useUserToken();

    async function saveCall(){

        const api = configdata.API_BASE + '/sales-visitors';
        const idToken = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }

        let callData = {
            "ENV" :configdata.APP_ENV ,
            "type" : "NEW_CALL",
            "VISITOR_ID" : props.VISITOR_ID,
            "CALL_DATE" : date,
            "NOTES" : notes,
        }
        console.log(callData);

        axios.post(api,callData,{headers})
        .then((response) => {
            console.log(response);
            props.res();
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });

    }

    return (
        <View>
            <View style={{flexDirection: 'column', justifyContent: 'space-between'}}>
                <Group header="Arama Tarihi">
                    <DatePicker value={date} style={styles.standard} format="dd-MM-yyyy" locale={locale} oneTap onChange={(value) => setDate(value)} ></DatePicker>
                </Group>
                <Group header="Arama Notları">
                    <Input as='textarea' style={styles.standard} value={notes} onChange={setNotes} rows = {5} />
                </Group>
                <Button style={{backgroundColor: 'blue'}} onClick={saveCall} ><CustomText style={{color: 'white'}}> Kaydet </CustomText></Button>
            </View>
        </View>
    )
}




const NewVisit = (props) => {

    const [date, setDate] = useState(new Date());
    const [notes, setNotes] = useState("");
    const [portfoyData, setPortfoyData] = useState([]);
    const [selectedPortfoy, setSelectedPortfoy] = useState([]);
    const [TL, setTL] = useState("");
    const [selectedPortfoyData, setSelectedPortfoyData] = useState([]);

    useEffect(() => {
        getPortfoy();
    }, []);

    function addCar(car){

        console.log(car);
        let temp = {
            "ID": car.value,
            "LABEL":car.label,
            "PRICE": Number(TL.replace('₺','').replace(',','.'))
        };
        setSelectedPortfoyData([...selectedPortfoyData,temp]);
    }

    const {fetchIdToken} = useUserToken();

    const getPortfoy = async () => {
        const api = configdata.API_BASE + '/sales-visitors';
        const idToken = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }

        let portfoyDataTemp = [];
        axios.post(api,{
            "ENV" :configdata.APP_ENV ,
            "type" : "GET_PORTFOY_CARS",
        },{headers})
        .then((response) => {
            console.log(response);
            response.data.forEach(element => {
               portfoyDataTemp.push({"label": element.CAR, "value": element.ID, "TYPE": element.TYPE});
            }
            );
            setPortfoyData(portfoyDataTemp);
            
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }

    function removeCar(index){
        let temp = selectedPortfoyData.filter((item,i) => i != index);
        setSelectedPortfoyData(temp);
    }

    const TLMask = createNumberMask({
        prefix: '₺',
        allowDecimal: true,
        decimalLimit: 2,
        integerLimit: 7,
        allowNegative: false,
        allowLeadingZeroes: false
    })

    async function saveVisit(){

        const api = configdata.API_BASE + '/sales-visitors';
        const idToken = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }

        let visitData = {
            "ENV" :configdata.APP_ENV ,
            "type" : "SAVE_VISIT",
            "VISITOR_ID" : props.VISITOR_ID,
            "DATE" : date,
            "NOTES" : notes,
            "CARS" : selectedPortfoyData
        }
        console.log(visitData);

        axios.post(api,visitData,{headers})
        .then((response) => {
            console.log(response);
            props.res();
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });


    }



    return (
        <View>
            <View style={{flexDirection: 'column', justifyContent: 'space-between', width:'100%'}}>
                <Group header="Ziyaret Tarihi">
                    <DatePicker value={date} style={styles.standard} format="dd-MM-yyyy" locale={locale} oneTap onChange={(value) => setDate(value)} ></DatePicker>
                </Group>
                <Group header="Ziyaret Notları">
                    <Input as='textarea' style={styles.standard} value={notes} onChange={setNotes} rows = {5} />
                </Group>
                <Divider > <CustomText> Araç Ekle </CustomText></Divider>
                <Group header="Araç Tipi">
                    <SelectPicker groupBy='TYPE' style={styles.standard} data={portfoyData} value={selectedPortfoy.value} onSelect={(value,item) => {setSelectedPortfoy(item)}} />
                </Group>
                <Group header="Araç Fiyatı">
                    <MaskedInput style={styles.standard} mask= {TLMask} onChange={(value) => {setTL(value)}} /> 
                </Group>
                <View style={{height: 20}}></View>
                <Button  style={{backgroundColor: 'green'}} onClick={() => {addCar(selectedPortfoy,TL)}} ><CustomText style={{color: 'white'}}> Araç Ekle </CustomText></Button>
                {
                selectedPortfoyData.length > 0 &&
                    <List>
                        {selectedPortfoyData.map((item,index) => {
                            return (
                                <List.Item key={index} index={index} style={{padding: 10}}>
                                    <CarLabel index={index} remove={removeCar} item={item} />
                                </List.Item>

                            )
                        }
                        )}
                    </List>
                }

                <View style ={{height: 20}}></View>
                <Button style={{backgroundColor: 'blue'}} onClick={saveVisit} ><CustomText style={{color: 'white'}}> Kaydet </CustomText></Button>
            </View>
        </View>
    )
}


const CarLabel = (props) => {
    return (
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
            <View>
                <CustomText fontSize={14} fontWeight={800} style={{color: '#5A6273'}}>{props.item.LABEL}</CustomText>
            </View>
            <View>
                <CustomText fontSize={14} fontWeight={800} style={{color: '#5A6273'}}>{props.item.PRICE}</CustomText>
            </View>
            <View>
                <IconButton icon={<Icon><FontAwesomeIcon icon={"times"}></FontAwesomeIcon></Icon>} onClick={() => {props.remove(props.index)}} > {props.index} </IconButton>
            </View>
        </View>
    )
}




const Group = ({ children, ...props }) => (
    <View {...props} style={[styles.group,{flexDirection: props.layout != undefined ? props.layout == 'inline' ? 'row' : 'column' :'row' }]}>
      <View style = {{ margin: 5}}>
        <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
      </View>
      <View style={{flex: 1, alignItems: 'flex-end'}}>
        {children}
      </View>
    </View>
);

export default SalesVisitorInfo;


const styles = StyleSheet.create({
    group: {
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginVertical: 6,
    },
    buttonStyle: {
      width: 120,
      alignSelf: 'flex-end',
      backgroundColor: '#5E6EED',
      color: 'white',
      borderRadius: 7,
      marginVertical: 5,
    },
    standard: { flex: 1 , maxWidth: 350, minWidth: 150, width: '100%'},

    wide: {
      width: 350,
      height: 30,
    },
    Panel: {
        backgroundColor: 'white',
      marginTop: 20,
    },

    bottomBar: {
        height: 40,
        backgroundColor: '#fff',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent:'flex-start',
      },
  });
