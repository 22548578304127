import { Dimensions, Text, View } from 'react-native'
import React, { PureComponent, useEffect, useState } from 'react'
import {Panel,Button,Form,FormGroup,FormControl,ControlLabel,SelectPicker,IconButton,Message,Input, Table, Stack, Whisper, Popover, Divider, List, Radio, RadioGroup} from 'rsuite';
import axios from 'axios';
import configdata from "../../config/config.json";
import { useUserToken } from '../../hooks/useUserToken';
import { useMessage } from '../../hooks/useMessage';
import CustomText from '../../Components/CustomText';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewRequestScreen from './NewRequestScreen';
import { Cell ,HeaderCell} from 'rsuite-table';
const Requests = () => {

    const [userList, setUserList] = useState([]);
    const [requests, setRequests] = useState([]);

    const {fetchIdToken,fetchUserID} = useUserToken();

    const getRequests = async () => {
        const api = configdata.API_BASE + '/requests';
        let userID = await fetchUserID();
        console.log(userID);
        axios.post(api,{
            "ENV" :configdata.APP_ENV ,  
            "type" : "GET_REQUESTS",
        })
        .then((response) => {
            console.log(response);
            let newData = response.data.filter((request) =>  request.CREATE_USER == userID || request.TARGET_USERS.some((user) => user.ID == userID));

            setRequests(newData);
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }

    useEffect(() => {
        getRequests();
    },[]);

    const [showType, setShowType] = useState("table");



    return (
      <View style={{padding: 20}}>

        <Panel header="Yeni İstek" shaded style={{margin: 10, backgroundColor:'white'}} collapsible>
            <NewRequestScreen  getRequests={getRequests}></NewRequestScreen>
        </Panel>



        <Panel header="İstekler" shaded style={{margin: 10, backgroundColor:'white'}}>
        <RadioGroup appearance='picker' inline value={showType} onChange={value => setShowType(value)}>
                <Radio value="table">Tablo</Radio>
                <Radio value="list">Liste</Radio>
            </RadioGroup>
            <View style={{height: 20}}></View>
         {showType == "table" ? 
            <Table  data={requests} 
                bordered
                autoHeight
                fluid
                wordWrap
                virtualized
                >
                <Table.Column width={50} align="center">
                    <Table.HeaderCell>  </Table.HeaderCell>
                    <CustomCellStatus dataKey="STATUS" />
                </Table.Column>
                <Table.Column width={150} align="center">
                    <CustomHeaderCell > Durum </CustomHeaderCell>
                    <CustomTextCell dataKey="STATUS_NAME" />
                </Table.Column>
                <Table.Column width={200} align="center">
                    <CustomHeaderCell>İstek Başlığı</CustomHeaderCell>
                    <CustomTextCell dataKey="HEADER" />
                </Table.Column>
                <Table.Column width={120} align="center">
                    <CustomHeaderCell>En Son İşlem</CustomHeaderCell>
                    <DateCell dataKey="LATEST_INTERACTION" />
                </Table.Column>
                <Table.Column width={80} align="center">
                    <CustomHeaderCell>Oluşturan</CustomHeaderCell>
                    <CreateUserCell dataKey="CREATE_USER_NAME" />
                </Table.Column>
                <Table.Column width={200} align="center">
                    <CustomHeaderCell>Hedef</CustomHeaderCell>
                    <TargetUserCell dataKey="TARGET_USERS" />
                </Table.Column>
                <Table.Column width={400} align="center">
                    <CustomHeaderCell>İstek Açıklaması</CustomHeaderCell>
                    <CustomTextCell dataKey="DESCRIPTION" />
                </Table.Column>
            </Table>
            :
            <List>
                {requests.map((request) => {
                    return (
                        <List.Item  key={request.ID} >
                            <RequestLabel data={request} label={request.HEADER} ></RequestLabel>
                        </List.Item>
                    )
                })}
            </List>
        }

        </Panel>
        
      </View>
    )
  }

  const CustomHeaderCell = ({children,...props}) => {
    return (
        <HeaderCell {...props} style={{ display: 'flex', justifyContent: 'flex-start'}}>
            <CustomText style={{fontSize: 14}}>{children}</CustomText>
        </HeaderCell>
    );
};

  const CustomTextCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props} style={{ display: 'flex', justifyContent: 'flex-start'}}>
                <CustomText style={{fontSize: 14}}>{rowData[dataKey]}</CustomText>
            </Cell>
        );
    };


  const CreateUserCell = ({ rowData, dataKey, ...props }) => {

    function getCreateUser(){
        return {
            "NAME" : rowData.CREATE_USER_NAME,
            "SURNAME" : rowData.CREATE_USER_SURNAME,
            "EMAIL" : rowData.CREATE_USER_EMAIL
        }
    }

    return (
        <Cell {...props}>
            <UserCircleTooltip User={getCreateUser()}></UserCircleTooltip>
        </Cell>
    );
};

const TargetUserCell = ({ rowData, dataKey, ...props }) => {

    return (
        <Cell {...props} style={{ display: 'flex', justifyContent: 'flex-start'}}>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            {
                rowData.TARGET_USERS.map((user) => {
                    return (
                        <UserCircleTooltip User={user}></UserCircleTooltip>
                    )
                }
                )
            }
            </View>
        </Cell>
    );
};

            

  const DateCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props} style={{ display: 'flex', justifyContent: 'flex-start'}}>
            <CustomText style={{fontSize: 14}}> 
              {new Date(rowData[dataKey]).toLocaleDateString()}
            </CustomText>
        </Cell>
    );
};



  const CustomCellStatus = ({ rowData, dataKey, ...props }) => {

    function getStatusColor(){
        if(rowData.STATUS == 0){
            return "orange";
        }
        else if(rowData.STATUS == 1){
            return "blue";
        }
        else if(rowData.STATUS == 2){
            return "green";
        }
        else if(rowData.STATUS == 3){
            return "red";
        }
    }

    return (
        <Cell {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        width: 16,
                        height: 16,
                        borderRadius: 12,
                        backgroundColor: getStatusColor(),
                    }}
                />
            </div>
        </Cell>
    );
};
                    

  const RequestLabel = (props) => {

    function getCreateUser(){
        return {
            "NAME" : props.data.CREATE_USER_NAME,
            "SURNAME" : props.data.CREATE_USER_SURNAME,
            "EMAIL" : props.data.CREATE_USER_EMAIL
        }
    }

    function getStatusColor(){
        if(props.data.STATUS == 0){
            return "orange";
        }
        else if(props.data.STATUS == 1){
            return "blue";
        }
        else if(props.data.STATUS == 2){
            return "green";
        }
        else if(props.data.STATUS == 3){
            return "red";
        }
    }

    return (
        <View style={{width: '100%'}}>
            <Panel style={{margin: 10, backgroundColor:'white'}} shaded  collapsible
                    header={
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
                                <View style={{flex: 4 , flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <CustomText fontSize = {16} fontWeight={800} style={{color: '#5A6273'}}  >{props.label}</CustomText> 
                                <View style = {{flex: 4}}></View>
                                <Icon><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon></Icon>
                                <View style = {{width: 10}}></View>
                                <UserCircleTooltip User={getCreateUser()}></UserCircleTooltip>
                                <View style = {{width: 10}}></View>
                                {
                                    props.data.TARGET_USERS.map((user) => {
                                        return (
                                            <UserCircleTooltip User={user}></UserCircleTooltip>
                                            
                                        )
                                    }
                                    )
                                }
                                </View>
                                <Divider vertical></Divider>
                                <CustomText>{props.data.STATUS_NAME}</CustomText>
                                <Divider vertical></Divider>
                                <View style={{width: 20, height: 20, borderRadius: 50, backgroundColor: getStatusColor()}}></View>
                            </View>
                    } 
                >
                    
                    <View style={{padding: 10}}>
                        <CustomText>{props.data.DESCRIPTION}</CustomText>
                        <Divider></Divider>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <CustomText>{props.data.STATUS_NAME}</CustomText>
                            <Divider vertical></Divider>
                            <CustomText>{props.data.LATEST_INTERACTION}</CustomText>
                        </View>
                    </View>
                    <View style={{backgroundColor: getStatusColor(), height: 10, borderRadius: 12 }}></View>

                </Panel>
        </View>
    
    )
  }


  const UserCircleTooltip = (props) => {

    //assign Random bright colors to each user
    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    return (
        
            <Whisper
                placement="top"
                trigger="hover"
                controlId="control-id-hover-enterable"
                speaker={
                    <Popover title={props.User.NAME + " " + props.User.SURNAME}>
                        <CustomText>{props.User.NAME + " " + props.User.SURNAME}</CustomText>
                    </Popover>
                }
                enterable
            >
                 <View style={{backgroundColor: getRandomColor(), borderRadius: 50, width: 30, height: 30, justifyContent: 'center', alignItems: 'center', marginHorizontal: 2}}>
                     <CustomText style={{color:'white'}} > {props.User.NAME[0] + props.User.SURNAME[0]} </CustomText>
                 </View>

            </Whisper>
    )
  }


export default Requests