import { Text, View } from 'react-native'
import React, { PureComponent,useState, useEffect} from 'react'
import { Button,DatePicker,Form, Input, MaskedInput, SelectPicker ,IconButton,RadioGroup,Radio} from 'rsuite'
import axios from 'axios';
import { useUserToken } from '../../../hooks/useUserToken';
import configdata from '../../../config/config.json';
import { StyleSheet } from 'react-native';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BuyInfoScreenNewCar from './BuyInfoScreenNewCar';
import BuyInfoScreenUsedCar from './BuyInfoScreenUsedCar';

const BuyInfoScreen = (props) => {

    return (
        <View style={styles.container}>      
            
            {props.data["ARAÇ EDİNİM ŞEKLİ"].toLowerCase().includes("yeni") ?
                <BuyInfoScreenNewCar data= {props.data} carID = {props.data.ID} ></BuyInfoScreenNewCar>
            :
                <BuyInfoScreenUsedCar data= {props.data} carID = {props.data.ID} ></BuyInfoScreenUsedCar>
            }
        </View>
    )
}
export default BuyInfoScreen;


function Group ({ label, children, layout }) {
    return (
        <View style={{flexDirection: layout == "inline" ? 'row' : 'column', marginHorizontal : 5}}>
            <Text style={{ fontSize: 14, color: '#000', margin: 10}}>{label}</Text>
            <div>{children}</div>
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F2FFFB',
        alignItems: 'center',
        justifyContent: 'center',
    },
    group: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        borderRadius: 7,
        marginVertical: 10,
        padding: 10,
        height: 120,
        borderWidth: 1,
        borderColor: '#F2FFFB',
    },

    listItem: {
        backgroundColor: "#FFF",
        width: 200,
        height: 35,
    },
    listItemDisabled: {
        backgroundColor: "#aac9fa",
        width: 200,
        height: 35,
    },
    buttonRed : {
        backgroundColor: "#FF0000",
        width: 120,
        height: 35,
        color: 'white',
    },
    buttonBlue : {
        backgroundColor: "#267aff",
        width: 120,
        height: 35,
        color: 'white',

    },

});