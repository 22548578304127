  
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { View } from 'react-native';
import { MultiCascader ,Form} from 'rsuite'; 
import configdata from '../../../config/config.json';
import axios from 'axios';


const CarTagFilter = forwardRef((props,ref) => {


    const[tagOptions,setOptionsCartag] = useState([]);
    const[carTagSelection,setCarTagSelection] = useState([]);
    const[refresh,setRefresh] = useState(false);
    const[parents,setParents] = useState([]);
    const[childs,setChilds] = useState([]);

    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      filter: () => {
        if(carTagSelection != null || carTagSelection != undefined || carTagSelection.length != 0  ){
          props.refresh();
          return filter_data_multiple()
        }
      },
      clear: () => {
        setCarTagSelection([]);
        setRefresh(!refresh);
      },
      types: () => {
      }
      
    }))


    useEffect(()=> {
      getCarTags();
    },[])
    useEffect(() => {
      console.log(carTagSelection);
      if((carTagSelection == null || carTagSelection.length == 0  ) && props.isFilterApplied){
          props.reset();
          props.filter();
          // filter_data_multiple();
      } if((carTagSelection != null && carTagSelection.length != 0  ) ){
          // filter_data_multiple();
           props.filter();
      }
    },[carTagSelection])


    function parseCarTags(input){
      var result= [];
      var heads = [];
      var parentValues= [];
      var childValues= [];
      var otherLevels = [] // objects for not added to level 1 and 2
      input.forEach(element => {
        if(element.PARENT_ID == "-1"){
          heads.push( {"label": element.İSİM, "id" : element.NO ,"value": element.İSİM  ,"children": [] })
        }
      });
      setParents(heads);
      input.forEach(element => {
        if(element.PARENT_ID != "-1"){
          var cond = false;
          heads.find(head => {
            cond =  head.id == element.PARENT_ID;
            return head.id == element.PARENT_ID}).children.push({"label": element.İSİM, "id" : element.NO , "value": element.İSİM ,"children": [] })
            if(cond){
                otherLevels.push(element);
            }
        }
      });
      setChilds(otherLevels);
      // console.log(otherLevels);
      // console.log(heads);
      setOptionsCartag(heads);
    }

    function getCarTags(){
      const api = configdata.API_BASE + '/cardata';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,
          "type" : "GET_CAR_TAGS_ALL",
          
      })
      .then((response) => {
        // console.log(response.data);
        parseCarTags(response.data);
      })
      .catch((error) => {
        console.log(error);
        
      });
    }


    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => !checkContains(item,e)))
    }

    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }

    function carTagsToStringInputs(){
      carTagSelection.forEach(
          
      )
    }
   

    const filter_data_multiple = () =>{

      if(carTagSelection.length > 0  && carTagSelection != null){
        var columns = ["KULLANICI"]
        var inputs = carTagSelection;
          let temps = []
          for(let c = 0 ; c< columns.length; c++){
            let temp = [];
              for(let i = 0; i< props.data.length ; i++){
              
                  let tempstr = String(props.data[i][columns[c]])
                  tempstr = tempstr.toLowerCase();
                  inputs.forEach(element => {
                    var temp_element = String(element).toLowerCase();
                      if(tempstr.includes(temp_element)){
                      temp.push(props.data[i]);
                    }
                  });
              
              }
          //console.log(" temp " + c +temp);
          temps.push(temp);
          }
        // console.log(temps);
          let first =  temps.shift();
          let final = intersect(first,temps);
        // props.setFiltered(final);
          // props.setApplied(true);
        return final 
      }
  return props.data
}


    const item_style = {
      standard: { width : 200, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
  }
useEffect(()=> {
  console.log(carTagSelection);
},[carTagSelection]);


    return (
      <View style={{marginVertical: 10}}>
        <Form >
          <Form.Group>
              {/* <Form.ControlLabel> KULLANICI </Form.ControlLabel> */}
              <MultiCascader 
                cleanable={true}
                  style={props.item_style}
                  menuWidth = {300}
                  menuHeight = {250}
                  value = {carTagSelection}
                  data={tagOptions}
                  onChange= {(value,event) =>{ setCarTagSelection(value);}}
                  onClean = {() => {
                    props.reset();
                    // filter_data_multiple();
                  }}
                  cascade = {true}
                  // onSelect= {setBMP}
                 
              />
          </Form.Group>
        </Form>
    </View>
    )

  })

  export default CarTagFilter;