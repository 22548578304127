import { Text, View  } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import { Table} from 'rsuite';
import 'react-phone-input-2/lib/style.css'
import ExportData from './TableManagement/ExportData';


const BasicTable = (props) => {

    const [loading, setLoading] = useState(false);
    const [bordered, setBordered] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    // const [limit, setLimit] = React.useState(10);
    // const [page, setPage] = React.useState(1);
    const [refresh , setRefresh] = useState(false);

    const toggle_refresh = () => {
        setRefresh(!refresh)
    }
    function handleModalClose(){
        setPopupActive(false);
    }

    useEffect(()=> {getData()},[props.data]);
  
    const handleChangeLimit = dataKey => {
      setPage(1);
      setLimit(dataKey);
    };
  
    // const data = props.data.filter((v, i) => {
    //   const start = limit * (page - 1);
    //   const end = start + limit;
    //   return i >= start && i < end;
    // });
    const columns = props.tableHead;

    var currency_symbol = "₺"
    var formatter = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      maximumFractionDigits: 5,
    });

    function formatAmount(input){
      return formatter.format(input).replace("₺","₺ ")
    }


    function editRowData(input,dataKey){
        // console.log(props.tableName + " " + dataKey )
      
        if(dataKey.includes("Tutar") || dataKey.includes("TUTAR")){
            return formatAmount(input[dataKey])
        }if(dataKey.includes("KDV DAHİL")){
          return formatAmount(input[dataKey])
        }if(dataKey.includes("KDV HARİÇ")){
          return formatAmount(input[dataKey])
        }
        if(dataKey.includes("Tarih")){
            var dateN = input[dataKey];
            return dateN.toLocaleDateString("en-GB")
        }
        if(dataKey.includes("TARİH")){
          var dateN = new Date(input[dataKey]);
          return dateN.toLocaleDateString("en-GB")
        }
        if(dataKey.includes("DURUM")){
            var strResult = "ÖDENDİ"
            if(input[dataKey] == 0){ strResult = "ÖDENMEDİ"}
            if(input[dataKey] == 2){ strResult = "KISMİ ÖDENDİ"}
            
            return strResult
        }
        return input[dataKey];
    }

    function reverseDate(input){
        var result = "";  
        
        var comps = input.split("-");

        for(var c = 2 ; c >= 0 ; c--){
        for(var i = 0 ; i < comps[c].length  ; i++){
            result += comps[c][i];
        }
        c != 0 ? result += "-": result += "";
        }
    
        return result;
    }
    const CustomCellView = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
            <View style={{ height: '80%' , alignItems : 'flex-start'  }}>
                <Text style={{textAlign: 'left'}}>{editRowData(rowData,dataKey)}</Text>
            </View>
        </Table.Cell>
    );

      const [checkedKeys, setCheckedKeys] = React.useState([]);
      let checked = false;
      let indeterminate = false;

    
      const getData = () => {
        if (sortColumn && sortType) {
         return props.data.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (typeof x === 'string') {
              x = x.charCodeAt();
            }
            if (typeof y === 'string') {
              y = y.charCodeAt();
            }
            if (sortType === 'asc') {
              return x - y;
            } else {
              return y - x;
            }
          });
        }

          return props.data;
       
      };

      const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };

      function getWidth(input){
        var max = input.length * 10;
        // console.log(props.data);
        props.data.forEach(element => {
          
          var str = editRowData(element,input) + "";
          if(str.length * 10 > max){
            max = str.length * 10;
          }
         
        })

        if(max < 50)
         { max = 50}
          return max;
      }

      function getflexGrow(input){
          var index = props.tableHead.indexOf(input);
          if(index == props.tableHead.length - 1){
            // console.log(input);
            return 1
         
          }
          return 0;
      }
    
    return ( 

    <View style = {{marginVertical: 3}}>
            <View style = {{flexDirection : 'row', marginVertical: 3}}>
              <ExportData data = {props.data} tableHead = {props.tableHead} tableName={props.tableName}></ExportData>
            </View>
            <View style={{width: '100%'}}>
                <Table
                        wordWrap="break-word"
                        loading={loading}
                        showHeader={showHeader}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        data={getData()}
                        bordered={false}
                        cellBordered={true}
                        autoHeight = {true}
                    >
                    {columns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Table.Column flexGrow={getflexGrow(column)} width = {getWidth(column)}>
                                <Table.HeaderCell>{column}</Table.HeaderCell>
                                <CustomCellView dataKey={column} />
                            </Table.Column>
                        );
                    })}
                    </Table>
                    {/* <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={props.data.length}
                        limitOptions={[12, 24]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                        locale={{
                            more: 'Daha',
                            prev: 'Önceki',
                            next: 'Sonraki',
                            first: 'İlk',
                            last: 'Son',
                            limit: '{0} / sayfa',
                            total: 'Toplam Satır: {0}',
                            skip: 'Git {0}'
                        }}
                        /> */}
            </View>
        </View>
    
  
    )
}

export default BasicTable;