import React, { useEffect, useImperativeHandle, useState } from 'react';
import { StyleSheet, Dimensions, View ,Image,FlatList,Text, ScrollView} from 'react-native';
import FileUploader from '../FileManagements/FileUploader';
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { Modal } from 'react-native-paper';
import axios from 'axios';
import { Button, Message, Stack, useToaster } from 'rsuite';
import { forwardRef } from 'react';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../CustomText';

// Amplify.configure(awsconfig);
Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});
const ImageLoader = forwardRef((props,ref) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
               {input}
            </Message>, { placement: 'topEnd' })
    }
    

    const fileTypes = ["JPG","JPEG", "PNG"];
    const [uploadProcessText, setUploadProcessText] =  useState("");
    const [toggleUploadProcess, setToggleUploadProcess] = useState(false);
    const [label,setLabel] = useState("DÖKÜMANI YÜKLE");
    const [refresh,setRefresh] = useState(true);
    // const [fileName,setFileName] =useState("");
    const [file,setFile] = useState(null);
    const [s3KEY,setS3Key] = useState(undefined);

    const [files, setFiles] = useState([]); // to store multiple files
    const [imageUrls, setImageUrls] = useState([]); 
    useImperativeHandle(ref, ()=>({
        load: (ObjectID) => {
            if(files.length > 0){
                files.forEach(file => {
                    loadFile(file,ObjectID,files.indexOf(file) + 1);
                }
            );}
            else{
                console.log("file is null");
            }
        }
    }));


    const DragDrop = () => {
        const handleChange = (newFile) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            // Use `reader.result` which contains a data URL of the file's content
            setImageUrls((prevImageUrls) => [...prevImageUrls, event.target.result]);
            setFiles((prevFiles) => [...prevFiles, newFile]);
            setLabel("Documents Ready to be Uploaded");
        };

    // Read the file as Data URL to create a preview
     reader.readAsDataURL(newFile);

        };
        return (
            <View style={{marginVertical: 20, alignItems: 'flex-start', marginHorizontal: 3}}>
                <FileUploader handleChange={handleChange} name="file" types={fileTypes} style={{width: '100%'}} />
            </View>
        
        );
    }

    const RenderUploadedImages = () => {
        return ( 
        <Stack wrap direction='row' spacing={20} style={{marginTop : 20}} >
            {imageUrls.map((url, index) => {
                return(
                <Stack.Item>
                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                        <Image key={index} source={{ uri: url }} style={{ width: 200, height: 200 }} />
                        <Button onClick={() => handleRemoveImage(index)}  style={{marginTop : 10, flex: 1, backgroundColor: '#FF4747'}}> <CustomText style={{color: 'white'}}> Sil </CustomText></Button>
                    </View>
                </Stack.Item>)
            })}
        </Stack>)

    };

    const handleRemoveImage = (index) => {
        const newImageUrls = [...imageUrls];
        newImageUrls.splice(index, 1);
        setImageUrls(newImageUrls);
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };


    const {fetchIdToken} = useUserToken();
    async function sendData (s3Key,fileName,ObjectID){
        const token = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        const headers = {
            "Authorization" : `Bearer ${token}`,
        }
        const body = {
            "ENV" :configdata.APP_ENV, 
            "type" : "FILE_SET",
            "category" : props.fileCategory,
            "FILE_NAME" : fileName,
            "S3_KEY" : s3Key,
            "CAR_ID": props.carID,
            "OBJECT_ID" :ObjectID
        }

        axios.post(api,body,{headers}
            ).then((response) => {
                console.log(response)
                if(response.data["errorMessage"] != null ){
                    messageToggle(fileName + " Dosya yüklenemedi","error")
                }else{
                    messageToggle(fileName +  " başarıyla yüklendi","success")
                    if(props.reset != null){
                        props.reset();
                    }
                }
              
            })
            .catch((error) => {
                console.log(error)
                messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin RDS","error")
            });
        
       
    }



    async function loadFile (file,ObjectID,counter){

        console.log(file);
        const name =  "" + file.name.toString();
        console.log(name.includes(".png"));
        var content_type = "application/pdf";
        var testCon = "";
        console.log(typeof(configdata.APP_ENV))
        if(configdata.APP_ENV == "DEV"){
            testCon = "TESTDB_"
        }
        let target_location = ""
        if(name.includes(".png")){
            target_location = testCon + "CAR_" + props.carID +"/"+props.fileCategory + "/PNGs/" + file.name
            content_type = "image/png";
        }
        if(name.includes(".jpeg")){
            target_location =testCon + "CAR_" +  props.carID +"/"+props.fileCategory + "/JPEGs/" + file.name
            content_type = "image/jpeg";
        }
        if(name.includes(".jpg")){
            target_location =testCon + "CAR_" +  props.carID +"/"+props.fileCategory + "/JPEGs/" + file.name
            content_type = "image/jpeg";
        }


        let newFileName = props.fileCategory + "_" + ObjectID + "_" + counter + "." + file.name.split('.')[1];
        target_location =  target_location.replace(file.name,newFileName);
         console.log(target_location);
         console.log(content_type);
        try {

            await Storage.put(target_location, file, { 
                contentType: content_type, // contentType is optional
                progressCallback(progress) {
                    setToggleUploadProcess(true);
                    let value =((progress.loaded/progress.total) * 100) | 0
                    console.log(`Uploaded:` + value.toString());
                    setUploadProcessText(value.toString());
                    
                    if(progress.loaded/progress.total == 1){
                        setToggleUploadProcess(false);
                    }
              },
            }).then((response) => {
                console.log(response);
                sendData(response.key,newFileName,ObjectID);
                // sendData(response.key,file.name);
            });
            // getList();
            setRefresh(!refresh);
          } catch (error) {
            messageToggle("Dosya yüklenemedi , bağlantınızı kontrol edin s3","error")
            console.log("Error uploading file: ", error);
            setUploadProcessText("Upload Not Completed : " + error);
          }
    }

    return (
      <View>
         <DragDrop></DragDrop>
         {
            imageUrls.length > 0 && <RenderUploadedImages></RenderUploadedImages>
         }
        
      </View>
    )
});
const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 7,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.10,
        shadowRadius: 4,
        elevation: 5
      },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height:'100%',
        margin: 25,
    },

    listItem:{
        backgroundColor: 'white',
        borderRadius: 7,
    },
    pdf: {
        flex:1,
        width:Dimensions.get('window').width,
        height:Dimensions.get('window').height,
    }
});

export default ImageLoader;