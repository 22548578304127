import { Text, View , StyleSheet} from 'react-native'
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Button, Form ,Nav ,MaskedInput,Modal,IconButton, DatePicker, Loader, Panel  } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-phone-input-2/lib/style.css'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import locale from '../../../tr';
import BasicTable from '../../../Components/BasicTable';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import AgreementCarsTable from '../../../Components/CarComponents/AgreementsCarTable';
import configdata from '../../../config/config.json';
import {useMessage} from '../../../hooks/useMessage';
import CustomText from '../../../Components/CustomText';

const AgreementInfoScreen = (props) =>  {
  
  const {messageToggle} = useMessage();

  
  const [attributesC,setAttributes] = useState()

  async function get_curent_user (){
      const authUser = await Auth.currentAuthenticatedUser({bypassCache: false});
      const { attributes } =  authUser;
      setAttributes(attributes);
      console.log(attributes["custom:rds_user_id"])
    }

  const [data,setData] = useState([])

  const [cars,setCars] = useState([])
  const [installments,setInstallments] = useState([])
  const [payments,setPayments] = useState([])
  const [active, setActive] = useState('TAKSİTLER');
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [paymentAmountLabel, setPaymentAmountLabel] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [allPayedAmount, setAllPayedAmount] = useState(0);
  const [allToPayedAmount, setAllToPayedAmount] = useState(0);
  const[choosen_s3KEY,setChoosenS3KEY] = useState("");
  const [PopupActive, setPopupActive] = useState(false);
  
  
  function viewDocument(input){
    console.log(JSON.parse(input))
    var dataS3 = JSON.parse(input).data
    console.log(dataS3);
    if( dataS3 != null){
     dataS3.sort(function(a, b) {
        return parseFloat(b.ID) - parseFloat(a.ID);
    });}
    console.log(dataS3[0].S3_KEY);
    setChoosenS3KEY(dataS3[0].S3_KEY);
    // console.log(dataS3[0].s3_KEY);
  }
  useEffect(()=> {
    if(choosen_s3KEY != ""){
      setPopupActive(true);
    }
  },[choosen_s3KEY])
  function handleModalClose(){
    setPopupActive(false);
}
  useEffect(()=> {

      console.log(props.data)
      getAggrement();
      getCars();
      getInstallments();
      getPayments();
      get_curent_user();
    
  },[])

  function refreshTable(){
    props.refreshTable();
    getAggrement();
    getCars();
    getInstallments();
    getPayments();
    setPaymentDate(new Date());
    setPaymentAmountLabel(0);
    setPaymentAmount(0);
  }
  useEffect(()=> {
   console.log(active);
  },[active])

  function getAggrement(){
    const api = configdata.API_BASE +'/rent-agreements';
    axios.post(api,{
      "ENV" : configdata.APP_ENV,
      "type" : "GET_CONTRACT_DETAILS",
      "CONTRACT_ID" : props.data["NO"]
    }).then((response) => {
      console.log("CONTRACT DATA");
      console.log(response.data[0]);
      setData(response.data[0]);
    }).catch((error)=>{
      console.log(error)
    })


  }

  function getCars(){
    const api = configdata.API_BASE +'/rent-agreements';
    axios.post(api,{
      "ENV" : configdata.APP_ENV,
      "type" : "GET_CONTRACT_DETAILS_CARS",
      "CONTRACT_ID" : props.data["NO"]
    }).then((response) => {

      setCars(response.data);
    }).catch((error)=>{
      console.log(error)
    })
  }

  function getInstallments(){
    const api = configdata.API_BASE +'/rent-agreements';
    axios.post(api,{
      "ENV" : configdata.APP_ENV,
      "type" : "GET_CONTRACT_DETAILS_INSTALLMENTS",
      "CONTRACT_ID" : props.data["NO"]
    }).then((response) => {

      var result = response.data;
      result.forEach(
        (element) => {element["KALAN TUTAR"]   = element["KDV DAHİL"] - element["ÖDENEN TUTAR"]}
      )
      setInstallments(result);
    }).catch((error)=>{
      console.log(error)
    })
  }
  function getPayments(){
    var paymentSum = 0;
    const api = configdata.API_BASE +'/rent-agreements';
    axios.post(api,{
      "ENV" : configdata.APP_ENV,
      "type" : "GET_CONTRACT_DETAILS_PAYMENTS",
      "CONTRACT_ID" : props.data["NO"]
    }).then((response) => {
      console.log(response.data);
      response.data.forEach((element) => {
        console.log(element);
        paymentSum += parseFloat(element["ÖDEME TUTARI"])
      })
      
      setPayments(response.data);
      setAllPayedAmount(paymentSum);
      setAllToPayedAmount(props.data["KDV DAHİL TUTAR"] - paymentSum )
    }).catch((error)=>{
      console.log(error)
    })
  }
  const numberMaskTL = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol : '.',
    suffix: ' TL' ,// This will put the dollar sign at the end, with a space.
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 5
  })
  function getFormattedDate(input){
    var dateN = new Date(input);
    return dateN.toLocaleDateString("en-GB")
  }

  var currency_symbol = "₺"
  var formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    maximumFractionDigits: 5,
  });



  function formatAmount(input){
    return formatter.format(input).replace("₺","₺ ")
  }


  const labelStyle = {
    standard: {width: 150},
    button: {
      textAlign: 'center', marginHorizontal: 6, backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
    },
    buttonWide: {
      textAlign: 'center', marginHorizontal: 6, backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 200
    },
  }

  function sendPaymentData(){
    const api = configdata.API_BASE +'/rent-agreements';
    const header= {
      "ENV" : configdata.APP_ENV,
      "type" : "SET_PAYMENTS",
      "CONTRACT_ID" : props.data["NO"],
      "AWS_USER_ID": attributesC["custom:rds_user_id"],
      "PAYMENT_DATE": paymentDate,
      "PAYMENT_AMOUNT": paymentAmount,
    }

    axios.post(api,header).then((response) => {
      console.log(response);
      if(response.errorMessage != null){
        messageToggle("Ödeme Bilgileri Eklenemedi","error")
      }else{
        messageToggle("Ödeme Bilgileri Eklendi","success")
        refreshTable();
       
      }
    }).catch((error) =>  {
      console.log(error);
      messageToggle("Ödeme Bilgileri Eklenemedi","error")
    })
    
  }



  function getCarIDS(){
    var carIDS = [];
    cars.forEach((element) => {
      carIDS.push(element["CAR_ID"])
    })
    return carIDS;
  }

  function ApproveContract(){
    setApproveLoader(true);
    const api = configdata.API_BASE +'/rent-agreements';
    const carIDS = getCarIDS();
    const header= {
      "ENV" : configdata.APP_ENV,
      "type" : "APPROVE_CONTRACT",
      "CONTRACT_ID" : props.data["NO"],
      "RENT_TYPE" : props.data["RENT_TYPE_ID"],
      "CARS": carIDS,
    }

    console.log(header);

    axios.post(api,header).then((response) => {
      console.log(response);
      if(response.errorMessage != null){
        messageToggle("Sözleşme Onaylanamadı","error")
      }else{
        messageToggle("Sözleşme Onaylandı","success")
        refreshTable();
        setApproveLoader(false);
       
      }
    }
    ).catch((error) =>  {
      console.log(error);
      messageToggle("Sözleşme Onaylanamadı","error")
    })

  }

  useEffect(()=> {
    console.log(sessionStorage.getItem('isAdmin'))
  },[])

  const [approveLoader, setApproveLoader] = useState(false);

    return (
      <View style={{width:'100%',padding : 10,  flex : 1, alignItems: 'center'}}>
        <Modal  overflow={true} size={'full'} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
                  <Modal.Header>
                    <Modal.Title> {data["SÖZLEŞME ADI"]} </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                      <FileViewer inline={true} s3KEY = {choosen_s3KEY}></FileViewer>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleModalClose} appearance="subtle">
                      Cancel
                    </Button>
                  </Modal.Footer>
        </Modal>
        <Panel shaded  style={{ marginTop: 20 ,width: '95%', backgroundColor: 'white'}}>

          {sessionStorage.getItem('isAdmin') == 'true' && 
            <View style={{width: '100%',flexDirection: 'row', justifyContent: 'flex-start', alignItems :'center'}}>
                <IconButton onClick={ApproveContract} icon= {<Icon><FontAwesomeIcon icon = {faCircleCheck}></FontAwesomeIcon></Icon>} > Sözleşmeyi Onayla </IconButton>
                { approveLoader &&
                   <Loader></Loader>

                }
            </View>
          }
          <InfoLabel layout='inline' header = {"Sözleşme Durumu"} value = {data["SÖZLEŞME DURUMU"]}></InfoLabel>

        </Panel>
        <Panel shaded  style={{ marginTop: 20 ,width: '95%', backgroundColor: 'white'}}>
          <View style={{width: '100%',flexDirection: 'row', justifyContent: 'flex-start', alignItems :'center'}}>
              <InfoLabel layout='inline' header = {"Müşteri Adı"} value = {data["MÜŞTERİ ADI"]}></InfoLabel>
              <InfoLabel layout='inline' header = {"Sözleşme Adı"} value = {data["SÖZLEŞME ADI"]}></InfoLabel>
              <InfoLabel layout='inline' header = {"Kira Tipi"} value = {data["KİRA TİPİ"]}></InfoLabel>
          </View>
          <View style={{marginVertical: 10,width: '100%',flexDirection: 'row', justifyContent: 'space-between', alignItems :'center'}}>
            <InfoLabel  layout='inline' header = {"Sözleşme No"} value = {data["NO"]}></InfoLabel>
            <InfoLabel layout='inline' header = {"Başlangıç Tarihi"} value = {getFormattedDate(data["BAŞLANGIÇ TARİHİ"])}></InfoLabel>
            <InfoLabel layout='inline' header = {"Bitiş Tarihi"} value = {getFormattedDate(data["BİTİŞ TARİHİ"])}></InfoLabel>
          </View>
          <IconButton style={{backgroundColor: 'white'}} icon= {<Icon><FontAwesomeIcon icon={JSON.parse(data["S3 KEYS"] != null && data["S3 KEYS"] != undefined)  ? "file-alt" : "file"} /></Icon>} onClick = {() => {viewDocument(props.data["S3 KEYS"])}}> Sözleşme Dökümanı Görüntüle</IconButton>
        </Panel>
      
        <Panel shaded  style={{ marginTop: 20 ,width: '95%', backgroundColor: 'white'}}>
            <View style={{ marginVertical: 10 , width: '95%'}}>
              <Navbar appearance="tabs" active={active} onSelect={setActive} />
              <View style={{width: '100%'}}>
               {active == 'TAKSİTLER' &&
               <View style= {{width: '100%',justifyContent: 'space-evenly'}}>
                <View style={{flexDirection: 'row',justifyContent: 'space-evenly'}}>
                <View style={{justifyContent: 'space-between',height: 100}}>
                    <InfoLabel style={{width: 300}} layout='inline' header = {"Taksit Sayısı"} value = {data["TAKSİT SAYISI"]}></InfoLabel>
                    <InfoLabel style={{width: 300}} layout='inline' header = {"İlk Taksit Tarihi"} value = {getFormattedDate(data["İLK TAKSİT TARİHİ"])}></InfoLabel>
                  </View>
                  <View style={{justifyContent: 'space-between'}}>
                    <InfoLabel style={{width: 300}} layout='inline' header = {"Toplam KDV Hariç Tutar"} value = {formatAmount(data["KDV HARİÇ TUTAR"])}></InfoLabel>
                    <InfoLabel style={{width: 300}} layout='inline' header = {"Toplam KDV Dahil Tutar"} value = {formatAmount(data["KDV DAHİL TUTAR"])}></InfoLabel>
                  </View>
                  <View style={{justifyContent: 'space-between'}}>
                    <InfoLabel style={{width: 300}} layout='inline' header = {"Ödenen Tutar"} value = {formatAmount(allPayedAmount)}></InfoLabel>
                    <InfoLabel style={{width: 300}} layout='inline' header = {"Kalan Tutar"} value = {formatAmount(allToPayedAmount)}></InfoLabel>
                  </View>
                  
                </View>
                  
                  <BasicTable tableName = {data["TAKSİT"] + " -- KiraSözleşmesiTaksitler "} tableHead={["TAKSİT" , "ÖDEME TARİHİ", "KDV HARİÇ", "KDV DAHİL","ÖDENEN TUTAR","KALAN TUTAR","DURUM" ]}  data={installments}> </BasicTable>
               </View>
               }
               {active == 'ÖDEMELER' &&
                <View>
                  <BasicTable tableName = {data["NO"] + " -- KiraSözleşmesiÖdemeler "} tableHead={["NO" , "ÖDEME TARİHİ", "ÖDEME TUTARI" ]}  data={payments}> </BasicTable>
                  <View style={{ alignItems: 'center' , justifyContent: 'space-evenly' , marginTop: 20 ,width: '95%'}}>
                    <Form layout='inline'>
                            <Form.Group>
                              <Form.ControlLabel> Ödeme Miktarı </Form.ControlLabel>
                              <Form.Control style={labelStyle.standard}  name = "paymentAmt"  accepter={MaskedInput}
                                  guide= {false}
                                  mask={numberMaskTL}
                                  value = {paymentAmountLabel} 
                                  onChange={(value) => {
                                    setPaymentAmount(parseFloat(value.replace(" TL","").replaceAll(".","").replace(',','.')))
                                    setPaymentAmountLabel(value)}}></Form.Control> 
                            </Form.Group>
                            <Form.Group>
                              <Form.ControlLabel> Ödeme Tarihi </Form.ControlLabel>
                              <DatePicker
                              style={labelStyle.standard} isoWeek = {true} onSelect={(value) =>
                                    {setPaymentDate(value);}} format="dd-MM-yyyy"
                                    locale={locale} oneTap
                                    value={paymentDate}
                                    disabledDate = {(date) => { return date >= new Date()}}
                            ></DatePicker>
                            </Form.Group>
                            <Form.Group>
                             <Button style={labelStyle.button} onClick={() => {sendPaymentData();}}>
                              KAYDET 
                             </Button>
                            </Form.Group>
                      </Form>
                  </View>
                </View>
                }
                {active == 'ARAÇLAR' &&   <AgreementCarsTable tableName = {data["NO"] + " -- KiraSözleşmesiAraçları "} tableHead={["NO" , "PLAKA", "ARAÇ","PERİYOD" ,"KDV HARİÇ TUTAR","KDV DAHİL TUTAR","TOPLAM KDV HARİÇ TUTAR","TOPLAM KDV DAHİL TUTAR" ]}  data={cars}> </AgreementCarsTable> }
              </View>
            </View>
           
        </Panel>
      </View>
    )
  
}

const InfoLabel = (props) => {
    return(
      <View style={{flexDirection: props.layout == 'inline' ? 'row' : 'column', flex: 1, alignItems: 'center' ,justifyContent: 'space-between'}}>
          <View>
              <CustomText fontWeight = {800} > {props.header + " :"}</CustomText>
          </View>
          <View style={{padding : 10 , backgroundColor : 'white', borderRadius: 5,flex:1}}>
            <CustomText>{props.value}</CustomText>
          </View>
          
      </View>
    )
}


const Navbar = ({ active, onSelect, ...props }) => {
  return (
    <Nav justified {...props} activeKey={active} onSelect={onSelect} style={{ backgroundColor: '#fcfdff', marginBottom: 20 }}>
      <Nav.Item eventKey="TAKSİTLER"> <CustomText> Taksitler </CustomText></Nav.Item>
      <Nav.Item eventKey="ÖDEMELER"><CustomText> Ödemeler </CustomText></Nav.Item>
      <Nav.Item eventKey="ARAÇLAR"><CustomText> Araçlar </CustomText></Nav.Item>
    </Nav>
  );
};

const styles = StyleSheet.create({
  cardView: 
    { alignItems: 'center' , justifyContent: 'space-evenly' , marginTop: 20 ,width: '95%', backgroundColor: 'white', borderRadius: 10, padding: 10, shadowColor: "powderblue",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.4,
    shadowRadius: 12,
    elevation: 5,}

}
)
export default AgreementInfoScreen;