  
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { View } from 'react-native';
import { RangeSlider ,Form} from 'rsuite'; 

const SliderFilter = forwardRef((props,ref) => {

    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      filter: () => {
        if(value != null || value != "" || value != minmax ){
          props.refresh();
          return filter_data_multiple();
        }
      },
      clear: () => {
        getMinMax(props.data);
        //setValue(minmax);
      },
      types: () => {
      }
      
    }))

    const[value,setValue] = useState(minmax);

    useEffect(()=> {
      getMinMax(props.data);
    },[])
    useEffect(() => {

      if((value == null || value == "" || value == minmax ) && props.isFilterApplied){
          props.reset();
          props.filter();
      }if((value != null && value != "" && value != minmax )){
          // filter_data_multiple();
          props.filter();
      }
    },[value])


    const [minmax,setMinMax] = useState([]); 
    
    function getMinMax(input){
      var _array = [];
      input.forEach(element => {
          if(_array.length == 0){
              _array.push(parseInt(element[props.tableHeadName]))
          }else{
              if(!_array.includes(parseInt(element[props.tableHeadName]))){
                  _array.push(parseInt(element[props.tableHeadName]));
              }
          }
      })
      // console.log(props.tableHeadName)
      _array.sort((a, b) => a - b)
      // console.log(_array);
      var result = [];
      result.push(_array[0]);
      result.push( _array[_array.length -1]);
      
      if(_array[0] ==_array[_array.length -1]){
        result=[_array[0],_array[0]+1]
      }

      if(isNaN(_array[0]) && isNaN(_array[_array.length -1]) ){
        result= [0,1]
      }
      if(!isNaN(_array[0]) && isNaN(_array[_array.length -1]) ){
        result= [_array[0] - 1,_array[0] + 1]
      }
      if(isNaN(_array[0]) && !isNaN(_array[_array.length -1]) ){
        result= [_array[_array.length -1] - 1,_array[_array.length -1] + 1]
      }
      setMinMax(result);
      return result;
    }
    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => checkContains(item,e)))
    }

    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }

    const filter_data_multiple = () =>{
        var columns = []
        var inputs = [];
        if(value != "" && value != null && value.length > 0){
          columns.push(props.tableHeadName)
          inputs.push(value);
        }
    
        if(columns.length > 0){
            let temps = []
            for(let c = 0 ; c< columns.length; c++){
              let temp = [];
                for(let i = 0; i< props.data.length ; i++){
                  let choosen = props.data[i][columns[c]];
                  if(choosen <= value[1] && choosen >= value[0] ){
                      temp.push(props.data[i]);
                }
                }
            //console.log(" temp " + c +temp);
            temps.push(temp);
            }
          // console.log(temps);
            let first =  temps.shift();
            let final = intersect(first,temps);
            // console.log("final" + final);
            
            // props.setFiltered(final);
            // props.setApplied(true);
            return final
        }
        return props.data
    }

    const item_style = {
      standard: { width : 200, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }

    return (
      <View style={{marginVertical: 10}}>
      <Form>
        <Form.Group>
            <Form.ControlLabel> {props.tableHeadName} </Form.ControlLabel>
           { minmax.length > 0 &&   <RangeSlider value={value}   style={props.item_style} min = {minmax[0]} max = {minmax[1]}
                      defaultValue = {minmax} onChange={(range) => {
                        var result = [];
                        result.push(range[0]);
                        result.push(range[range.length - 1]);
                        setValue(result);
                      }}/>}
        </Form.Group>
      </Form>
    </View>
    )


  })

  export default SliderFilter;