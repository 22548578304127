  
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { View } from 'react-native';
import { MultiCascader ,Form} from 'rsuite'; 
import configdata from '../../../config/config.json';
import axios from 'axios';


const BMPFilter = forwardRef((props,ref) => {


    const[BMPoptions,setBMPoptions] = useState([]);
    const[BMP,setBMP] = useState([]);
    const[refresh,setRefresh] = useState(false);

    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      filter: () => {
        if(BMP != null || BMP != undefined || BMP.length != 0  ){
          props.refresh();
          return filter_data_multiple();
         
        }
      },
      clear: () => {
        setBMP([]);
        setRefresh(!refresh);
        
      }
      
    }))


    useEffect(()=> {
      getBrands();
    },[])
    useEffect(() => {
      console.log(BMP);
      if((BMP == null || BMP.length == 0  ) && props.isFilterApplied){
          props.reset();
          //filter_data_multiple();
      }if((BMP != null && BMP.length != 0  ) ){
          // filter_data_multiple();
          props.filter();
      }
    },[BMP])


    function parseBrands(input){
      var result= [];
      var Brands = [];
      input.forEach(element => {
        if(element.LEVEL == "1"){
          Brands.push( {"label": element.İSİM, "id" : element.ID ,"value":{"level" : 1 , "id" : element.ID, "name" : element.İSİM }  ,"children": []  })
        }
      });
      input.forEach(element => {
        if(element.LEVEL == "2"){
          Brands.find(brand => brand.id == element.PARENT_ID).children.push({"label": element.İSİM,"id" : element.ID, "value":{"level" : 2 , "id" : element.ID, "name" : element.İSİM } ,"children": [] })
        }
      });

      input.forEach(element => {
        if(element.LEVEL == "3"){
          Brands.forEach(brand => {
           // console.log(brand.children.length);
            brand.children.forEach(model => 
              { 
                if(model.id == element.PARENT_ID){
                 // console.log(model.children);
                  model.children.push({"label": element.İSİM, "id": element.ID, "value":{"level" : 3 , "id" : element.ID, "name" : element.İSİM } })
              }}
            )
          })
        }
      });

      Brands.forEach(element => {
        result.push(element);
      })

      console.log(result);
      // result.push(Brands[0]);
      // result.push(Brands[1]);
      // console.log(Brands[0]);
      setBMPoptions(result);
    }
    function getBrands(){
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,
          "type" : "GET_BRAND_TREE",
          
      })
      .then((response) => {
          console.log(response);
          console.log(response.data);
          parseBrands(response.data);
      })
      .catch((error) => {
          console.log(error);
          
      });
    }

    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => !checkContains(item,e)))
    }
    function sum (first, rest) {    
      return first.filter(e => rest.every(item => checkContains(item,e)))
    }
    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }

    function getSelectedWithlevel(level){
      var result = [];
      BMP.forEach(element => {
        if(element.level == level){
          result.push(element.name);
        }
      })
      return result;
    }

    const filter_data_multiple = () =>{

      if(BMP.length > 0  && BMP != null){
        var arr1 = filter_data_multipleMarka();
        var arr2 = filter_data_multipleModel();
        var arr3 = filter_data_multiplePackage();
        // var arr4 = sum(arr1,[arr2,arr3])
        var arr4 = [...arr1,...arr2,...arr3]
        console.log(arr1)
        console.log(arr2)
        console.log(arr3)
        console.log(arr4)
        return arr4
       
      }
       return props.data
    }

    const filter_data_multipleMarka = () =>{

      
        console.log("FİLTER ---------- MARKA ------------------");
        var columns = ["MARKA"]
        var inputs = getSelectedWithlevel(1);
        console.log(inputs);
        if(inputs.length > 0){
            let temp = [];
              for(let i = 0; i< props.data.length ; i++){
                  
                  let tempstr = String(props.data[i]["MARKA"])
                  tempstr = tempstr.toLowerCase();
                  // console.log(tempstr);
                  inputs.forEach(element => {
                    // console.log(element);
                    var temp_element = element.toLowerCase();
                    // console.log(temp_element);
                      if(tempstr.includes(temp_element)){
                          temp.push(props.data[i]);
                          // console.log(true);
                      }
                  });
              
              }
          //console.log(" temp " + c +temp);
        // console.log(temps);
          // let first =  temps.shift();
          // let final = intersect(first,temps);
          var final = temp
          console.log(final);
          // props.setFiltered(final);
          // props.setApplied(true);
          return final
      }
    return []

}
    const filter_data_multipleModel = () =>{

      
        console.log("FİLTER ----------------------------");
        var columns = ["MODEL"]
        var inputs = getSelectedWithlevel(2);;
        console.log(inputs);
        
       
        if(inputs.length > 0){
          let temps = []
          for(let c = 0 ; c< columns.length; c++){
            let temp = [];
              for(let i = 0; i< props.data.length ; i++){
              
                  let tempstr = String(props.data[i][columns[c]])
                  tempstr = tempstr.toLowerCase();
                  inputs.forEach(element => {
                    var temp_element = String(element).toLowerCase();
                      if(tempstr.includes(temp_element)){
                      temp.push(props.data[i]);
                    }
                  });
              
              }
          //console.log(" temp " + c +temp);
          temps.push(temp);
          }
        // console.log(temps);
          let first =  temps.shift();
          let final = intersect(first,temps);
          
          
          // props.setFiltered(final);
          // props.setApplied(true);
          return final
      }
    return []

}
    const filter_data_multiplePackage = () =>{

     
        console.log("FİLTER ----------------------------");
        var columns = ["PAKET"]
        var inputs = getSelectedWithlevel(3);;
        console.log(inputs);
        
       
        if(inputs.length > 0){
          let temps = []
          for(let c = 0 ; c< columns.length; c++){
            let temp = [];
              for(let i = 0; i< props.data.length ; i++){
              
                  let tempstr = String(props.data[i][columns[c]])
                  tempstr = tempstr.toLowerCase();
                  inputs.forEach(element => {
                    var temp_element = String(element).toLowerCase();
                      if(tempstr.includes(temp_element)){
                      temp.push(props.data[i]);
                    }
                  });
              
              }
          //console.log(" temp " + c +temp);
          temps.push(temp);
          }
        // console.log(temps);
          let first =  temps.shift();
          let final = intersect(first,temps);
         
          
        // props.setFiltered(final);
          // props.setApplied(true);
          return final
      }
    return []

}

    const item_style = {
      standard: { width : 200, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
  }
useEffect(()=> {
  console.log(BMP);
},[BMP]);


    return (
      <View style={{marginVertical: 10}}>
        <Form >
          <Form.Group>
              <Form.ControlLabel> MARKA MODEL DONANIM </Form.ControlLabel>
              <MultiCascader 
                cleanable={true}
                  style={props.item_style.standard}
                  value = {BMP}
                  data={BMPoptions}
                  onChange= {(value) => setBMP(value) }
                  onClean = {() => {
                    if(props.isFilterApplied){
                      props.reset();
                      // filter_data_multiple();
                    }
                    }}
                  cascade = {false}
                  // onSelect= {setBMP}
                 
              />
          </Form.Group>
        </Form>
    </View>
    )

  })

  export default BMPFilter;