import { Text, View } from 'react-native'
import React, { PureComponent, useState , useEffect } from 'react'
import {Panel,Button,Form,FormGroup,FormControl,ControlLabel,SelectPicker,IconButton,Message,Input,TagPicker, InputNumber, Loader} from 'rsuite';
import axios from 'axios';
import configdata from "../../config/config.json";
import { useUserToken } from '../../hooks/useUserToken';
import { useMessage } from '../../hooks/useMessage';
import CustomText from '../../Components/CustomText';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const  NewRequestScreen = (props) => {


    const [userList, setUserList] = useState([]);
    const [reqHeader, setReqHeader] = useState("");
    const [reqDetail, setReqDetail] = useState("");
    const [reqStatus, setReqStatus] = useState("");
    const [reqType, setReqType] = useState("");
    const [reqTargets, setReqTargets] = useState([]);
    const [target, setTarget] = useState("");
    const [reqRepeatTime, setReqRepeatTime] = useState("");
    const [reqRepeatType, setReqRepeatType] = useState(1);
    const [repeatTypes, setRepeatTypes] = useState([
        {label: 'Saatlik', value: 0},
        {label: 'Günlük', value: 1},
        {label: 'Haftalık', value: 2},
      ]);
    
    const {fetchIdToken,fetchUserID} = useUserToken();
    const getUsers = async () => {
        const api = configdata.API_BASE + '/userdata';
        let token = await fetchIdToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        }

        axios.post(api,{
            "type" : "GET_USERS_BASIC",
        },{headers: headers}).then((response) => {
            console.log(response);
            let temp = [];
            response.data.forEach(element => {
                temp.push({label: element.NAME + " " + element.SURNAME + " ( " + element.EMAIL + " ) ", value: element.ID , department: element.DEPARTMENT_NAME, email: element.EMAIL});
            });
            setUserList(temp);
        })

        .catch((error) => {
            console.log("POST ERROR" + error);
        }
        );
    }

    useEffect(() => {
        getUsers();
    }
    ,[]);

    useEffect(() => {
        console.log(target);
    }
    ,[target]);

    const {messageToggle} = useMessage();

    const [waitSaveResponse, setWaitSaveResponse] = useState(false);

    async function saveRequest() {
        setWaitSaveResponse(true);
        const api = configdata.API_BASE + '/requests';
        let token = await fetchIdToken();
        let userID = await fetchUserID();
        const headers = {
            'Authorization': `Bearer ${token}`
        }

        const data = {
            "type" : "ADD_REQUEST",
            "ENV" :configdata.APP_ENV ,  
            "CREATE_USER" : userID,
            "HEADER" : reqHeader,
            "DESCRIPTION" : reqDetail,
            "STATUS" : 0,
            "TYPE" : reqType,
            "TARGETS" : reqTargets,
            "REPEAT_INTERVAL_TIME" : reqRepeatTime,
            "REPEAT_INTERVAL_TYPE" : reqRepeatType,
            "targetID" : target.value,
            "mailto": target.email
        }
        console.log(data);
        axios.post(api,data,{headers: headers}).then((response) => {
            console.log(response);
            if(response.data == "SUCCESS"){
                messageToggle("İstek başarıyla kaydedildi","success");
                setWaitSaveResponse(false);
                props.getRequests();
            }
            else{
                messageToggle("error","İstek kaydedilemedi","error");
                setWaitSaveResponse(false);
            }

        })
        .catch((error) => {
            console.log("POST ERROR" + error);
            setWaitSaveResponse(false);
            messageToggle("İstek kaydedilemedi","error")
        }
        );
    }

    return (
      <View>
      
            <Panel style={{margin: 3, backgroundColor:'white'}}>

                <View style={{padding: 10}}>
                    <Group header="İstek Başlığı">
                        <Input value={reqHeader} onChange={value => setReqHeader(value)} style={styles.standard} />
                    </Group>
                    <Group header="İstek Detayı">
                        <Input as='textarea' value={reqDetail} onChange={value => setReqDetail(value)} style={styles.standard} />
                    </Group>
                    <Group header="İstek Hedefi">
                        <SelectPicker data={userList} value={target.value} onSelect={(value,item) => setTarget(item)} style={styles.standard} groupBy='department' />
                    </Group>
                    <Group header="CC">
                        <TagPicker data={userList} value={reqTargets} onChange={(value,item) => setReqTargets(value)} style={styles.standard} groupBy='department' />
                    </Group>
                    <Group header="İstek Tekrar Tipi">
                        <SelectPicker data={repeatTypes} value={reqRepeatType} onChange={(value,item) => setReqRepeatType(value)} style={styles.standard} />
                    </Group>
                    <Group header="İstek Tekrar Zamanı">
                        <InputNumber value={reqRepeatTime} onChange={value => setReqRepeatTime(value)} style={styles.standard} />
                    </Group>
                    <CustomText fontSize = {12}  style={{fontStyle: 'italic'}} > İsteğin hangi aralıkla tekrarlanacağını belirler. Örneğin saatlik ve 1 girilirse istek her saat başı gönderilir. </CustomText>
                    <CustomText fontSize = {12}  style={{fontStyle: 'italic'}} >* İstek tekrar tipi ve zamanı boş bırakılırsa istek sadece bir defa gönderilir.</CustomText>
                </View>
                {
                    waitSaveResponse ? <Loader content="İstek Kaydediliyor..." vertical /> :
                <View style={{flex: 1 , justifyContent :'center', width: '100%' , alignItems: 'flex-end',padding: 10}}>
                <IconButton style={{width: 150}} icon={<Icon><FontAwesomeIcon icon = {"plus"}></FontAwesomeIcon> </Icon>} onClick={() => {saveRequest()}}> <CustomText> Kaydet </CustomText></IconButton>
                </View>
                }

            </Panel>
      </View>

    )
  }

  const Group = ({ children, ...props }) => (
    <View {...props} style={styles.group}>
      <View style = {{ marginRight: 5 }}>
        <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
      </View>
      <View style={{flex: 1, alignItems: 'flex-end'}}>
        {children}
      </View>
    </View>
  );

    const styles = {
        group: {
            width: '100%',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginVertical: 3,
        },
        standard: { maxWidth: 350, minWidth: 150, width: '100%'},

      };
  

export default NewRequestScreen