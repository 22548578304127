import { Text, View } from 'react-native'
import React, { PureComponent, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useDropdownData } from '../../hooks/useDropdownData'
import { useMessage } from '../../hooks/useMessage'
import { useUserToken } from '../../hooks/useUserToken'
import { Divider, Input , Panel, SelectPicker } from 'rsuite'
import CustomText from '../../Components/CustomText'
import configdata from '../../config/config.json'

import axios from 'axios'

const CarDeliveryInfoTab = forwardRef((props,ref) => {


    useImperativeHandle(ref, () => ({
        getInfo : () => {
            return {
                carModel,
                motor,
                chassisNo,
                stepne,
                customerName,
            }
        }
    }))

    const {fetchIdToken} = useUserToken()
    async function getCarPortfoy (){

        const api = configdata.API_BASE + '/sales-visitors'
        const token = await fetchIdToken()
        const headers = {
            'Authorization': 'Bearer ' + token
        }
        const body = {
            "type" : "GET_PORTFOY_CARS"
        }
        axios.post(api, body, {headers: headers})
        .then(res => {
            let result = [];
            res.data.forEach(element => {
                result.push({
                    label: element.CAR,
                    value: element.CAR
                })
            }
            )
            setModels(result)
        })
        .catch(err => {
            console.log(err)
        })
        
    }
    const [models, setModels] = useState([])

    const [carModel, setCarModel] = useState('')
    const [motor, setMotor] = useState('')
    const [chassisNo, setChassisNo] = useState('')
    const [plate, setPlate] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [stepne_tamir, setStepneTamir] = useState([
        {label: 'Stepne', value: 'Stepne'},
        {label: 'Tamir Kiti', value: 'Tamir Kiti'},
    ])
    const [stepne, setStepne] = useState('')


    useEffect(() => {
        getCarPortfoy()
    }, [])

    return (
      <View>
        <Panel header="Araç Bilgileri" shaded style={{marginBottom: 10, backgroundColor : '#fff', margin: 20}}>
            <SelectPicker data={models} placeholder="Model"  onChange={(value) => setCarModel(value)}  style={styles.standard}></SelectPicker>
            <Input placeholder="Motor" onChange={(value) => setMotor(value)} style={styles.standard} />
            <Input placeholder="Şasi No" onChange={(value) => setChassisNo(value)} style={styles.standard} />
            <SelectPicker data={stepne_tamir}  onChange={(value) => setStepne(value)}  style={styles.standard}></SelectPicker>
            <Input  placeholder="Müşteri" onChange={(value) => setCustomerName(value)} style={styles.standard} />

        </Panel>

      </View>
    )
  
});

export default CarDeliveryInfoTab

const styles = {
    standard : {
        marginBottom : 10,
        width : '90%'
    }
}