import { useState } from 'react';

const useTable = (initialSortColumn = false, initialSortType = true) => {
    const [loading, setLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState(initialSortColumn);
    const [sortType, setSortType] = useState(initialSortType);

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            console.log(sortColumn);
            console.log(sortType)
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    return {
        loading,
        sortColumn,
        sortType,
        handleSortColumn
    };
};

export default useTable;