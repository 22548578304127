import React, { useState ,useEffect, useRef} from 'react';
import { Button , Divider, Drawer, Input, InputGroup, List, Panel, SelectPicker, Stack  } from 'rsuite';
import { PDFDownloadLink , View, Text,Image , pdf, PDFViewer} from '@react-pdf/renderer';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import { Font  } from '@react-pdf/renderer';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../../Components/CustomText';
import SalesCarOfferDocWizard from './SalesCarOfferDocWizard';


const CarSalesOfferPDFCreator = () => {

    const [showPdf, setShowPdf] = useState(false);
    const [language, setLanguage] = useState('tr');
    const {fetchIdToken} = useUserToken();
    const [cars,setCars] = useState([]);

    const [signatures,setSignatures] = useState([
        {
            name: "Erdal Koluman",
            title: "Satış Danışmanı",
            mail: "erdal.koluman@bilenoto.com.tr",
            phone: "(533) 732 64 11",
            address1: "Mustafa Kemal, Hüseyin Okan Merzeci Blv ",
            address2: "No: 376, 33090 Toroslar/Mersin"
        },
    ]);
    const [selectedSignature,setSelectedSignature] = useState(        {
        name: "Erdal Koluman",
        title: "Satış Danışmanı",
        mail: "erdal.koluman@bilenoto.com.tr",
        phone: "(533) 732 64 11",
        address1: "Mustafa Kemal, Hüseyin Okan Merzeci Blv ",
        address2: "No: 376, 33090 Toroslar/Mersin"
    },);

    useEffect(() => {
        setSignatures([
            {
                name: "Erdal Koluman",
                title: "Satış Danışmanı",
                mail: "erdal.koluman@bilenoto.com.tr",
                phone: "(533) 732 64 11",
                address1: "Mustafa Kemal, Hüseyin Okan Merzeci Blv ",
                address2: "No: 376, 33090 Toroslar/Mersin"
            },
        ])
    },[])



    const refView = useRef();

    const getCar = () => {
        if(refView.current == undefined)
            return [];
        
        if(refView.current.getCar() == undefined)
            return [];

        return refView.current.getCar().Object;
    }

    const getCustomer = () => {
        if(refView.current == undefined)
            return [];
        return refView.current.getCustomer();
    }

    const getOffer = () => {
        if(refView.current == undefined)
            return [];
        return refView.current.getOffer();
    }

    const handleShowPdf = () => {
        setShowPdf(true);
    }

    const handleClosePdf = () => {
        setShowPdf(false);
    }



    return(
    <View style={{flex: 1, backgroundColor: '#F2F2F2', padding: 10}}>

        <SalesCarOfferDocWizard ref={refView} />

        <Drawer size="md" placement="left" open={showPdf} onClose={handleClosePdf}>
            <Drawer.Header>
                <Drawer.Title>PDF Dökümanı</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <MyDocument LANG={language} docNo={"000000"} car={getCar()} Customer={getCustomer()} offer={getOffer()} signature={selectedSignature}  />
                </PDFViewer>
            </Drawer.Body>
        </Drawer>
        <Panel shaded style={{ backgroundColor: 'white' , margin : 20}}>
                <Button onClick={handleShowPdf} appearance="primary"> Görüntüle </Button>
                <Divider></Divider>
                <Button > Kaydet </Button>
        </Panel>
    </View> 
    )

}

const MyDocument = (props) => (

    <Document>
        <Page size="A4" style={{flexDirection: 'column', flex: 1, backgroundColor: '#FF7982', alignItems:'center', position: 'relative'}}>

            <Image 
            src={require('../../../assets/ARJ_SALES_ASSETS/alfa_background.png')}
            style={{position: 'absolute', top: 200, left: 0, width: 595, height: 479, zIndex: -1}} 
            />
            <View style={{width: 500,height: 90 ,flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', paddingHorizontal: 20, paddingTop: 10}}>
                <Text style={styles.labelWhiteAlignedRight}>{"NO: " + "00000"}</Text>
                <View style={{height: 3, backgroundColor: 'white', width: 500}}></View>
            </View>
            <View style={{flex: 1, padding : 2}}>
                <View style={{width: 500, alignItems: 'flex-start', paddingTop: 20}}>
                    <Text style={{fontFamily: 'Artifakt-Black', fontSize: 42 , color: 'white' ,textAlign: 'left'}}>
                            {
                                props.LANG == "tr" ?  "ARAÇ SATIŞ \nTEKLİF DOSYASI"
                                : "SELLING OFFER \n FILE"
                            }
                    </Text>
                </View>
            </View>
            <View style={{backgroundColor : 'white', height : 3 , marginBottom: 15, width : 500}}></View>
            <Footer></Footer>
        </Page>

        <Page size="A4" style={{flexDirection: 'column', flex: 1, backgroundColor: 'white', alignItems:'center', position: 'relative'}}>
            <View style={{width: 500, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20, paddingTop: 20}}>

                <View style={{height: 10}}></View>
                <View style={{flexDirection: 'row', justifyContent: 'center', width: 500}}>
                        <Image source={require('../../../assets/ARJ_SALES_ASSETS/alfa_logo.png')} style={{ height: 100}} resizeMode='contain' />
                        <View style={{width: 40}}></View>
                        <Image source={props.offer.choosenImage} style={{ height: 100}} resizeMode='contain' />
                </View>
                <View style={{height: 30}}></View>
                <View style={{width: 500, backgroundColor: '#575757', height: 1}}></View>
                <Text style={{fontFamily: 'Artifakt-Black', fontSize: 14 , color: '#575757' ,textAlign: 'center'}}>
                    {
                        props.LANG == "tr" ?  "Teknik Özellikler"
                        : "Technical Specifications"
                    }
                </Text>
                <View style={{width: 500, backgroundColor: '#575757', height: 1}}></View>
                <View style={{height: 10}}></View>
                <Text style={{fontFamily: 'Artifakt-Bold', fontSize: 14 , color: '#575757' ,textAlign: 'left'}}>
                    { props.car.BRAND_NAME + " " + props.car.MODEL_NAME + " " + props.car.PACKAGE_NAME + " " + props.car.NAME_DETAIL}
                </Text>
                <View style={{height: 10}}></View>

            </View>
            <CarInfo car={props.car} LANG={props.LANG}></CarInfo>
            <CarPacakgeDetails PACKAGE_DETAILS={props.car.PACKAGE_DETAILS_ARR}></CarPacakgeDetails>
            <View style={{flex: 1, padding : 2}}></View>
            <Footer></Footer>
            </Page>
    </Document>
);

const CarInfo = (props) => {

    return (
        <View style={{width: 500, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
        <View style={{width: 250,  flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>

            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Motor Hacmi"
                    : "CC"
                }
            </Text>

            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Maksimum Güç"
                    : "HP"
                }
            </Text>
            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Maksimum Tork"
                    : "Maximum Torque"
                }
            </Text>
            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Maksimum Hız"
                    : "MAX SPEED"
                }
            </Text>
        </View>
        <View style={{width: 10}}></View>
        <View style={{ width: 250,  flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>

            <Text style={styles.label2}>
                {`${props.car.CC} cm\u00B3`}
            </Text>
            <Text style={styles.label2}>
                {
                    props.car.HP + "hp"
                }
            </Text>
            <Text style={styles.label2}>
                {
                    props.car.TORK + " Nm"
                }
            </Text>
            <Text style={styles.label2}>
                {
                    props.car.MAX_SPEED + " km/h"
                }
            </Text>

        </View>
        <View style={{width: 250,  flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Yakıt"
                    : "Fuel"
                }
            </Text>

            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Vites"
                    : "Transmission"
                }
            </Text>
            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Hızlanma"
                    : "Acceleration"
                }
            </Text>
            <Text style={styles.header2}>
                {
                    props.LANG == "tr" ?  "Ort. Yakıt Tüketimi"
                    : "Avg Fuel Consumption"
                }
            </Text>
            </View>
            <View style={{width: 10}}></View>
            <View style={{ width: 250,  flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>

            <Text style={styles.label2}>
                {
                    props.car.FUEL
                }
            </Text>
            <Text style={styles.label2}>
                {
                    props.car.TRANSMISSION
                }
            </Text>
            <Text style={styles.label2}>
                {
                    props.car.ACCELERATION + " sn 0-100 km/h"
                }
            </Text>
            <Text style={styles.label2}>
                {
                    props.car.AV_FUEL_CONS + " lt/100km"
                }
            </Text>

            </View>
    </View>
    )
}

const CarPacakgeDetails = (props) => {


    return (
        <View style={{width: 500, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
            <View style={{height: 30}}></View>
            <View style={{width: 500, backgroundColor: '#575757', height: 1}}></View>
            <Text style={styles.header2}>
                Ekstra Donanımlar
            </Text>
            <View style={{width: 500, backgroundColor: '#575757', height: 1}}></View>
            <View style={{height: 10}}></View>
            <View style={{width: 500, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                {
                    props.PACKAGE_DETAILS &&
                    props.PACKAGE_DETAILS.map((packageItem) => {
                        return (
                            <View style={{width: 500 , flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                <Text style={{fontFamily: 'Artifakt-Bold', fontSize: 9 , color: '#575757' ,textAlign: 'left'}}>
                                    {packageItem}
                                </Text>
                            </View>
                        )
                    }
                    )
                }
            </View>
        
        </View>

    )
}

const Header = () => {
    return (
        <View style={{width: 500, height: 80, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 20, backgroundColor: 'white'}}>

        </View>
    )
}






// the seller who prepared the offer
const SignatureLabel = (props) => {

    const signatureStyle = {
        name: {
            fontFamily: 'Artifakt-Bold',
            fontSize: 12,
            textAlign: 'left',
            color: '#575757',
        },
        title: {
            fontFamily: 'Artifakt-Bold',
            fontSize: 10,
            textAlign: 'left',
            color: '#575757',
        },
        standard: { 
            fontFamily: 'Artifakt',
            fontSize: 8,
            textAlign: 'left',
            color: '#575757',
        },
        
    }

    function translateTitle(title) {
        if(props.lang == "en"){
            if(title == "Satış Müdürü"){
                return "Sales Manager";
            }
            else if(title == "Genel Müdür"){
                return "General Manager";
            }
            else if(title == "Satış Danışmanı"){
                return "Sales Consultant";
            }
            else{
                return title;
            }
        }
        else{
            return title;
        }
    }

    return (
        <View style = {{flexDirection: 'row' , alignItems: 'center'}}>
            <View style={{width: 100, height: 100, justifyContent: 'center', alignItems: 'flex-end'}}>
                <Image source={require('../../../assets/ARJ_SALES_ASSETS/person.png')} style={{width: 22, height: 25}} resizeMode='contain' />
            </View>
            <View style={{width: 10}}></View>
            <View style={{ width: 1, height: 30, backgroundColor: '#575757'}}></View>
            <View style={{width: 10}}></View>
            <View style = {{justifyContent: 'space-between', alignItems: 'flex-start'}}>  
                <Text style={signatureStyle.name}> {props.signature.name} </Text>
                <Text style={signatureStyle.title}> {translateTitle(props.signature.title)} </Text>
                <Text style={signatureStyle.standard}> {props.signature.mail} </Text>
                <Text style={signatureStyle.standard}> {props.signature.phone} </Text>
                <Text style={signatureStyle.standard}> {props.signature.address1} </Text>
                <Text style={signatureStyle.standard}> {props.signature.address2} </Text>
            </View>
        </View>
    )
}



const Footer = () => {
    return (
        <View style={{ borderTopWidth : 3 , borderTopColor : '#575757'}}>
            <View style={{width: 595, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 20, backgroundColor: 'white'}}>
                <View style={{height: 80, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 18}}> Bilen Otomotiv </Text>
                    {/* <View style={{height: 5}}></View> */}
                    {/* <View style={{height: 1, backgroundColor: '#575757', width: 100}}></View> */}
                    <View style={{height: 7}}></View>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <Image source={require('../../../assets/ARJ_SALES_ASSETS/alfa_logo.png')} style={{width: 15, height: 15}} resizeMode='contain' />
                        <View style={{width: 10}}></View>
                        <Image source={require('../../../assets/ARJ_SALES_ASSETS/jeep_logo.png')} style={{height: 15, width: 37}} resizeMode='contain' />
                    </View>
                </View>
                <View style={{height: 80, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <View style={{width: 20 , height: 80, justifyContent: 'center', alignItems: 'center'}}>
                        <View style={{height: 5}}></View>
                        <Image source={require('../../../assets/ARJ_SALES_ASSETS/location_logo.png')} style={{width: 12, height: 12}} resizeMode='contain' />
                        <View style={{height: 18}}></View>
                        <Image source={require('../../../assets/ARJ_SALES_ASSETS/phone_logo.png')} style={{width: 12, height: 12}} resizeMode='contain' />
                    </View>
                    <View style={{width: 8}}></View>
                    <View style={{height: 80, justifyContent: 'center', alignItems: 'flex-start'}}>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}>{"Zeytinli, Turhan Cemal Beriker Blv. \nNo:808/A, 01355 Seyhan/Adana\n"} </Text>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}> {"(0322) 545 00 10"} </Text>
                    </View>
                    <View style={{width : 10 }}></View>
                    <View style={{height: 20, width: 1, backgroundColor: '#575757'}}></View>
                    <View style={{width : 10 }}></View>
                    <View style={{height: 80, justifyContent: 'center', alignItems: 'flex-start'}}>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}>{"Mustafa Kemal, Hüseyin Okan Merzeci Blv\nNo: 376, 33090 Toroslar/Mersin\n"} </Text>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}> {"(0324) 545 00 10"} </Text>
                    </View>
                    <View style={{width : 20 }}></View>
                </View>
            </View>
            {/* Page Number*/}
            <Text style={{position: 'absolute', bottom: 10, right: 10, width: 15 ,textAlign: 'center', borderLeftWidth: 1 , paddingLeft: 3 ,color: '#003D84', fontFamily: 'Artifakt-Bold', fontSize: 10,}}>{1}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        padding: 10,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    labelWhiteAlignedRight: {
        fontFamily: 'Artifakt-Black',
        fontSize: 12,
        color: 'white',
        textAlign: 'right',
    },
    header2: {
        fontFamily: 'Artifakt-Bold', fontSize: 12 , color: '#575757' ,textAlign: 'left'
    },
    label2: {
        fontFamily: 'Artifakt', fontSize: 12 , color: '#575757' ,textAlign: 'left'
    }

});




export default CarSalesOfferPDFCreator;



{/* <Page size="A4" style={{flexDirection: 'column', flex: 1, backgroundColor: 'white', alignItems:'center', position: 'relative'}}>
<Header></Header>
<View style={{width: 500, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20, paddingTop: 20}}>
    <Text style={{fontFamily: 'Artifakt-Black', fontSize: 14 , color: '#575757' ,textAlign: 'left'}}>
        {
            "SAYIN " + props.Customer.name + " " + props.Customer.surname 
        }
    </Text>
    <View style={{height: 10}}></View>
    <Text style={{fontFamily: 'Artifakt', fontSize: 12 , color: '#575757' ,textAlign: 'justify'}}>
        {
            "Bilen Otomotiv olarak, 1979 yılından bu yana Mersin ve Adana'da otomotiv sektöründe hizmet vermekteyiz. "+ 
            "Fiat, Alfa Romeo ve Jeep markalarının yetkili satıcısı ve servisi olarak geniş bir "+ 
            "hizmet yelpazesi sunuyoruz. Modern ve güncel model yılındaki araçlarımızla, yeni ve ikinci el araç " + 
            "satışları, kapsamlı servis hizmetleri, yedek parça tedariği ve araç kiralama hizmetleri sağlamaktayız." + 
            "Müşteri memnuniyetini öncelikli tutarak, satış sonrası destek ve periyodik bakım hizmetlerimizle " + 
            "en iyi deneyimi sunmayı hedefliyoruz."
        }
    </Text>
    <View style={{height: 10}}></View>
    <Text style={{fontFamily: 'Artifakt-Black', fontSize: 12 , color: '#575757' ,textAlign: 'justify'}}>
        {
                "Bilen Otomotiv olarak, " + props.car.BRAND_NAME + " " + props.car.MODEL_NAME + " " + props.car.PACKAGE_NAME + " aracınız için hazırlamış olduğumuz teklif dosyası ekte sunulmuştur. \n\n" +
                "Detaylı bilgi ve destek için iletişim kanallarımız üzerinden bizimle doğrudan iletişime geçebilirsiniz."
        }
    </Text>
    <View style={{marginTop : 20 , width: 500, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
        <SignatureLabel signature={props.signature} lang={props.LANG}></SignatureLabel>
    </View>

</View>
<View style={{flex: 1, padding : 2}}></View>

<Footer></Footer>
</Page> */}

