  
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { View } from 'react-native';
import { TagPicker ,Form} from 'rsuite'; 


const TagPickerFilter = forwardRef((props,ref) => {

    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      filter: () => {
        if(value != null || value != "" || value.length == 0){
          props.refresh();
          return filter_data_multiple();
          
        }
      },
      clear: () => {
        setValue([]);
      },
      types: () => {
        
        getTypes(props.data);
      }
      
    }))

    const[value,setValue] = useState([]);

    useEffect(()=> {
      getTypes(props.data);
    },[])
    useEffect(() => {

      if((value == null || value == "" ) && props.isFilterApplied){
          props.reset();
          props.filter();
         
      }if((value != null && value != "" ) ){
          // filter_data_multiple();
          props.filter();
      }
    },[value])

    useEffect(() => {
      getTypes(props.data);
    },[props.data])

    const [types , setTypes] = useState([]);
    function getTypes(input){
      var result = [];
      var added = [];
      
      if(input[0][props.tableHeadName] != null){
        result.push({"label": input[0][props.tableHeadName] , "value": input[0][props.tableHeadName] })
      }
      added.push( input[0][props.tableHeadName]);
      input.forEach(element => {
            if(!added.includes(element[props.tableHeadName]) && element[props.tableHeadName] != null ){
                result.push({"label": element[props.tableHeadName] , "value":element[props.tableHeadName] });
                added.push(element[props.tableHeadName] );
        }
      })
      result.sort(function(first, second) {
        return first.value - second.value;
      })
      setTypes(result);
    }
    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => checkContains(item,e)))
    }

    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }

    const filter_data_multiple = () =>{

        var columns = []
        var inputs = [];

      
        if(value != "" && value != null && value.length > 0){
          columns.push(props.tableHeadName)
          inputs = value;
        }
    
        if(columns.length > 0){
            let temps = []
            for(let c = 0 ; c< columns.length; c++){
              let temp = [];
                for(let i = 0; i< props.data.length ; i++){
                
                    let tempstr = String(props.data[i][columns[c]])
                    tempstr = tempstr.toLowerCase();
                    inputs.forEach(element => {
                      var temp_element = String(element).toLowerCase();
                        if(tempstr.includes(temp_element)){
                        temp.push(props.data[i]);
                      }
                    });
                
                }
            //console.log(" temp " + c +temp);
            temps.push(temp);
            }
          // console.log(temps);
            let first =  temps.shift();
            let final = intersect(first,temps);
            // props.setFiltered(final);
            // props.setApplied(true);
            return final
        }
        return props.data
    }


    const item_style = {
      standard: { width : 200, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
  }

    return (
      <View style={{marginVertical: 10}}>
      <Form>
        <Form.Group>
            {/* <Form.ControlLabel> {props.tableHeadName} </Form.ControlLabel> */}
            <TagPicker value={value} data = {types} style={props.item_style} onChange={ (value) => {setValue(value)}} name= {props.tableHeadName} /> 
        </Form.Group>
      </Form>
    </View>
    )


  })

  export default TagPickerFilter;