import React, { useEffect,useState,useCallback } from 'react';
import {
    useToaster,
    Schema,
    Message,
    Button,
    List,
    IconButton,
    SelectPicker,
    InputGroup,
    MaskedInput,
    Table,
    Input,
    Drawer,
    RadioGroup,
    Radio,
    Panel,
    Pagination
} from 'rsuite';
import { Image,View, Text, StyleSheet,TouchableOpacity} from 'react-native';
import { useDropdownData } from '../../../hooks/useDropdownData';
import { useUserToken } from '../../../hooks/useUserToken';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createNumberMask } from 'text-mask-addons';
import axios from 'axios';
import configdata from '../../../config/config.json';
import RentContractView from './RentContractView';
import RentContractTemplate from './RentContractTemplate';
import { ScrollView } from 'react-native-web';
import  CustomText  from '../../../Components/CustomText';
import {useCarImages} from "../../../hooks/useCarImages";

const RentContractOffers = () => {

    const [offerData, setOfferData] = useState([]);
    const [choosenOfferData, setChoosenOfferData] = useState({});
    const [carDetails, setCarDetails] = useState([]);
    const [choosenCarDetails, setChoosenCarDetails] = useState([]);

    const {fetchIdToken} = useUserToken();
    const [offerDrawer, setOfferDrawer] = useState(false);

    async function getData(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "type" : "GET_RENT_INFO",
            "ENV" : configdata.APP_ENV
        }

        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            setOfferData(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    }



    async function getDetailsCars(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}`
        }
        const body = {
            "type" : "GET_RENT_INFO_CARS",
            "ENV" : configdata.APP_ENV
        }

        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            setCarDetails(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    }





    useEffect(() => {
        getData();
        getDetailsCars();

    }
    ,[]);

    useEffect(() => {
        if(choosenCarDetails.length > 0 && choosenOfferData != {})
         setOfferDrawer(true);
    },[choosenCarDetails,choosenOfferData]);

    function newOpen(){
        setChoosenCarDetails([]);
        setChoosenOfferData({});
        setOfferDrawer(true);
    }

    function handleClose(){
        setOfferDrawer(false);
    }

    function getChoosenCars(input){
       console.log(input); 
       return carDetails.filter((item) => item.RENT_OFFER_ID == input);
    }


    function getParsedCars(){
      
        console.log(choosenCarDetails);
        let cars = [];
        choosenCarDetails.forEach((item,index) => {
            cars.push({
                'ID' : index,
                'OZET': item.BRAND + ' ' + item.MODEL + ' ' + item.PACKAGE ,
                'VITES_YAKIT' : item.FUEL_TRANSMISSION,
                'FIYAT' : item.RENT_PRICE,
                'RENT_TIME': item.RENT_TIME,
                'KM' : item.KM_ALT == item.KM_UST ? item.KM_ALT  + " KM " : item.KM_ALT + ' - ' + item.KM_UST + '  KM',
                'CAR_BODY_STYLE' : item.CAR_BODY_STYLE,
                'KM_LIMIT' : item.KM_LIMIT,
                'KM_FIYAT' : item.KM_LIMIT_PRICE,
                'MODEL_YILI' : item.MODEL_YEAR,
                'name' :( item.BRAND + '_' + item.MODEL + '_' + item.CAR_BODY_STYLE + "_" + item.PACKAGE ).toUpperCase(),
                'image' : item.IMAGE,
                'BMP' : [item.BRAND,item.MODEL,item.PACKAGE],
                'BMP_ID': [item.BRAND_ID,item.MODEL_ID,item.PACKAGE_ID],
                'class' : item.CAR_CLASS,
                'CAR_BODY_STYLE_ID' : item.CAR_BODY_STYLE_ID,
                "KASKO_PRICE" : item.KASKO_PRICE,
                "TIRE_PRICE" : item.WINTER_TIRE_PRICE,
                "KM_ALT": item.KM_ALT,
                "KM_UST": item.KM_UST,

            });
        });
        return cars;
    }

    function getCustomer(){
        console.log(choosenOfferData);
        let customer = {
                'ID' : choosenOfferData.ID,
                'CLIENT' : choosenOfferData.CLIENT,
                'CITY' : choosenOfferData.CITY,
                'COMPANY' : choosenOfferData.COMPANY,
                'OFFER_DATE' : choosenOfferData.OFFER_DATE,
                'OFFER_TIME_LIMIT' : choosenOfferData.OFFER_TIME_LIMIT,
                'WINTER_TIRE_COND' : choosenOfferData.WINTER_TIRE_COND == "VAR" ? true : false,
                
                // 'OFFER_TIME_LIMIT' : {"label" :choosenOfferData.OFFER_TIME_LIMIT + " Gün" , "value" : choosenOfferData.OFFER_TIME_LIMIT}
        }
        return customer;
    }

    function getOfferInfo(){
        let offerInfo = {
            'ID' : choosenOfferData.ID,
            'RENT_NO' : choosenOfferData.RENT_NO,
            'OFFER_DATE' : choosenOfferData.OFFER_DATE,
            'OFFER_TIME_LIMIT' : choosenOfferData.OFFER_TIME_LIMIT,
            'WINTER_TIRE_COND' : choosenOfferData.WINTER_TIRE_COND == "VAR" ? true : false,
            'SIGNED_EMAIL' : choosenOfferData.SIGNED_EMAIL,
            "IMM_PRICE" : choosenOfferData.IMM_PRICE,
        }
        return offerInfo;
    }

    const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

    const handleExpanded = (rowData, dataKey) => {
      let open = false;
      const nextExpandedRowKeys = [];
  
      expandedRowKeys.forEach(key => {
        if (key === rowData["ID"]) {
          open = true;
        } else {
          nextExpandedRowKeys.push(key);
        }
      });
  
      if (!open) {
        nextExpandedRowKeys.push(rowData["ID"]);
      }
  
      setExpandedRowKeys(nextExpandedRowKeys);
    };

    const renderRowExpanded = rowData => {
        console.log(rowData);
        return (
          <ScrollView>
            <List>
                {getChoosenCars(rowData["ID"]).map((item) => {
                    return (
                        <List.Item>
                            <CarLabel car={item}></CarLabel>
                        </List.Item>
                    );
                }
                )}
            </List>
          </ScrollView>
        );
    };

    const [page, setPage] = useState(1); // Current page
    const [pageSize, setPageSize] = useState(15); // Items per page

    const paginatedData = offerData.slice((page - 1) * pageSize, page * pageSize);

    const [search, setSearch] = useState('');

    // RENT_NO , COMPANY , CLIENT , CITY
    // NO CASE SENSITIVE
    useEffect(() => {
        if(search != ''){
            const filteredData = offerData.filter((item) => {
                return item.RENT_NO.toLowerCase().includes(search.toLowerCase()) || item.COMPANY.toLowerCase().includes(search.toLowerCase()) || item.CLIENT.toLowerCase().includes(search.toLowerCase()) || item.CITY.toLowerCase().includes(search.toLowerCase());
            });
            setOfferData(filteredData);
        }
        else{
            getData();
        }
    },[search]);



    return (
        <View style={{padding: 20}}>
            <Drawer open={offerDrawer} onClose={handleClose} size='full' backdrop= 'static'>
                <Drawer.Header>
                    <Drawer.Title>{getOfferInfo().RENT_NO} </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body style={{ backgroundColor: '#F2F2F2'}}>
                    <RentContractTemplate cars = {getParsedCars()} customer = {getCustomer()} offerInfo = {getOfferInfo()}   ></RentContractTemplate>
                </Drawer.Body>
            </Drawer>
            <Panel shaded style={{backgroundColor: 'white', marginBottom :20 }}>
                <View style={{ justifyContent: 'flex-start', flexDirection: 'row'}}>
                    <IconButton  icon = {<Icon style={{backgroundColor: '#5E6EED'}} ><FontAwesomeIcon icon={"plus"}></FontAwesomeIcon></Icon>} style={{backgroundColor: '#5E6EED', color: 'white'}} onClick={newOpen}>
                    <CustomText style={{color:'white'}}>{"Yeni Teklif Dökümanı Oluştur"}</CustomText> </IconButton>
                    <View style={{width: 20}}> </View>
                    <Input placeholder={"Ara"}  onChange={ (value) => setSearch(value)} ></Input>
                </View>
            </Panel>
            <Panel shaded style={{backgroundColor: 'white'}}>
                <Table
                    height={800}
                    data = {paginatedData}
                    bordered
                    fluid
                    rowKey={"ID"}
                    onRowClick={data => {
                        console.log(data);
                      }}
                    rowExpandedHeight={500}
                    renderRowExpanded={renderRowExpanded}
                    expandedRowKeys={expandedRowKeys}
                >
                    <Table.Column width={70} align="center">
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <ExpandCell dataKey="ID" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
                    </Table.Column>
                    <Table.Column flexGrow={1} align="center" fixed>
                        <Table.HeaderCell>İşlem</Table.HeaderCell>
                        <Table.Cell>
                            {rowData => {
                                function handleAction(){
                                    setChoosenCarDetails(getChoosenCars(rowData.ID));

                                    console.log(rowData);
                                    setChoosenOfferData(rowData);
                                }
                                return (
                                    <span>
                                        <IconButton icon={<Icon><FontAwesomeIcon icon={"edit"}></FontAwesomeIcon></Icon>} onClick={handleAction} />
                                    </span>
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column  flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Teklif No </Table.HeaderCell>
                            <Table.Cell dataKey="RENT_NO" />
                    </Table.Column>
                    <Table.Column  flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Şirket </Table.HeaderCell>
                            <Table.Cell dataKey="COMPANY" />
                    </Table.Column>                    
                    <Table.Column  flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Yetkili Kişi </Table.HeaderCell>
                            <Table.Cell dataKey="CLIENT" />
                    </Table.Column>
                    <Table.Column flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Şehir </Table.HeaderCell>
                            <Table.Cell dataKey="CITY" />
                    </Table.Column>
                    <Table.Column flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Sözleşme Tarihi </Table.HeaderCell>
                            <Table.Cell dataKey="OFFER_DATE" />
                    </Table.Column>
                    <Table.Column flexGrow={1}  align="center" fixed>
                            <Table.HeaderCell> Teklif Geçerlilik Süresi </Table.HeaderCell>
                            <Table.Cell dataKey="OFFER_TIME_LIMIT" />
                    </Table.Column>

                </Table>
                <Pagination
                    activePage={page}
                    displayLength={pageSize}
                    total={offerData.length}
                    onChangePage={setPage}
                    onChangeLength={setPageSize}
                    showLength
                ></Pagination>
            </Panel>
        </View>
    );

}



export default RentContractOffers;


const CarLabel = (props) => {

    return (
        <View style={{flexDirection: 'row', height: 120, alignItems: 'center', padding: 3 }}>
            <Image {...props.car} style={{height: 50, width: 200}} resizeMode='contain' source={images[props.car.IMAGE]} />
            <View style ={{flex: 1 }}>
                <Text  style={styles.label} > {props.car.BRAND + " " + props.car.MODEL + " " + props.car.PACKAGE+ " " + props.car.CAR_BODY_STYLE} </Text>
                <Text  style={styles.label} > {props.car.VITES_YAKIT} </Text>
                <Text  style={styles.label} > {props.car.MODEL_YILI} </Text>
                <Text  style={styles.label} > {props.car.KM} </Text>
            </View>
            <View style ={{flex: 1 }}>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Kiralama Süresi : </Text>
                    <Text  style={styles.label} >  {props.car.RENT_TIME + " AY"} </Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Aylık Km Limiti : </Text>
                    <Text style={styles.label} >  {props.car.KM_LIMIT} </Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start', borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Km Aşım Fiyatı : </Text>
                    <Text style={styles.label} >  {props.car.KM_LIMIT_PRICE} </Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Aylık Kira Bedeli : </Text>
                    <Text style={styles.label} >  {props.car.RENT_PRICE} </Text>
                </View>
            </View>
          
        </View>
    );
}

const styles = StyleSheet.create({
    label: {
        textAlign: 'left',
        padding: 5,
    },
    labelBold: {
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    labelHeader:{
        textAlign: 'right',
        padding: 5,
    },
    labelWhite: {
        textAlign: 'center',
        padding: 5,
        color: 'white',
    },
    standard: {
        width: 160,
    },
    dropDownGroupBack : {
        borderRadius: 12,flexDirection: 'row',height: 40, paddingHorizontal : 0, justifyContent: 'center', alignItems: 'center',backgroundColor:'#F2FFFB'}

});


const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Table.Cell {...props} style={{ padding: 5 }}>
      <IconButton
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          expandedRowKeys.some(key => key === rowData[dataKey]) ? (
            <Icon><FontAwesomeIcon icon={"arrow-down"}></FontAwesomeIcon></Icon>

          ) : (
            <Icon><FontAwesomeIcon icon={"arrow-right"}></FontAwesomeIcon></Icon>
          )
        }
      />
    </Table.Cell>
);


const images = useCarImages().returnCarImages();
        