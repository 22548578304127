import { Text, StyleSheet, View,TouchableOpacity } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons';
const CustomCheckBox = (props) => {

    return (
      <View style={{marginHorizontal: props.horizontalMargin != null ? props.horizontalMargin : 0, flexDirection: 'row', alignItems: 'center'}}>
        <Text style = {{flex: 1 , textAlign: 'center',marginHorizontal: props.horizontalMargin != null ? props.horizontalMargin : 0}}>{props.title}</Text>
        <TouchableOpacity
          style={{marginLeft: 0 , justifyContent: 'center',alignItems: 'center'}} 
          onPress = {props.onPress }
        >
        <MaterialCommunityIcons size = {20} name = {props.condition?  'checkbox-marked' : 'checkbox-blank'}  color = {'#00589B'} ></MaterialCommunityIcons>
        </TouchableOpacity>
      </View>
    )
  
}

const styles = StyleSheet.create({})

export default CustomCheckBox;