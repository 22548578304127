import { Text, View } from 'react-native'
import React, { PureComponent, useState, useEffect, useImperativeHandle, forwardRef ,createRef,useRef  } from 'react'
import FolderManagementSystem from '../../Components/FolderManagementSystem'
import JSZip, { file } from 'jszip';
import { FileUploader } from 'react-drag-drop-files';
import { Button, IconButton, List } from 'rsuite';
import {useUserToken} from '../../hooks/useUserToken';
import configdata from '../../config/config.json';
import axios from 'axios';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
;
import Amplify, {Auth, Storage} from 'aws-amplify';


Storage.configure({
    AWSS3: {
      bucket: "bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev",
      region: 'eu-central-1',
    },
});

const  MultipleFileUploadScreen = () => {
    

    const [uploadType, setUploadType] = useState('ZIP');
    const [files, setFiles] = useState([]); // This will contain the uploaded files
    const { user ,fetchIdToken } = useUserToken();

    const handleZipUpload = async (zipFile) => {
        // Create JSZip instance
        const zip = new JSZip();
        // Read the uploaded zip file
        await zip.loadAsync(zipFile);
        
        const allFiles = [];
        const promises = [];
      
        // Iterate through each file in the zip
        zip.forEach((relativePath, zipEntry) => {
          // Ignore the macOS metadata files and directories
          if (relativePath.startsWith('__MACOSX/') || relativePath.startsWith('._') || relativePath.includes('.DS_Store') || relativePath.includes('.zip') || !relativePath.includes(".pdf")) return;
          
          console.log(relativePath);
          // If it's a directory, ignore
          if (zipEntry.dir) return

            const promise = zipEntry.async('blob').then(fileBlob => {
            // Create a File object from Blob
            const file = new File([fileBlob], zipEntry.name, {
              type: determineFileType(zipEntry.name),
            });
            allFiles.push(file);
          });
          promises.push(promise);
          
      
          // Extract each file as a Blob and push to promises array
          
        });
      
        // Wait for all promises to be resolved
        await Promise.all(promises);

        getCarAssociated([...files, ...allFiles]);
        // Update state with all the files
        setFiles([...files, ...allFiles]);
        updateFileItemRefs([...files, ...allFiles]);
      };
      
      const [fileItemRefs, setFileRefs] = useState([]);


    //   useEffect(() => {
    //     // Create a ref for each file item
    //     fileItemRefs.current = files.map((_, i) => fileItemRefs.current[i] ?? createRef());
    //   }, [files]);

      const updateFileItemRefs = (newFiles) => {
        setFileRefs(newFiles.map(() => createRef()));
      };
    
      const handleUpload = () => {
          console.log("uploading");
          fileItemRefs.forEach((fileItemRef) => {
              fileItemRef.current.handleUpload();
          })
      }
      

    function handleChange(file) {
        console.log(file);
        setFiles([...files, file]);
    }

    // useEffect(() => {
    //     if(files.length > 0) {
    //         getCarAssociated();
    //     }
    // }, [files]);

    function determineFileType(filename) {
        const extension = filename.split('.').pop().toLowerCase();
      
        switch (extension) {
          case 'pdf':
            return 'application/pdf';
          case 'png':
            return 'image/png';
          case 'jpg':
          case 'jpeg':
            return 'image/jpeg';
          case 'txt':
            return 'text/plain';
          // Add more cases as needed for your application
          default:
            // Default or unknown type; you may want to handle this differently
            return 'application/octet-stream';
        }
      }


    const [carsAssociated, setCarsAssociated] = useState([]); // This will contain the uploaded files
    const [idTokenTemp, setIdTokenTemp] = useState("");
    async function getCarAssociated(input) {
        console.log(input);
        const idToken = await fetchIdToken();
        setIdTokenTemp(idToken);
        const api = configdata.API_BASE + '/cardata'
        const headers = {
            "Authorization" : `Bearer ${idToken}`,
        }

        let codes = [];
            input.forEach((file) => {
            if(file.name.includes('/')){
                codes.push(file.name.split('/')[1].split('.')[0].split('_')[0]);
            }else{
                codes.push(file.name.split('.')[0].split('_')[0]);

            }
        })
        console.log(codes);

        const mainBody ={
            "ENV" :configdata.APP_ENV ,  
            "type": "GET_MULTIPLE_CAR_DATA_WITH_SPEC" ,
            "DATA": codes,
        }

        axios.post(api,mainBody,{headers}).then((response) => {
            console.log(response);
            setCarsAssociated(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        console.log(carsAssociated);
    }
    , [carsAssociated]);

    return (
      <View style={{backgroundColor : '#F2FFFB', width: '100%', padding: 50}}>
        <View style={{backgroundColor: 'white', width: '100%' , height: 100 , alignItems: 'center', justifyContent:'center',borderRadius: 5}}>
            <FileUploader handleChange={uploadType === 'ZIP' ? handleZipUpload : handleChange} />
        </View>
        <View>
            <Text style={{fontSize: 12, textAlign: 'center',width: '100%',  fontWeight: '400', marginVertical: 2}}>{"ZİP DOSYASI İÇİNDEKİ PDF İSİM ŞABLONU = <ŞASE>_<DOSYA TÜRÜ>.pdf ,NM426300006B34541_RUHSAT.pdf"}</Text>
        </View>

        {
        (files.length > 0 && carsAssociated.length > 0) &&
        <View style ={{backgroundColor : 'white', marginVertical: 50 , width: '100%' , alignItems: 'center', borderRadius: 5}}>
            <List style={{width: '100%'}}>
                    <List.Item style={{backgroundColor: '#fafafa'}} index={-1}>
                        <ListHeader></ListHeader>
                    </List.Item>
                {files.map((file, index) => ( 
                    <List.Item style={{backgroundColor: index % 2 == 0 ? 'white' : '#fafafa'}} key={index} index={index}>
                        <FileItem ref={fileItemRefs[index]} idToken = {idTokenTemp} file={file} index = {index} carsAssociated = {carsAssociated}  />
                    </List.Item>
                ))}
            </List>
            <View style={{height: 10}}></View>
            <IconButton onClick={handleUpload} style={{width: 120}} icon = {<Icon><FontAwesomeIcon icon = {'upload'}></FontAwesomeIcon></Icon>}>
                Yükle
            </IconButton>
            <View style={{height: 10}}></View>


        </View>
        }
        
      </View>
    )
  
}


const FileItem = forwardRef((props,ref) => {
    const { user, idToken } = useUserToken();
    
    const parseFileName = () => {
        // console.log(file.name);
        if(props.file.name.includes('/')){
            const split = props.file.name.split('/');
            const name = split[1];
            const fileDetails = name.split('_');
            return fileDetails[0]
        }else{
            const split = props.file.name.split('.');
            const name = split[0];
            const fileDetails = name.split('_');
            return fileDetails[0]
        }

    }

    const getCarAssociated = (input) => {

        let result = "";
        props.carsAssociated.forEach((car) => {
            if(car.ŞASİ_NO === input) {
                result =  car;
            }
        })
        // console.log(props.file.name + " : " +result);
        return result;
    }

    const styles = {
        listColumn: {
            display: 'inline-block',
            width: 'calc(100% / 4)',
            padding: '0 10px',
            textAlign: 'center',

            },
        Status: {
            display: 'inline-block',
            width: 'calc(100% / 4)',
            padding: '0 10px',
            textAlign: 'center',

            }
    }


    const [uploadProcessText, setUploadProcessText] =  useState("");
    const [toggleUploadProcess, setToggleUploadProcess] = useState(false);
    const [refresh,setRefresh] = useState(true);

    useImperativeHandle(ref, () => ({
        handleUpload : () => {
            if(getCarAssociated(parseFileName()) != undefined && getCarAssociated(parseFileName()) != "" ){
                console.log("uploading" + props.file.name);
                loadFile(props.file);
            }
        }
    }));

    async function loadFile (file){

        const name =  "" + file.name.toString();
        console.log(name.includes(".png"));
        var content_type = "application/pdf";
        var testCon = "";

        let CarID = getCarAssociated(parseFileName()).ID;
        if(configdata.APP_ENV == "DEV"){
            testCon = "TESTDB_"
        }
        let target_location = ""
        if(name.includes(".png")){
            target_location = testCon + "CAR_" + CarID + "/PNGs/" + file.name
            content_type = "image/png";
        }
        if(name.includes(".jpg")){
            target_location =testCon + "CAR_" +  CarID + "/JPEGs/" + file.name
            content_type = "image/jpeg";
        }
        else if(name.includes(".pdf")){

            target_location =testCon + "CAR_" + CarID + "/CAR_LICENSE/PDFs/"+ file.name
            content_type = "application/pdf";
        }
        else if(name.includes(".docx")){
            target_location =testCon + "CAR_" +CarID +  "/DOCs/"+ file.name
            content_type = "application/msword";
        }
        else if(name.includes(".xlsx")){
            target_location =testCon + "CAR_" + CarID+  "/DOCs/"+ file.name
            content_type = "application/excel";
        }
        else if(name.includes(".csv")){
            target_location = testCon + "CAR_" + CarID + "/DOCs/"+ file.name
            content_type = "application/excel";
        }

        try {

            await Storage.put(target_location, file, { 
                contentType: content_type, // contentType is optional
                progressCallback(progress) {
                    setToggleUploadProcess(true);
                    let value =((progress.loaded/progress.total) * 100) | 0
                    console.log(`Uploaded:` + value.toString());
                    setUploadProcessText(value.toString());
                    
                    if(progress.loaded/progress.total == 1){
                        setToggleUploadProcess(false);
                    }
              },
            }).then((response) => {
                console.log(response);
                sendData(response.key,file.name);
            });
            // getList();
            setRefresh(!refresh);
          } catch (error) {
            console.log("Error uploading file: ", error);
            setUploadProcessText("Upload Not Completed : " + error);
          }
    }

    const {  fetchIdToken } = useUserToken();
    async function sendData (s3Key,fileName){

        const idToken = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        
        const headers = {
            "Authorization" : `Bearer ${idToken}`,
        }

        const body = {

            "ENV" :configdata.APP_ENV , 
            "type" : "FILE_SET",
            "category" : "CAR_LICENSE",
            "FILE_NAME" : fileName,
            "S3_KEY" : s3Key,
            "CAR_ID": getCarAssociated(parseFileName()).ID,
            "OBJECT_ID" : getCarAssociated(parseFileName()).ID,
        }

        axios.post(api,body,{headers}
            ).then((response) => {
                console.log(response)
                if(response.data["errorMessage"] != null ){
                setUploadProcessText("Yükleme Tamamlanamadı !");

                }else{
                    setUploadProcessText("Yükleme Tamamlandı !");

                }
              
            })
            .catch((error) => {
                console.log(error)
                setUploadProcessText("Yükleme Tamamlanamadı !");
            });
    }

    return (
        <View style={{flexDirection: 'row',backgroundColor: props.index % 2 == 0 ? 'white' : '#fafafa', width: '100%' , height: 60 , alignItems: 'center', justifyContent:'center',borderRadius: 5}}>
            <View style = {styles.listColumn}>
                <Text>{props.file.name.includes('/') ? props.file.name.split('/')[1] : props.file.name}</Text>
            </View>
            <View style = {styles.listColumn}>
                <Text>{parseFileName()}</Text>
            </View>
            <View style = {styles.listColumn}>
                <Text>{getCarAssociated(parseFileName()) == undefined ? "" : getCarAssociated(parseFileName()).ARAÇ}</Text>
            </View>
            <View style = {styles.Status}>

                <Text>{ (getCarAssociated(parseFileName()) == undefined || getCarAssociated(parseFileName()) =="" )? "ARAÇ BULUNAMADI" : uploadProcessText != "" ? uploadProcessText : "YÜKLENEBİLİR"}</Text>
            </View>
            
            
        </View>
    )

});

const ListHeader = () => {
    const styles = {
        listColumn: {
            display: 'inline-block',
            width: 'calc(100% / 4)',
            padding: '0 10px',
            textAlign: 'center',
            },
        textStyles: {
            fontWeight: 'bold',
            fontSize: 16
        }
    }
    return (
        <View style={{flexDirection: 'row',backgroundColor: '#fafafa', width: '100%' , height: 30 , alignItems: 'center', justifyContent:'center',borderRadius: 5}}>
            <View style = {styles.listColumn}>
                <Text style={styles.textStyles} >Dosya İsmi</Text>
            </View>
            <View style = {styles.listColumn}>
                <Text style={styles.textStyles} > Özel Kod </Text>
            </View>
            <View style = {styles.listColumn}>
                <Text style={styles.textStyles} > Araç </Text>
            </View>
            <View style = {styles.listColumn}>
                <Text style={styles.textStyles} >Durum</Text>
            </View>
            
            
        </View>
    )
}

export default MultipleFileUploadScreen