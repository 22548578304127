import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Dimensions, StyleSheet, SafeAreaView } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import CustomText from './CustomText';
import { IconButton } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



// Set the worker URL to the unpkg CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDF = (props) => {
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const safeAreaBottomInset = 20;


  useEffect(() => {
    const onChange = ({ window }) => {
      setDimensions(window);
    };

    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    props.setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const [scale, setScale] = useState(0.5);

  function zoomOut() {
    if(scale > 0.1){
      setScale(scale - 0.1);
    }
  }

  function zoomIn() {
    if(scale < 1.5){
      setScale(scale + 0.1);
    }
  }


  return (
    <SafeAreaView style={styles.safeArea}>
    <View style={styles.container}>
      <View style={styles.pdfContainer}>
      {props.url ? (
        <>
          <Document
            file={props.url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => console.log("Error loading document:", error)}
          >
            <Page
              pageNumber={props.pageNumber}
              scale={props.scale}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </>
      ) : (
        <Text>PDF Yükleniyor...</Text>
      )}
    </View>

    </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'transparent', // Adjust as needed
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdfContainer: {
    flex: 1,
    // Other styles as needed
  },
  controlsContainer: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 300,
    padding: 10,
    zIndex: 1,
    borderRadius: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.85)', // Adjust opacity as needed
    // bottom is set dynamically in the component
  },
  iconButton:{
    backgroundColor: '#4e4e4e',
  }
});

export default PDF;



// <TouchableOpacity style = {{ margin: 8,backgroundColor: '#00589B', width: 30, height:100, padding: 5,borderRadius: 7}}
// onPress = {() =>previousPage()}  disabled= {pageNumber <= 1}  
// >
// <MaterialCommunityIcons name='arrow-left' color={'white'} size= {20}></MaterialCommunityIcons>
// </TouchableOpacity>
// <TouchableOpacity style = {{ margin: 8 ,backgroundColor: '#00589B', width: 30, height:100 , padding: 5, borderRadius:7}}
// onPress = {() =>nextPage() }  disabled= {pageNumber  >= numPages} 
// >
// <MaterialCommunityIcons name='arrow-right' color={'white'} size= {20}></MaterialCommunityIcons>
// </TouchableOpacity>
// </View>